import React, { Component } from 'react'
import { connect } from 'react-redux'
//import LogForm from './LogForm'
import LogFeelingsAndNeeds from './LogFeelingsAndNeeds'
import { editLog } from '../../store/actions/logActions'
import { Redirect } from 'react-router-dom'


//This creates JSX for the user to create a new log

class EditLog extends Component {
    state = {
        //log passed through prop location's state parameter
        //as set in EditLogButton: call: this.props.history.push
        ...this.props.location.state
    }

    static defaultProps = {
        returnURL: '/mylogs',
    }

    //need this to pass additional arguments to editLog
    editLogWrapper = (log) => {
        //log returned from Log component only has the log properties, it no longer has the 'id' property. 
        //We can access id because we passed it in through props.location.state
        const auth = this.props.auth;
        //editLog is action creator defined in logActions.js
        console.log('log', log)
        console.log('current auth', auth)

        const id = this.props.location.state.id
        this.props.editLog(id, log, auth)
    }

    render() {
        const { auth, location } = this.props;

        const log = location.state.log
        const showPos = location.state.showPos || false
        const showNeg = location.state.showNeg || false
        const currentStep = location.state.currentStep || 1

        const returnURL = location.state.prevPath
        console.log(log)
        if (!auth.uid) return <Redirect to='/signin' />
        return (
            <div>
                {/*<LogForm callbackFunc={this.editLogWrapper} log={log} formTitle='Edit Log' returnURL={returnURL} />*/}
                <LogFeelingsAndNeeds callbackFunc={this.editLogWrapper} log={log} showPos={showPos} showNeg={showNeg} currentStep={currentStep} returnURL={returnURL} />
            </div>
        )
    }
}




const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,

    }
}

//NOTE: editLog is now a variable in props and its value is a function that dispatches
//the action-creator 'editLog' function defined in logActions.js, w
//editLog is an action-creator which instead of returning an action object (as done when we did not need asynchronous calls)
//now returns a function that makes an asynch call and then calling dispatch.
//'log' below is just a variable in the arrow function
const mapDispatchToProps = (dispatch) => {
    return {
        editLog: (id, log, auth) => dispatch(editLog(id, log, auth)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EditLog)