import React, { Component } from 'react'
import { firebaseAuth } from '../../config/fbConfig'
import { Form, Button } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import CheckDeleteModal from '../../Utilities/CheckDeleteModal'

class DeleteAccount extends Component {
    state = {
        show: false,
        submitError: null
    }

    showModal = (e) => {
        this.setState({
            show: true
        })
        e.preventDefault()
        e.stopPropagation()


    }

    closeModal = (e) => {
        e.stopPropagation()
        e.preventDefault()
        this.setState({ show: false });

    }


    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();
        var user = firebaseAuth.currentUser;

        user.delete().then(() => {
            alert("You have successfully deleted your account. We will log you out and delete your personal records")
            setTimeout(() => { return <Redirect to='/signin' /> }, 5000)
            // User deleted.
        }).catch((error) => {
            // An error happened.
            this.setState({ submitError: error.message })
        });
    }



    render() {
        return (

            <Form onSubmit={this.showModal}>

                <div>
                    <Button type="submit" > Delete Account</Button>
                    <CheckDeleteModal show={this.state.show} closeModal={this.closeModal} handleYes={this.handleSubmit} text="Are you sure you want to Permanently Delete your account?" />
                    <p className='text-danger'>{this.state.submitError}</p>
                </div>
            </Form>


        )
    }
}

export default DeleteAccount