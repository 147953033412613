import React from 'react'
import { Form, Col, Container, Row } from 'react-bootstrap'
import TermsAndConditionsText from './TermsAndConditionsText'
import './PublicPages.css'

function TermsAndConditions({ handleChange, checked }) {
    return (

        <Container>
            <Row>
                <Col>
                    <TermsAndConditionsText />
                    <br></br>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="terms" as={Row} >
                        <Form.Check
                            required
                            type="checkbox"
                            label="I have read the Terms of Use and agree"
                            onChange={handleChange}
                            checked={checked ? true : false}
                        />
                    </Form.Group >
                </Col>
            </Row>

        </Container>

    )
}

export default TermsAndConditions
