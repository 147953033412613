import React from 'react'
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap'

import TrainingPageLayout from './TrainingPageLayout'


const contentList = [
    {
        title: "Welcome to Mastering Difficult Conversations",
        name: "Welcome",
        introText: "Welcome to our course on Mastering Difficult Conversations!  This is a 5-day course, which takes less than 10 minutes a day. It teaches a simple, practical, science-based  method that's easy to apply.  Each lesson will be delivered via email and comes with a short video as well as a quiz for you to check your learning. Watch our introductory video below.",
        quizTitle: "",
        videoLink: "https://vimeo.com/709103152",
        quizLink: null
    },
    {
        title: "Lesson 1: Aspirations",
        name: "Lesson 1: Aspirations",
        quizTitle: "Lesson 1 Quiz: Check Your Learning",
        introText: "The first step to approaching a difficult conversation is to set your aspirations for the situation. Watch the video below on setting aspirations and complete the short quiz below.",
        videoLink: "https://vimeo.com/709104631",
        quizLink: "https://qmulsciences.qualtrics.com/jfe/form/SV_898lke6w9H0PKtw"
    },
    {
        title: "Lesson 2: Objective Descriptions Part 1",
        name: "Lesson 2: Objective Descriptions Part 1",
        quizTitle: "Lesson 2 Quiz: Check Your Learning",
        introText: "The most powerful skill you can develop for difficult conversations is to master objective descriptions of situations. Today's lesson is Part 1 out of 2 on how to use objective language. We'll also introduce two common objectivity pitfalls. Watch the video and then complete the short quiz below.",
        videoLink: "https://vimeo.com/735581591",
        quizLink: "https://qmulsciences.qualtrics.com/jfe/form/SV_3XcELTzW6khjIoK",
    },
    {
        title: "Lesson 3: Objective Descriptions Part 2",
        name: "Lesson 3: Objective Descriptions Part 2",
        quizTitle: "Lesson 3 Quiz: Check Your Learning",
        introText: "Congratulations, you're almost half way through Mastering Difficult Conversations! In the previous lesson we learned how powerful it is to use objective descriptions. Today we continue with two additional common objectivity pitfalls.Watch the video and then complete the short quiz below.",
        videoLink: "https://vimeo.com/735583142",
        quizLink: "https://qmulsciences.qualtrics.com/jfe/form/SV_7PdafBMMbaSPtTo",
    },

    {
        title: "Lesson 4: Conveying Impact",
        name: "Lesson 4: Conveying Impact",
        quizTitle: "Lesson 4 Quiz: Check Your Learning",
        introText: "An important part of difficult conversations is to convey the impact of someone's actions. In this lesson you'll learn how to communicate impact, including emotional impact, in a way that is least likely to trigger a defensive response. Watch the video and then complete the short quiz below.",
        videoLink: "https://vimeo.com/709110261",
        quizLink: "https://qmulsciences.qualtrics.com/jfe/form/SV_1Z9gjMt8g7Qm1im",
    },
    {
        title: "Lesson 5: Ways Forward",
        name: "Lesson 5: Ways Forward",
        quizTitle: "Lesson 5 Quiz: Check Your Learning",
        introText: "The final step in a difficult conversation is to come up with strategies for the ways forward. Watch the video and then complete the short quiz below. Watch the video and then complete the short quiz below.",
        videoLink: "https://vimeo.com/709112631",
        quizLink: "https://qmulsciences.qualtrics.com/jfe/form/SV_a3NiwOSknHo0dcG",
    },



]



export default function Objectivity(props) {

    const id = props.match.params.id

    console.log(props.match.params.id)
    return (
        <Container><Row><Col>
            <TrainingPageLayout
                title={contentList[id]['title']}
                quizTitle={contentList[id]['quizTitle']}
                introText={contentList[id]['introText']}
                videoLink={contentList[id]['videoLink']}
                mcqLink={contentList[id]['quizLink'] ? contentList[id]['quizLink'] + '?userId=' + props.userId : null} />
        </Col></Row>
            <Row><Col className="my-5"></Col></Row>
        </Container>
    )
}
