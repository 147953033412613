import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './TextBox.css';
import LoadingScreen from 'react-loading-screen';
import EmpathicResponse from '../EmpathicResponse';
import TextareaAutosize from 'react-textarea-autosize';
//import { connect } from 'react-redux';
//import * as actionTypes from "../../store/actions/botActions"
import { Button } from "react-bootstrap"
import { appendPublicResponse } from '../../components/forms/formFunctions'
//import { TestingFunMemes } from '../../Utilities/Testing_config';
import * as config from "../../config/default_config"



/**
 * TextBox class. Main outer component for rendering the bot.
 */
class TextBox extends Component {

    /**
     * @constructor
     * @param {{Object.<string, string>}} props.urlParameters passed on by parent component which are typically retrieved GET parameters in URL from parent component url call 
     * If parent component does not have url parameters from GET call, can also manually set urlParameters object when calling TextBox,
     * even though urlParameters are coded rather than retrieved from URL. However, note these parameters are passed to AWS botAPI.
     * so they should be recognised parameters such as first_name, last_name, email etc. These are all passed onto the bot API.
     * @param {string} props.prompt prompt to be passed to Feedback component to pass to bot-API. If doesn't exist use config.DEFAULT_QUESTION
     * @param {boolean} props.userFeedback passed on by parent component and determines whether user feedback option will be shown
     * @param {callbackFunc} props.callbackFunc(this.state.value) When user types in input, this callback function gets called to pass input to parent component
     * @param {Integer} props.textAreaHeight. Number of rows in text area.
     * @param {string} props.attemptPath path string denoting where a getFeedback attempt should be stored. default is 'generalAttempt'
     * @param {boolean} props.recordAttempt path whether or not to record 'getFeedback' attempts
     * @param {boolean} props.showSaveTextButton. Button that allows user to append text to saveTextArrayName field in firestore document reference to call set with, saveTextDocRef for group learnings/knowledge bank creation
     * @param {string} props.saveTextArrayName name of array field where text will be appended to in saveTextDocRef
     * @param {object} props.saveTextDocRef firestore document reference with which we can call appendPublicResponse
     */




    constructor(props) {
        super(props);

        /**
        * State object of TextBox. 
        *

        * @property {string} value Input text typed in Textbox from user
        * @property {array}  CSVquestions Array of question read using @getCSVQuestion 
        * @property {boolean} loadingScreen Initial initialization of of loadingScreen (false)
        * @property {string} promptQuestion Question to display that comes from props.prompt if that exists
        * @property {boolean} userFeedback whether to display options to give userFeedback.
        * @property {boolean} showFinaliseButton whether to show button that allows user to finalise their answer to be saved in database
        * @property {boolean} setUserInput prop function called when showFinaliseButton is called that returns text to parent component
        * @property {string} placeholder the grey text in text box to show to write something there
        * @property {string} initialTextValue initialTextValue for text box
        * @property {boolean} funMemes Whether to use Memes - NOT IMPLEMENTED
        */



        this.state = {
            value: props.initialTextValue || '',
            CSVquestions: [],
            loadingScreen: false,
            funMemes: false,
            gotFeedback: false,
            newInput: false,
            showSaveTextThanks: false,

        }

    }
    static defaultProps = {
        attemptLimit: null, //default is to not have any attemptLimit (limit on user attempts to get Feedback)
        showPrompt: true,
        allowUserFeedback: false,
        callbackFunc: () => { },
        prompt: config.DEFAULT_QUESTION,
        urlParameters: {},
        textAreaHeight: 5,
        placeholder: 'Write the message you want to get empathy for: e.g., "Jackal should not have dismissed my point during the meeting."',
        initialTextValue: '',
        setUserInput: (value) => { console.log('doing nothing with:', value) },
        showFinaliseButton: false,
        useHovering: true,
        blockUserInput: false,
        feedbackButtonClass: '',
        clearFeedbackOnUserInput: false,
        noVisibleTextBox: false,
        textAreaClasses: null,
        maxLength: 1000,
        newInput: false,
        type: 'G', //the type of attempt, can be the name of the prompt?
        groups: '',
        tags: [], //any tags where we want to indicate the themes relating to the prompt
        direction: 'a', //seems to be if I wanted to record the order that different attempts were presented? Not used really
        recordAttempt: false,
        attemptPath: 'generalAttempt',
        message_to_translate: '',
        usageRecordsPath: null,
        usageId: '',
        location_id: 'default', //where the feedback button was pressed for fb_analytics
        getFeedbackOnLoad: false, //automatically ask for input when the text box is called, to be used from 'Get Feedback' from the home page
        loadingScreenText: 'Getting empathy for you',
        sessionResponse: '',
        showSaveTextButton: false,
        saveTextDocRef: null,
        saveTextArrayName: null,
        showSaveTextThanks: false,

    }


    componentDidUpdate(prevProps) {
        if (prevProps.initialTextValue !== this.props.initialTextValue) {
            this.setState({ value: this.props.initialTextValue, newInput: true })
        }

    }


    /**
     * @function
     * @param event when user types in the textArea.
     * Set *value* in state to be changed.
     */
    handleChange = (event) => {

        if (this.state.gotFeedback === true) { this.setState({ gotFeedback: false }) }
        this.setState({ value: event.target.value, showSaveTextThanks: false }, () => { if (this.props.callbackFunc) { this.props.callbackFunc(this.state.value) } })
    }


    /**
     * @function
     * @summary
     * Would have been the toggle switch.
     * 
     * For Memes implementation - not done
     */
    handToggle = () => {
        this.setState({ funMemes: !this.state.funMemes });
    }


    appendPublicResponseWrapper = (valueToAppend) => {
        console.log('insidepublicResponse', this.props.saveTextDocRef, this.props.saveTextArrayName)
        if (this.props.saveTextDocRef && this.props.saveTextArrayName) {
            appendPublicResponse(this.props.saveTextDocRef, this.props.saveTextArrayName, valueToAppend)
            this.setState({ showSaveTextThanks: true })
        }
    }


    executeLoadingScreen = (isLoading) => {
        this.setState({ loadingScreen: isLoading })
    }
    /**
     * Render method to load HTML of the TextBox
     */
    render() {

        var classNameTextArea = this.props.textAreaClasses ? `${this.props.textAreaClasses} form-control z-depth-1` : "form-control z-depth-1";


        return (

            <div className="" >
                <h5 className="font-italic">{this.props.showPrompt ? " " + this.props.prompt : ""}</h5>
                {this.props.noVisibleTextBox ?
                    <TextareaAutosize
                        className={classNameTextArea}
                        minRows={this.props.textAreaHeight}
                        placeholder={this.props.placeholder}
                        value={this.state.value}
                        disabled={this.props.blockUserInput}
                        maxLength={this.props.maxLength}
                        onChange={this.handleChange}
                        style={{ border: 'transparent', backgroundColor: 'white', resize: 'none', fontSize: '1.2em', color: '#666', fontStyle: 'italic' }} /> :
                    <TextareaAutosize
                        className={classNameTextArea}
                        minRows={this.props.textAreaHeight}
                        placeholder={this.props.placeholder}
                        value={this.state.value}
                        disabled={this.props.blockUserInput}
                        maxLength={this.props.maxLength}
                        onChange={this.handleChange} />

                }

                <LoadingScreen
                    fluid={true}
                    loading={this.state.loadingScreen}
                    bgColor='white'
                    spinnerColor='#9ee5f8'
                    textColor='#676767'
                    text={this.props.loadingScreenText}
                >
                    {/* Memes Implementation - Not completed 
                    <Form hidden={!TestingFunMemes}>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Check this Fun memes"
                            className="position-absolute"
                            onClick={this.handToggle}
                        />
                    </Form>
                    */}


                    <EmpathicResponse
                        newInput={this.state.newInput}
                        clearFeedbackOnUserInput={this.props.clearFeedbackOnUserInput}
                        feedbackButtonClass={this.props.feedbackButtonClass}
                        executeLoadingScreen={this.executeLoadingScreen}
                        allowUserFeedback={this.props.allowUserFeedback}
                        urlParameters={this.props.urlParameters}
                        user_input={this.state.value}
                        funMemes={this.state.funMemes}
                        prompt={this.props.prompt}
                        setGotFeedback={() => { this.setState({ gotFeedback: true, newInput: false }) }}
                        //useHovering as default set the "true". To not use hovering as default, set it to  "false" underneath & if the useHovering props is used, it will use whatever is specified. If useHovering is not boolean, the default value will be used.
                        useHovering={this.props.useHovering !== undefined && typeof (this.props.useHovering) === "boolean" ? this.props.useHovering : true}
                        groups={this.props.groups}
                        tags={this.props.tags}
                        direction={this.props.direction}
                        message_to_translate={this.props.message_to_translate}
                        recordAttempt={this.props.recordAttempt}
                        attemptPath={this.props.attemptPath}
                        usageRecordsPath={this.props.usageRecordsPath}
                        usageId={this.props.usageId}
                        location_id={this.props.location_id}
                        getFeedbackOnLoad={this.props.getFeedbackOnLoad}
                        sessionResponse={this.props.sessionResponse}
                        feedbackButtonText={this.props.feedbackButtonText}
                        attemptLimit={this.props.attemptLimit}
                    />
                    {/*need to send prompt as separate prop because if it is in urlParameters, 
                            React does not re-render and it does not get to Feedback. 
                            So we pass prompt to state which prompts a re-render and pass it as separate prop  here. */}

                </LoadingScreen>

                {this.props.showSaveTextButton && this.state.gotFeedback !== false && <div className="mt-3"><Button type="button submit" className="btn btn-primary float-right mt-1 pnkbtn" style={{ background: '#ff7000' }} onClick={() => this.appendPublicResponseWrapper(this.state.value)} value=""> Add to Knowledge Bank</Button></div>}



                {this.state.showSaveTextThanks ? <h5><div style={{ color: '#ff7000' }}>Thanks for submitting your example response to the scenario!</div></h5> : null}


            </div>
        );
    }

}

/**
 * Redux function to stop/start of the loading screen.
 * Mapping that to Props accessible from any class
 * @param {*} state
 */

/*
const mapStateToProps = state => {
   return {
                    loadingScreen: state.bot.loadingScreen,
   }
}
*/
/**
 * Dispatching the method to be accessed from any class
 * @param {*} dispatch
 */
/*
const mapDispatchToProps = dispatch => {
    return {
        /**
         * @param {boolean} loadingScreen
         * Stop/Start loading Screen.
         */
/*
executeLoadingScreen: (loadingScreen) => dispatch({type: actionTypes.UPDATE_LOADING_SCREEN, isLoading: loadingScreen })
}
}


export default connect(mapStateToProps, mapDispatchToProps)(TextBox);
*/
export default withRouter(TextBox)