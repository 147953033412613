import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string';
import ScenarioFormShort from './ScenarioFormShort'
import { submitPublicScenario } from '../../store/actions/scenarioActions'
import { PUBLIC_SCENARIOS_CONTENT } from '../../config/PublicScenarioContent'
import { getDocRef, createNewDoc, validateGetPathFromObj } from '../forms/formFunctions'

/**
 * RespondToScenario is a functional component that allows users to respond to posted scenarios. 
 * 
 */

const PublicScenario = ({ match, submitPublicScenario, location }) => {


    const id = match.params.id;
    //create new scenario object with only title and description so that the 'answers' are not shown when the user is responding.

    let params = queryString.parse(location.search)
    console.log('params', params)
    var docRef = null
    if (validateGetPathFromObj(params)) {
        const docPath = 'groups/' + params.groupName + '/' + params.sessionId + '/' + PUBLIC_SCENARIOS_CONTENT[id].name
        console.log('docPath', docPath)
        docRef = getDocRef(docPath)
        console.log('docRef', docRef)
        if (params.initialiseDoc === 'true')
            createNewDoc(docRef)

    }

    const returnURL = '/'
    const attemptPathBase = 'publicScenarios/' + PUBLIC_SCENARIOS_CONTENT[id].name + '/'
    return (
        <div>
            <ScenarioFormShort docRef={docRef} attemptPathBase={attemptPathBase} recordAttempt={true} parentDoc={id} scenario={PUBLIC_SCENARIOS_CONTENT[id]} callbackFunc={submitPublicScenario} isPublic={true} returnURL={returnURL} />
        </div>
    )
}



const mapDispatchToProps = (dispatch) => {
    return {
        submitPublicScenario: (scenario) => { dispatch(submitPublicScenario(scenario)); console.log('trying to displatch submitPublicScenario') },
    }
}


export default connect(null, mapDispatchToProps)(PublicScenario)



