import React from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal';



/**
 * HigherOrderModal higher order component that wraps any content inside
 * @param {*} WrappedComponent 
 * @param {string} props.buttonVariant The type of button for the button that toggles the modal on and off
 * @param {string} props.openButtonText The text that goes on the button that opens the modal
 * @param {string} props.title the title shown in the title field of the modal 
 * @param {boolean} props.showState whether modal defaults to being open on declaration.
 * @param {boolean} props.showToggleButton whether or not to show the toggle button, this is for the terms and conditions modal
 * where I want the popup to occur automatically and for it to disappear always after without a button
 * @param {boolean} props.showCloseButton do not allow close button (until something changes in parent component to changet his value)
 * @param {function} props.modalCloseFunction  additional function to be called upon modalClose
 */
const HigherOrderModal = (WrappedComponent) => {
    return class extends React.PureComponent {
        constructor(props) {
            super(props)
            this.state = {
                show: props.showState //default has to be false because unless propsshowState is true, the default will always be chosen anyway
            }

        }
        static defaultProps = {

            buttonVariant: null,
            openButtonText: '',
            title: '',
            showState: false,
            showToggleButton: true,
            showCloseButton: true,
            controlShowFromParent: false,
            modalCloseFunction: null,
            bodyText: null,
            size: 'sm',
            style: {}

        }


        handleClose = () => {
            this.setState({ show: false });
            if (this.props.modalCloseFunction) {
                this.props.modalCloseFunction()
            }

        }
        handleShow = () => this.setState({ show: true });

        componentDidUpdate() {
            if (this.props.controlShowFromParent && (this.props.showState !== this.state.show)) { this.setState({ show: this.props.showState }) } //if props.showState is true and state.show is false, set state.show to true.
        }

        render() {

            const { showToggleButton, showCloseButton, bodyText, size } = this.props
            return (
                <>
                    {showToggleButton ?
                        <Button variant={this.props.buttonVariant ? this.props.buttonVariant : "light"} style={this.props.style} className={this.props.buttonClassName ? this.props.buttonClassName : ""} onClick={this.handleShow} >
                            {this.props.openButtonText}
                        </Button> :
                        null
                    }
                    <Modal show={this.state.show} onHide={this.handleClose} size={size} >
                        <Modal.Header closeButton={showCloseButton} >
                            <Modal.Title>{this.props.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                            {bodyText ? bodyText : null}
                            <WrappedComponent {...this.props} />
                        </Modal.Body>
                        <Modal.Footer>
                            {showCloseButton ? <Button variant="secondary" onClick={this.handleClose}>Close </Button> : null}
                        </Modal.Footer>
                    </Modal>
                </>
            );
        }
    }
}


export default HigherOrderModal