import React from 'react'
import DiplomacyPrinciples from "./DiplomacyPrinciples"


export default function WelcomeWrapper() {
    return (
        <div>

            {/*<span style={{ color: "blue" }}><i >Review this in a new tab any time by clicking on </i></span> <span style={{ background: 'black', color: 'white' }}>DiplomacyDojo</span>
            <span style={{ color: "blue" }}><i> in the Navigation Menu </i></span>
    */}
            <DiplomacyPrinciples></DiplomacyPrinciples>

        </div>
    )
}
