import React from 'react'

import DeleteScenarioButton from './DeleteScenarioButton'
import EditScenarioButton from './EditScenarioButton'
import { Card } from 'react-bootstrap'
import moment from 'moment'


//child component of ScenarioList, inherits scenario prop from ScenarioList
const ScenarioSummary = ({ scenario, showDate = true }) => {

    return (
        <div className='mt-3'>

            <Card style={{ width: '80%' }} >
                <Card.Body>
                    <Card.Title as="div" style={{ color: "black " }}>{scenario.title}</Card.Title>
                    <Card.Text as="div" style={{ color: "black " }} >
                        {/*<div>Posted by {scenario.authorUsername} </div>*/}
                        {showDate ?
                            <div className="className grey-text">{moment(scenario.createdAt.toDate()).calendar()}</div> : null}
                    </Card.Text>
                    {/* need to call id separately because when Edit/DeleteScenarioButton is called from ScenarioDetails.js, that scenario is from firestore 
                    and does not have id as a property in scenario, so scenario.id is null called over there, even though scenario.id has a value here */}


                    <Card.Link to="#" as="div" className="float-left"><EditScenarioButton scenario={scenario} id={scenario.id} /></Card.Link>
                    <Card.Link to="#" as="div" className="float-right"><DeleteScenarioButton scenario={scenario} id={scenario.id} /></Card.Link>
                </Card.Body>
            </Card >
        </div>
    )
}

export default ScenarioSummary