import React, { Component } from 'react'

/**
 *Show score with feedback
*/
class ShowScore extends Component {
    /**
     * @param <Integer> props.numCorrect
     * @param <Integer> props.numIncorrect
     * */
    constructor(props) {
        super()
        this.state = {

        };
    }

    static defaultProps = {
        showFeedback: true,
        numCorrect: 0,
        numIncorrect: 0
    }

    getScore = (numCorrect, numIncorrect) => {
        const Total = numCorrect + numIncorrect
        if (Total === 0 || !Total) return null
        return <h3>Final Score: {numCorrect} out of {Total}</h3>
    }

    getFeedback = (numCorrect, numIncorrect) => {
        const Total = numCorrect + numIncorrect
        const ratio = numCorrect / Total
        if (Total === 0)
            return null
        else if (ratio === 1)
            return "Fantastic job!"
        else if (ratio >= 0.9)
            return "Excellent work!"
        else
            return ""

    }


    render() {
        const { numCorrect, numIncorrect } = this.props
        const score = this.getScore(numCorrect, numIncorrect)
        const feedback = this.props.showFeedback ? this.getFeedback(numCorrect, numIncorrect) : null
        return (
            <div>
                {score}
                <h3>{feedback}</h3>
            </div>
        )
    }
}

export default ShowScore
