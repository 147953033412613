import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ReactGA from 'react-ga';
import Footer from './components/publicpages/Footer'
import Navbar from "./components/layout/Navbar";
import Dashboard from "./components/dashboard/Dashboard"
import IntroChat from "./components/forms/IntroChat"
import CreateChallenge from "./components/challenges/CreateChallenge"
import RespondToChallenge from "./components/challenges/RespondToChallenge"
import EditChallenge from "./components/challenges/EditChallenge"
import ChallengeResponseDetails from './components/challenges/ChallengeResponseDetails'
import MyScenarios from "./components/dashboard/MyScenarios"
import DailyLogs from "./components/dashboard/DailyLogs"
import PseudofeelingsQuiz from "./components/activities/PseudofeelingsQuiz"
import LogFeelingsAndNeedsPublic from "./components/publicpages/LogFeelingsAndNeedsPublic"
import WorkshopSurvey from "./components/activities/WorkshopSurvey"
import EffectiveCommunication3StepsCheatSheet from "./components/downloadables/EffectiveCommunication3StepsCheatSheet"
import AssertivelyCharmingWorkshopNotes from "./components/downloadables/AssertivelyCharmingWorkshopNotes"
import CreateLog from "./components/logs/CreateLog"
import EditLog from "./components/logs/EditLog"
import LogDetails from "./components/logs/LogDetails"
import LogFeelingsAndNeeds from "./components/logs/LogFeelingsAndNeeds"

import MyScenarioDetails from './components/scenarios/MyScenarioDetails'
import Articles from "./components/dashboard/Articles"
import CreateArticle from "./components/articles/CreateArticle"
import EditArticle from "./components/articles/EditArticle"
import ArticleDetails from "./components/articles/ArticleDetails"
import SignIn from './components/auth/SignIn'
import SignUp from './components/auth/SignUp'
import Account from './components/auth/Account'
import ForgotPassword from './components/auth/ForgotPassword'
import Admin from './components/auth/Admin'
import CreatePublicScenario from './components/scenarios/CreatePublicScenario'
import CreatePrivateScenario from './components/scenarios/CreatePrivateScenario'
import RespondToScenario from './components/scenarios/RespondToScenario'
import PublicScenario from './components/scenarios/PublicScenario'
import EditScenario from './components/scenarios/EditScenario'
import CheckMessage from './components/scenarios/CheckMessage'
import { toast } from 'react-toastify';
import IFrame from "./bot_components/IframeFunctions"
import RequireAuthWrapper from "./components/auth/RequireAuthWrapper"
import TermsAndConditionsText from "./components/publicpages/TermsAndConditionsText"
import DiplomacyPrinciples from "./Utilities/DiplomacyPrinciples"
import HomePageEmpty from "./components/publicpages/HomePageEmpty"
import Demo from "./components/publicpages/Demo"
import PracticeFeedback from "./components/publicpages/PracticeFeedback"
import Practice from "./components/publicpages/Practice"

import AIHelper from "./components/publicpages/AIHelper"
import MessageHelper from "./components/publicpages/MessageHelper"
import InstantEmpathy from "./components/publicpages/InstantEmpathy"
import InstantEmpathyUAT from "./components/publicpages/InstantEmpathyUAT"
import MessageHelperFront from "./components/publicpages/MessageHelperFront"
import PlainTextBox from "./components/publicpages/PlainTextBox"
import withClearCache from './ClearCache';
import packageJson from "../package.json";
import { getBuildDate } from "./Utilities/utils";
import RouteChangeTracker from "./Utilities/RouteChangeTracker"
import ScrollToTop from './Utilities/ScrollToTop'
import Tools from './components/publicpages/Tools'
//import PrivacyPolicy from "./components/publicpages/PrivacyPolicy"
//import Training from "./components/publicpages/Training"
//import AgileCommunication from "./components/publicpages/AgileCommunication"
//import Technology from "./components/publicpages/Technology"
//import Studies from "./components/articles/Studies"
import PublicChallenges from './components/challenges/PublicChallenges'
//import PublicScenarios from './components/scenarios/PublicScenariosAll'
//import About from './components/publicpages/About'
//import Subscribe from './components/publicpages/SubscribePage'
//import Contact from './components/publicpages/ContactUs'
//import Aspirations from './components/articles/Aspirations'
//import ThoughtsvsFeelings from './components/articles/ThoughtsvsFeelings'
//import Objectivity from './components/articles/Objectivity'
//import ExpressingNegativeEmotions from './components/articles/ExpressingNegativeEmotions'
import RespondToPublicChallenge from "./components/challenges/RespondToPublicChallenge"
import TrainingPage from './components/trainingpages/TrainingPage'
import TrainingPageYoutube from './components/trainingpages/TrainingPageYoutube'
import TrainingPageMCQOnly from './components/trainingpages/TrainingPageMCQOnly'

import TrainingPageEmailSequence from './components/trainingpages/TrainingPageEmailSequence'
import './index.css'

ReactGA.initialize('UA-163967756-1');

const DefaultContainer = () => (

  <>
    <ScrollToTop>
      <Navbar />
      <div>


        <Route exact path='/' component={HomePageEmpty} />
        <Route path='/admin' component={Admin} />
        <Route exact path='/main' component={RequireAuthWrapper(Dashboard)} />
        <Route exact path='/createchallenge' component={CreateChallenge} />
        <Route path='/editchallenge/:id' component={EditChallenge} />
        <Route exact path='/takechallenge/:id' component={RespondToChallenge} />
        <Route path='/create' component={CreatePublicScenario} />
        <Route path='/createprivate' component={CreatePrivateScenario} />
        <Route exact path='/submittedchallenge/:id' component={ChallengeResponseDetails} />
        <Route exact path='/edit/:id' component={EditScenario} />
        <Route path='/respondtoscenario/:id' component={RespondToScenario} />
        <Route path='/publicscenario/:id' component={PublicScenario} />
        <Route path='/qmul/:id' component={PublicScenario} />
        <Route path='/biggerpie/:id' component={PublicScenario} />
        <Route path='/mediationnetwork/:id' component={PublicScenario} />
        <Route exact path='/myscenarios' component={MyScenarios} />
        <Route path='/myscenarios/:id' component={MyScenarioDetails} />
        <Route path='/signin' component={SignIn} />
        <Route path='/signup' component={SignUp} />
        <Route path='/account' component={Account} />
        <Route path='/forgotpassword' component={ForgotPassword} />
        <Route path='/newlog' component={CreateLog} />
        <Route exact path='/mylogs' component={DailyLogs} />
        <Route path='/mylogs/:id' component={LogDetails} />
        <Route path='/editlog/:id' component={EditLog} />
        <Route path='/newarticle' component={CreateArticle} />
        <Route exact path='/articles' component={Articles} />
        <Route path='/articles/:id' component={ArticleDetails} />
        <Route path='/editarticle/:id' component={EditArticle} />
        <Route path='/checkmessage' component={CheckMessage} />
        <Route path='/termsofuse' component={TermsAndConditionsText} />
        <Route path='/diplomacyprinciples' component={DiplomacyPrinciples} />

        <Route path='/introchat' component={IntroChat} />
        <Route path='/logfeelingsandneeds' component={LogFeelingsAndNeeds} />
        <Route path='/judgmentsquiz' component={PseudofeelingsQuiz} />
        <Route path='/emotionsandvalues' component={LogFeelingsAndNeedsPublic} />


        <Route path='/workshopsurvey' component={WorkshopSurvey} />
        <Route path='/EffectiveCommunication3StepsCheatSheet' component={EffectiveCommunication3StepsCheatSheet} />
        <Route path='/charminglyassertive' component={AssertivelyCharmingWorkshopNotes} />
        <Route path='/example' component={Demo} />
        <Route path='/feedbackpractice' component={PracticeFeedback} />
        <Route path='/practice/:id' component={Practice} />

        <Route path='/messagehelper' component={MessageHelper} />
        <Route path='/instantempathy' component={InstantEmpathy} />
        <Route path='/instantempathyuat' component={InstantEmpathyUAT} />
        <Route path='/front' component={MessageHelperFront} />
        <Route path='/plaintextbox' component={PlainTextBox} />
        <Route exact path='/challenges/:id' component={RespondToPublicChallenge} />
        <Route exact path='/challenges' component={PublicChallenges} />
        <Route exact path='/intro-course734/:id' component={TrainingPageEmailSequence} />
        <Route exact path='/tools' component={Tools} />
        {/* <Route exact path='/scenarios/:id' component={RespondToScenario} />
        <Route exact path='/scenarios' component={PublicScenarios} />
        <Route exact path='/challenges/:id' component={RespondToPublicChallenge} />
        <Route exact path='/challenges' component={PublicChallenges} />
        <Route path='/about' component={About} />
        <Route path='/training' component={Training} />
        <Route path='/agilecommunication' component={AgileCommunication} />
        <Route path='/technology' component={Technology} />
        <Route path='/contact' component={Contact} />
        <Route path='/privacypolicy' component={PrivacyPolicy} />
        <Route path='/subscribe' component={Subscribe} />
        <Route path='/aspirations' component={Aspirations} />
        <Route path='/thoughtsvsfeelings' component={ThoughtsvsFeelings} />
        <Route path='/objectivity' component={Objectivity} />
        <Route path='/expressingnegativeemotions' component={ExpressingNegativeEmotions} />
        <Route path='/studies' component={Studies} />
        */}
      </div>
      <Footer />
    </ScrollToTop>
  </>

)





const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}



toast.configure();

function MainApp(props) {
  const buildDate = getBuildDate(packageJson.buildDate)
  console.log('Build date', buildDate)

  return (


    <BrowserRouter>
      <div className="App image-background">
        <RouteChangeTracker />
        <Switch>

          <Route exact path="/iframeFeedback" component={IFrame} />
          <Route exact path='/usersStudentExpt1' component={TrainingPage} />
          <Route exact path='/demoCourse' component={TrainingPageYoutube} />
          <Route exact path='/usersStudentExpt2' component={TrainingPageMCQOnly} />
          <Route exact path='/AIHelper' component={AIHelper} />
          {/*
            <Route exact path="/qmul" component={noNavContainer} />
            <Route exact path="/qmulold" component={noNavContainer} />
            */}
          <Route component={DefaultContainer} />
        </Switch>
      </div>
    </BrowserRouter>
  );

}



export default App;
