import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'


function DiplomacyPrinciples() {
    return (
        <Container><Row><Col>
            <div className="mt-5">

                <br></br>

                <h2> Master the three skills of diplomacy</h2>


Conflict is upsetting but it can also connect us to to what is important to us. Mastering diplomacy gives you the power to transform conflict into opportunity.

                <br></br><br></br><br></br>
                <h4> 1) Make observations without interpretation, blame or judgment. </h4>


Describe things only in terms of what you can see or hear, as if captured on a video camera. If needed, use only direct quotes of what someone said.

Social negativity is processed in the brain in similar ways to the fear and pain of being cut or stabbed. We respond to social threat like physical danger: our attention narrows to the singular focus of preparing for fight or flight. When you use language implying blame or negative judgment, you are more likely to trigger a defensive response in others. They become less likely to hear and understand you as their minds become occupied with gearing up for battle, nursing their wounds, or running away. By sticking to observable, indisputable facts, you will not get stuck arguing over invisible positions such what was or wasn’t intended.
<br></br>
                <br></br>

                <li><b><i> Instead of: </i></b>  You left a mess behind</li> <br></br>

                <li><b><i> Say: </i></b>  I noticed the cups were left in the sink overnight</li> <br></br>


                <li><b><i> Instead of: </i></b>  You are not listening to me</li> <br></br>
                <li><b><i> Say: </i></b>  You started speaking before I finished my sentence. </li> <br></br>

                <br></br>

                <h4> 2) See the feelings and needs behind judgments, thoughts, and interpretations. </h4>

Learn to see the universal needs behind negative feelings. It is natural for us to say ‘we feel’ something when we are actually thinking something or making an interpretation about others. For example, we may say “I feel misunderstood’, but ‘misunderstood’ is not a core feeling, it is an interpretation of someone else’s state of mind. Regardless of whether your judgments/interpretations are true or not, being stuck on interpreting others distracts us from what is underneath the issue. Also implying interpretations of others will likely prompt them to be defensive (see above). Instead, focus on how you feel within yourself and what needs you had.
                <br></br><br></br>
                <li><b><i> Instead of: </i></b>  I felt belittled</li> <br></br>

                <li><b><i> Say: </i></b>  I felt frustrated because I wanted consideration for my ideas. </li> <br></br>

You can also acknowledge values the other person may have. Mention these generally, not personally.
<br></br>
                <br></br>

                <li><b><i> Instead of: </i></b>  I know you value efficiency above everything else</li> <br></br>

                <li><b><i> Say: </i></b>  I recognise that time is limited and there are many things we need to get done. </li> <br></br>



                <h4>3) Say what you want in terms of values, needs and aspirations (VNA’s). </h4>
Develop vocabulary of saying what you want by appealing to universal needs, personal values and aspirations. Use VNA’s as the motivation behind your requests of others. And also attribute any negative feelings you want to share to your VNA’s rather than to other’s actions.
<br></br><br></br>


                <li><b><i> Instead of: </i></b>  Stop interrupting me, you aren’t listening to what I have to say. </li> <br></br>
                <li><b><i> Say: </i></b>  I want to be as effective as possible in getting my full point across. Can you write down your comments so we can field them after I finish the (section of the) presentation. </li> <br></br>

                <li><b><i> Instead of: </i></b>  You seem to always misunderstand what I say, can you repeat back to me what I just said? </li> <br></br>
                <li><b><i> Say: </i></b>  I want confidence that I’m being clear and getting things across. Can you just give me a headline back of what you heard so we know we are on the same page. </li> <br></br>



            </div></Col></Row></Container>
    )
}

export default DiplomacyPrinciples
