import React, { Component } from 'react'
import ScenarioList from '../scenarios/ScenarioList'
import ChallengeResponseList from '../challenges/ChallengeResponseList'
//import Notifications from './Notifications'  We deleted the corresponding cloud functions and commented it out of firebase/functions/index.js
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
const myScenarios = 'myPrivateScenarios'
const myChallenges = 'myChallengeResponses'



/**
 * MyScenarios is a class component showing cards with summaries of all users scenarios. They are connected automatically through firestore-redux props
 */
class MyScenarios extends Component {

    /**
    * State object of TextBox. 
    * @property {boolean | undefined} props.profile.terms whether or not user has agreed to the terms and profile
    */
    constructor(props) {
        super(props);
        this.state = {
        }
    }







    render() {
        const { auth, scenarios, challenge_responses } = this.props;
        if (!auth.uid) return <Redirect to='/signin' />
        console.log(challenge_responses)
        return (
            <Container>
                <Row><Col className="mt-5">
                    <h1>Completed Scenario and Situation Activities</h1>
                </Col></Row>
                <Row>
                    <Col md={{ span: 6, offset: 0 }} sm={{ span: 12, offset: 0 }}>
                        <div className="input-field mt-5"><Button style={{ background: '#ff7000', border: 0 }} href={'/createprivate'}>Create New Private Scenario</Button ></div>
                    </Col>
                </Row>

                <Row>
                    <Col md={{ span: 6, offset: 0 }} sm={{ span: 12, offset: 0 }}>


                        <ScenarioList scenarios={scenarios} urlBase={'/myscenarios/'} />

                    </Col>

                    <Col md={{ span: 5, offset: -1 }} sm={{ span: 12 }}>


                        <ChallengeResponseList challenge_responses={challenge_responses} urlBase={'/submittedchallenge/'} />

                    </Col>
                    {/*
                    <Col md={{ span: 5, offset: -1 }} sm={{ span: 12 }}>
                        <Notifications notifications={notifications} />

                    </Col>
                     */}
                </Row>
                <Row><Col style={{ marginBottom: "10rem" }}></Col></Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        challenge_responses: state.firestore.ordered[myChallenges],
        scenarios: state.firestore.ordered[myScenarios],
        auth: state.firebase.auth,
        // notifications: state.firestore.ordered.notifications,

    }
}



export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => {
        if (props.auth.uid) {
            return [

                {
                    collection: 'users',
                    doc: props.auth.uid,
                    subcollections: [{ collection: 'scenarios', orderBy: ['createdAt', 'desc'] }],
                    storeAs: myScenarios
                },

                {
                    collection: 'users',
                    doc: props.auth.uid,
                    subcollections: [{ collection: 'challenges', orderBy: ['createdAt', 'desc'] }],
                    storeAs: myChallenges
                }
            ]
        }

    })
)(MyScenarios)