const initState = {
    isAdmin: false,
    authError: null,
    isNewUser: false,
    anonymousUserId: null,

}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN_ERROR':
            console.log('login error')
            return {
                ...state,
                authError: action.error
            }
        case 'LOGIN_SUCCESS':
            console.log('login success')
            return {
                ...state,
                authError: null,
                isNewUser: false
            }
        case 'SIGNOUT_SUCCESS':
            console.log('signout success')
            return state
        case 'SIGNUP_SUCCESS':
            console.log('signup success')
            return {
                ...state,
                authError: null,
                isNewUser: true
            }
        case 'SIGNUP_ERROR':
            console.log('signup error')
            return {
                ...state,
                authError: action.error
            }
        case 'SIGNUP_SUCCESS':
            console.log('signup success')
            return {
                ...state,
                authError: null,
                isNewUser: true
            }
        case 'SIGNUP_ERROR':
            console.log('signup error')
            return {
                ...state,
                authError: action.error
            }
        case 'NEW_ANONYMOUS_USER_SUCCESS':
            console.log('NEW_ANONYMOUS_USER successfully created: ', action.docRef.id)
            return {
                ...state,
                authError: null,
                anonymousUserId: action.docRef.id
            }
        case 'NEW_ANONYMOUS_USER_ERROR':
            console.log('NEW_ANONYMOUS_USER error', action.error)
            return {
                ...state,
                authError: action.error
            }

        case 'IS_ADMIN':
            console.log('is admin checked' + action.isAdmin)
            return {
                ...state,
                isAdmin: action.isAdmin //action.IS_ADMIN is returned from authActions.signUp
            }
        case 'USER_GROUP':
            console.log('user Group retrieved' + action.userGroup)
            return {
                ...state,
                userGroup: action.userGroup //action.userGroup is returned in verifyGroup inside renderless function VerifyGroupFirebase.js
            }
        case 'ANONYMOUS_USER':
            console.log('anonymouse user Id retrieved ' + action.anonymousUserId)
            return {
                ...state,
                anonymousUserId: action.anonymousUserId //action.anonymousUserId is returned from authActions.signUp
            }

        default:
            return state
    }
}

export default authReducer