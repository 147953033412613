import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize';
import { Multiselect } from 'multiselect-react-dropdown';


export class Note extends Component {

    constructor(props) {
        super();
        console.log(props.dropdownOptions)

        var selectedValues = []
        if (props.selectedValuesIDList) {
            //let name and id be the same. Multiselect component requires list of objects with properties name and id
            props.selectedValuesIDList.forEach((id) => {
                selectedValues.push({ name: id, id: id })
            })
        }

        this.state = {
            selectedValues: selectedValues
        }
    }

    static defaultProps = {
        textValue: '',
        dropdownOptions: [],
        selectedValuesIDList: [], //list of id's only of selected values. Needs to be spread into a list of objects before feeding into this.state.selectedValues
        dropdownTitle: '',
        noteID: '',
        callbackUpdateNote: (noteObj) => { },
        minRows: 2,
        textPlaceholder: '',
        dropdownPlaceholder: 'Choose Associations'

    }


    onSelect = (selectedList, selectedItem) => {

        const selectedValues = [...this.state.selectedValues, selectedItem]
        const selectedValueNames = selectedValues.map((obj) => obj.name)
        console.log('selectedList', selectedValues)
        this.setState({ selectedValues },
            this.props.callbackUpdateNote(this.props.noteID, 'selected', selectedValueNames)
        )
    }


    onRemove = (selectedList, removedItem) => {
        const selectedValues = selectedList.filter(selectedObj => selectedObj !== removedItem);
        const selectedValueNames = selectedValues.map((obj) => obj.name)
        this.setState({ selectedValues },
            this.props.callbackUpdateNote(this.props.noteID, 'selected', selectedValueNames)
        )
    }

    handleChange = (e) => {
        this.setState({ textValue: e.target.value },
            this.props.callbackUpdateNote(this.props.noteID, 'text', e.target.value)
        )

    }

    componentDidUpdate() {
        const newSelectedValues = this.props.selectedValuesIDList.map(val => { return ({ name: val, id: val }) })
        if (!(this.props.textValue === this.state.textValue) && (newSelectedValues !== this.state.selectedValues)) {
            this.setState({ textValue: this.props.textValue, selectedValues: newSelectedValues })
        }

    }

    render() {

        return (



            <div>
                <Row><Col>
                    <TextareaAutosize
                        onChange={this.handleChange}
                        value={this.state.textValue}
                        id={'textValue'}
                        minRows={2}
                        placeholder={this.props.textPlaceholder}
                        style={{ width: '100%' }}
                    />
                </Col></Row>
                {
                    this.props.dropdownOptions.length > 0 ?
                        <Row><Col>
                            <Multiselect
                                options={this.props.dropdownOptions} // Options to display in the dropdown
                                selectedValues={this.state.selectedValues} // Preselected value to persist in dropdown
                                onSelect={this.onSelect} // Function will trigger on select event
                                onRemove={this.onRemove} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                showCheckbox={true}
                                placeholder={this.props.dropdownPlaceholder}
                                avoidHighlightFirstOption={true}
                                style={{ searchBox: { background: 'white' }, inputField: { color: 'white' } }}
                            /></Col></Row >
                        : null
                }
            </div>

        )
    }
}

export default Note
