import React, { Component } from 'react'
import { connect } from 'react-redux'
import ScenarioForm from './ScenarioForm'
import { editScenario } from '../../store/actions/scenarioActions'
import { Redirect } from 'react-router-dom'


//This creates JSX for the user to create a new scenario

class EditScenario extends Component {
    state = {
        //scenario passed through prop location's state parameter
        //as set in EditScenarioButton: call: this.props.history.push
        ...this.props.location.state
    }

    static defaultProps = {
        returnURL: '/',
    }

    //need this to pass additional arguments to editScenario
    editScenarioWrapper = (scenario) => {
        //scenario returned from Scenario component only has the scenario features
        //it no longer has the 'id' property. Even though when we pass the Scenario component location.state, it does include the id property, the id property isn't used.
        const auth = this.props.auth;
        //editScenario is action creator defined in scenarioActions.js
        console.log(this.props)
        console.log('current auth', auth)
        console.log('isAdmin at submit: ', this.props.isAdmin)
        const id = this.props.location.state.id
        const isAdmin = this.props.isAdmin
        this.props.editScenario(id, scenario, auth, isAdmin)
    }

    render() {
        const { auth, location } = this.props;

        const scenario = location.state

        const returnURL = location.state.prevPath
        console.log(scenario)
        if (!auth.uid) return <Redirect to='/signin' />
        return (
            <div>
                <ScenarioForm callbackFunc={this.editScenarioWrapper} scenario={scenario} copyToObservation={false} copyToFinalMessage={false} formTitle='Edit Story' returnURL={returnURL} />
            </div>
        )
    }
}




const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        isAdmin: state.auth.isAdmin
    }
}

//NOTE: editScenario is now a variable in props and its value is a function that dispatches
//the action-creator 'editScenario' function defined in scenarioActions.js, w
//editScenario is an action-creator which instead of returning an action object (as done when we did not need asynchronous calls)
//now returns a function that makes an asynch call and then calling dispatch.
//'scenario' below is just a variable in the arrow function
const mapDispatchToProps = (dispatch) => {
    return {
        editScenario: (id, scenario, auth, isAdmin) => dispatch(editScenario(id, scenario, auth, isAdmin)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EditScenario)