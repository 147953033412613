import React from 'react'
import moment from 'moment'
import DeleteChallengeResponseButton from './DeleteChallengeResponseButton'
import { Card } from 'react-bootstrap'



//child component of ChallengeList, inherits challenge_response prop from ChallengeList
const ChallengeResponseSummary = ({ challenge_response }) => {
    console.log(challenge_response)
    return (
        <div className='mt-3'>
            <Card style={{ width: '80%' }} >
                <Card.Body>{
                    challenge_response.title ?
                        <Card.Title as="div" style={{ color: "black " }}>{challenge_response.title}</Card.Title> : null}
                    <Card.Text as="div" style={{ color: "black " }} >
                        {/*<div>Posted by {challenge_response.authorUsername} </div>*/}
                        <div className="className grey-text">{moment(challenge_response.createdAt.toDate()).calendar()}</div>
                    </Card.Text>
                    {/* need to call id separately because when Edit/DeleteChallengeButton is called from ChallengeDetails.js, that challenge_response is from firestore 
                    and does not have id as a property in challenge_response, so challenge_response.id is null called over there, even though challenge_response.id has a value here */}

                    <Card.Link to="#" as="div" className="float-right"><DeleteChallengeResponseButton challenge_response={challenge_response} id={challenge_response.id} /></Card.Link>
                </Card.Body>
            </Card >
        </div>
    )
}

export default ChallengeResponseSummary