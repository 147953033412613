import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import GreenCheckMark from '../../files/GreenCheckMark.png'
import RedXMark from '../../files/RedXMark.png'


/**
 *shows tabulated results for a quiz that is testing sorting items into categories
*/
class ShowQuizAnswersCategoryTypes extends Component {

    /**
     * @param {Array.<Object>} props.answersRecord, list of objects with properties: { q: this.state.question, ans: correctOption.content, user_ans: userAnswer.value (e.g., "Correct") }
     * @param {Array.<Object>} props.optionCategories, list of objects with properties {header: (e.g., 'Feelings') category: 'feeling'}. The header may be different from category
     * @param <Object> props.quizImages object with properties of quiz options and values of images to be shown instead of text-options and radio button. If null, text with radio buttons is shown
     */

    static defaultProps = {
        optionCategories: [],
        answersRecord: [],
        quizImages: null
    }

    filterResultsByCategory = (currentCategory, answersRecord) => {
        return answersRecord.flatMap((record, ind) => {
            const correct = record.ans === record.user_ans
            return (
                record.ans === currentCategory ?
                    <div key={ind}>{record.q} {correct ?
                        <img src={GreenCheckMark} width="10" height="10" alt="Correct!" /> :
                        < img src={RedXMark} width="10" height="10" alt="Incorrect" />}
                    </div> : [])
        })
    }

    renderTable = (labels, columns, quizImages) => {

        const table_headers = labels.map(label => <th key={label}>{label}</th>)
        const data_cells = columns.map((column, ind) => <td key={ind}>{column}</td>)
        return (
            <>
                <h4 >Results</h4>
                < Table >

                    <thead>
                        <tr>
                            {table_headers}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {data_cells}
                        </tr>
                    </tbody>
                </Table >
            </>)

    }

    render() {
        const data_columns = this.props.optionCategories.map(currentCategoryObj => this.filterResultsByCategory(currentCategoryObj.category, this.props.answersRecord))
        const labels = this.props.optionCategories.map(currentCategoryObj => currentCategoryObj.header)
        const table = this.renderTable(labels, data_columns, null)

        return (
            <div>
                {table}
            </div>
        )
    }
}

export default ShowQuizAnswersCategoryTypes
