import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import EnterValidGroup from './EnterValidGroup';
import HigherOrderModal from '../../Utilities/HigherOrderModal'
import TermsAndConditions from '../publicpages/TermsAndConditions'
import firebase from "../../config/fbConfig"
const ModalTandC = HigherOrderModal(TermsAndConditions)


function RequireAuth(Component) {

    class AuthenticatedComponent extends React.Component {

        constructor(props) {
            super(props);



            /**
            * State object of Dashboard
            * @property {('off'|'on')}this.state.terms  whether or not user has checked the Agree to Terms checkbox in the modal
            * @property {boolean} this.state.termsErrorMessage error message saying user must agree to terms before continuing to use the program
            */

            this.state = {
                terms: 'off',
                termErrorMessage: '',


            }

        }
        modalCloseFunctionTandC = () => {
            console.log('into modalCloseFunctionTandC', this.state.terms)
            if (this.state.terms === 'on') {

                const profiles = firebase.firestore().collection('users')
                return profiles.doc(this.props.auth.uid).update({
                    terms: true
                }).then(() => {
                    console.log('terms accepted')
                }).catch((error) => {
                    console.log('error when setting terms')
                })
            }
        }

        handleChange = (e) => {

            if (this.state.termsMessage) { this.setState({ termsMessage: null }) }
            console.log(e.target.value)
            this.setState({
                [e.target.id]: e.target.value
            })
            if ((e.target.id === 'terms') && (this.state.terms === 'on')) {
                this.setState({ terms: 'off' })
            }
        }

        render() {
            const showModalCloseTandC = this.state.terms === 'on' ? true : false
            if (this.props.auth.uid) {
                if (!this.props.profile.isLoaded) {
                    return null
                }
                else if (!this.props.group) {

                    return <EnterValidGroup />
                }
                else if (!this.props.terms) {

                    return <ModalTandC
                        showState={true}
                        controlShowFromParent={true}
                        showToggleButton={false}
                        showCloseButton={showModalCloseTandC}
                        buttonClassName="termsAndConditions"
                        handleChange={this.handleChange}
                        checked={this.state.terms === 'on' ? true : false}
                        size="lg"
                        modalCloseFunction={this.modalCloseFunctionTandC} //cannot use onExit because modalClosefunctionTandC needs to be called before close iniitates.
                    />
                }

                return <Component {...this.props} />


            }
            else {
                return <Redirect to='/signin' />;

            }

        }

    }



    return withRouter(AuthenticatedComponent);
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.firebase.isAdmin,
        auth: state.firebase.auth,
        terms: state.firebase.profile.terms,
        group: state.firebase.profile.group,
        profile: state.firebase.profile

    }
}

//need to compose the higher order component, requireAuth with mapstate to props and then export
const RequireAuthWrapper = compose(connect(mapStateToProps), RequireAuth)



export default RequireAuthWrapper