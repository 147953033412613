import React from 'react'
import { Container, Col, Row, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import ReactPlayer from 'react-player'


function TrainingPageLayout(props) {

    return (
        <div>


            <Row><Col className="my-5" >
                {props.title ? <h1>{props.title}</h1> : null}
                {props.introText ? <h5>{props.introText}</h5> : null}
            </Col></Row>
            <Row>
                {props.videoLink ?
                    <Col className="my-5">

                        <div className="player-wrapper" >
                            <ReactPlayer
                                url={props.videoLink}

                                playing={false}
                                width='100%'
                                height='100%'
                                controls={true}
                                style={{ backgroundColor: 'white' }}
                                className="player" />

                        </div>
                    </Col>
                    : null}
            </Row>
            <Row><Col className="my-5">
                {props.quizTitle ? <h1>{props.quizTitle}</h1> : null}
            </Col></Row>
            <Row>
                <Col className=" mb-5" >
                    {props.mcqLink ?
                        <iframe src={props.mcqLink}
                            frameBorder="0"
                            height="800"
                            style={{ width: '100%', maxWidth: "1000px" }}
                        >

                        </iframe> : null
                    }
                </Col>
            </Row>

        </div>
    )

}




export default TrainingPageLayout

