import React from 'react'
import DeleteChallengeResponseButton from './DeleteChallengeResponseButton'

import { Card } from 'react-bootstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'


//called by ChallengeResponseDetails
function ChallengeResponseDetailCard({ challenge_response, id, returnURL = '/myscenarios' }) {


    return (

        <Card >
            <Card.Body>
                <Card.Title as="div" style={{ color: "black " }}><h3>{challenge_response.title}</h3></Card.Title>
                <Card.Text as="div" style={{ color: "black " }} >
                    <div className="className grey-text">{moment(challenge_response.createdAt.toDate()).calendar()}</div>
                    <p></p>
                    <div>{challenge_response.summary}</div>
                    <p></p>
                    {challenge_response.sentence0 ?
                        <div>Sentence0: {challenge_response.sentence0}<br></br></div>
                        : null
                    }
                    {challenge_response.sentence1 ?
                        <div>Sentence1: {challenge_response.sentence1}<br></br></div>
                        : null
                    }
                    {challenge_response.sentence2 ?
                        <div>Sentence2: {challenge_response.sentence2}<br></br></div>
                        : null
                    }
                    {challenge_response.sentence3 ?
                        <div>Sentence3: {challenge_response.sentence3}<br></br></div>
                        : null
                    }
                    {challenge_response.sentence4 ?
                        <div>Sentence4: {challenge_response.sentence4}<br></br></div>
                        : null
                    }
                    {challenge_response.sentence5 ?
                        <div>Sentence5: {challenge_response.sentence5}<br></br></div>
                        : null
                    }
                    {challenge_response.sentence6 ?
                        <div>Sentence6: {challenge_response.sentence6}<br></br></div>
                        : null
                    }
                    {challenge_response.sentence7 ?
                        <div>Sentence7: {challenge_response.sentence7}<br></br></div>
                        : null
                    }
                    {challenge_response.sentence8 ?
                        <div>Sentence8: {challenge_response.sentence8}<br></br></div>
                        : null
                    }
                    {challenge_response.sentence9 ?
                        <div>Sentence9: {challenge_response.sentence9}<br></br></div>
                        : null
                    }
                    {challenge_response.additional ?
                        <div>Additional Response: {challenge_response.additional}<br></br></div>
                        : null
                    }
                    <br></br>
                    <p></p><p></p>

                </Card.Text>
                <p></p><p></p>
                <Card.Link as={Link} to={returnURL} className="float-left">Back</Card.Link>
                <Card.Link as={Link} to="#" className="float-right"><DeleteChallengeResponseButton challenge_response={challenge_response} id={id} returnURL={returnURL} /></Card.Link>

            </Card.Body>
        </Card >

    )
}

export default ChallengeResponseDetailCard
