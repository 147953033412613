import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { Container, Col } from 'react-bootstrap'
import LogDetailCard from './LogDetailCard'
const myLogs = 'myLogs'

const LogDetails = (props) => {
    const { auth, log, id } = props


    if (!auth.uid) return <Redirect to='/signin' />
    if (log) {
        console.log(props)
        return (
            <Container className='mt-5'>
                <Col md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }}    >
                    <LogDetailCard log={log} id={id} />
                </Col>


            </Container >

        )
    }
    else {
        return (
            <div className="container center">
                <p>Loading log...</p>
            </div>
        )

    }

}



//react router dom passes following value match.params.id to props of LogDetails because of this line in App.js:
//     <Route path='/log/:id' component={LogDetails} />
//Thus redux can access this through the optional built-in 'ownProps' argument in mapStateToProps

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const logs = state.firestore.data[myLogs]
    const log = logs ? logs[id] : null
    return {
        id: id,
        log: log,
        auth: state.firebase.auth
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => {
        if (props.auth.uid) {
            return [
                {
                    collection: 'users',
                    doc: props.auth.uid,
                    subcollections: [{ collection: 'logs', orderBy: ['createdAt', 'desc'] }],
                    storeAs: myLogs
                }
            ]
        }

    })
)(LogDetails)