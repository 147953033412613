const initState = {}

const challengeReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_CHALLENGE':
            console.log('created challenge', action.challenge)
            return state;
        case 'CREATE_CHALLENGE_ERROR':
            console.log('create challenge error', action.error)
            return state;
        case 'CREATE_CHALLENGE_RESPONSE':
            console.log('created challenge response', action.challenge)
            return state;
        case 'CREATE_CHALLENGE_RESPONSE_ERROR':
            console.log('create challenge response error', action.error)
            return state;
        case 'DELETE_CHALLENGE':
            console.log('deleted challenge', action.challenge)
            return state;
        case 'DELETE_CHALLENGE_ERROR':
            console.log('delete challenge error', action.error)
            return state;
        case 'DELETE_CHALLENGE_RESPONSE':
            console.log('deleted challenge response', action.challenge)
            return state;
        case 'DELETE_CHALLENGE_RESPONSE_ERROR':
            console.log('delete challenge response error', action.error)
            return state;


        case 'EDIT_CHALLENGE':
            console.log('edit challenge', action.challenge)
            return state;
        case 'EDIT_CHALLENGE_ERROR':
            console.log('edit challenge error', action.error)
            return state;
        default:
            return state;


    }
}

export default challengeReducer