import firebase from '../../config/fbConfig'
import queryString from 'query-string';

export const signIn = (credentials) => {
    return (dispatch, getState, { getFirebase }) => {
        //return (dispatch, getState) => {
        const firebase = getFirebase();
        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).catch((error) => {
            if (error.code === 'auth/wrong-password') {
                //I wrote the following as inline arrow function because I thought I had to re-use this. But
                //turns out firebaseui handles this automatically so I only have to define this for my own
                //signInWithEmailAndPassword
                ((email) => {
                    // Check if User has signed up with a OAuthProvider
                    firebase.auth().fetchSignInMethodsForEmail(email).then((result) => {
                        console.log('providers for email', result)
                        var provider
                        switch (result[0]) {
                            case firebase.auth.GoogleAuthProvider.PROVIDER_ID:
                                provider = new firebase.auth.GoogleAuthProvider()
                                break;
                            case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
                                provider = new firebase.auth.FacebookAuthProvider()
                                break;
                            default:
                                provider = null
                        }
                        if (provider) {
                            dispatch({ type: 'LOGIN_ERROR', error: { code: 'ALTERNATIVE AUTH USED', message: 'Please use previous sign up method: ' + result[0] } })
                            // … show OAuthProvider Login Button
                            firebase.auth().signInWithPopup(provider).then(function (result) {
                                dispatch({ type: 'LOGIN_SUCCESS' })
                            }).catch(function (error) {
                                console.log('innerloop login error')
                                dispatch({ type: 'LOGIN_ERROR', error: error });
                            });
                        }
                        else {
                            //no other provider, just show incorrect password.
                            console.log('outerloop login error')
                            dispatch({ type: 'LOGIN_ERROR', error })
                        }
                    });
                })(credentials.email)
            }
            else {
                console.log('sign in error outer loop', error)
                dispatch({ type: 'LOGIN_ERROR', error });
            }
        })
    }
}



export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            dispatch({ type: 'SIGNOUT_SUCCESS' })
        });
    }
}


export const setUserGroup = (email, group) => {
    console.log('authActions inside setUserGroup', group)
    const setUserGroup = firebase.functions().httpsCallable('setUserGroup');
    return setUserGroup({ email, group }).then((result) => {
        console.log('add userGroup result', result)

    })
        .catch((error) => {
            console.log(`error: ${JSON.stringify(error)}`);
        });
}

export const signUp = (newUser) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then(resp => {
            //  var username = newUser.username || 'Anonymous'.concat(Math.floor(Math.random() * 100000).toString());

            //add custom claims
            setUserGroup(newUser.email, newUser.groupCode)
            return firestore.collection('users').doc(resp.user.uid).set({
                username: '',
                initials: '',
                terms: newUser.terms === "on" ? true : false,
                //should be undefined if they do not put one in.
                group: newUser.groupCode ? newUser.groupCode : null
            });
        }).then(() => {
            dispatch({ type: 'SIGNUP_SUCCESS' });
        }).catch((error) => {
            dispatch({ type: 'SIGNUP_ERROR', error });
        });
    }
}



//processSuccessfulSignIn is callback when user has successfully signed in with alternative credentials (e.g., google login, twitter etc.)
export const processSuccessfulSignIn = (userCredential, termsCheckboxState, groupCode) => { //termscheckboxstate registering as undefined not sure why but maybe I don't want to propagate agreement anyway as the usernamd and password could be someone else
    const terms = termsCheckboxState === 'on' ? true : false

    console.log('entering processSuccessfulSignIn', termsCheckboxState, terms, groupCode)
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        if (userCredential.additionalUserInfo.isNewUser) {
            var group = null
            const firebase = getFirebase();
            const validateGroup = firebase.functions().httpsCallable('validateGroup');
            validateGroup(groupCode).then((result) => {
                console.log('Called validateGroup and returned', result.data)
                console.log('userCredential', userCredential.user.email, groupCode)
                if (result.data) {
                    setUserGroup(userCredential.user.email, groupCode)
                }

            }).catch((err) => { console.log('error when processing validateGroup') })

            // var username = 'Anonymous'.concat(Math.floor(Math.random() * 100000).toString());
            firestore.collection('users').doc(userCredential.user.uid).set({
                username: '',
                initials: '',
                terms: terms,
                group: group
            }).then(() => {
                dispatch({ type: 'SIGNUP_SUCCESS' });
            }).catch((error) => {
                dispatch({ type: 'SIGNUP_ERROR', error });
            });
        }

    }
}


export const createUserGroup = (userGroup) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //make async call to database (writing 'userGroup' is  shorthand for 'userGroup: userGroup' )
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid
        const docLocation = firestore.collection('users').doc(authorId).collection('userGroups')

        docLocation.add({ //if userGroups does not exist, it will create a new collection
            ...userGroup, //this is like putting title: userGroup.title and content: userGroup.summary
            authorId: authorId,
            createdAt: new Date(),
            //new fields get automatically added. I just needed to refresh browser for change to take place

        }).then(() => {
            dispatch({ type: 'CREATE_LOG', userGroup })

        }).catch((error) => {
            dispatch({ type: 'CREATE_LOG_ERROR', userGroup })
        })
    }

}


/*New user with anonymous id created for public experiments. 
A new userFolder, e.g., usersStudentExpt1 will have to be manually added in Firebase with permissions set to general 
userFolder is string name of userFolder
match /usersStudentExpt1/{docName} {
                allow read, create, write: if true
    }    
*/

