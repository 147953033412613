import React from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import SubscribeLong from '../publicpages/SubscribeLong'

const images = require.context('../../../public/articleImages', true);


function ArticleLayout({ tags = [],
    groups = [],
    title = '',
    mainText = '',
    imageName = '',
    width = "60%",
    quote = '',
    citation = '',
    videoLink = '',
    id = '' }) {


    let image = imageName ? images(`./${imageName}`) : null


    return (

        <div className="image-background plainBlockQuote " style={{ backgroundColor: "white" }} >

            <Button className="text-left ml-3" variant="link" style={{ color: "#007399" }} href='https://www.diplomacydojo.com/'>Back to Home</Button>

            <Container>
                <Row ><Col className="mt-5 mb-5 pr-5" lg={{ span: 9, offset: 0 }} sm={{ span: 12, offset: 0 }}>
                    <Row>

                        {imageName ?
                            <Row >
                                <Col className="text-center"><img src={image} width={width} alt={''} /></Col>

                            </Row> : null}




                        <Row>
                            <Col className="text-center mt-5"><h1><b>{title}</b></h1>
                            </Col></Row>

                        {quote ?
                            <Row><Col>
                                <h5><div className='text-center'>"{quote}"</div>
                                    <div className='text-center'>--{citation}</div></h5>

                            </Col></Row> : null
                        }
                    </Row>

                    <Row>
                        <div dangerouslySetInnerHTML={{ __html: mainText }}>
                        </div>
                    </Row>
                    {videoLink ?
                        <Row >
                            <Col className="mt-5">
                                <div className="player-wrapper" >
                                    <ReactPlayer
                                        url={videoLink}

                                        playing={false}
                                        width='100%'
                                        height='100%'
                                        controls={true}
                                        style={{ backgroundColor: 'white' }}
                                        className="player" />

                                </div>
                            </Col>
                        </Row> : null}

                </Col>

                    <Col className="my-5" lg={{ span: 3, offset: 0 }} sm={{ span: 12, offset: 0 }} >
                        <Row>
                            <SubscribeLong />
                        </Row>
                        <Row><Col className="mt-5 bg-light" >
                            <h1><b>Want help for your organization?</b></h1>
                            <h5>
                                <a href="contact" >Contact Us</a></h5>
                            <h5 >or email us at: <a href={"mailto:info@diplomacydojo.com"}>info@diplomacydojo.com</a></h5>

                        </Col></Row>
                    </Col>


                </Row>

                <Row><Col style={{ marginBottom: '10rem' }}></Col></Row>
            </Container>
        </div >
    )
}

export default ArticleLayout
