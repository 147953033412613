import React, { Component } from 'react'
import ReactToPrint from 'react-to-print';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import { Col, Button, Container, Row, Tab, Nav } from 'react-bootstrap'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import TextBox from "../../bot_components/TextBox"
import Instructions, {
    InterpretationsInstructions, InterpretationsTooltip,
    EmotionsInstructions, EmotionsTooltip,
    ValuesInstructions, ValuesTooltip,
    OthersValuesInstructions,
    AspirationsInstructions, AspirationsTooltip,
    ObservationsInstructions, ObservationsTooltip,
    StrategiesInstructions, StrategiesTooltip,
} from "./Instructions"
import CheckboxForm from '../forms/CheckboxForm'
import CheckboxFormExpandable from '../forms/CheckboxFormExpandable'
import TooltipCustom from "../../Utilities/TooltipCustom"
import "../../index.css"
import CheckDeleteModal from '../../Utilities/CheckDeleteModal'
import { removeThumbsDownFromBotFeedbackList, displayBotFeedbackList, arr2str_with_and, arr2str} from '../forms/formFunctions'


import "./ScenarioForm.css"


const defaultTooltipColor = "#0099CC"

class ScenarioFormShortSummary extends Component {
    constructor(props) {
        super(props);
        // deleteCounter updates so that this component rerenders with deleted items removed.
        this.state = { deleteCounter: 0 };
      
    }
    static defaultProps = {
        callbackFunc: ()=>{}
    }

    processItemDeletion = () => {
         this.setState((prevState) => ({ deleteCounter: prevState.deleteCounter + 1 }), ()=>this.props.callbackFunc(this.props.botFeedbackList)) 
         
    }


render(){
    return (
        <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0, offset: 0 }} className='pl-4 pr-4 mt-5 mx-5'  >
            <h5><strong>Final Message</strong></h5>
            <h5 className='mb-5'> {this.props.finalMessage}</h5>
            <h5><strong>Details:</strong></h5>
            <h5> {this.props.pseudoFeelingsLabel}</h5>
            <h5> {this.props.ownFeelingsLabel}</h5>
            <h5> {this.props.ownNeedsLabel}</h5>
            <h5> {this.props.othersNeedsLabel}</h5>
            <h5> {this.props.aspirationsLabel}</h5>
            <h5> {this.props.observationsLabel}</h5>
            <h5 className='mb-5'> {this.props.strategiesLabel}</h5>
            <h5><strong>AI Feedback:</strong></h5>
            <ul>
                {displayBotFeedbackList(this.props.botFeedbackList, this.processItemDeletion, true)}
            </ul>
        </Col>

    )

}
}


/**
 * ScenarioForm class
 * Allows user to create or edit a new scenario and stores it in firestore through callbackFunc defined in parent class.
 * passes a prop function callbackFunc to get arr of checkbox values to CollapsibleCheckboxForm which is CheckboxForm wrapped in a higher order Collapsible Modal
 */


/**
 * @constructor
 * @param {*} props.scenario, all attributes related to the scenario being passed in.
 */

class ScenarioFormShort extends Component {

    /**
     * @constructor
     * @param {boolean} this.props.scenario.isPublic determines whether the scenario is public or private and thus which collection its stored in
     * @param {array} this.props.scenario.tags, list of tags relating to scenario
     * * @param {array} this.props.scenario.tags, list of groups for whom scenario is relevant (to be used later to organise different groups)
     * @param {boolean} this.props.scenario.authByAdmin whether scenario was created by Admin
     * @param {boolean} this.props.showAllSteps whether to walk through all steps or whether to just show short version
     * @param {boolean} this.props.parentDoc The doc id of the parentScenario if it is responding to a challenge or public scenario, then title and summary should be fixed
 */

    constructor(props) {
        super(props);
        //note these createRef() need to be assigned to a DOM element (e.g., Col) and not a component. It won't work on a component

        this.ownFeelingsRef = React.createRef();
        this.ownNeedsRef = React.createRef();
        this.othersFeelingsRef = React.createRef();
        this.othersNeedsRef = React.createRef();


        const localData = localStorage.getItem('ScenarioForm' + props.location.pathname)

        const localDataObj = localData ? JSON.parse(localData) : null

        //value of isPublic comes from scenarios, if scenarios is passed in as props. Otherwise define it from props.isPublic, otherwise false
        const isPublicFromProps = this.props.isPublic || false

        //need to define conditional || in case props.scenario does not have the attribute. This won't be caught by defaultProps as scenario does exist
        //and the attribute are 2nd order properties.
        //collpasibleState_X are to control collapsibles so only one is open at a time. controlled via function: close_all_other_collapsibles
        this.state = {

            tags: (localDataObj && localDataObj.hasOwnProperty('tags') && localDataObj.tags) || props.scenario.tags || [],
            groups: (localDataObj && localDataObj.hasOwnProperty('groups') && localDataObj.groups) || props.scenario.groups || [],
            isPublic: props.scenario.isPublic || isPublicFromProps,
            authByAdmin: props.scenario.authByAdmin || false,
            title: (localDataObj && localDataObj.hasOwnProperty('title') && localDataObj.title) || props.scenario.title || '',
            summary: (localDataObj && localDataObj.hasOwnProperty('summary') && localDataObj.summary) || props.scenario.summary || '',
            //judgment: (localDataObj && localDataObj.hasOwnProperty('judgment') && localDataObj.judgment) || props.scenario.judgment || '',
            pseudoFeelings: (localDataObj && localDataObj.hasOwnProperty('pseudoFeelings') && localDataObj.pseudoFeelings) || props.scenario.pseudoFeelings || [],
            ownFeelings: (localDataObj && localDataObj.hasOwnProperty('ownFeelings') && localDataObj.ownFeelings) || props.scenario.ownFeelings || [],
            ownNeeds: (localDataObj && localDataObj.hasOwnProperty('ownNeeds') && localDataObj.ownNeeds) || props.scenario.ownNeeds || [],
            othersFeelings: (localDataObj && localDataObj.hasOwnProperty('othersFeelings') && localDataObj.othersFeelings) || props.scenario.othersFeelings || [],
            othersNeeds: (localDataObj && localDataObj.hasOwnProperty('othersNeeds') && localDataObj.othersNeeds) || props.scenario.othersNeeds || [],
            observation: (localDataObj && localDataObj.hasOwnProperty('observation') && localDataObj.observation) || props.scenario.observation || '',
            acknowledge: (localDataObj && localDataObj.hasOwnProperty('acknowledge') && localDataObj.acknowledge) || props.scenario.acknowledge || '',
            aspiration: (localDataObj && localDataObj.hasOwnProperty('aspiration') && localDataObj.aspiration) || props.scenario.aspiration || '',
            strategies: (localDataObj && localDataObj.hasOwnProperty('strategies') && localDataObj.strategies) || props.scenario.strategies || '',
            request: (localDataObj && localDataObj.hasOwnProperty('request') && localDataObj.request) || props.scenario.request || '',
            finalMessage: (localDataObj && localDataObj.hasOwnProperty('finalMessage') && localDataObj.finalMessage) || props.scenario.finalMessage || '',
            parentDoc: props.parentDoc || null,
            groupName: props.groupName || null,
            showCombineButton: true,
            showCombineModal: false,
            key: (localDataObj && localDataObj.hasOwnProperty('key') && localDataObj.key) || props.scenario.key || 0,
            botFeedbackList: (localDataObj && localDataObj.hasOwnProperty('botFeedbackList') && localDataObj.botFeedbackList) || props.scenario.botFeedbackList || [],
        }
    }

    static defaultProps = {
        recordAttempt: false,
        attemptPathBase: '',

        formTitle: '',
        groupName: null,
        scenario: {
            tags: [],
            groups: [],
            groupName: null,
            isPublic: false,
            authByAdmin: false,
            title: '',
            summary: '',
            //judgment: '',
            pseudoFeelings: [],
            ownFeelings: [],
            ownNeeds: [],
            othersFeelings: [],
            othersNeeds: [],
            observation: '',
            acknowledge: '',
            aspiration: '',
            strategies: '',
            request: '',
            finalMessage: '',
        },
        copyToObservation: true,
        copyToFinalMessage: true,
        showAllSteps: true,
        parentDoc: null,
        showGroups: false,
        showTags: false,
        showAllStepsToggle: false,
        showCombineModal: false,
        showSubmitThanks: false,
        docRef: null

    }  
    
    componentDidMount() {
        this.props.recordBotFeedbackListLength(this.state.botFeedbackList.length)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.botFeedback !== this.props.botFeedback) {
            //remove s_tok field from new feedback from bot, concatenate it to botFeedbackList
            const botFeedback2 = this.props.botFeedback.map((sentenceObj) =>{
                sentenceObj.s_tok = []
                return sentenceObj
            }
            )
            console.log('concatenating new feedback to botFeedbackList, length before:', this.state.botFeedbackList.length)
            this.setState((prevState) => ({ botFeedbackList: prevState.botFeedbackList.concat(botFeedback2) }),
                () => { this.writeToLocalStorage(); this.props.recordBotFeedbackListLength(this.state.botFeedbackList.length) })
        }

        if (prevProps.userFeedbackList !== this.props.userFeedbackList) {
            //new feedback from bot, concatenate it to botFeedbackList
            console.log('removing thumbs down', this.props.userFeedbackList)

           this.setState((prevState) => ({ botFeedbackList: 
                removeThumbsDownFromBotFeedbackList(prevState.botFeedbackList,  this.props.userFeedbackList, this.props.currentOffset)
            }))
        }

    }


    handleSubmit = (e) => {
        e.preventDefault()
        //callbackFunc is action creator defined in scenarioActions.js
        let scenario_to_send = Object.assign({}, this.state);
        //remove collapsible, copyTo and shos state keys from what is sent via callbackFun 
        // this is because createScenario and editScenario write all properties of scenario into the database record, using destructuring, i.e., ...scenario
        Object.keys(scenario_to_send).forEach((key) => {
            if (key.startsWith('collapsibleState') || key.startsWith('copyTo') || key.startsWith('show') || key.startsWith('key'))
                delete scenario_to_send[key]
        })

        console.log('scenario_to_send:', scenario_to_send)
        this.props.callbackFunc(scenario_to_send)
        this.setState({ showSubmitThanks: true })

    }



    make_needs_sentence = () => {
        if (this.state.ownNeeds.length === 0) { return '' }
        else if (this.state.ownFeelings.length >= 1 && this.state.ownNeeds.length >= 1) {
            return 'I felt '.concat(arr2str_with_and(this.state.ownFeelings)).concat(' because I wanted ').concat(arr2str_with_and(this.state.ownNeeds)).concat('. ')
        }
        else if (this.state.ownNeeds.length >= 1) {
            return 'I need '.concat(this.state.ownNeeds.toString()).concat('. ')
        }
    }

    handle_end_punc = (str) => {
        if (!str) { return '' }
        else {
            str = str.trim()
            const lastchar = str.charAt(str.length - 1)
            if (lastchar.match(/^[.,:!?]/g) !== null) {

                str = str.concat(' ')
                console.log(lastchar.match(/^[.,:!?]/g), 'str', str)
            }
            else {
                str = str.concat('. ')
                console.log('final else entered', str)
            }
            return str
        }
    }

    addDescriptionToObservation = () => {
        if (!this.state.Observation && this.state.copyToObservation)
            this.setState({
                observation: this.state.summary,
                copyToObservation: false
            })

    }


    compose_message = () => {
        this.setState({ copyToFinalMessage: true }) // set off the  key for finalMessage so it will re-render

        // const feelings_needs_str = this.make_needs_sentence()

        // const finalMessage = this.handle_end_punc(this.state.observation).concat(feelings_needs_str).concat(this.handle_end_punc(this.state.aspiration)).concat(this.handle_end_punc(this.state.request))
        const combinedMessage = this.handle_end_punc(this.state.observation).concat(this.handle_end_punc(this.state.acknowledge)).concat(this.handle_end_punc(this.state.aspiration)).concat(this.handle_end_punc(this.state.request))
        // const newFinalMessage = this.state.finalMessage.concat(' ').concat(combinedMessage)
        const newFinalMessage = combinedMessage
        this.setState({
            finalMessage: newFinalMessage,
            //copyToFinalMessage: false,
            //showCombineButton: false,
        }, () => this.setState({ copyToFinalMessage: false }))

        console.log('inside compose_message:', this.state.finalMessage, newFinalMessage)
        if (this.state.showCombineModal)
            this.setState({ showCombineModal: false })

    }

    closeCombineModal = (e) => {
        e.stopPropagation()
        e.preventDefault()
        this.setState({ showCombineModal: false });

    }

    showCombineModal = (e) => {

        if (this.state.finalMessage) {
            this.setState({
                showCombineModal: true
            })
            console.log('delete clicked')
            e.preventDefault()
            e.stopPropagation()
        }
        else {
            this.compose_message()
        }

    }





    //writes jsonified state into session storage. To be called after all calls to setState.
    writeToLocalStorage = () => {
        localStorage.setItem('ScenarioForm' + this.props.location.pathname, JSON.stringify(this.state))
    }

    //define prop callbacks ahead of time to prevent re-rendering of checkboxForm, which happens if props are defined inline
    //copyToObservation (which automatically sets observation to description) is set to false after user first types in their own observation

    tagsCallback = (tags) => this.setState({ tags }, this.writeToLocalStorage)
    groupsCallback = (groups) => this.setState({ groups }, this.writeToLocalStorage)
    pseudoFeelingsCallback = (pseudoFeelings) => this.setState({ pseudoFeelings }, this.writeToLocalStorage)
    ownFeelingsCallback = (ownFeelings) => this.setState({ ownFeelings }, this.writeToLocalStorage)
    ownNeedsCallback = (ownNeeds) => this.setState({ ownNeeds }, this.writeToLocalStorage)
    othersFeelingsCallback = (othersFeelings) => this.setState({ othersFeelings }, this.writeToLocalStorage)
    othersNeedsCallback = (othersNeeds) => this.setState({ othersNeeds }, this.writeToLocalStorage)
    observationCallback = (observation) => this.setState({ observation, copyToObservation: false }, this.writeToLocalStorage)
    aspirationCallback = (aspiration) => this.setState({ aspiration }, this.writeToLocalStorage)
    acknowledgeCallback = (acknowledge) => this.setState({ acknowledge }, this.writeToLocalStorage)
    strategiesCallback = (strategies) => this.setState({ strategies }, this.writeToLocalStorage)
    requestCallback = (request) => this.setState({ request }, this.writeToLocalStorage)
    finalMessageCallback = (finalMessage) => this.setState({ finalMessage }, this.writeToLocalStorage)
    showScenarioFormCallback = (botFeedbackList) => this.setState({ botFeedbackList }, this.writeToLocalStorage)






    //function for showAllStepsToggle button that either expands all the detailed steps or collapses them.
    changeShowAllStepsState = () => {
        if (this.state.showAllSteps) {
            this.setState({ showAllSteps: false })
        }
        else {
            this.setState({ showAllSteps: true })
        }

    }

    // method called to handle toggle (next/prev)
    toggleNextButtons = (direction) => {
        console.log('inside toggleNextButton')


        if ((direction === 'next') && (this.state.key < 9)) {
            this.setState((prevState, props) => ({
                key: prevState.key + 1,
                showAllSteps: false
            }), this.writeToLocalStorage);
        }
        else if ((direction === 'prev') && (this.state.key > 0)) {
            this.setState((prevState, props) => ({
                key: prevState.key - 1,
                showAllSteps: false
            }), this.writeToLocalStorage);
        }


    }

    handleSelect = (key) => {
        this.setState({ key: parseInt(key, 10), showAllSteps: false  }, this.writeToLocalStorage);
    }

    deleteAllFeedback = () => { 
        this.setState({ botFeedbackList: [] }, ()=>this.props.recordBotFeedbackListLength(this.state.botFeedbackList.length))
    
    
    }

    render() {

        const { auth, isPublic} = this.props;
   
        //console.log('userFeedbackList', userFeedbackList)
        //console.log('botFeedbackList length', this.state.botFeedbackList.length)
       
        if (!auth.uid && !isPublic) return <Redirect to='/signin' />
        const returnURL = this.props.location.pathname.startsWith("/respondtoscenario") ? '/' : this.props.returnURL

        const finalMessageTooltip = 'Compose your final message here. Again we will help with feedback if anything sounds judgmental.'


        const pseudoFeelingsLabel = (<span>My Interpretations: <i>{arr2str(this.state.pseudoFeelings)}</i></span>)
        const ownFeelingsLabel = (<span>My Emotions: <i>{arr2str(this.state.ownFeelings)}</i></span>)
        const ownNeedsLabel = (<span>My Values: <i>{arr2str(this.state.ownNeeds)}</i></span>)
        const othersNeedsLabel = (<span>Other's Values: <i>{arr2str(this.state.othersNeeds)}</i></span>)
        const observationsLabel = (<span>Situation and Impact: <i>{this.state.observation}</i></span>)
        const aspirationsLabel = (<span>Aspirations: <i>{this.state.aspiration}</i></span>)
        const strategiesLabel = (<span>Strategies: <i>{this.state.strategies}</i></span>)




        const showAllStepsColor = '#FF8C00'
        return (

            <Tab.Container id="left-tabs-example" activeKey={this.state.key} onSelect={this.handleSelect} >
                {!isPublic ? <>
                    < Button variant="link" style={{ color: "#0099CC" }} href={returnURL} > Back</Button>
                </> : null}


                <Row>
                    <Col className="mt-5 px-5">
                        <Container >
                            <Row>
                                <Col sm={{ offset: 3 }} style={{ backgroundColor: 'white', border: "1px solid black" }} className="py-3" >

                                    <h5>SCENARIO: {this.state.summary}</h5>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-5 mr-0" sm={3}>
                        <Nav variant="pills" className="flex-column left_navlinks">
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(0) }} eventKey={0} className={this.state.key === 0 ? 'active' : ''}>Instructions</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(1) }} eventKey={1}>My Interpretations</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(2) }} eventKey={2}>My Emotions</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(3) }} eventKey={3}>My Values</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(4) }} eventKey={4}>Other's Values </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(5) }} eventKey={5}>Aspirations</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(6) }} eventKey={6}>Situation & Impact</Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(7) }} eventKey={7}>Strategies</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(8) }} eventKey={8}>Final Message</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(9) }} eventKey={9}>Summary</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col >

                    <Col sm={9} className="mt-5">
                        <Container>


                            <Row><Col className="px-5 mr-5">
                                <Tab.Content>
                                    <Tab.Pane eventKey={0}>

                                        <Instructions />


                                    </Tab.Pane>
                                    <Tab.Pane eventKey={1}>

                                        <h5 className='mb-3'>
                                            <strong>{InterpretationsInstructions}</strong>
                                            <TooltipCustom innertext={InterpretationsTooltip} className="" color={defaultTooltipColor} />
                                        </h5>

                                        < CheckboxForm
                                            callbackFunc={this.pseudoFeelingsCallback}
                                            checkboxFilename={'pseudofeelings'}
                                            useHeaders={false}
                                            classNameValues={'h-blue'}
                                            initialCheckedFields={this.state.pseudoFeelings}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={2}>

                                        <Col className='pl-4 pr-4' ref={this.ownFeelingsRef}>
                                            <h5 className='mb-3'><strong>{EmotionsInstructions}</strong>
                                                <TooltipCustom innertext={EmotionsTooltip} className="" color={defaultTooltipColor} />
                                                <div ><a style={{ color: defaultTooltipColor }} href="https://www.diplomacydojo.com/judgmentsquiz">Read More</a></div>
                                            </h5>
                                            <h5> {pseudoFeelingsLabel}</h5>
                                            <h5> {ownFeelingsLabel}</h5>
                                            <CheckboxFormExpandable

                                                callbackFunc={this.ownFeelingsCallback}
                                                label={ownFeelingsLabel}
                                                checkboxFilename={'negfeelingsexpandable'}

                                                classNameValues={'h-blue'}
                                                initialCheckedFields={this.state.ownFeelings}
                                            />
                                        </Col>


                                    </Tab.Pane>


                                    <Tab.Pane eventKey={3}>

                                        <Col className='mb-5 pl-4 pr-4' ref={this.ownNeedsRef} >
                                            <h5 className='mb-3'><strong>{ValuesInstructions}</strong>
                                                <TooltipCustom innertext={ValuesTooltip} className="" color={defaultTooltipColor} />

                                            </h5>
                                            <h5> {ownFeelingsLabel}</h5>
                                            <CheckboxFormExpandable
                                                callbackFunc={this.ownNeedsCallback}
                                                label={ownNeedsLabel}
                                                checkboxFilename={'needs'}
                                                classNameValues={'h-blue'}
                                                initialCheckedFields={this.state.ownNeeds}
                                            />
                                        </Col>

                                    </Tab.Pane>

                                    <Tab.Pane eventKey={4}>
                                        <Col className='mb-5  pl-4 pr-4' ref={this.othersNeedsRef} >
                                            <h5 className='mb-3'><strong>{OthersValuesInstructions}</strong>
                                            </h5>
                                            <CheckboxFormExpandable
                                                callbackFunc={this.othersNeedsCallback}
                                                label={othersNeedsLabel}
                                                checkboxFilename={'needs'}
                                                classNameValues={'h-blue'}
                                                initialCheckedFields={this.state.othersNeeds}                                         
                                            />
                                        </Col>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={5}>
                                        <h5 ><strong>{AspirationsInstructions}</strong>
                                            <TooltipCustom innertext={AspirationsTooltip} className="" color={defaultTooltipColor} />
                                            <div ><a style={{ color: defaultTooltipColor }} href="https://www.diplomacydojo.com/aspirations">Read More</a></div>
                                        </h5>

                                        <h5 className='mb-5'> {ownNeedsLabel}</h5>
                                        <TextBox
                                            callbackFunc={this.aspirationCallback}
                                            allowUserFeedback={true}
                                            showFinaliseButton={false}
                                            textAreaHeight={2}
                                            prompt={''}
                                            placeholder={'write what you would say here...'}
                                            initialTextValue={this.state.aspiration}
                                            recordAttempt={this.props.recordAttempt}
                                            attemptPath={this.props.attemptPathBase + 'aspirations'}
                                            showSaveTextButton={true}
                                            saveTextDocRef={this.props.docRef}
                                            saveTextArrayName= {'aspirations'}
                                        />


                                    </Tab.Pane>


                                    <Tab.Pane eventKey={6}>
                                        <h5 className='mb-3'><strong>{ObservationsInstructions}</strong>
                                            <TooltipCustom innertext={ObservationsTooltip} className="" color={defaultTooltipColor} />
                                            <div ><a style={{ color: defaultTooltipColor }} href="https://www.diplomacydojo.com/objectivity">Read More</a></div>
                                        </h5>
                                        <h5> {ownFeelingsLabel}</h5>
                                        <h5 className='mb-5'> {ownNeedsLabel}</h5>
                                        <TextBox
                                            callbackFunc={this.observationCallback}
                                            allowUserFeedback={true}
                                            showFinaliseButton={false}
                                            textAreaHeight={2}
                                            prompt={''}
                                            placeholder={'write what you would say here...'}
                                            initialTextValue={this.state.observation}
                                            recordAttempt={this.props.recordAttempt}
                                            attemptPath={this.props.attemptPathBase + 'observations'}
                                            showSaveTextButton={true}
                                            saveTextDocRef={this.props.docRef}
                                            saveTextArrayName={'observations'}
                                        />


                                    </Tab.Pane>

                                    <Tab.Pane eventKey={7}>
                                        <h5 className='mb-3'><strong>{StrategiesInstructions}</strong>
                                            <TooltipCustom innertext={StrategiesTooltip} className="" color={defaultTooltipColor} />
                                        </h5>
                                        <h5> {ownFeelingsLabel}</h5>
                                        <h5> {ownNeedsLabel}</h5>
                                        <h5> {ownNeedsLabel}</h5>
                                        <h5 className='mb-5'> {aspirationsLabel}</h5>
                                        <TextBox
                                            callbackFunc={this.strategiesCallback}
                                            allowUserFeedback={true}
                                            showFinaliseButton={false}
                                            textAreaHeight={2}
                                            prompt={''}
                                            placeholder={'write what you would say here...'}
                                            initialTextValue={this.state.strategies}
                                            recordAttempt={this.props.recordAttempt}
                                            attemptPath={this.props.attemptPathBase + 'strategies'}
                                            showSaveTextButton={true}
                                            saveTextDocRef={this.props.docRef}
                                            saveTextArrayName={'strategies'}
                                        />


                                    </Tab.Pane>
                                    <Tab.Pane eventKey={8}>


                                        <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0, offset: 0 }} className='pl-4 pr-4'  >
                                            <h5><strong>Compose your final message here</strong>
                                                <TooltipCustom innertext={finalMessageTooltip} className="float-left" color={defaultTooltipColor} />
                                            </h5>
                                            <h5> {pseudoFeelingsLabel}</h5>
                                            <h5> {ownFeelingsLabel}</h5>
                                            <h5> {ownNeedsLabel}</h5>
                                            <h5> {othersNeedsLabel}</h5>
                                            <h5> {aspirationsLabel}</h5>
                                            <h5> {observationsLabel}</h5>
                                            <h5 className='mb-5'> {strategiesLabel}</h5>

                                            < div className="input-field">
                                                <TooltipCustom innertext={"Automatically combine all your text from above into a final message."} color={showAllStepsColor} className="float-left" />
                                                <Button style={{ background: showAllStepsColor, border: 0 }} onClick={this.showCombineModal} disabled={!this.state.showCombineButton}>Combine Previous Steps into Final Message</Button>
                                                <CheckDeleteModal show={this.state.showCombineModal} closeModal={this.closeCombineModal} handleYes={this.compose_message} text="Are you sure you want to overwrite what you have by combining your above sections?" />
                                            </div>
                                            <TextBox
                                                key={this.state.copyToFinalMessage}
                                                callbackFunc={this.finalMessageCallback}
                                                allowUserFeedback={true}
                                                showFinaliseButton={false}
                                                prompt={''}
                                                initialTextValue={this.state.finalMessage}
                                                defaultValue={this.state.finalMessage}
                                                textAreaHeight={5}
                                                recordAttempt={this.props.recordAttempt}
                                                attemptPath={this.props.attemptPathBase + 'finalMessage'}
                                                showSaveTextButton={true}
                                                saveTextDocRef={this.props.docRef}
                                                saveTextArrayName={'finalMessage'}
                                            />

                                        </Col>
                                        {/** 
                                        <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0, offset: 0 }} className='pl-4 pr-4'  >
                                            <div className="input-field text-right">
                                                <TooltipCustom innertext={submitTooltip} color={'#ff7000'} />
                                                <Button onClick={this.handleSubmit} style={{ background: '#ff7000', color: { defaultTooltipColor }, border: 0 }}>Submit</Button>
                                            </div>

                                            {this.state.showSubmitThanks ? <h5><div style={{ color: '#ff7000' }}>Thanks for submitting your example response to the scenario!</div></h5> : null}
                                        </Col>
                                        */}

                                    </Tab.Pane>
                                    <Tab.Pane eventKey={9}>
                                        <ReactToPrint
                                            trigger={() => {
                                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                // to the root node of the returned component as it will be overwritten.
                                                return <Button href="#">Print Summary</Button>;
                                            }}
                                            content={() => this.componentRef}
                                        />
                                       
                                        <ScenarioFormShortSummary
                                            ref={el => (this.componentRef = el)}
                                            callbackFunc={this.showScenarioFormCallback}
                                            pseudoFeelingsLabel={pseudoFeelingsLabel}
                                            ownFeelingsLabel={ownFeelingsLabel}
                                            ownNeedsLabel={ownNeedsLabel}
                                            othersNeedsLabel={othersNeedsLabel}
                                            aspirationsLabel={aspirationsLabel}
                                            observationsLabel={observationsLabel}
                                            strategiesLabel={strategiesLabel}
                                            finalMessage={this.state.finalMessage}
                                            botFeedbackList={this.state.botFeedbackList}
                                        />
    
                                        <div className = 'text-right mt-3'><Button onClick={this.deleteAllFeedback}>Delete All Feedback</Button></div>
                                    </Tab.Pane>

                                </Tab.Content>
                            </Col></Row >

                            <Row><Col className="mt-3 mr-5 pr-5 text-right mt-5">
                                {this.state.key > 0 ?
                                    <Button className="btn-carousel-progress" onClick={() => this.toggleNextButtons('prev')}><FaAngleLeft /></Button> : null
                                }
                                {this.state.key < 9 ?
                                    <Button className="btn-carousel-progress" onClick={() => this.toggleNextButtons('next')}><FaAngleRight /></Button> : null
                                }
                            </Col></Row>

                        </Container >
                    </Col >
                </Row >

                <Row><Col style={{ marginBottom: "10rem" }}></Col></Row>
            </Tab.Container >

        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.firebase.auth,
        isAdmin: state.auth.isAdmin, //whether current user is admin
        botFeedback: state.bot.botFeedback,
        userInput: state.bot.userInput,
        userFeedbackList: state.bot.userFeedbackList,
        currentOffset: state.bot.currentOffset //the length of botFeedbackList right before bot gave current feedback that user is currently commenting on

    }
}


const mapDispatchToProps = (dispatch) => {
    return {

        recordBotFeedbackListLength: (botFeedbackListLength) => dispatch({ type: 'RECORD_BOT_FEEDBACK_LIST_LENGTH', botFeedbackListLength }),
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScenarioFormShort))

