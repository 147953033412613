import React from 'react'
import { FaInfoCircle } from 'react-icons/fa';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

export default function Instructions() {
    return (
        <span style={{ color: 'black' }}>
            <h5>
                <strong>About the activity</strong>
                <p>
                    This activity helps you prepare for a difficult conversation.
                                                Click on the <FaInfoCircle size="20px" style={{ color: '#0099CC' }} /> icons for more rationale for each step.
                </p>
                <strong>Assistance from Artificial Intelligence (AI)</strong>
                <p>

                    You will have the opportunity to get feedback from an AI on language you use that may trigger defensiveness. When people are triggered, they'll become less likely to hear you and respond in a way you'd like.

                            </p>

                <strong>Exempting phrases from feedback</strong>
                <p>
                    If you want to exempt something from AI feedback, you can write it in curly brackets.
                    For example, imagine you wrote, "He is elderly" and you get AI feedback that "'elderly' seems to be a protected characteristic." However, perhaps the phrase "elderly" is appropriate for your context.
                                To exempt this phrase, you would write, "He is {'{elderly}'}." and the AI will exempt all phrases inside curly brackets from feedback.
                                </p>


                <strong>The AI would love feedback too</strong>
                <p>
                    The AI is not perfect and is still learning too.
                    Please help the AI improve by giving it feedback for anything it says that is incorrect or doesn't make sense. You will see the following icons and also be able to provide optional text message for negative feedback.
                                <FaThumbsDown display={"inline"} style={{ color: "#0099CC" }} className="ml-1" aria-controls="example-collapse-text" size="20px" />
                    <FaThumbsUp display={"inline"} style={{ color: "#0099CC" }} className="ml-1" size="20px" />




                </p>


            </h5>

        </span>

    )
}

export const InterpretationsInstructions = "What interpretations do you have about the situation? "
export const InterpretationsTooltip = "When negative emotions arise, often what we are most immediate aware of are our interpretations of other people. In the following steps we will help you go beyond these interpretations to discover what is important is essential for you about the situation."


export const EmotionsInstructions = "What emotions were behind your interpretations? "
export const EmotionsTooltip = "Emotions are indisputable, they capture only what's going on inside you and don't involve interpretations of others. Research shows that putting words to emotions can help bring calm activate clearer thinking."

export const ValuesInstructions = "What values of yours were at stake? "
export const ValuesTooltip = "Negative emotions arise because a situation is not meeting our values and needs. In this step, we ask you to explore the core issues underlying negative emotions by identify your values that were at stake."
export const OthersValuesInstructions = "What are possible values and needs for the other(s) in this situation? Remember these are only guesses, the only way to really know is to ask. "

export const AspirationsInstructions = "What are your aspirations for the situation? "
export const AspirationsTooltip = "Based on your understanding of the values and needs involved, write your aspiration(s) for the situation. This will frame your conversation. The AI will give you feedback on any language that may trigger defensiveness."

export const StrategiesInstructions = "What are some strategies to meet the aspirations, values, and needs? "
export const StrategiesTooltip = "Here we invite you to think of the many ways you can get aspirations, needs and values met. We might initially think that we needed a specific strategy. However, in reality, there are many strategies to meet values, needs and aspirations. Be creative."

export const ObservationsInstructions = "What is an objective description of the situation and it's impact? "
export const ObservationsTooltip = "Describe situation objectively. Try to stick only to what can be objectively observed on a video recording. If you choose to talk about emotional impact, be sure to link these to the values at stake. We will help give feedback and let you know if we detect any judgmental language."