import React, { useState } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal';
//import { Form, InputGroup, Collapse } from 'react-bootstrap';
import { Form } from 'react-bootstrap';//
//import { CORS_URL, API_URL, USER_FEEDBACK_ENDPOINT, USER_FEEDBACK_THANK_YOU_MSG } from '../../../config/default_config';


import { toast } from 'react-toastify';
import { recordUserFeedback, recordNonLoggedInUserFeedback } from '../../../store/actions/botActions'
import '../../bot_index.css'
/**
 * @class
 * @summary
 * This is the Modal/Pop-up Window Which Loads from Problem Not Detected Button. Called from Feedback class
 * @param {*} props The Property sent from {@link Feedback} class
 * @param {string | number} props.message_id the ID of the message which the user is sending Problem Report For.
 * @param {string} props.message The message the Problem is for.
 */
function ProblemNotDetected(props) {

  var message = props.message;

  /**
   * Controlling Constant to Open/Close the Modal
   */
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(true);
  var formValues = {
    name: "",
    email: "",
    message: message,
    userFeedbackStr: ""
  }
  /**
   * @summary
   * Handles Close of the Window
   */
  const handleClose = () => setShow(false);
  /**
  * @summary
  * Handles Show of the Window
  */
  const handleShow = () => setShow(true);
  var msgSent = false;
  /**
   * @function
   * @async
   * @summary
   * The function used to send the values to the API
   * If successful displays a success toast, Else Error Toast.
   * Uses the following properties
   * @property {{}} data The data sent to the API
   * @property {string | number} data.message_id The message_id in the data.
   * @property {number} data.user_feedback_value=-1 The value sent
   * @property {string} data.user_feedback_str The user input towards the issue.
   * @property {string} url The url of the API send to.
   * @property {function} props.sendToAPI Function used from {@link Feedback.sendToAPI} to send Request. This was for AWS and is no longer used now that we are storing in Firebase
   * 
   */
  const reportIssue = async () => {
    const textInput = open ? message : ''
    props.recordUserFeedback({
      user_feedback_str: formValues.userFeedbackStr,
      textInput: textInput,
      email: formValues.email,
      user_feedback_value: 'general',
    })
    toast.success("Thank you. If you included your email, we will get back to you .");
    msgSent = true;
    handleClose();



  }
  /**
   * @function
   * @param {*} messageThe userInput displayed.
   * @summary
   * A way to hide or open on display the userInput for which the user is giving Problem Report Upon.
   */
  const CollapseInput = (message) => {


    return (
      <div className="my-5">
        <input
          type="checkbox"
          name={'useMessage'}
          checked={open ? true : false}
          onChange={() => setOpen(!open)}
          id={"useMessage"}
        /> Include your recent text input with your feedback. (This will help us understand your feedback better.)

        {open ?
          <>
            <div id="collapse-text" className="mt-3 font-italic">
              RECENT INPUT: {message}
            </div>
          </>
          : null}
      </div>
    );
  }


  /**
   *
   * @param {*} e Event that checks whether user inputs something.
   * Updates the values in Forms when user enters something on the form.
   */
  const handleOnChange = (e) => {
    formValues = {
      ...formValues,
      [e.target.name]: e.target.value
    }

  }

  /**
   * HTML layout of the form.
   */
  return (
    <>

      <Button hidden={msgSent} className="pl-0" variant="link" style={{ color: "#007399" }} onClick={handleShow}>
        <i>Questions or comments? Let us know.</i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>How can we improve?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-left">

            <Form className="ProblemData">
              {CollapseInput(message)}
              <Form.Group controlId="ControlTextarea1">
                <Form.Label>Did we miss something? Did we confuse you? Please give us any general feedback and let us know how we can help you better.</Form.Label>
                <Form.Control as="textarea" rows="10" name="userFeedbackStr" onChange={handleOnChange.bind(this)} />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>If you'd like to get a response from our team, please enter your email (optional). We'll never share your email with anyone else.</Form.Label>
                <Form.Control type="email" rows="1" name="email" onChange={handleOnChange.bind(this)} />
              </Form.Group>
            </Form>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit" onClick={reportIssue}>
            Send
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );


}


const mapStateToProps = (state) => {
  return {

    auth: state.firebase.auth,

  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    recordUserFeedback: (userFeedback) => dispatch(recordUserFeedback(userFeedback)),
    // recordNonLoggedInUserFeedback: (userFeedback) => dispatch(recordNonLoggedInUserFeedback(userFeedback, userId, userIdPathName)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProblemNotDetected)

