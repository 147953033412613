import React from 'react'
import { Container, Row } from 'react-bootstrap'

class WorkshopSurvey extends React.Component {

  componentDidMount() {
    let el = document.createElement("script");
    el.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd9GqnBM27_2BgYy7UGVAO3U7Jao4nG68F0s_2BCaH7swaa6r.js";
    document.body.appendChild(el);
  }

  render() {
    return <Container flex className="pageContainer">
      <Row className="mt-5">

        <div id="smcx-sdk"></div>

      </Row>
    </Container>
  }
}
export default WorkshopSurvey