import React, { Component } from 'react'


import { Col, Button, Row } from 'react-bootstrap'
import HigherOrderCollapsible from '../../Utilities/HigherOrderCollapsible'
import TextBox from "../../bot_components/TextBox"
import { connect } from 'react-redux'
import { createChallengeResponse } from '../../store/actions/challengeActions'
import { Redirect } from 'react-router-dom'

const CollapsibleTextBox = HigherOrderCollapsible(TextBox)



/**
 * ChallengeForm class
 * creates a series higherorder collapsible forms challenge and stores it in firestore through callbackFunc defined in parent class.
  */


//child component of Dashboard (the component routed to '/' which is the main page) 
class RespondToChallenge extends Component {
    /**
     * @constructor
     * @param {boolean} this.props.sentencesToTranslate. List of sentencesToTranslate that need to be translated.
 */
    constructor(props) {
        //don't need to set collapsibleState_sentence[n] states because they get automatically created in close_all_other_collapsibles
        //the first time a collapsible is opened.
        super(props);

        this.collapsibleList = []
        this.state = {
            title: props.location.state.challenge.title || '',
            summary: props.location.state.challenge.summary || '',
            sentence0: '',
            collapsibleState_0: false,
            sentence1: '',
            collapsibleState_1: false,
            sentence2: '',
            collapsibleState_2: false,
            sentence3: '',
            collapsibleState_3: false,
            sentence4: '',
            collapsibleState_4: false,
            sentence5: '',
            collapsibleState_5: false,
            sentence6: '',
            collapsibleState_6: false,
            sentence7: '',
            collapsibleState_7: false,
            sentence8: '',
            collapsibleState_8: false,
            sentence9: '',
            collapsibleState_9: false,
            parentDoc: props.location.state.challenge.id || null,
            additional: ''
        }
    }


    static defaultProps = {
        sentencesToTranslate: ['', '', '', '', '', '', '', '', '', '']
    }


    close_all_other_collapsibles = (currentCollapsible) => {
        console.log(currentCollapsible)
        const collapsibleList = [
            'collapsibleState_0',
            'collapsibleState_1',
            'collapsibleState_2',
            'collapsibleState_3',
            'collapsibleState_4',
            'collapsibleState_5',
            'collapsibleState_6',
            'collapsibleState_7',
            'collapsibleState_8',
            'collapsibleState_9',

        ]
        collapsibleList.forEach(el => {
            console.log('inside forEach: ', el)
            if (el !== currentCollapsible) {
                if (this.state[el] === true) {
                    this.setState({ [el]: false })
                    console.log('el', el)
                }
            }
            else {
                this.setState({ [el]: true })
                console.log('el2', el)
            }
        })

    }

    signalOpen = (showState, id) => {
        if (showState)
            this.close_all_other_collapsibles('collapsibleState_' + id)
        else
            this.setState({ ['collapsibleState_' + id]: false })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        //callbackFunc is action creator defined in challengeActions.js
        console.log(this.props)
        let translated_sentences = Object.assign({}, this.state);
        //remove collapsible, copyTo and shos state keys from what is sent via callbackFun 
        // this is because createScenario and editScenario write all properties of challenge into the database record, using destructuring, i.e., ...challenge
        Object.keys(translated_sentences).forEach((key) => {
            if (key.startsWith('collapsibleState') || key.startsWith('show'))
                delete translated_sentences[key]
        })


        console.log('translated_sentences:', translated_sentences)
        this.props.createChallengeResponse(translated_sentences)
        const returnURL = '/myscenarios'
        if (this.props.history) this.props.history.push(returnURL) //return to the home route
    }

    callback0 = (val) => this.setState({ sentence0: val })
    callback1 = (val) => this.setState({ sentence1: val })
    callback2 = (val) => this.setState({ sentence2: val })
    callback3 = (val) => this.setState({ sentence3: val })
    callback4 = (val) => this.setState({ sentence4: val })
    callback5 = (val) => this.setState({ sentence5: val })
    callback6 = (val) => this.setState({ sentence6: val })
    callback7 = (val) => this.setState({ sentence7: val })
    callback8 = (val) => this.setState({ sentence8: val })
    callback9 = (val) => this.setState({ sentence9: val })
    callback9 = (val) => this.setState({ sentence9: val })
    additionalCallback = (val) => this.setState({ additional: val })

    render() {
        const sentenceToTranslate0 = this.props.location.state.challenge.sentence0
        const sentenceToTranslate1 = this.props.location.state.challenge.sentence1
        const sentenceToTranslate2 = this.props.location.state.challenge.sentence2
        const sentenceToTranslate3 = this.props.location.state.challenge.sentence3
        const sentenceToTranslate4 = this.props.location.state.challenge.sentence4
        const sentenceToTranslate5 = this.props.location.state.challenge.sentence5
        const sentenceToTranslate6 = this.props.location.state.challenge.sentence6
        const sentenceToTranslate7 = this.props.location.state.challenge.sentence7
        const sentenceToTranslate8 = this.props.location.state.challenge.sentence8
        const sentenceToTranslate9 = this.props.location.state.challenge.sentence9
        if (!this.props.auth.uid) return <Redirect to='/signin' />
        return (
            <div >
                <Row>
                    <Col md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }} className=' mt-5 pl-4 pr-4' >
                        <div>
                            <div className="input-field text-right" ><Button variant="link" style={{ color: "#0099CC" }} href={'/'}>CANCEL</Button></div>
                            <h4>Message Makeover: {this.props.location.state.challenge.title} </h4>


                            <br></br>
                            <h5>{this.props.location.state.challenge.summary}</h5>
                            <br></br>

                        </div>
                    </Col>


                    <Col md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }} className='mt-1 pl-4 pr-4' >
                        {sentenceToTranslate0 ?
                            <>
                                <h5 style={{ color: '#0059b3' }}><i>Try to rephrase the following sentences more diplomatically (click to expand):</i></h5>
                                <CollapsibleTextBox
                                    callbackFunc={this.callback0}
                                    label={sentenceToTranslate0 + ' ->  ' + this.state.sentence0}
                                    allowUserFeedback={true}
                                    showFinaliseButton={false}
                                    prompt={''}
                                    textAreaHeight={1}
                                    placeholder={'write the diplomatic version here...'}
                                    initialTextValue={this.state.sentence0}
                                    showState={this.state.collapsibleState_0}
                                    signalOpenCallback={this.signalOpen}
                                    id={'0'}
                                /></> : null}
                        {sentenceToTranslate1 ?
                            <CollapsibleTextBox
                                callbackFunc={this.callback1}
                                label={sentenceToTranslate1 + ' ->  ' + this.state.sentence1}
                                allowUserFeedback={true}
                                showFinaliseButton={false}
                                prompt={''}
                                textAreaHeight={1}
                                placeholder={'write the diplomatic version here...'}
                                initialTextValue={this.state.sentence1}
                                showState={this.state.collapsibleState_1}
                                signalOpenCallback={this.signalOpen}
                                id={'1'}
                            /> : null}
                        {sentenceToTranslate2 ?
                            <CollapsibleTextBox
                                callbackFunc={this.callback2}
                                label={sentenceToTranslate2 + ' ->  ' + this.state.sentence2}
                                allowUserFeedback={true}
                                showFinaliseButton={false}
                                prompt={''}
                                textAreaHeight={1}
                                placeholder={'write the diplomatic version here...'}
                                initialTextValue={this.state.sentence2}
                                showState={this.state.collapsibleState_2}
                                signalOpenCallback={this.signalOpen}
                                id={'2'}
                            /> : null}
                        {sentenceToTranslate3 ?
                            <CollapsibleTextBox
                                callbackFunc={this.callback3}
                                label={sentenceToTranslate3 + ' ->  ' + this.state.sentence3}
                                allowUserFeedback={true}
                                showFinaliseButton={false}
                                prompt={''}
                                textAreaHeight={1}
                                placeholder={'write the diplomatic version here...'}
                                initialTextValue={this.state.sentence3}
                                showState={this.state.collapsibleState_3}
                                signalOpenCallback={this.signalOpen}
                                id={'3'}
                            /> : null}
                        {sentenceToTranslate4 ?
                            <CollapsibleTextBox
                                callbackFunc={this.callback4}
                                label={sentenceToTranslate4 + ' ->  ' + this.state.sentence4}
                                allowUserFeedback={true}
                                showFinaliseButton={false}
                                prompt={''}
                                textAreaHeight={1}
                                placeholder={'write the diplomatic version here...'}
                                initialTextValue={this.state.sentence4}
                                showState={this.state.collapsibleState_4}
                                signalOpenCallback={this.signalOpen}
                                id={'4'}
                            /> : null}
                        {sentenceToTranslate5 ?
                            <CollapsibleTextBox
                                callbackFunc={this.callback5}
                                label={sentenceToTranslate5 + ' ->  ' + this.state.sentence5}
                                allowUserFeedback={true}
                                showFinaliseButton={false}
                                prompt={''}
                                textAreaHeight={1}
                                placeholder={'write the diplomatic version here...'}
                                initialTextValue={this.state.sentence5}
                                showState={this.state.collapsibleState_5}
                                signalOpenCallback={this.signalOpen}
                                id={'5'}
                            /> : null}
                        {sentenceToTranslate6 ?
                            <CollapsibleTextBox
                                callbackFunc={this.callback6}
                                label={sentenceToTranslate6 + ' ->  ' + this.state.sentence6}
                                allowUserFeedback={true}
                                showFinaliseButton={false}
                                prompt={''}
                                textAreaHeight={1}
                                placeholder={'write the diplomatic version here...'}
                                initialTextValue={this.state.sentence6}
                                showState={this.state.collapsibleState_6}
                                signalOpenCallback={this.signalOpen}
                                id={'6'}
                            /> : null}
                        {sentenceToTranslate7 ?
                            <CollapsibleTextBox
                                callbackFunc={this.callback7}
                                label={sentenceToTranslate7 + ' ->  ' + this.state.sentence7}
                                allowUserFeedback={true}
                                showFinaliseButton={false}
                                prompt={''}
                                textAreaHeight={1}
                                placeholder={'write the diplomatic version here...'}
                                initialTextValue={this.state.sentence7}
                                showState={this.state.collapsibleState_7}
                                signalOpenCallback={this.signalOpen}
                                id={'7'}
                            /> : null}
                        {sentenceToTranslate8 ?
                            <CollapsibleTextBox
                                callbackFunc={this.callback8}
                                label={sentenceToTranslate8 + ' ->  ' + this.state.sentence8}
                                allowUserFeedback={true}
                                showFinaliseButton={false}
                                prompt={''}
                                textAreaHeight={1}
                                placeholder={'write the diplomatic version here...'}
                                initialTextValue={this.state.sentence8}
                                showState={this.state.collapsibleState_8}
                                signalOpenCallback={this.signalOpen}
                                id={'8'}
                            /> : null}
                        {sentenceToTranslate9 ?
                            <CollapsibleTextBox
                                callbackFunc={this.callback9}
                                label={sentenceToTranslate9 + ' ->  ' + this.state.sentence9}
                                allowUserFeedback={true}
                                showFinaliseButton={false}
                                prompt={''}
                                textAreaHeight={1}
                                placeholder={'write the diplomatic version here...'}
                                initialTextValue={this.state.sentence9}
                                showState={this.state.collapsibleState_9}
                                signalOpenCallback={this.signalOpen}
                                id={'9'}
                            />
                            : null}
                        <div className='mt-3'  >
                            <TextBox
                                callbackFunc={this.additionalCallback}
                                allowUserFeedback={true}
                                showFinaliseButton={false}
                                prompt={'Anything else you want to say?'}
                                initialTextValue={this.state.additional}
                                textAreaHeight={5}
                            />
                        </div>
                    </Col>


                    <Col md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }} className='mb-5 pl-4 pr-4'  >
                        <div className="input-field text-right">
                            <Button onClick={this.handleSubmit} style={{ background: '#ff7000', color: "white", border: 0 }}>Submit</Button>
                        </div>
                    </Col>
                </Row>
                <Row><Col style={{ marginBottom: "10rem" }}></Col></Row>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createChallengeResponse: (challenge) => dispatch(createChallengeResponse(challenge)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RespondToChallenge)

