import React from 'react';
import PropTypes from 'prop-types';
import './QuizForm.css'


function AnswerOption(props) {

    const class_name = props.image ? "invisibleItem" : ""
    return (
        <span className="answerOption">
            <label className="radioCustomLabel" htmlFor={props.answerContent}>
                <input
                    type="radio"
                    className={class_name}
                    name="radioGroup"
                    checked={props.value === props.userAnswerValue}
                    id={props.answerContent}
                    value={props.value}
                    disabled={props.userAnswerValue}
                    onChange={props.onAnswerSelected}
                />
                {props.image ?
                    <img src={props.image} width="120" height="100" alt={props.answerContent} /> : props.answerContent
                }
            </label>
        </span>
    );
}

AnswerOption.propTypes = {
    value: PropTypes.string.isRequired,
    answerContent: PropTypes.string.isRequired,
    userAnswerValue: PropTypes.string.isRequired,
    onAnswerSelected: PropTypes.func.isRequired
};

export default AnswerOption;