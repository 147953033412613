import React, { Component } from 'react'
import { connect } from 'react-redux'
import PublicChallengeForm from './PublicChallengeForm'
import { editChallenge } from '../../store/actions/challengeActions'
import { Redirect } from 'react-router-dom'


//This creates JSX for the user to create a new challenge

class EditChallenge extends Component {
    state = {
        //challenge passed through prop location's state parameter
        //as set in EditChallengeButton: call: this.props.history.push
        ...this.props.location.state
    }

    static defaultProps = {
        returnURL: '/challenges',
    }

    //need this to pass additional arguments to editChallenge
    editChallengeWrapper = (challenge) => {
        //challenge returned from Challenge component only has the challenge features
        //it no longer has the 'id' property. Even though when we pass the Challenge component location.state, it does include the id property, the id property isn't used.
        const auth = this.props.auth;
        //editChallenge is action creator defined in challengeActions.js
        console.log('challenge', challenge)
        console.log('current auth', auth)

        const id = this.props.location.state.id
        this.props.editChallenge(id, challenge, auth)
    }

    render() {
        const { auth, location } = this.props;

        const challenge = location.state

        const returnURL = location.state.prevPath
        console.log(challenge)
        if (!auth.uid) return <Redirect to='/signin' />
        return (
            <div>
                <PublicChallengeForm callbackFunc={this.editChallengeWrapper} challenge={challenge} formTitle='Edit Challenge' returnURL={returnURL} />
            </div>
        )
    }
}




const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,

    }
}

//NOTE: editChallenge is now a variable in props and its value is a function that dispatches
//the action-creator 'editChallenge' function defined in challengeActions.js, w
//editChallenge is an action-creator which instead of returning an action object (as done when we did not need asynchronous calls)
//now returns a function that makes an asynch call and then calling dispatch.
//'challenge' below is just a variable in the arrow function
const mapDispatchToProps = (dispatch) => {
    return {
        editChallenge: (id, challenge, auth) => dispatch(editChallenge(id, challenge, auth)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EditChallenge)