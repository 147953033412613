import React from 'react'
//import moment from 'moment'
import DeleteChallengeButton from './DeleteChallengeButton'
import EditChallengeButton from './EditChallengeButton'
import { Card } from 'react-bootstrap'



//child component of ChallengeList, inherits challenge prop from ChallengeList
const ChallengeSummary = ({ challenge }) => {

    return (
        <div className='mt-3'>
            <Card style={{ width: '80%' }} >
                <Card.Body>
                    <Card.Title as="div" style={{ color: "black " }}>{challenge.title}</Card.Title>
                    <Card.Text as="div" style={{ color: "black " }} >
                        {/*<div>Posted by {challenge.authorUsername} </div>*
                        <div className="className grey-text">{moment(challenge.createdAt.toDate()).calendar()}</div>*/}
                    </Card.Text>
                    {/* need to call id separately because when Edit/DeleteChallengeButton is called from ChallengeDetails.js, that challenge is from firestore 
                    and does not have id as a property in challenge, so challenge.id is null called over there, even though challenge.id has a value here */}

                    <Card.Link to="#" as="div" className="float-right"><DeleteChallengeButton challenge={challenge} id={challenge.id} /></Card.Link>

                    <Card.Link to="#" as="div" className="float-left"><EditChallengeButton challenge={challenge} id={challenge.id} /></Card.Link>
                </Card.Body>
            </Card >
        </div>
    )
}

export default ChallengeSummary