import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import AnswerOption from './AnswerOption'
import quizQuestions from '../../files/quizQuestions'
import { shuffleArray } from './formFunctions'
import GreenCheckMark from '../../files/GreenCheckMark.png'
import RedXMark from '../../files/RedXMark.png'


//create object where value is true for all choices that are judgments when they are pseudofeelings
//value should be true for choices that are feelings when they are feelings
quizQuestions.forEach((question) => shuffleArray(question.answers));
shuffleArray(quizQuestions)


/**
 *Creates quiz with object array quizQuestions
*/
class QuizForm extends Component {

    /**
    
    * @param {Array.<Object>} props.quizQuestions a list of objects with properties 'question' and 'answers'
    * @param {Array.<Object>} props.quizQuestions[n].answers list of objects with properties 'content' (the label to be shown for the option) and 'value': e.g, "Correct" or "Incorrect"
     * @param {Array.<Object>} answerOptions, the current list of question answers
     * @param <String> props.additionalQuestionText any text that is wanted to show up before all questions
     * @param <Object> props.quizImages object with properties of quiz options and values of images to be shown instead of text-options and radio button. If null, text with radio buttons is shown
     * @param <Boolean> props.showImmediateFeedback show immediately after each trial whether user was correct or not
     * @param {Array.<Object>} answersRecord, list of objects with properties: { q: this.state.question, ans: correctOption.content, user_ans: answer }
    * @param <String> answer:  the value in option chosen by user (from quizQuestions.answers[n].value
    * @param answerOptions: 
     */

    constructor(props) {
        super()
        this.state = {
            counter: 0,
            questionId: 1,
            question: props.quizQuestions[0].question,
            answerOptions: props.quizQuestions[0].answers,
            answer: '',
            answersCount: {},
            answersRecord: [], //record of objects showing question and answer chosen
            result: '',
            feedback: null,
        };
    }

    static defaultProps = {
        quizQuestions: quizQuestions,
        showImmediateFeedback: true,
        additionalQuestionText: "",
        quizImages: null,
        callbackFunc: () => { },
        callbackSendScore: () => { }
    }


    renderAnswerOptions = (key) => {
        const img = (this.props.quizImages && this.props.quizImages[key.content]) ? this.props.quizImages[key.content] : null

        return (
            <span key={key.content} className="mr-3 ml-3">
                <AnswerOption

                    answerContent={key.content}
                    value={key.value} //event.currentTarget.value
                    userAnswerValue={this.state.answer}
                    questionId={this.state.questionId}
                    onAnswerSelected={this.handleAnswerSelected}
                    image={img}
                />
            </span>
        );
    }

    setNextQuestion = () => {
        const counter = this.state.counter + 1;
        const questionId = this.state.questionId + 1;
        this.setState({
            counter: counter,
            questionId: questionId,
            question: this.props.quizQuestions[counter].question,
            answerOptions: this.props.quizQuestions[counter].answers,
            answer: '',
            feedback: null
        });
    }

    setUserAnswer = (answer, id) => {
        const correctOption = this.state.answerOptions.find(option => option.value === "Correct");
        const newAnswersRecord = this.state.answersRecord.concat({ q: this.state.question, ans: correctOption.content, user_ans: id })
        this.setState((state) => ({
            answersCount: {
                ...state.answersCount,
                [answer]: (state.answersCount[answer] || 0) + 1
            },
            answersRecord: newAnswersRecord,
            answer: answer
        }));
        this.props.callbackFunc(newAnswersRecord)
    }

    handleAnswerSelected = (event) => {
        const { value, id } = event.currentTarget
        console.log(event.currentTarget)
        this.setUserAnswer(value, id);
        if (this.state.questionId < this.props.quizQuestions.length) {
            if (this.props.showImmediateFeedback) {
                setTimeout(() => { this.renderFeedback(value); }, 100);
                setTimeout(() => this.setNextQuestion(), 100);

            }
            else
                setTimeout(() => this.setNextQuestion(), 300);
        }
        else {
            if (this.props.showImmediateFeedback) {
                setTimeout(() => { this.renderFeedback(value); }, 300);
                setTimeout(() => this.sendAndSetResults(), 300);
            }
            else
                setTimeout(() => this.sendAndSetResults(), 300);
        }
    }

    getResults = () => {
        const answersCount = this.state.answersCount;
        const answersCountKeys = Object.keys(answersCount);
        const answersCountValues = answersCountKeys.map((key) => answersCount[key]);
        const maxAnswerCount = Math.max.apply(null, answersCountValues);

        return answersCountKeys.filter((key) => answersCount[key] === maxAnswerCount);
    }

    sendAndSetResults = () => {
        this.props.callbackSendScore(this.state.answersCount)
        const result = this.getResults()
        this.setState({ result })
    }


    renderFeedback = value => {
        const feedback = value === "Correct" ?
            <img src={GreenCheckMark} width="50" height="50" alt="Correct!" /> :
            < img src={RedXMark} width="50" height="50" alt="Incorrect" />
        const correctOptions = this.state.answerOptions.filter(option => option.value === "Correct")

        this.setState({ feedback, answerOptions: correctOptions })
    }

    render() {
        return (

            <>
                {this.state.result ? null :
                    <div className="quiz">

                        <h5 className="questionCount mb-5">
                            Question <span>{this.state.questionId}</span> of <span>{this.props.quizQuestions.length}</span>
                            {this.state.feedback}
                        </h5>

                        <Row className="text-center mt-5 mb-5">
                            <Col xs={{ span: 12 }} className=" mb-5">
                                <h1 className="question">{this.props.additionalQuestionText} {this.state.question}</h1>
                            </Col>

                            <Col xs={{ span: 12 }} className="mb-5">

                                {this.state.answerOptions.map(this.renderAnswerOptions)}
                            </Col>

                        </Row>
                    </div>
                }
            </>

        )
    }
}

export default QuizForm
