import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Nav, Navbar } from 'react-bootstrap';
import { signOut } from '../../store/actions/authActions'
import './Navbar.css'
import VerifyAdminFirebase from '../auth/VerifyAdminFirebase'


const SignedInLinks = (props) => {
  const isAdmin = props.isAdmin
  const verifyAdmin = props.verifyAdmin
  useEffect(() => {

    if (!isAdmin) {
      console.log("not admin")

    }



  })



  console.log(isAdmin)

  return (

    <Nav className="ml-auto">
      <VerifyAdminFirebase />
      {
        isAdmin ?
          <>

            <Nav.Item className="ml-md-auto mr-md-0 mr-auto">

              <Nav.Link href="/admin"><Navbar.Text><span className='linkstyle'>Admin Panel</span></Navbar.Text></Nav.Link>
            </Nav.Item>
            <Nav.Item className="ml-md-auto mr-md-0 mr-auto">

              <Nav.Link href="/create"><Navbar.Text><span className='linkstyle'>Add Scenario</span></Navbar.Text></Nav.Link>
            </Nav.Item>
            <Nav.Item className="ml-md-auto mr-md-0 mr-auto">

              <Nav.Link href="/createchallenge"><Navbar.Text><span className='linkstyle'>Add Challenge</span></Navbar.Text></Nav.Link>
            </Nav.Item>
          </>
          : null

      }
      <Nav.Item className="ml-md-auto mr-md-0 mr-auto">

        <Nav.Link href="/"><Navbar.Text><span className='linkstyle'>Main</span></Navbar.Text></Nav.Link>
      </Nav.Item>
      <Nav.Item className="ml-md-auto mr-md-0 mr-auto">

        <Nav.Link href="/mylogs"><Navbar.Text><span className='linkstyle'>Logs</span></Navbar.Text></Nav.Link>
      </Nav.Item>
      <Nav.Item className="ml-md-auto mr-md-0 mr-auto">

        <Nav.Link href="/checkmessage"><Navbar.Text><span className='linkstyle'>Compose Message</span></Navbar.Text></Nav.Link>
      </Nav.Item>
      <Nav.Item className="ml-md-auto mr-md-0 mr-auto">

        <Nav.Link href="/myscenarios"><Navbar.Text><span className='linkstyle'>My Scenarios</span></Navbar.Text></Nav.Link>
      </Nav.Item>
      <Nav.Item className="ml-md-auto mr-md-0 mr-auto">

        <Nav.Link href="#" onClick={props.signOut}><Navbar.Text><span className='linkstyle'>Logout</span></Navbar.Text></Nav.Link>
      </Nav.Item>
      {/*}
      <Nav.Item  className="ml-md-auto mr-md-0 mr-auto">

        <Nav.Link href="/about"><Navbar.Text><span className='linkstyle'>About</span></Navbar.Text></Nav.Link>
      </Nav.Item>
    */}
      <Nav.Item className="ml-md-auto mr-md-0 mr-auto">

        <Nav.Link href="/account">
          <button className="button button5">{props.profile.initials ? props.profile.initials : null}</button>
          <div style={{ color: "black" }}>{props.profile.username}</div>
        </Nav.Link>
      </Nav.Item>

    </Nav>

  );
};



const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    verifyAdmin: (isAdmin) => dispatch({ type: 'IS_ADMIN', isAdmin: isAdmin }),
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignedInLinks)
