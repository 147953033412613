import React, { Component } from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';



export class StepThrough extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            direction: null,
            numItems: this.props.numItems
        }

    }

    static defaultProps = {
        callbackFunc: () => { },
        show_at_end: false, //if false, it causes back and forth buttons to disappear at end, which is what we want for the demo
    }

    // like toggleCarousel but it doesn't circle from last to first and from first to last.
    stepBackAndForth = (direction) => {
        console.log('inside toggle Carousel')
        let index = this.state.index

        if (direction === 'next' && index < this.state.numItems) {
            index++
        }
        else if (direction === 'prev' && index > 0) {
            index--
        }

        this.props.callbackFunc(index)
        this.setState({
            direction,
            index
        })
    }

    handleIndicatorClick = (event) => {

        const { id } = event.target;
        this.setState({ index: Number(id) })
        this.props.callbackFunc(id)
    }

    createIndicators = (numItems) => {

        const indicatorArr = []
        for (var i = 0; i < numItems; i++) {
            indicatorArr.push(<Button key={i} id={i} className={this.state.index === i ? 'btn-carousel-indicators selected' : 'btn-carousel-indicators'} onClick={this.handleIndicatorClick}></Button>
            )
        }
        return indicatorArr
    }

    render() {
        const indicators = this.createIndicators(this.props.show_at_end ? this.state.numItems + 1 : this.state.numItems)
        return (
            <div>
                {(this.state.index < this.state.numItems) || this.props.show_at_end ?
                    <Row><Col className="mt-3 mr-5 pr-5 text-right" sm={8}>
                        <Button disabled={!(this.state.index > 0)} className="btn-carousel-progress" onClick={() => this.stepBackAndForth('prev')}><FaAngleLeft /></Button>
                        <Button disabled={!(this.state.index < this.state.numItems)} className="btn-carousel-progress" onClick={() => this.stepBackAndForth('next')}><FaAngleRight /></Button>
                    </Col></Row> : null}
                {this.props.children}
                {(this.state.index < this.state.numItems) || this.props.show_at_end ?
                    <Row><Col className="text-center mb-5">
                        {indicators}
                    </Col></Row> : null}
            </div >
        )
    }
}

export default StepThrough
