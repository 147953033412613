const initState = {}

const activityReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SAVE_ACTIVITY':
            console.log('save activity', action.activity, action.activityType)
            return state;
        case 'SAVE_ACTIVITY_ERROR':
            console.log('save activity error', action.error)
            return state;
        case 'DELETE_ACTIVITY':
            console.log('deleted activity', action.activityType)
            return state;
        case 'DELETE_ACTIVITY_ERROR':
            console.log('delete activity error', action.error)
            return state;
        default:
            return state;


    }
}

export default activityReducer