import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Container, Collapse, InputGroup, FormControl } from 'react-bootstrap'
import ModalProblem from '../../bot_components/Feedback/ProblemNotDetected'
import Sempi from '../../files/Sempi.png'
import { FaThumbsUp, FaThumbsDown, FaArrowRight } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as config from '../../config/default_config'

const privacyText = "Read More"

function ChallengeIntroText() {

    const [agree, setAgree] = useState(false);
    const [disagree, setDisagree] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [showPrivacyText, setShowPrivacyText] = useState(privacyText);

    const showFeedbackToast = () => {
        toast.info("Thanks! This would record your feedback");

    }

    useEffect(() => {
        // Update the document title using the browser API
        if (showPrivacy)
            setShowPrivacyText('See Less')
        else {
            setShowPrivacyText(privacyText)
        }

    }, [showPrivacy]);

    return (
        <Container>
            <ToastContainer
                autoClose={5000}
                className="toast-container"
                closeOnClick={true}
                pauseOnFocusLoss={false}
                hideProgressBar={true}
            />
            <h3><img src={Sempi} width="50" alt={'Sempi'} className='mr-1' />Welcome to Phrase Finder!</h3>
            <Row><Col >

                Hi I'm Sempi. I will help you notice any words you've used that may be less effective. I want you to know I'm not perfect, I'm still learning too.
                    <br></br><br></br>
                    Please help me improve by giving me feedback. You can give feedback on individual comments as shown  below. Please remember to click "Submit Feedback"
            </Col></Row>

            <Row>  <Col className="my-3 px-3">
                <Container><Row>
                    <Col sm={0.5} className="float-right"><FaArrowRight color={"red"} /></Col>
                    <b><i>Something I said that you agree or disagree with </i></b>

                    <Col sm={0.5} className="m-1 ml-5 ">

                        <FaThumbsUp display={"block"} size="20px" onClick={() => { setAgree(!agree); setDisagree(false); console.log('agree clicked') }} color={agree ? "green" : "grey"} />

                    </Col>
                    <Col className="m-1 FaThumbsDown float-right" sm={0.5}>

                        <FaThumbsDown display={"block"} aria-controls="example-collapse-text" size="20px" onClick={() => { setDisagree(!disagree); setAgree(false); console.log('disagree clicked') }} color={disagree ? "red" : "grey"} />


                    </Col >

                </Row>
                    <Row>
                        <Collapse in={disagree}>
                            <InputGroup className="mb-3" >
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default" >Tell us more?</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder="e.g., are you not in agreement or confused with this feedback?"
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                />
                            </InputGroup>
                        </Collapse>
                    </Row>
                    <Row><Col>
                        <Button
                            disabled={false}

                            className="btn btn-orange float-right"
                            variant="light"
                            onClick={showFeedbackToast}
                        >
                            {config.SUBMIT_FEEDBACK_TEXT}
                        </Button>
                    </Col>
                    </Row></Container>
            </Col></Row>
            <Row><Col className="my-3">You can also give me general feedback if I've missed something or confused you.</Col></Row>
            <ModalProblem

                message={'This is your recent input text. You can choose to include this with your feedback. '}
                message_id={1}
                displayMsg={true}
            />


            <Row><Col className="mt-5">

                PRIVACY INFO: This Phrase Finder activity is completely anonymous. We don't collect personal data. <div onClick={() => setShowPrivacy(!showPrivacy)} className="ml-0 pl-0 link" style={{ border: 'none' }}>{showPrivacyText}</div>
                <Collapse in={showPrivacy}>
                    <div>
                        PRIVACY INFO: We will be using random samples of your inputs to help improve the algorithm. Your input is completely anonymous. We are not collecting any personal data about you, e.g., no IP addresses etc.  If you happen to write any identifying inputs, we will de-indentify the data before using it for our algorithms.  If you give us general feedback, you have an option to
                        provide your email address and whether you'd like to include your response in the feedback. But that is completely optional and we will delete your message as soon as your query is addressed.
                     </div>
                </Collapse>
            </Col></Row>


        </Container >
    )
}

export default ChallengeIntroText
