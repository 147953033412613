import React from 'react'

function SubscribeFormHome() {
    return (
        <div className="ml-form-embed"
            data-account="2951050:b7x1g4e3m2"
            data-form="3692386:c0f4e2">
        </div>
    )
}

export default SubscribeFormHome
