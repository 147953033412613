import React, { Component } from 'react'
import ChallengeList from './ChallengeList'

//import Notifications from './Notifications'  We deleted the corresponding cloud functions and commented it out of firebase/functions/index.js
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Container, Row, Col } from 'react-bootstrap'

import TooltipCustom from "../../Utilities/TooltipCustom"




/**
 * PublicChallenges class. Has the main screen after login. Props.challenges are connected via firestoreConnect to automatically
 * sync with challenges collection in firestore. auth is automatically synced (don't need separate Connect) 
 * isNewUser is 
 * Has a Modal containing changeUserName and also agree to terms for when the user is a newUser. 
 * **NOT FUNCTIONAL**
 */
class PublicChallenges extends Component {

    /**

    * @property {boolean | undefined} props.profile.terms whether or not user has agreed to the terms and profile
    */
    constructor(props) {
        super(props);



        /**
        * State object of PublicChallenges
        * @property {('off'|'on')}this.state.terms  whether or not user has checked the Agree to Terms checkbox in the modal
        * @property {boolean} this.state.termsErrorMessage error message saying user must agree to terms before continuing to use the program
        * @property {boolean} seenUpdateUsername whether the updateUserNameModal has been shown (keep it closed if it has already been shown)
        */

        this.state = {


        }

    }







    render() {


        console.log('props', this.props)
        console.log('state', this.state)
        const { challenges } = this.props;

        const challengeTooltip = 'Challenges help you practice difficult situations in your own life.'

        const textColor = 'black' //'#1E90FF'
        const tooltipColor = '#ff7000'
        return (
            <Container>


                <Row>
                    <Col md={{ span: 6, offset: 0 }} xs={{ span: 12, offset: 0 }}>
                        <Col md={{ span: 8, offset: 0 }} xs={{ span: 12, offset: 0 }}>
                            <TooltipCustom innertext={challengeTooltip} className="float-left" color={tooltipColor} />
                            <h3 style={{ color: textColor, border: 0 }} className="mt-5">Situations</h3>

                        </Col>
                        <ChallengeList challenges={challenges} urlBase={'/challenges/'} />

                    </Col>



                </Row>
                <Row><Col className="my-5"></Col></Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {

        challenges: state.firestore.ordered.publicChallenges,


    }
}




export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'publicChallenges', orderBy: ['createdAt', 'desc'] },

    ])
)(PublicChallenges)