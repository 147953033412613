const initState = {}

const scenarioReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_SCENARIO':
            console.log('created scenario', action.scenario)
            return state;
        case 'CREATE_SCENARIO_ERROR':
            console.log('create scenario error', action.error)
            return state;
        case 'CREATE_PRIVATE_SCENARIO':
            console.log('created scenario', action.scenario)
            return state;
        case 'CREATE_PRIVATE_SCENARIO_ERROR':
            console.log('create scenario error', action.error)
            return state;
        case 'DELETE_SCENARIO':
            console.log('deleted scenario', action.scenario)
            return state;
        case 'DELETE_SCENARIO_ERROR':
            console.log('delete scenario error', action.error)
            return state;
        case 'EDIT_SCENARIO':
            console.log('edit scenario', action.scenario)
            return state;
        case 'EDIT_SCENARIO_ERROR':
            console.log('edit scenario error', action.error)
            return state;
        default:
            return state;


    }
}

export default scenarioReducer