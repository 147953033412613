import React from 'react'
import LogSummary from './LogSummary'
import { Link } from 'react-router-dom'

//child component of Dashboard (the component routed to '/' which is the main page) 
const LogList = ({ logs, urlBase = '/log/', headerToColorObj = {}, stateNameBaseList = [] }) => {



    return (
        <div className="log-list section">
            {/*logs && needs to return true for this clause to run*/}
            {logs && logs.map(log => {
                return (
                    <Link as="div" to={urlBase + log.id} key={log.id}>
                        <LogSummary log={log} headerToColorObj={headerToColorObj} stateNameBaseList={stateNameBaseList} />
                    </Link>)

            })}
        </div>
    )
}

export default LogList