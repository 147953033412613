import React from 'react'
import ChallengeSummary from './ChallengeSummary'
import { Link } from 'react-router-dom'


//child component of Dashboard (the component routed to '/' which is the main page) 
const ChallengeList = ({ challenges, urlBase = '/challenges/' }) => {

    console.log(challenges)
    return (
        <div className="challenge-list section">
            {/*challenges && needs to return true for this clause to run*/}
            {challenges && challenges.map(challenge => {
                return (
                    <Link
                        as="div"
                        to={{
                            pathname: urlBase + challenge.id,
                            state: {
                                challenge: challenge,
                            }
                        }}
                        key={challenge.id}>
                        <ChallengeSummary challenge={challenge} showDate={false} />
                    </Link>
                )
            })}
        </div>
    )
}

export default ChallengeList