import React from 'react'

import SubscribeFormHomeLong from './SubscribeFormHomeLong'


export default function SubscribeHomeComponentLong() {




    const checkMailerLite = (loaded) => {
        //wait 1000ms check if mailerlite has been loaded
        setTimeout(function () {

            console.log(loaded)
            if (!loaded)
                window.location.reload();
            console.log('loaded', loaded)
        }, 1000);


    }

    return (


        <SubscribeFormHomeLong callbackfunc={(loaded) => checkMailerLite(loaded)} />

    )
}
