import React, { Component } from 'react'
import { deleteArticle } from '../../store/actions/articleActions'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import CheckDeleteModal from '../../Utilities/CheckDeleteModal'


class DeleteArticleButton extends Component {
    state = {
        show: false
    }

    showModal = (e) => {
        this.setState({
            show: true
        })
        console.log('delete clicked')
        e.preventDefault()
        e.stopPropagation()


    }

    closeModal = (e) => {
        e.stopPropagation()
        e.preventDefault()
        this.setState({ show: false });

    }

    handleDelete = (e) => {
        console.log(this.props)
        console.log('DELETE Pressed')
        e.preventDefault()

        this.props.deleteArticle(this.props.id, this.props.article, this.props.auth, this.props.isAdmin)
        e.stopPropagation()
        this.props.history.push(this.props.location.pathname)
    }


    render() {


        const { article, auth, isAdmin } = this.props;
        if ((article.authorId === auth.uid) || isAdmin) {
            return (
                <span>
                    <span className="card-action" onClick={this.showModal} >Delete</span>
                    <CheckDeleteModal show={this.state.show} closeModal={this.closeModal} handleYes={this.handleDelete} text="Are you sure you want to delete?" />
                </span>
            )
        } else { return (null) }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        isAdmin: state.auth.isAdmin
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteArticle: (id, article, auth, isAdmin) => dispatch(deleteArticle(id, article, auth, isAdmin)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteArticleButton))
