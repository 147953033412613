import React, { Component } from 'react'
import { connect } from 'react-redux'
import firebase, { db } from '../../config/fbConfig'
import { Col, Button, Container, Row, Tab, Nav } from 'react-bootstrap'

import TrainingPageLayout from './TrainingPageLayout'
import TrainingPractice from './TrainingPractice'
import queryString from 'query-string';
import { TRANSLATION_PRACTICE_CONTENT, INSTRUCTIONS } from '../../config/TranslationPracticeContent'
import { arr2str } from '../forms/formFunctions'


const contentList = [
    {
        text: "",
        name: "Introduction",
        videoLink: "https://vimeo.com/709103152",
        quizLink: null
    },
    {
        text: "",
        name: "Lesson 1: Aspirations",
        videoLink: "https://vimeo.com/709104631",
        quizLink: "https://qmulsciences.qualtrics.com/jfe/form/SV_898lke6w9H0PKtw"
    },
    {
        text: "",
        name: "Lesson 2a: Objectivity Pitfalls, Characterisations",
        videoLink: "https://vimeo.com/709107208",
        quizLink: "https://qmulsciences.qualtrics.com/jfe/form/SV_2fqTUcVHtc6TZKC",
    },
    {
        text: "",
        name: "Lesson 2b: Objectivity Pitfalls, Exaggerations",
        videoLink: "https://vimeo.com/709108320",
        quizLink: "https://qmulsciences.qualtrics.com/jfe/form/SV_2mmjLiirldOgQ2q",
    },
    {
        text: "",
        name: "Lesson 2c: Objectivity Pitfalls, Interpretations",
        videoLink: "https://vimeo.com/709108590",
        quizLink: "https://qmulsciences.qualtrics.com/jfe/form/SV_do6ffhGu45c0T0G",
    },
    {
        text: "",
        name: "Lesson 2d: Objectivity Pitfalls, Judgments",
        videoLink: "https://vimeo.com/709109127",
        quizLink: "https://qmulsciences.qualtrics.com/jfe/form/SV_dcY1eMHhVnejqaG",
    },
    {
        text: "",
        name: "Lesson 2e: Objectivity Pitfalls, Assuming feelings",
        videoLink: "https://vimeo.com/709109693",
        quizLink: "https://qmulsciences.qualtrics.com/jfe/form/SV_3KlavDBAVrc38ai",
    },
    {
        text: "",
        name: "Lesson 3: Conveying Impact",
        videoLink: "https://vimeo.com/709110261",
        quizLink: "https://qmulsciences.qualtrics.com/jfe/form/SV_1Z9gjMt8g7Qm1im",
    },
    {
        text: "",
        name: "Lesson 4: Strategies",
        videoLink: "https://vimeo.com/709112631",
        quizLink: "https://qmulsciences.qualtrics.com/jfe/form/SV_a3NiwOSknHo0dcG",
    },
    {
        text: "",
        name: "AI Practice: Instructions",
        videoLink: "https://vimeo.com/711751878",
        quizLink: null
    }


]

var navItemLabels = [];
contentList.forEach(function (item, index) {
    navItemLabels.push(item.name); // you push it to the array
});

const practice_names = ['objectivity_practice', 'angry_emoter']
const practiceNameLabels = ['AI Practice 1', 'AI Practice 2', 'Final Page']

navItemLabels.push(...practiceNameLabels)

class TrainingPage extends Component {
    /**
     * @constructor
     * @param {boolean} this.props.scenario.isPublic determines whether the scenario is public or private and thus which collection its stored in
     * @param {array} this.props.scenario.tags, list of tags relating to scenario
     * @param {array} this.props.scenario.tags, list of groups for whom scenario is relevant (to be used later to organise different groups)
     * @param {boolean} this.props.scenario.authByAdmin whether scenario was created by Admin
     * @param {boolean} this.props.showAllSteps whether to walk through all steps or whether to just show short version
     * @param {boolean} this.props.parentDoc The doc id of the parentScnario if it is responding to a challenge or public scenario, then title and summary should be fixed
 */

    constructor(props) {
        super(props);
        //note these createRef() need to be assigned to a DOM element (e.g., Col) and not a component. It won't work on a component

        this.videoRef = React.createRef();
        this.mcqRef = React.createRef();
        this.botRef = React.createRef();


        const localData = localStorage.getItem(props.location.pathname)

        const localDataObj = localData ? JSON.parse(localData) : null
        const params = queryString.parse(this.props.location.search)
        console.log("params", params.key)
        //need to define conditional || in case props.scenario does not have the attribute. This won't be caught by defaultProps as scenario does exist
        //and the attribute are 2nd order properties.
        //collpasibleState_X are to control collapsibles so only one is open at a time. controlled via function: close_all_other_collapsibles
        this.state = {
            AI_PracticeSectionEnd: false,
            key: params.key && Number(params.key)
                || (localDataObj && localDataObj.hasOwnProperty('key') && localDataObj.key) || 0,

        }
    }




    /**
     * 
     * @param {*} arr of strings for field value names
     * @returns array of Nav.Items with field values
     */

    createNavItems(arr) {
        if (!arr || arr.length === 0) return null
        return (<span>{(arr.map((fieldVal, i) => {
            return (
                i == 0 ?
                    <Nav.Item key={"navItem" + i} >
                        <Nav.Link onClick={() => { this.handleSelect(i) }} className={this.state.key === 0 ? 'active' : ''} eventKey={i}>{fieldVal}</Nav.Link>
                    </Nav.Item>

                    :
                    <Nav.Item key={"navItem" + i}>
                        <Nav.Link onClick={() => { this.handleSelect(i) }} eventKey={i}>{fieldVal}</Nav.Link>
                    </Nav.Item>
            )
        })
        )}
        </span>)
    }


    createTrainingPageLayoutTabContent(arr, userId) {
        if (!arr || arr.length === 0) return null
        return (<>{(arr.map((fieldVal, i) => {
            return (
                <Tab.Pane key={"layoutTab" + i} eventKey={i}>
                    <TrainingPageLayout
                        introText={contentList[i]['text']}
                        videoLink={contentList[i]['videoLink']}
                        mcqLink={contentList[i]['quizLink'] ? contentList[i]['quizLink'] + '?userId=' + userId : null} />
                </Tab.Pane>
            )
        })
        )}
        </>)
    }





    // method called to handle toggle (next/prev)
    toggleNextButtons = (direction) => {
        console.log('inside toggleNextButton')
        const [min, max] = [0, contentList.length - 1 + Object.keys(TRANSLATION_PRACTICE_CONTENT).length]


        if ((direction === 'next') && (this.state.key < max)) {
            this.setState((prevState, props) => ({
                key: prevState.key + 1,
                showAllSteps: false
            }), this.writeToLocalStorage);
        }
        else if ((direction === 'prev') && (this.state.key > 0)) {
            this.setState((prevState, props) => ({
                key: prevState.key - 1,
                showAllSteps: false
            }), this.writeToLocalStorage);
        }


    }

    handleSelect = (key) => {
        this.setState({ key: parseInt(key, 10) }, this.writeToLocalStorage);
    }



    createAnonymousUser = (userFolder, userId) => {

        console.log('inside createAnonymousUserId')

        const usersRef = db.collection(userFolder).doc(userId)
        usersRef.get()
            .then((doc) => {
                if (!doc.exists) {
                    usersRef.set({
                        createdAt: new Date(),
                    }).then((docRef) => {
                        console.log("new user doc created ", usersRef);
                    }).catch((error) => {
                        console.log("error when creating new user doc ", error)
                    });
                }
                else {
                    console.log('doc already exists')
                }
            }).catch(function (error) {
                console.log("Error getting document:", error);
            });

    }


    /**
     * Checks whether userId is in parameter list, and if not, then checks user storage.
     * This is not a redux function, just a normal function, probably should not be in store, but don't know where else to put it.
     */
    getAnonymousUserId = (location) => {

        let params = queryString.parse(location.search)
        const userFolder = location.pathname
        var userId
        if (params.userId)
        //if userId is in the paramery query string, we will assign the anonymous userId to and store it in redux
        {
            userId = params.userId

        }
        else {
            //if userId is not in the parameter query string, check local storage under the object userFolder, which is the current experiment group name
            const localData = localStorage.getItem(userFolder)
            const localDataObj = localData ? JSON.parse(localData) : null

            userId = (localDataObj && localDataObj.hasOwnProperty('userId') && localDataObj.userId) || null



        }
        return userId
    }

    render() {
        let params = queryString.parse(this.props.location.search)
        const userFolder = this.props.location.pathname.split('/').slice(1).join('/')
        const userId = this.getAnonymousUserId(this.props.location)

        const navItems = this.createNavItems(navItemLabels)
        const trainingPageLayoutTabs = this.createTrainingPageLayoutTabContent(contentList, userId)

        if (userId) {
            console.log("userId", userId, "userFolder", userFolder)
            if (this.state.key == 0) {
                console.log('calling createAnonymousUserId')
                this.createAnonymousUser(userFolder, userId)
            }

            return (
                <Tab.Container id="left-tabs-example" activeKey={this.state.key} onSelect={this.handleSelect} >
                    <Row>
                        <Col className="mt-5 mr-0" sm={3}>
                            <Nav variant="pills" className="flex-column left_navlinks">
                                {navItems}
                            </Nav>
                        </Col >

                        <Col sm={8} className="mt-5">

                            <Row><Col className="text-right"  >
                                Page  {this.state.key + 1} / {navItemLabels.length}
                            </Col></Row>
                            <Tab.Content>

                                {trainingPageLayoutTabs}

                                <Tab.Pane eventKey={contentList.length}>
                                    < TrainingPractice
                                        userId={userId}
                                        userFolder={userFolder}
                                        practice_id={practice_names[0]}
                                        callbackFunc={(boolean_val) => { this.setState({ AI_PracticeSectionEnd: boolean_val }) }}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey={contentList.length + 1}>
                                    < TrainingPractice
                                        userId={userId}
                                        userFolder={userFolder}
                                        practice_id={practice_names[1]}
                                        callbackFunc={(boolean_val) => { this.setState({ AI_PracticeSectionEnd: boolean_val }) }}
                                    />
                                </Tab.Pane>

                                <Tab.Pane eventKey={contentList.length + 2}>
                                    <h1>
                                        Congrats on finishing the training! </h1>
                                        
                                    <h5>Please continue on to our post-training survey.

                                        You can access the end survey

                                        <span> <a href={"https://qualtrics.ucl.ac.uk/jfe/form/SV_bykU4EFjmA4gWcS"}>here</a></span>.
                                     
                                    </h5> 


                                </Tab.Pane>


                            </Tab.Content>
                            {(this.state.key >= contentList.length) && !this.state.AI_PracticeSectionEnd && this.state.key < navItemLabels.length - 1 ? <Row><Col style={{ color: 'red' }}>
                                Next Button will be enabled when you have finished all sentences and are on the summary page for this practice section.</Col></Row> : null}


                            <Row><Col className="text-center"><h3>
                                <span className="mx-2">

                                    {this.state.key == 0 ?
                                        <Button variant="link" disabled={true} onClick={() => this.toggleNextButtons('prev')} style={{ background: '#ff7000', color: 'white' }} >Prev</Button> :
                                        <Button variant="link" onClick={() => this.toggleNextButtons('prev')} style={{ background: '#ff7000', color: 'white' }} >Prev</Button>
                                    }
                                </span><span className="mx-2">
                                    {
                                        this.state.key > navItemLabels.length - 1
                                            || (this.state.key >= contentList.length) && !this.state.AI_PracticeSectionEnd ?
                                            <Button variant="link" disabled={true} onClick={() => this.toggleNextButtons('next')} style={{ background: '#ff7000', color: 'white' }} >Next </Button> :
                                            <Button variant="link" onClick={() => this.toggleNextButtons('next')} style={{ background: '#ff7000', color: 'white' }} >Next </Button>
                                    }

                                </span>
                            </h3>
                            </Col>
                            </Row>


                        </Col >
                    </Row >
                </Tab.Container >
            )
        }
        else { return null }
    }
}




export default TrainingPage

