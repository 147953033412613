import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { cloneDeep } from "lodash"
import { TiDelete } from "react-icons/ti";
import firebase, { db } from '../../config/fbConfig'

export function shuffleArray(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

//colors tested at https://js.do/Ash123/501279 
const hex2RGB = (hexStr) => {

    var RGB = []
    let isFirstDigit = true
    let currentVal = 0 //currentVal is what is added together from two hexStr characters to get an RGB value
    for (let i = 0; i < hexStr.length; i++) {
        if (hexStr[i] === '#') { continue; }

        if (isFirstDigit) {

            currentVal = parseInt(hexStr[i], 16) * 16
            isFirstDigit = false
        }
        else {

            currentVal = currentVal + parseInt(hexStr[i], 16)
            RGB.push(currentVal)
            isFirstDigit = true
        }

    }
    return RGB
}

const RGB2hex = (RGBTuple) => {
    let v0 = Math.floor(RGBTuple[0] / 16)
    let r0 = RGBTuple[0] % 16
    let v1 = Math.floor(RGBTuple[1] / 16)
    let r1 = RGBTuple[1] % 16
    let v2 = Math.floor(RGBTuple[2] / 16)
    let r2 = RGBTuple[2] % 16

    return '#' + v0.toString(16) + r0.toString(16) + v1.toString(16) + r1.toString(16) + v2.toString(16) + r2.toString(16)
}

//takes colorFraction and baseColor and turns into RGB with min 25% lightness and max 75% darkness and
//returns corresponding hexStr. if colorFraction = .5, just return the hextStr itself. Otherwise 1 is darkest, 0 is lightest
export function colorFraction2RGB(colorFraction, hexStr) {
    const baseRGB = hex2RGB(hexStr)
    let newRGB = Array.from(baseRGB)

    if (colorFraction === 0.5) { return hexStr }
    else if (colorFraction < 0.5) {
        const tint_factor = (.5 - colorFraction) + .25 //scale to betwee .25-.75
        newRGB[0] = Math.round(baseRGB[0] + (255 - baseRGB[0]) * tint_factor)
        newRGB[1] = Math.round(baseRGB[1] + (255 - baseRGB[1]) * tint_factor)
        newRGB[2] = Math.round(baseRGB[2] + (255 - baseRGB[2]) * tint_factor)
    }
    else if (colorFraction > .5) {
        const shade_factor = (colorFraction - .5) + .25
        newRGB[0] = Math.round(baseRGB[0] * (1 - shade_factor))
        newRGB[1] = Math.round(baseRGB[1] * (1 - shade_factor))
        newRGB[2] = Math.round(baseRGB[2] * (1 - shade_factor))

    }
    return RGB2hex(newRGB)

}

/**
 * @function
 * @param takes a header str and decomposes into headerLabel (full name without color specification at end), baseColor and id (name of first part without the space) and stateName (stateNameBase + id)
 *stateNameBase is only needed if we are returning stateName. Otherwise not needed to get id, baseColor and headerLabel
 */

export function getNameColorIdStatename(checkboxHeader, stateNameBase = '') {
    var result = {}
    result['headerLabel'] = checkboxHeader.indexOf('#') === -1 ? checkboxHeader : checkboxHeader.substr(0, checkboxHeader.indexOf('#'));
    result['baseColor'] = checkboxHeader.indexOf('#') === -1 ? '#ff8080' : checkboxHeader.substr(checkboxHeader.indexOf('#'))
    result['id'] = result['headerLabel'].indexOf(' ') === -1 ? result['headerLabel'] : result['headerLabel'].substr(0, result['headerLabel'].indexOf(' '))
    result['stateName'] = stateNameBase + result['id']
    return result
}


/**
 * @function
 * @param returns list of note values, filtered by whether its 'selected' property contains header
 */
export function createNotesList(notesList, header = null, id = null, urlBase = null, showTags = false, valToColorObj = {}, currentStep = 1, showPos = false, showNeg = false, log = null) {
    const notesArr = []
    notesList && notesList.forEach((note, i) => {
        if (note.selected.includes(header) || !header) {

            const tags = showTags ? createHeaderTags(note.selected, valToColorObj) : null
            notesArr.push(
                <div key={'note' + i} className="mt-0">
                    <div>
                        {tags}
                    </div>
                    <div>
                        <span style={{ background: "white" }} className="mr-2">
                            <h6><i>{" " + note.text + " "}</i></h6>
                        </span>
                        {(urlBase && id) ? < Link as="div" to={{ pathname: urlBase + id, state: { id: id, currentStep: currentStep, showPos: showPos, showNeg: showNeg, log: log } }}  > edit </Link > : null}
                    </div>
                </div>)
        }
    })
    return notesArr
}

/**
 * @function
 * @param creates tags from an array with fractions at the end of the string denoted with # and a base color.
 */
export function createTags(arr, baseColor = '') {
    if (!arr || arr.length === 0) return null

    return <div>{(arr.map(fieldVal => {
        var colorFraction = fieldVal.indexOf('#') === -1 ? .5 : parseFloat(fieldVal.substr(fieldVal.indexOf('#') + 1))
        var label = fieldVal.indexOf('#') === -1 ? fieldVal : fieldVal.substr(0, fieldVal.indexOf('#'))
        var color = fieldVal.indexOf('#') === -1 ? baseColor : colorFraction2RGB(colorFraction, baseColor)
        return (<span disabled size='sm' key={label} style={{ background: color, color: "white" }}>{label} </span>)
    })
    )}
    </div>
}

/**
 * @function
 * @param creates tags from an array and obj that maps tag values to color.
 */
export function createHeaderTags(arr, valToColorObj = {}) {
    if (!arr || arr.length === 0) return null
    return (<span>{(arr.map((fieldVal, i) => {
        var color = (valToColorObj && Object.keys(valToColorObj).length > 0 && valToColorObj.hasOwnProperty(fieldVal)) ? valToColorObj[fieldVal] : 'grey'
        return (<span size='sm' key={fieldVal + i} style={{ background: color, color: "white" }}>{fieldVal}</span>)
    })
    )}
    </span>)
}




/**
 * @function
 * @param takes output of csvRead and returns an object with properties that are the header names (first value of each row)
 * and with values of the color for that header
 */
export function createNameColorObj(result) {
    //also fills in a state headersList, list of all headers (including baseColor ending)
    var data = result.data //for some reason calling result.forEach causes an error
    if (!data) {
        console.log("no CSV results found:", data)
    }
    else {
        const nameColorObj = {}
        data.forEach(arr => {
            //id is the short name as a result of the Header
            const { headerLabel, baseColor } = getNameColorIdStatename(arr[0])
            //set State for header buttons, for headersLists and needs as function of feelings
            nameColorObj[headerLabel] = baseColor
            const arr2 = arr.slice(1)
            arr2.forEach(fieldVal => {
                var colorFraction = fieldVal.indexOf('#') === -1 ? .5 : parseFloat(fieldVal.substr(fieldVal.indexOf('#') + 1))
                var label = fieldVal.indexOf('#') === -1 ? fieldVal : fieldVal.substr(0, fieldVal.indexOf('#'))
                var color = fieldVal.indexOf('#') === -1 ? baseColor : colorFraction2RGB(colorFraction, baseColor)
                nameColorObj[label] = color
            })


        })
        return nameColorObj
    }
}

const remove_chars_after_pound = (str) => {
    return str.split("#")[0]
}

export const arr2str = (arr, separator = " ") => {
    if (!arr) return ''
    var arr2 = arr.map(remove_chars_after_pound)
    return arr2.toString().replace(/,/g, separator)
}

export const arr2str_with_and = (arr) => {
    if (arr.length === 0) {
        return ''
    }
    else if (arr.length === 1) {
        return arr.toString()
    }
    else if (arr.length > 1) {
        return arr.slice(0, -1).toString().replace(/,/g, ', ').concat(' and ').concat(arr[arr.length - 1])
    }

}


/**
 * @function
 * @param {array} botFeedbackList is the full list of botFeedbacks in that component
 * @param {array} botFeedback is the latest chunk of botFeedback (i.e., sentenceOutputList), as stored in redux
 * @param {array} userFeedbackList is the latest userFeedback returned by user after clicking "Send Feedback", stored in redux
 * maps through userFeebackList, finds all values where userFeedback.user_feedback_value === "-1" (i.e.,  negative feedback)
 * Once a feedback is gone from the render (can no longer give thumbs up/down) then the opportunity to remove from botFeedbackList is also gone
 * 
 */
export function removeThumbsDownFromBotFeedbackList(botFeedbackListOrig, userFeedbackList, offset) {
    //userFeedbackList.offset is length botFeedbackListOrig was when that feedback was called and tells us which sentenceIndex the userFeedbackList thumbsdown value refers to inside botFeedbackList
    //Even if we remove a thumbsdown, this won't affect trying to remove again because all the sentences (sentenceIndex's) will remain intact so 
    //the reference to sentenceIndex remains the same.

    var botFeedbackList = cloneDeep(botFeedbackListOrig)
    userFeedbackList.forEach((userFeedback) => {
        if (userFeedback.user_feedback_value === "-1") {
            //if user_feedback_value === "-1", i.e., it is negative feedback remove this from userFeedbackList

            var sentenceIndex = offset + userFeedback.s_i
            console.log('sentenceIndex', sentenceIndex)
            if (botFeedbackList.length > sentenceIndex)
                botFeedbackList[sentenceIndex].r_l = botFeedbackList[sentenceIndex].r_l.filter(function (obj) {
                    return obj.id !== userFeedback.comment_id;
                });
        }

    })
    return botFeedbackList
}


export function displayBotFeedbackList(botFeedbackList, callbackFunc = () => { }, showDelete = false,) {
    return (
        botFeedbackList.map((val) => {

            return (val.r_l.map((value, index) => {
                let response_str = value.r_str

                return (


                    <li key={value.id + value.r_i.join()}>
                        {response_str}
                        {showDelete && <Button
                            variant="link"
                            onClick={() => {
                                val.r_l.splice(index, 1);
                                callbackFunc();
                            }}><TiDelete size={30} /></Button>}

                    </li>)
            }
            )
            )
        })
    )
}

export function getPhrasesToWatchOut(botFeedbackList) {
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }
    var phrasesArr = []
    botFeedbackList.forEach((val) => {
        phrasesArr = phrasesArr.concat(val.phr)
    })
    return phrasesArr.filter(onlyUnique);
}

/**
 * @function
 * @param {obj} obj checks whether object has the proper fields for creating a docRef, e.g.obj, has none 0 length str properties of groupName and sessionId 
 * to create a docRef using getDocRef
 */

export function validateGetPathFromObj(obj) {

    if ((!obj.groupName) && (typeof obj.groupName === 'string') && (obj.groupName.length > 0)) {
        console.log('groupName is undefined', obj.groupName)
        return false
    }
    if ((!obj.sessionId) && (typeof obj.sessionId === 'string') && (obj.sessionId.length > 0)) {
        console.log('sessionId is undefined', obj.sessionId)
        return false
    }
    return true

}


export function getLocationRef(collectionPath) {
    const collectionPathArr = collectionPath.split("/")
    let docLocation
    try {
        if (collectionPathArr.length === 1) {
            docLocation = db.collection(collectionPathArr[0])
            return docLocation
        }
        else if (collectionPathArr.length === 3) {
            docLocation = db.collection(collectionPathArr[0]).doc(collectionPathArr[1]).collection(collectionPathArr[2])
            return docLocation
        }
        else throw new Error("'collectionPath needs to be of the form 'path' or 'path/doc/path1'")
    }
    catch (err) {
        console.log(err)
    }
}

export function getDocRef(docPath) {
    var docPathArr = docPath.split("/")
    let docRef
    try {
        if (docPathArr.length === 2) {
            docRef = db.collection(docPathArr[0]).doc(docPathArr[1])
            return docRef
        }
        else if (docPathArr.length === 4) {
            docRef = db.collection(docPathArr[0]).doc(docPathArr[1]).collection(docPathArr[2]).doc(docPathArr[3])
            return docRef
        }
        else throw new Error("'docPath needs to be of the form 'path/doc' or 'path/doc/path1/doc'")
    }
    catch (err) {
        console.log(err)
    }
}

export function createNewDoc(docRef, obj = {}) {
    docRef.get().then((doc) => {
        if (doc.exists) {

            console.log("Document exists. data:", doc.data());
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document--creating!");
            docRef.set({
                ...obj,
                createdAt: new Date(),
                //new fields get automatically added. I just needed to refresh browser for change to take place

            }).then(() => {
                console.log('doc successfully created', docRef)

            }).catch((error) => {
                console.log('error creating doc', error)
            })
        }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });

}

export function appendPublicResponse(docRef, arrayName, valueToAppend) {
    docRef.update({
        [arrayName]: firebase.firestore.FieldValue.arrayUnion(valueToAppend)
    }).then(() => {
        console.log('appended to docRef')
    }).catch((error) => {
        console.log('error recording user bot call', error)
    })
}