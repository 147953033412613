import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { Container, Col } from 'react-bootstrap'
import ChallengeResponseDetailCard from './ChallengeResponseDetailCard'
const myChallenges = 'myChallengeResponses'

//shown under url /submittedchallenge/
//props are received via mapStateToProps from redux-firestore
//get to this page from ChallengeResponseList link set on Link element
const ChallengeResponseDetails = (props) => {
    const { auth, challenge_response, id } = props

    console.log(props)
    if (!auth.uid) return <Redirect to='/signin' />
    if (challenge_response) {

        console.log(props.history.goBack)

        return (
            <Container className='mt-5'>
                <Col md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }}    >
                    <ChallengeResponseDetailCard challenge_response={challenge_response} id={id} returnURL='/myscenarios' />
                </Col>
            </Container >

        )
    }
    else {
        return (
            <div className="container center">
                <p>Loading challenge...</p>
            </div>
        )

    }

}



//react router dom passes following value match.params.id to props of ChallengeDetails because of this line in App.js:
//     <Route path='/challenge/:id' component={ChallengeDetails} />
//Thus redux can access this through the optional built-in 'ownProps' argument in mapStateToProps

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const challenges = state.firestore.data[myChallenges]
    const challenge = challenges ? challenges[id] : null
    return {
        id: id,
        challenge_response: challenge,
        auth: state.firebase.auth
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => {
        return [
            {
                collection: 'users',
                doc: props.auth.uid,
                subcollections: [{ collection: 'challenges', orderBy: ['createdAt', 'desc'] }],
                storeAs: myChallenges
            }


        ]
    })
)(ChallengeResponseDetails)

