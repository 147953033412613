import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/analytics';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBYeYy39tx9eEFMOgSwka2JF_TV779r8LQ",
    authDomain: "diplomacy-dojo.firebaseapp.com",
    databaseURL: "https://diplomacy-dojo.firebaseio.com",
    projectId: "diplomacy-dojo",
    storageBucket: "diplomacy-dojo.appspot.com",
    messagingSenderId: "533646911785",
    appId: "1:533646911785:web:3ea70ca6acc7e6f0c1ef38",
    measurementId: "G-G4XNH0NRCK"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

if (window.location.hostname === 'localhost') {
    if (false) {
        //if (true) {
        console.log("testing locally -- hitting local functions and firestore emulators");
        firebase.functions().useFunctionsEmulator('http://localhost:5001');
        //  firebase.firestore().settings({
        // host: 'localhost:8080',
        //  ssl: false
        //  });
    }
}

const analytics = firebase.analytics();
//firebase.firestore().settings({ timestampsInSnapshots: true }); 

const firebaseAuth = firebase.auth();
const db = firebase.firestore();
export default firebase;

export { firebaseAuth, db, analytics }