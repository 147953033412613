import React, { PureComponent } from "react";
import Checkbox from "./Checkbox";
import { Row, Col } from 'react-bootstrap'
import { colorFraction2RGB } from './formFunctions'
import { feelingsNegativeArr, feelingsPositiveArr, needsArr, pseudofeelingsArr, groupsArr, tagsArr } from '../forms/readCSV'
import './index.css'




/**
 *Creates checkbox form with fields obtained from CSV file rows. Has options for checkboxes to be organised by headers
 *It is a pure component because we don't want it to re-render when the parent rerenders when user types into Textbox.
 */
class CheckboxForm extends PureComponent {

    /**

     * @param {string} props.useHeaders whether or not first item in row of csv should be ignored as checkbox and used as heading for that portion of checkboxes
     * @param {string} props.csvFilePath path to csvFile
     * @param {function} props.callbackFunc callback function to return array of selected values back to parent component
     * @param {string} props.title  Title to show on top of checkbox form
     * @param {string} props.showIndividualGroup, str of header name for group to show if we want to only an individual group of a header. Only works if useHeaders = true
     * @param {string} in ['needs', 'feelings']. Is the file from which to read in the range of checkbox labels to use. 
     * Needs to be pre-defined otherwise node.js throws compiles warning. defaults to needs.
  */
    constructor(props) {
        super(props);

        /**
    
         * @param {Object.<string, number>} this.state.checkboxes object with keys corresponding to every checkbox label and value of whether it is true or false
         * @param {boolean} this.state.checkboxesReady turns true via updateCheckBoxFieldsInState is called, when Papa.parse has successfully read the CSV file
         * @param {Array.<string>} this.state.headerNamesArr If this.props.useHeaders = true, then this is list of all headers
         * @param {Array.<string[]>} this.state.checkboxLabels  2D array with the checkbox labels to be passed into Checkbox component to create jsx for render
         * if useHeaders = true, then the first element in each of inner array is the header
      */
        this.state = {
            checkboxes: {},
            checkboxesReady: false,
            checkboxLabels: [],
            initialCheckedFields: props.initialCheckedFields || []
        }



    }

    static defaultProps = {
        showState: true,
        showDeselectAll: true,
        title: null,
        useHeaders: true,
        showHeaders: true, //even if headers are used we may not want to show them if form is inside collapsible labels with the headers already on them
        checkboxFilename: 'needs',
        initialCheckedFields: [],
        showIndividualGroup: 'all', //the name of header to show if we want to show only checkboxes for one header
        callbackFunc: (arr) => {
        }

    }

    //cannot call the imported async result feelingsNegativeArr in Constructor because this.State is called immediately

    componentDidMount() {
        var readCSVPromise
        switch (this.props.checkboxFilename) {
            case 'needs':
                readCSVPromise = needsArr
                break
            case 'feelings':
                readCSVPromise = feelingsNegativeArr
                break
            case 'feelingsPositive':
                readCSVPromise = feelingsPositiveArr
                break
            case 'pseudofeelings':
                readCSVPromise = pseudofeelingsArr
                break
            case 'tags':
                readCSVPromise = tagsArr
                break
            case 'groups':
                readCSVPromise = groupsArr
                break
            default:
                readCSVPromise = needsArr
        }
        readCSVPromise.then(result => this.initialiseCheckboxFieldsInState(result)).catch((err) => console.log(err));

    }


    /**
     * @function
     * @summary
     * Creates an object with keys corresponding to checkbox fields and values corresponding to true or false depending on if checked.
     * Used by createCheckbox
     *      */
    makeOptions = (arr) => {
        const checkboxObject = arr.reduce(
            (options, option) => ({
                ...options,
                [option]: this.state.initialCheckedFields.includes(option) ? true : false
            }),
            {}
        )

        return (checkboxObject)


    }

    initialiseCheckboxFieldsInState = (result) => {
        //console.log('papa parse output', result.data)
        const data = result.data;
        if (!data) {
            console.log("no CSV results found:", data)
        }
        else {
            //data should be a 2D array where each item is an array representing row from csv
            //if useHeaders, the first value in each inner-array is used as Header and not included as check-box
            //This assumes PapaParse returns 2D array (even when reading a one row csv). Will error out if it returns single array.
            this.setState({
                checkboxLabels: data
            })
            data.forEach(arr => {
                var arr2 = this.props.useHeaders ? arr.slice(1) : arr
                this.setState({
                    checkboxes: {
                        ...this.state.checkboxes,
                        ...this.makeOptions(arr2)
                    }
                })
            })
            this.setState({ checkboxesReady: true })
        }
    }




    selectAllCheckboxes = isSelected => {
        Object.keys(this.state.checkboxes).forEach(checkbox => {
            // BONUS: Can you explain why we pass updater function to setState instead of an object?
            //answer: states may be updated asynchronously and we use updater function to reliably get current states.
            //Because we just want to grab all checkboxes and the checkbox options currently are fixed this is not an issue
            //however, some application may have dynamically varying checkboxes and we want to make sure to grab all current ones.
            //we need to use spread operator as setState will override previous of nested objects
            this.setState(prevState => ({
                checkboxes: {
                    ...prevState.checkboxes,
                    [checkbox]: isSelected
                }
            }));
        });
        if (!isSelected) {
            this.props.callbackFunc([])
        } else if (isSelected) {
            this.props.callbackFunc(Object.keys(this.state.checkboxes))
        }

    };

    selectAll = () => this.selectAllCheckboxes(true);

    deselectAll = () => this.selectAllCheckboxes(false);

    handleCheckboxChange = changeEvent => {
        const { id } = changeEvent.target;
        this.setState(prevState => ({
            checkboxes: {
                ...prevState.checkboxes,
                [id]: !prevState.checkboxes[id]
            }
        }), this.sendChosenItemsArrToParent);

    };



    sendChosenItemsArrToParent = () => {
        const selectedOptions = Object.keys(this.state.checkboxes)
            .filter(checkbox => this.state.checkboxes[checkbox])
        this.props.callbackFunc(selectedOptions)
    }

    componentDidUpdate() {
        // console.log('CheckboxForm re-rendered:', this.props.checkboxFilename)
    }



    // creates a single Checkbox
    createCheckbox = (fieldVal, baseColor = '#0000ff') => {
        if (!fieldVal) return null
        var colorFraction = fieldVal.indexOf('#') === -1 ? .5 : parseFloat(fieldVal.substr(fieldVal.indexOf('#') + 1))
        var label = fieldVal.indexOf('#') === -1 ? fieldVal : fieldVal.substr(0, fieldVal.indexOf('#'))
        var color = fieldVal.indexOf('#') === -1 ? baseColor : colorFraction2RGB(colorFraction, baseColor)

        return (
            < Checkbox
                label={label}
                isSelected={this.state.checkboxesReady ? this.state.checkboxes[fieldVal] : false}
                onCheckboxChange={this.handleCheckboxChange}
                key={label}
                color={color}
                id={fieldVal}

            />
        )
    };

    //wrapper for creating a single checkbox to allow it to have a color parameter
    createCheckboxesWrapper = (baseColor) => {
        return (x) => this.createCheckbox(x, baseColor);
    };

    //returns an array of checkboxes 
    createCheckboxes = (arr, baseColor) => {

        return (arr.map(this.createCheckboxesWrapper(baseColor)))
    }


    //creates many sets of checkboxes, iterates through rows of CSV's and separates them into headers of useHeaders = True
    createCheckboxes2D = (arr2D) => {
        return (<div className="">
            {arr2D.map((arr) => {
                if (this.props.useHeaders && !(arr[0].startsWith(this.props.showIndividualGroup) || this.props.showIndividualGroup === 'all')) {
                    return null
                }

                const keyName = arr[0].indexOf('#') === -1 ? arr[0] : arr[0].substr(0, arr[0].indexOf('#'));
                const baseColor = arr[0].indexOf('#') === -1 ? '#ff7000' : arr[0].substr(arr[0].indexOf('#'))

                return (
                    <Row key={keyName + '1'} >

                        <div className="checkbox-list section" key={keyName}>
                            <h5 style={{ color: baseColor, fontFamily: 'Verdana' }}>{(this.props.useHeaders && this.props.showHeaders) ? keyName : null}</h5>
                            {this.props.useHeaders ? this.createCheckboxes(arr.slice(1), baseColor) : this.createCheckboxes(arr, baseColor)}
                        </div>

                    </Row>
                )
            })}
        </div>
        )
    }





    render() {

        if (!this.state.checkboxesReady || !this.props.showState) { return null }
        const newboxes = this.createCheckboxes2D(this.state.checkboxLabels)
        return (
            <div className="ml-3">
                {this.props.title ?
                    <Row>
                        <div className="mb-5">
                            <h5>
                                {this.props.title}
                            </h5>
                        </div>
                    </Row> : null
                }

                {/*  wrapping newboxes around div makes it a column and the different checkboxes get wrapped into multiple columns */}
                {newboxes}


                <Row className="form-group-buttons text-right" >
                    <Col className="col-xs-12 float-right">
                        {/* We don't want a select all button
                                <button
                                    type="button"
                                    className="btn btn-outline-primary mr-2"
                                    onClick={this.selectAll}
                                >
                                    Select All
                                </button>
                               
 
                            <button type="submit" className="btn btn-primary" onClick={this.handleFormSubmit}>
                                Save
                </button>
                 */}
                        {this.props.showDeselectAll ?
                            <button
                                type="button"
                                className="btn btn-outline-primary mr-2"
                                onClick={this.deselectAll}
                            >
                                Deselect All
                </button> : null}
                    </Col>
                </Row>



            </div>
        );
    }
}

export default CheckboxForm;