import authReducer from './authReducer'
import scenarioReducer from './scenarioReducer'
import challengeReducer from './challengeReducer'
import logReducer from './logReducer'
import articleReducer from './articleReducer'
import activityReducer from './activityReducer'
import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import botReducer from './botReducer'



const rootReducer = combineReducers({
    auth: authReducer,
    scenario: scenarioReducer,
    challenge: challengeReducer,
    log: logReducer,
    activity: activityReducer,
    article: articleReducer,
    bot: botReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
})

export default rootReducer