import React from 'react'
import style from './popup.module.css';

const CheckDeleteModal = ({ show, closeModal, handleYes, text }) => {
    if (show) {
        return (
            <div className={style.popup} >
                <div className={style.popup_inner}>
                    <div className="class container">
                        <p className="black-text center">{text}</p>
                        <button className="float-left" onClick={closeModal}>Cancel</button>
                        <button className="float-right" onClick={handleYes}>Yes</button>
                    </div>
                </div >
            </div >
        )
    }
    return null
}


export default CheckDeleteModal
