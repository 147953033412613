import * as actionTypes from "../actions/botActions";

const initialState = {
   feedbackDisplayed: false,
   botFeedback: [],
   loadingScreen: false,
   userInput: "",
   userFeedbackList: [],
   usageId: '',
   usageRecordsPath: null,
   botFeedbackListLength: 0,

};

const botReducer = (state = initialState, action) => {
   switch (action.type) {

      case actionTypes.UPDATE_LOADING_SCREEN:
         return {
            ...state,
            loadingScreen: action.isLoading
         };
      case 'RECORD_GET_FEEDBACK':
         return {
            ...state,
            userInput: action.userInput,
            botFeedback: action.botFeedback
         }
      //botFeedbackList is the accumulated list of all bot feedback that's been given for a certain activity. To be used with removeThumbsDownFromBotFeedbackList
      //to keep track of lastChunkStartIndex if people give thumbsdown on previous part of activity after getting additional feedback (i.e., the chunk being given thumbs down is not most recent chunk)
      case 'RECORD_BOT_FEEDBACK_LIST_LENGTH':
         return {
            ...state,
            botFeedbackListLength: action.botFeedbackListLength
         }
      //userFeedbackList is the list of objects containing user feedback to store in redux

      case 'RECORD_USER_FEEDBACK_LIST':
         return {
            ...state,
            userFeedbackList: action.userFeedbackList,
            currentOffset: action.currentOffset
         }
      case 'RECORD_USAGE_INFO':
         return {
            ...state,
            usageId: action.usageId,
            usageRecordsPath: action.usageRecordsPath
         }
      //userFeedback is what was stored in firestore database
      case 'RECORD_USER_FEEDBACK':
         console.log('recorded user feedback', action.userFeedback)
         return state;
      case 'RECORD_USER_FEEDBACK_ERROR':
         console.log('record user feedback error', action.error)
         return state;
      case 'RECORD_ATTEMPT':
         console.log('recorded attempt successfully', action.attempt)
         return state;
      case 'RECORD_ATTEMPT_ERROR':
         console.log('record attempt error', action.error)
         return state;
      default:
         return state;

   }
};

export default botReducer;

