import React from 'react'
import { connect } from 'react-redux'
import PublicChallengeForm from './PublicChallengeForm'
import { createChallenge } from '../../store/actions/challengeActions'

function CreateChallenge(props) {
    return (
        <div>
            <PublicChallengeForm callbackFunc={props.createChallenge} formTitle='New Challenge' isPublic={true} returnURL={'/challenges'} />
        </div>
    )
}

//NOTE: createChallenge is now a variable in props and its value is a function that dispatches
//the action-creator 'createChallenge' function defined in challengeActions.js, w
//createChallenge is an action-creator which instead of returning an action object (as done when we did not need asynchronous calls)
//now returns a function that makes an asynch call and then calling dispatch. 
//'challenge' below is just a variable in the arrow function



const mapDispatchToProps = (dispatch) => {
    return {
        createChallenge: (challenge) => dispatch(createChallenge(challenge)),
    }
}


export default connect(null, mapDispatchToProps)(CreateChallenge)

