import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signUp, processSuccessfulSignIn } from '../../store/actions/authActions'
import { Form, Col, Button, Container, Row } from 'react-bootstrap'
import firebase, { firebaseAuth } from '../../config/fbConfig'
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import 'firebase/functions'
import './SignUp.css'
//import HigherOrderModal from '../../Utilities/HigherOrderModal'
//import TermsAndConditionsText from '../publicpages/TermsAndConditionsText'



//const ModalTermsAndConditions = HigherOrderModal(TermsAndConditionsText)

class SignUp extends Component {
    state = {
        email: '',
        password: '',
        terms: 'off',
        termsMessage: null,
        groupCode: '',
        groupCodeValidated: false,
        groupCodeMessage: null,
    }

    uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            {/* 
                firebase.auth.GithubAuthProvider.PROVIDER_ID,
            firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID
            */}
        ],
        callbacks: {
            signInSuccessWithAuthResult: (authResult) => {
                console.log('auth Result:', authResult)
                console.log('terms', this.state.terms)
                this.props.processSuccessfulSignIn(authResult, this.state.terms, this.state.groupCode)

            },

        }
    }

    componentDidMount = () => {
        console.log('signInOptions', this.uiConfig.signInOptions)
        firebaseAuth.onAuthStateChanged(user => {
            console.log("user", user)
            return <Redirect to='/' />

        })
    }



    customErrorMessage = (authError) => {
        if (authError) {
            console.log('authError', authError)
            if (authError.code === "auth/email-already-in-use") {
                return <span> Try <a href='/signin'>signing in</a> instead </span>
            }
            else {
                return authError.message
            }
        }
        return null


    }

    getTermsLabel = () => {
        return <span> I have read and agree to the
            <span className="ml-1" >
                {/*
                <ModalTermsAndConditions buttonVariant="link" openButtonText="  Terms of Use" buttonClassName="termsAndConditions" handleChange={this.handleChange} checked={this.state.terms === 'on' ? true : false} size="lg" />
                */}
                <a href="/termsofuse" target="_blank" rel="noopener noreferrer" style={{ color: "#0099CC" }}>Terms of Use</a>

            </span></span>
    }

    handleChange = (e) => {

        if (this.props.authError) {
            this.props.resetAuthErrorSignUp()
        }

        this.setState({
            [e.target.id]: e.target.value
        })
        //we need this because all e.target.values sent from checkbox is 'on'.
        if ((e.target.id === 'terms') && (this.state.terms === 'on')) {
            this.setState({ terms: 'off' })
        }
        if (this.state.terms === 'on') {
            this.setState({ termsMessage: null })
            if (this.state.groupCodeValidated) { this.setState({ groupCodeMessage: null }) }

        }
        if (e.target.id === 'groupCode')
            this.setState({ groupCodeMessage: null })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.terms === 'off') {

            this.setState({ termsMessage: "You must agree to terms and conditions before submitting" })
        }
        if (!this.state.groupCode) {


            this.setState({ groupCodeMessage: "A valid joining code is required" })
        }

        if (this.state.terms === 'on' && this.state.groupCode) {
            const validateGroup = firebase.functions().httpsCallable('validateGroup');

            validateGroup(this.state.groupCode).then((result) => {
                if (result.data) {
                    this.setState({ groupCodeMessage: null })
                    this.props.signUp(this.state)

                }
                else {
                    console.log('result.data', result.data)
                    this.setState({ groupCodeMessage: "Joining code not valid" })
                }
            }).catch(
                this.setState({ groupCodeMessage: "Validating Group Code" })

            )


        }
    }



    render() {
        const { auth, authError } = this.props;
        if (auth.uid) return <Redirect to='/' />
        return (
            <div>
                <Container fluid>
                    <Container>
                        <Row>
                            <Col className="mt-lg-5" md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }}    >

                                <Form className="pb-0" noValidate onSubmit={this.handleSubmit}>
                                    <Form.Row>
                                        <h4 className="grey-text text-darken-3">Sign Up</h4>
                                    </Form.Row>
                                    <Form.Group controlId="email" as={Row}>
                                        <Form.Label column="lg" sm="3">
                                            Email
                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control size="lg" type="email" autoComplete="UserName" onChange={this.handleChange} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group controlId="password" as={Row} >
                                        <Form.Label column="lg" sm="3">
                                            Password
                            </Form.Label>
                                        <Col sm="9">
                                            <Form.Control size="lg" sm="9" type="password" onChange={this.handleChange} />
                                        </Col>
                                    </Form.Group>
                                    <Row >
                                        <Col className="text-right">
                                            <label>

                                                <input
                                                    type="checkbox"
                                                    name={'terms'}
                                                    checked={this.state.terms === 'on' ? true : false}
                                                    onChange={this.handleChange}
                                                    id={"terms"}
                                                />
                                                <span>{this.getTermsLabel()}</span>
                                            </label>
                                            <p className='text-danger text-right'>{((this.state.termsMessage) && (this.state.terms === 'off')) ? this.state.termsMessage : null}</p>
                                        </Col>
                                    </Row>
                                    <Form.Group controlId="groupCode" as={Row}>

                                        <Col sm="5">
                                            <Form.Control required type="text" onChange={this.handleChange} placeholder="ACCESS CODE" />
                                        </Col>
                                        <Col className="input-field text-right  mt-md-0 mt-3 ">
                                            <Button type="submit" className='btn-blue'>Sign Up</Button>
                                            {authError ? <p className='text-danger'>{authError.message}</p> : null}
                                            <p className='text-danger'>{this.customErrorMessage(authError)}</p>
                                        </Col>
                                    </Form.Group>
                                    <p className='text-danger'>{((this.state.groupCodeMessage) && (!this.state.groupCodeValidated)) ? this.state.groupCodeMessage : null}</p>

                                </Form>
                            </Col>
                        </Row>

                        <Row >
                            <Col className="text-center">
                                <StyledFirebaseAuth
                                    uiConfig={this.uiConfig}
                                    firebaseAuth={firebaseAuth}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-5 mb-5">
                            </Col>
                        </Row>
                    </Container>
                </Container >

            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (creds) => dispatch(signUp(creds)),
        processSuccessfulSignIn: (creds) => dispatch(processSuccessfulSignIn(creds)),
        resetAuthErrorSignUp: () => {
            dispatch({ type: 'SIGNUP_ERROR', error: null })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)