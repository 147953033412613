import React, { Component } from "react";
import { Row, Col, Collapse, InputGroup, FormControl } from 'react-bootstrap';
import { FaThumbsUp, FaArrowRight, FaThumbsDown } from 'react-icons/fa';


/**
 * UserFeedback Class
 * This class is used to Display the feedback in a table format so that the button and the feedback are aligned properly.
 * Called from teh Feedback Class
 */
class UserFeedback extends Component {
   /**
    * @constructor
    * @param {*} props The properties sent from {@link Feedback} class
    * @param {string | number} props.comment_id the comment ID of each Feedback
    * @param {*} props.responseFeedbackAPI The response(feedback) from the API to display
    * @param {array<number>} props.indices The indices from which the feedback is based upon.
    * @param {*} props.s_str The string of the sentence the feedback is for so it can be recorded in potential userFeedback
    * @param {*} props.cid The cid encoded val for feedback is for so it can be recorded in potential userFeedback
    * @param {function} props.updateUserFeedback The function is used to update the userFeedback list in the Feedback class.
    * @summary
    * Each Feedback is arranged in a table manner to have the Thumps Up/Down button.
    */
   constructor(props) {
      super(props)
      /**
       * @summary
       * The State Object of UserFeedback
       * @property {string | number}comment_id the comment ID of each Feedback
       * @property {boolean} userFeedbackInput=false When true, displays the optional Comment box
       * @property {boolean} agree=false When true will make the thumps up go green.
       * @property {boolean} disagree=false When true will make thumps down go red
       * @property {boolean} disagreementReason Whatever is typed in the Optional Comment Box.
       * @property {string} display="block" Used to display thumps up/down as block.
       * @property {userAttemptCount} //this is a prop sent to userFeedback so that when it changes (every time get Feedback is submitted) the thumbsup/down are cleared.
       */
      this.state = {
         comment_id: props.comment_id,
         userFeedbackInput: false,
         agree: false,
         disagree: false,
         disagreementReason: "",
         display: "block",
         s_str: props.sentenceStr,
         cid: props.cid,
         response_str: props.response_str,
         sentenceIndex: this.props.sentenceIndex
      }


   }


   static defaultProps = {
      readMoreLink: null
   }

   componentDidUpdate(prevProps) {
      if (prevProps.userAttemptCount !== this.props.userAttemptCount) {
         this.setState({
            comment_id: this.props.comment_id,
            userFeedbackInput: false,
            agree: false,
            disagree: false,
            disagreementReason: '',
            cid: this.props.cid,
            s_str: this.props.sentenceStr,
            response_str: this.props.response_str,
            sentenceIndex: this.props.sentenceIndex
         });
      }
   }

   /**
    * @function
    * @summary
    * When the Thumbs up is pressed, it updates the {@link userFeedback object}  in the {@link Feedback} class's state.
    * It updates the values of {@link userFeedback} using the {@link updateUserFeedback} props which passes the {@link state} as props.
    * It also updates the {@link state.agree} value to reverse (ie. true-> false or false-> true).
    */
   Agree = () => {
      this.setState({ agree: !this.state.agree, disagree: false, userFeedbackInput: false, disagreementReason: "" },
         () => {
            this.props.updateUserFeedback(this.state)
         }
      );

   }

   /**
    * @function
    * @summary
    * When the Thumbs down is pressed, it updates the {@link userFeedback object}  in the {@link Feedback} class's state.
    * It updates the values of {@link userFeedback} using the {@link updateUserFeedback} props which passes the {@link state} as props.
    * It also updates the {@link state.disagree} & {@link state.userFeedbackInput} value to reverse (ie. true-> false or false-> true) and makes {@link agree} to true
    */
   Disagree = () => {
      this.setState({ disagree: !this.state.disagree, agree: false, userFeedbackInput: !this.state.userFeedbackInput });
      if (!this.state.disagree) this.setState({ disagreementReason: "" });

      this.setState({}, () => this.props.updateUserFeedback(this.state)
      );

   }

   /**
    * @function
    * @param {Event} event The event that happened in the HTML
    * @summary
    * This function handles the letters enters in the Comment box which gets shown when {@link userFeedbackInput} is true
    * It updates the values of {@link userFeedback} using the {@link updateUserFeedback} props which passes the {@link state} as props.
    */
   handleChangeFeedback = (event) => {

      this.setState({ disagreementReason: event.target.value },
         () => this.props.updateUserFeedback(this.state)
      );
   }

   /**
    * @function
    * render method for each Feedback to be displayed with or without UserFeedback buttons (Thumbs Up/Down
    * )
    * 
    */
   render() {
      // Style to use For when using Hovering

      var linkStyle;
      if (this.props.hovered) {
         linkStyle = { color: '#ed1212', cursor: 'pointer', transform: 'scale(1.1)', transformOrigin: 'left' }
      } else {
         linkStyle = {}
      }
      return (<div key={this.props.comment_id + this.props.indices.join()} style={linkStyle}>
         <Row>

            <Col sm={0.5}><FaArrowRight color={this.props.definitely_bad === 1 ? "red" : "black"} /></Col>

            {/* <Col> {this.props.responseFeedbackAPI}</Col> */}
            <Col className={this.props.definitely_bad !== 1 ? "text-secondary" : ""}>
               {this.props.responseFeedbackAPI}
               {this.props.readMoreLink ?
                  <span style={{ color: "#007399" }}><a href={this.props.readMoreLink} target="_blank" rel="noopener noreferrer" > Read More</a></span> : null}
               {/* <HighlightFeedback
                  sentence={this.props.sentenceArray}
                  response_str={this.props.responseFeedbackAPI}
                  indices={this.props.indices}
               /> */}
            </Col>
            {
               this.props.userFeedbackNeeded ?
                  <>
                     <Col sm={0.5} className="m-1">

                        <FaThumbsUp display={this.state.display} size="20px" onClick={this.Agree} color={this.state.agree ? "green" : "grey"} />

                     </Col>
                     <Col className="FaThumbsDown m-1" sm={0.5}>

                        <FaThumbsDown display={this.state.display} aria-controls="example-collapse-text" size="20px" onClick={this.Disagree} color={this.state.disagree ? "red" : "grey"} />


                     </Col >
                  </>
                  : <></>}
         </Row>

         <Row>
            <Collapse in={this.state.disagree}>
               <InputGroup className="mb-3" onChange={this.handleChangeFeedback.bind(this)}>
                  <InputGroup.Prepend>
                     <InputGroup.Text id="inputGroup-sizing-default" >Tell us more?</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                     placeholder="e.g., are you not in agreement or confused with this feedback?"
                     aria-label="Default"
                     aria-describedby="inputGroup-sizing-default"
                  />
               </InputGroup>
            </Collapse>
         </Row>
         <br />
      </div >);
   }
}


export default UserFeedback