import React from 'react'
import { Redirect } from 'react-router-dom'
import PasswordChangeForm from './PasswordChangeForm'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import UpdateUsername from './UpdateUsername'
import DeleteAccount from './DeleteAccount'


const Account = ({ authRedux, verifyAdmin, profile }) => {

    if (!authRedux.uid) return <Redirect to='/signin' />


    return (
        <Container>
            <Row>
                <div>
                    <h1>Account Page</h1>
                    {/* <PasswordForgetForm />*/}
                    <h5>email: {authRedux.email}</h5>
                    <h5>username: {profile.username}</h5>
                </div>
            </Row>
            <Row><Col className="mt-5"><h5>Change password</h5></Col></Row>
            <Row>
                <Col>
                    <PasswordChangeForm />
                </Col>
            </Row>
            <Row><Col className="mt-5"><h5>Update username</h5></Col></Row>

            <Row>
                <Col>
                    <UpdateUsername />
                </Col>
            </Row>

            <Row>
                <Col className="mt-5">
                    <DeleteAccount />
                </Col>
            </Row>
        </Container>
    );
}


const mapStateToProps = (state) => {
    return {
        authRedux: state.firebase.auth,
        profile: state.firebase.profile,
        isAdmin: state.auth.isAdmin,
    }
}



export default connect(mapStateToProps)(Account);