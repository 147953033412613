import React, { Component } from 'react'
import { db } from "../../config/fbConfig"
import { Form, Col, Button, Row } from 'react-bootstrap'
import TooltipCustom from "../../Utilities/TooltipCustom"
import { connect } from 'react-redux'

import '../../index.css'
var hri = require('human-readable-ids').hri
/**
 * UpdateUsername class. 
 * Allows users to change current username. 
 * 
 */



class UpdateUsername extends Component {

    /**
    * @constructor
    * @param {*} props.profile Obtained from redux mapstatetoprops to obtain any current username in profile (may be '')
    */
    constructor(props) {
        super(props);
        console.log(props.profile)

        /**
         * State Object for UpdateUnsername Class
         * @property {string} username Textbox value of username. Updated through handleChange as it detects user typed input
         * and this value gets merged to Firestore profile upon update.
          *@property { string } errorMessage message that gets displayed when username is not successfully updated
          */
        this.state = {
            username: '',
            errorMessage: null,
            usernameMessage: null,
            textboxUntouched: true

        }
    }

    static defaultProps = {
        callbackFunc: () => { }
    }



    updateUsernameTextbox = () => {

        if (this.state.textboxUntouched && this.props.profile.username) { return this.props.profile.username }
        else {
            return this.state.username
        }


    }

    isEmptyOrSpaces = (str) => {
        if (!str) { return true }
        return str === null || str.match(/^ *$/) !== null;
    }

    generateUsername = () => {
        var generatedName = hri.random()
        console.log('generatedName', generatedName)
        this.setState({ username: generatedName, errorMessage: null, usernameMessage: 'Click "Set Username" if you are happy with this proposed name.', textboxUntouched: false })
    }


    updateUsername = (username) => {

        if (this.isEmptyOrSpaces(username)) {
            this.setState({ errorMessage: 'No update to username detected' })
            return null
        }
        if (username === this.props.profile.username) {
            this.setState({ errorMessage: 'This is already your current username' })
            return null
        }

        const profiles = db.collection('users')
        console.log(username, profiles)
        profiles.where('username', '==', username).get()
            .then((snapshot) => {
                console.log('snapshot', snapshot)
                if (snapshot.docs.length) {
                    console.log('snapshot exists')
                    this.setState({ errorMessage: null })
                    this.setState({ usernameMessage: 'This username is taken. Please choose another' })
                }
                else {
                    //if empty, update uesr profile to   
                    return profiles.doc(this.props.auth.uid).update({
                        username: username,
                        initials: username[0]
                    }).then(() => {
                        this.setState({ usernameMessage: 'user name updated successfully to ' + username, username })
                        this.setState({ errorMessage: null })
                        this.props.callbackFunc()

                    }).catch((error) => {
                        console.log('error trying to update user name')
                        this.setState({ errorMessage: error.message })
                    })
                }
            }).catch((error) => {
                console.log('error in retrieving profiles with matching username', error.message)
                this.setState({ errorMessage: error.message })
            })
    }


    handleChange = (e) => {
        this.setState({ errorMessage: null, usernameMessage: null, textboxUntouched: false })
        this.setState({
            [e.target.id]: e.target.value
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.updateUsername(this.state.username)
    }



    render() {
        var proposedName = this.updateUsernameTextbox(this.state.username || '')
        console.log(proposedName, this.state.username)
        return (

            <Row>
                <Col lg={{ span: 9, order: 0, offset: 0 }} xs={{ span: 12, order: 0, offset: 0 }}    >
                    <Form onSubmit={this.handleSubmit}>

                        < Form.Group controlId="username" as={Row} >
                            <Form.Label column="lg" sm="3">
                                Username <TooltipCustom innertext={'This name gets displayed if you ever choose to publish anything attributed to you to the community'} />
                            </Form.Label>
                            <Col sm="9">
                                <Form.Control size="lg" sm="9" type="text" value={proposedName} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Col>

                            <div className="input-field text-right">
                                <Button type="button" size="sm" variant="secondary" className="mb-3" onClick={this.generateUsername} >Auto-generate username</Button>
                            </div>
                            <div className="input-field text-right">
                                <Button type="submit" > Set username</Button>

                                <p className='text-danger'>{this.state.errorMessage}</p>
                                <p className='text-primary'>{this.state.usernameMessage}</p>
                            </div>
                        </Col>
                    </Form>
                </Col>
            </Row>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }
}

export default connect(mapStateToProps)(UpdateUsername)
