import React, { Component } from 'react'
import { firebaseAuth } from '../../config/fbConfig'
import { Form, Col, Button, Container, Row } from 'react-bootstrap'

class ForgotPassword extends Component {
    state = {
        email: '',
        submitError: null
    }

    passwordReset = email => {
        return firebaseAuth.sendPasswordResetEmail(email)
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.passwordReset(this.state.email
        ).then(() => {
            console.log('Password reset email sent successfully')
        }).catch((error) => {
            this.setState({ submitError: error.message })
        })
    }



    render() {
        return (

            <Container>
                <Row>
                    <Col md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }}    >
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <h3 >Reset Password</h3>
                            </Form.Row>
                            <Form.Group controlId="email" as={Row}>
                                <Form.Label column="lg" sm="3">
                                    Forgot Password?
                                    </Form.Label>
                                <Col sm="9">
                                    <Form.Control size="lg" type="text" placeholder="Enter email" onChange={this.handleChange} />
                                </Col>
                            </Form.Group>


                            <div className="input-field text-right">
                                <Button type="submit" > Send email to Reset Password</Button>
                                <p className='text-danger'>{this.state.submitError}</p>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default ForgotPassword