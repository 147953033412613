import React from "react";
import './CheckboxStyleSheet.css'

/**
 * * @function
Creates individual checkbox component used inside CheckboxForm.js. 

     * @param {string} props.label label of checkbox field
     * @param {boolean} props.isSelected whether or not checkbox is checked 
     * @param {function} props.onCheckboxChange (function to be called on change)
  */

const Checkbox = ({ label, isSelected, onCheckboxChange, color, id }) => (
    <div className="form-check" style={{ background: isSelected ? color : 'white', borderColor: color, color: color, fontFamily: 'Verdana' }}>
        <label>
            <input
                type="checkbox"
                name={label}
                checked={isSelected}
                onChange={onCheckboxChange}
                className="form-check-input"
                id={id}
            />
            <span>{label}</span>
        </label>
    </div >
);

export default Checkbox;