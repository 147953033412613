import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function DownloadLink({ url = "/resourcesforpublic/3StepEffectiveCommunicationCheatsheet.pdf", text = "You can the download " }) {
    return (
        <Container><Row ><Col className="mt-5 text-center">
            <h1>
                {text}</h1>
            <h3>
                <Link to={url} target="_blank" rel="noopener noreferrer" download>Download Here</Link>
            </h3>
        </Col></Row></Container>
    )
}
