import React, { Component } from 'react';
import { firebaseAuth } from '../../config/fbConfig'
import { connect } from 'react-redux'
import { Form, Col, Button, Row } from 'react-bootstrap'

const INITIAL_STATE = {
    passwordOne: '',
    passwordTwo: '',
    error: null,
    pwResetMessage: null
};

class PasswordChangeForm extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }



    doPasswordUpdate = password =>
        firebaseAuth.currentUser.updatePassword(password);

    onSubmit = event => {
        const { passwordOne } = this.state;
        this.doPasswordUpdate(passwordOne)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                console.log('password successfully reset')
                this.setState({ pwResetMessage: 'password reset successfully' })
            })
            .catch(error => {
                this.setState({ pwResetMessage: error.message });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ pwResetMessage: null });
        this.setState({ [event.target.id]: event.target.value });
    };

    render() {
        const { passwordOne, passwordTwo, pwResetMessage } = this.state;

        const isInvalid =
            passwordOne !== passwordTwo || passwordOne === '';

        return (
            <Row>
                <Col lg={{ span: 9, order: 0, offset: 0 }} xs={{ span: 12, order: 0, offset: 0 }}    >


                    <Form onSubmit={this.onSubmit}>


                        < Form.Group controlId="passwordOne" as={Row} >
                            <Form.Label column="lg" sm="3">

                            </Form.Label>
                            <Col sm="9">
                                <Form.Control size="lg" sm="9" type="password" placeholder="New Password" value={passwordOne} onChange={this.onChange} />
                            </Col>
                        </Form.Group>

                        < Form.Group controlId="passwordTwo" as={Row} >
                            <Form.Label column="lg" sm="3">

                            </Form.Label>
                            <Col sm="9">
                                <Form.Control size="lg" sm="9" type="password" placeholder="Confirm New Password" value={passwordTwo} onChange={this.onChange} />
                            </Col>
                        </Form.Group>


                        <div className="input-field text-right">
                            <Button disabled={isInvalid} type="submit" >
                                Reset My Password
        </Button>
                        </div >
                        <div className='text-danger text-right'>
                            {pwResetMessage && <p>{pwResetMessage}</p>}
                        </div>
                    </Form>
                </Col>
            </Row>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }
}

export default connect(mapStateToProps)(PasswordChangeForm)
