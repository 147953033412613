import React from 'react'
import DownloadPage from './DownloadPage'

function EffectiveCommunication3StepsCheatSheet() {
    return (
        <DownloadPage url="/resourcesforpublic/3StepEffectiveCommunicationCheatsheet.pdf" text="Get your free Effective Communication Guide " />
    )
}

export default EffectiveCommunication3StepsCheatSheet
