export const pseudofeelings = ['abandoned',
    'deserted',
    'abused',
    'mistreated',
    'maltreated',
    'ill-treated',
    'abused',
    'ill-used',
    'attacked',
    'assaulted',
    'belittled',
    'disparaged',
    'picked at',
    'minimized',
    'denigrated',
    'derogated',
    'minimised',
    'belittled',
    'disparaged',
    'betrayed',
    'cheated on',
    'cheated',
    'denounced',
    'told on',
    'crapped on',
    'deceived',
    'lead astray',
    'denounced',
    'blamed',
    'faulted',
    'bullied',
    'browbeaten',
    'bossed around',
    'pushed around',
    'ripped off',
    'screwed over',
    'coerced',
    'pressured',
    'forced',
    'cornered',
    'criticised',
    'picked apart',
    'shot down,',
    'voted down,',
    'discriminated against',
    'singled out',
    'disliked',
    'distrusted',
    'mistrusted',
    'suspected',
    'dumped on',
    'inflicted',
    'brought down',
    'imposed upon',
    'used',
    'harassed',
    'provoked',
    'hassled',
    'ignored',
    'disregarded',
    'snubbed',
    'dismissed',
    'brushed aside',
    'brushed off',
    'discounted',
    'pushed aside',
    'neglected',
    'insulted',
    'dissed',
    'affronted',
    'invalidated',
    'nullified',
    'left out',
    'banished',
    'banned',
    'ostracized',
    'shunned',
    'cast out',
    'let down',
    'manipulated',
    'controlled',
    'misunderstood',
    'misconstrued',
    'misinterpreted',
    'misconceived',
    'offended',
    'patronized',
    'condescended',
    'blackmailed',
    'put down',
    'taken down',
    'degraded',
    'disgraced',
    'demeaned',
    'degraded',
    'disgraced',
    'rejected',
    'disapproved of',
    'turned down',
    'turned away',
    'refused',
    'jilted',
    'spurned',
    'scorned',
    'refused',
    'taken for granted',
    'unappreciated',
    'unvalued',
    'undervalued',
    'threatened',
    'jeopardized',
    'unheard',
    'unseen',
    'unloved',
    'unsupported',
    'unwanted',
    'undesirable',
    'exploited',
    'victimized',
    'defrauded',
    'short changed',
    'swindled',
    'violated',
    'dishonored',
    'wronged',
    'oppressed',
    'disrespected',
    'micromanaged',
    'undermined',
    'shut down',
    'egged on',
    'made fun of',
    'picked on',
    'messed with',
    'set up',
    'messed around with',
    'unrecognised',
    'underrecognised',
    'unacknowledged',
    'underacknowledged',
    'left out',
    'brought down',
    'cheated on',
    'bossed around',
    'cut off',
    'picked apart',
    'shot down']


export const feelingsNegative = [
    'down',
    'afraid',
    'apprehensive',
    'dread',
    'foreboding',
    'frightened',
    'mistrustful',
    'panicked',
    'petrified',
    'scared',
    'suspicious',
    'stressed',
    'nervous',
    'terrified',
    'wary',
    'worried',
    'concerned',
    'annoyed',
    'aggravated',
    'dismayed',
    'disgruntled',
    'displeased',
    'displeasure',
    'exasperated',
    'frustrated',
    'impatient',
    'irritated',
    'irked',
    'angry',
    'enraged',
    'rage',
    'furious',
    'infuriated ',
    'incensed',
    'indignant',
    'indignant',
    'livid',
    'outraged',
    'resentful',
    'aversion',
    'animosity',
    'appalled',
    'contempt',
    'disgusted',
    'dislike',
    'horror',
    'hostile',
    'confused',
    'ambivalent',
    'baffled',
    'bewildered',
    'dazed',
    'hesitant',
    'lost',
    'mystified',
    'perplexed',
    'puzzled',
    'torn',
    'disconnected',
    'alienated',
    'apathetic',
    'bored',
    'detached',
    'distant',
    'distracted',
    'distraction',
    'indifferent',
    'numb',
    'uninterested',
    'withdrawn',
    'agitated',
    'alarmed',
    'discombobulated',
    'disconcerted',
    'disturbed',
    'perturbed',
    'rattled',
    'restless',
    'shocked',
    'startled',
    'surprised',
    'troubled',
    'turbulent',
    'in turmoil',
    'uncomfortable',
    'discomfort',
    'uneasy',
    'unnerved',
    'unsettled',
    'upset',
    'embarrassed',
    'shame',
    'ashamed',
    'flustered',
    'guilty',
    'mortified',
    'self‐conscious',
    'fatigued',
    'beat',
    'burned out',
    'depleted',
    'exhausted',
    'lethargic',
    'listless',
    'sleepy',
    'tired',
    'weary',
    'worn out',
    'pained',
    'agony',
    'anguished',
    'bereaved',
    'devastated',
    'grief',
    'heartbroken',
    'hurt',
    'lonely',
    'loneliness',
    'misery',
    'miserable',
    'regret',
    'remorse',
    'sad',
    'depressed',
    'dejected',
    'despair',
    'desperate',
    'desperation',
    'despondent',
    'disappointed',
    'discouraged',
    'disheartened',
    'forlorn',
    'gloom',
    'heavy hearted',
    'hopeless',
    'melancholy',
    'unhappy',
    'wretched',
    'envious',
    'vulnerable',
    'cranky',
    'wistful',
    'jittery',
    'sensitive',
    'fragile',
    'tense',
    'nostalgic',
    'self-conscious',
    'yearning',
    'cold',
    'insecure',
    'shaky',
    'distraught',
    'guarded',
    'helpless',
    'fidgety',
    'frazzled',
    'distressed',
    'edgy',
    'irritable',
    'stressed out',
    'jealous',
    'reserved',
    'overwhelmed',
    'anxious',
    'longing'
]