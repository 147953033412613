export const UPDATE_LOADING_SCREEN = "UPDATE_LOADING_SCREEN";


export const recordUserFeedback = (userFeedback) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {

        //make async call to database (writing 'challenge' is  shorthand for 'challenge: challenge' )
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid || null;
        const docLocation = userFeedback.user_feedback_value === "-1" ? firestore.collection('userFeedbackNeg') : (userFeedback.user_feedback_value === "general" ? firestore.collection('userFeedbackGeneral') : firestore.collection('userFeedbackPos'))
        docLocation.add({ //if challenges does not exist, it will create a new collection
            ...userFeedback, //this is like putting title: challenge.title and content: challenge.summary
            authorId: authorId,
            createdAt: new Date(),
            //new fields get automatically added. I just needed to refresh browser for change to take place

        }).then((docRef) => {
            console.log('authorId', authorId)
            dispatch({ type: 'RECORD_USER_FEEDBACK', userFeedback })
            if (authorId) {
                var userprofiledoc = firestore.collection('users').doc(authorId);
                return userprofiledoc.update({
                    userFeedback: firestore.FieldValue.arrayUnion(docRef)
                })

                    .then(function () {
                        console.log("User feedback successfully recorded in profile!");
                    })
                    .catch(function (error) {
                        // The document probably doesn't exist.
                        console.error("Error recording user feedback in profile: ", error);
                    });

            }


        }).catch((error) => {
            dispatch({ type: 'RECORD_USER_FEEDBACK_ERROR', error })
        })
    }

}

/*
records feedback from nonLoggedInUser,

*/

export const recordNonLoggedInUserFeedback = (userFeedback, userId, userIdPathName) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {

        //make async call to database (writing 'challenge' is  shorthand for 'challenge: challenge' )
        const firestore = getFirestore();

        const docLocation = userFeedback.user_feedback_value === "-1" ? firestore.collection('userFeedbackNeg') : (userFeedback.user_feedback_value === "general" ? firestore.collection('userFeedbackGeneral') : firestore.collection('userFeedbackPos'))
        docLocation.add({
            ...userFeedback,
            authorId: userId,
            createdAt: new Date(),
            //new fields get automatically added. I just needed to refresh browser for change to take place

        }).then((docRef) => {

            dispatch({ type: 'RECORD_USER_FEEDBACK', userFeedback })
            if (userId) {
                var userprofiledoc = firestore.collection(userIdPathName).doc(userId);
                return userprofiledoc.update({
                    userFeedback: firestore.FieldValue.arrayUnion(docRef)
                })

                    .then(function () {
                        console.log("User feedback successfully recorded in profile!");
                    })
                    .catch(function (error) {
                        // The document probably doesn't exist.
                        console.error("Error recording user feedback in profile: ", error);
                    });

            }


        }).catch((error) => {
            dispatch({ type: 'RECORD_USER_FEEDBACK_ERROR', error })
        })
    }

}











