
import { connect } from 'react-redux';
import { firebaseAuth } from '../../config/fbConfig'

/**
 * A renderless functional component for checking whether user has custom claim userGroup
 */
const VerifyGroupFirebase = ({ verifyGroup }) => {
    console.log('inside verifyGroupFirebase')
    const user = firebaseAuth.currentUser
    if (user) {
        console.log('inside verifyGroup with user:', user)
        user.getIdTokenResult().then(idTokenResult => {
            console.log('idTokenResults.claims', idTokenResult.claims)
            const group = idTokenResult.claims.group;
            console.log('inside verifyGroup', group);
            if (group) {
                verifyGroup(group)
            }
        });
    }

    return (null)
}





const mapDispatchToProps = (dispatch) => {
    return {
        verifyGroup: (group) => dispatch({ type: 'USER_GROUP', userGroup: group }),
    }
}

export default connect(null, mapDispatchToProps)(VerifyGroupFirebase)

