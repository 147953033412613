import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'



class HomePageEmpty extends Component {
    constructor(props) {
        super(props)
        // create a ref to store the Demo DOM element


    }





    render() {
        const { auth } = this.props;
        if (auth.uid) return <Redirect to='/main' />
        return null

    }
}


const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}


export default connect(mapStateToProps)(HomePageEmpty)
