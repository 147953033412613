import React from 'react'
import { connect } from 'react-redux'
//import LogForm from './LogForm'
import LogFeelingsAndNeeds from './LogFeelingsAndNeeds'
import { createLog } from '../../store/actions/logActions'

function CreateLog(props) {
    return (
        <div>
            {/*<LogForm callbackFunc={props.createLog} formTitle='New Daily Log' returnURL={'/mylogs'} />*/}
            <LogFeelingsAndNeeds callbackFunc={props.createLog} returnURL={'/mylogs'} />
        </div>
    )
}

//NOTE: createLog is now a variable in props and its value is a function that dispatches
//the action-creator 'createLog' function defined in logActions.js, w
//createLog is an action-creator which instead of returning an action object (as done when we did not need asynchronous calls)
//now returns a function that makes an asynch call and then calling dispatch. 
//'log' below is just a variable in the arrow function



const mapDispatchToProps = (dispatch) => {
    return {
        createLog: (log) => dispatch(createLog(log)),
    }
}


export default connect(null, mapDispatchToProps)(CreateLog)

