export const createArticle = (article) => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid;
        firestore.collection('articles').doc(article.id).set({
            ...article,
            authorId: authorId,
            createdAt: new Date()
        }).then(() => {
            dispatch({ type: 'CREATE_ARTICLE_SUCCESS' });
        }).catch(err => {
            dispatch({ type: 'CREATE_ARTICLE_ERROR' }, err);
        });
    }
};




export const deleteArticle = (id, article) => {
    return (dispatch, { getFirestore }) => {

        const firestore = getFirestore();
        const docLocation = firestore.collection('articles')
        docLocation.doc(id).delete(
        ).then(() => {
            dispatch({ type: 'DELETE_ARTICLE', article })
        }).catch((error) => {
            dispatch({ type: 'DELETE_ARTICLE_ERROR', error })
        })


    }
}

export const editArticle = (id, log) => {
    return (dispatch, { getFirestore }) => {
        const firestore = getFirestore();
        const docLocation = firestore.collection('articles')

        docLocation.doc(id).update({
            ...log,
        }).then(() => {
            dispatch({ type: 'EDIT_ARTICLE', log })
        }).catch((error) => {
            dispatch({ type: 'EDIT_ARTICLE_ERROR', error })
        })
    }



}
