import React from 'react'
//import moment from 'moment'
import DeleteArticleButton from './DeleteArticleButton'
import EditArticleButton from './EditArticleButton'
import { Card } from 'react-bootstrap'



//child component of ArticleList, inherits article prop from ArticleList
const ArticleSummary = ({ article }) => {

    return (
        <div className='mt-3'>
            <Card style={{ width: '80%' }} >
                <Card.Body>
                    <Card.Title as="div" style={{ color: "black " }}>{article.title}</Card.Title>
                    <Card.Text as="div" style={{ color: "black " }} >
                        {/*<div>Posted by {article.authorUsername} </div>*
                        <div className="className grey-text">{moment(article.createdAt.toDate()).calendar()}</div>*/}
                    </Card.Text>
                    {/* need to call id separately because when Edit/DeleteArticleButton is called from ArticleDetails.js, that article is from firestore 
                    and does not have id as a property in article, so article.id is null called over there, even though article.id has a value here */}

                    <Card.Link to="#" as="div" className="float-right"><DeleteArticleButton article={article} id={article.id} /></Card.Link>

                    <Card.Link to="#" as="div" className="float-left"><EditArticleButton article={article} id={article.id} /></Card.Link>
                </Card.Body>
            </Card >
        </div>
    )
}

export default ArticleSummary