import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import ArticleList from '../articles/ArticleList'

class Articles extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }



    render() {
        const { articles, challenge_responses } = this.props;
        console.log(challenge_responses)
        return (
            <Container>


                <Row>
                    <Col md={{ span: 6, offset: 0 }} sm={{ span: 12, offset: 0 }}>


                        <ArticleList articles={articles} urlBase={'/myarticles/'} />

                    </Col>


                </Row>
                <Row><Col style={{ marginBottom: "10rem" }}></Col></Row>
            </Container>
        )
    }
}



const mapStateToProps = (state) => {
    return {

        scenarios: state.firestore.ordered.articles,

    }
}




export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'articles', orderBy: ['createdAt', 'desc'] },
        //{ collection: 'users', doc: props.auth.uid, subcollections: [{ collection: 'daily', }] }
        // { collection: 'notifications', limit: 3, orderBy: ['time', 'desc'] }
    ])
)(Articles)