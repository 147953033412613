export const CORS_URL = "";
export const API_URL = 'https://6apsi3nlz8.execute-api.eu-west-2.amazonaws.com/prod/';
//export const API_URL = "http://127.0.0.1:3000/"
export const USER_FEEDBACK_ENDPOINT = "USER_RESPONSE_FEEDBACK";
export const FEEDBACK_ENDPOINT = "user_input";
export const FEEDBACK_NOT_GIVEN_MSG = "No Feedback Detected";
export const USER_FEEDBACK_THANK_YOU_MSG = "THANK YOU!";
export const DEFAULT_QUESTION = " Write what you would like to say: "
export const DEFAULT_TOOLTIP_INFO = "Optional feedback to us:\nThumbs up 👍 for any feedback you found helpful\nThumbs down 👎 for any feedback you disagreed with or found confusing"
export const DB_FLAG = false;
export const SUBMIT_FEEDBACK_TEXT = "Submit Feedback"



export const PROMPT_OBJECT = {
    'OBSERVE': 'Consider a more objective description.',
    'LINK_FEELING_TO_NVA': 'It also helps to link negative feelings to unmet needs and values.',
    'PERSONAL_WANT ': 'Can you say what you want instead?',
    'NO_BIAS': 'Can you rephrase this to be less biased?',
    'REQUEST': 'This may be better framed as a request.',
    'POSITIVE_REQUEST': 'Can you phrase this in terms of what you do want?',
    'POSITIVE_WANT': 'Can you phrase this in terms of what you wanted?',
    'ACTIONS_NOT_PEOPLE_CAUSE': 'Better to say that someone\'s actions caused something?',
    'OBJECTIVE_CAUSE': '',
    'OBJECTIVE_EFFECT': '',
    'THOUGHT_NOT_FEELING': '',
    'NEG_FEELING_WITHOUT_NVA': 'It might help to mention the values that aren\'t being met.',
    'PSEUDOFEELING': '',
    'GENERAL_NOT_PERSONAL': 'For example, you can say \'That type of joke is not appropriate\' instead of \'Your joke is not appropriate\'',
}

export const PROMPT_OBJECT_LINKS = {
    'OBSERVE': 'https://diplomacydojo.com/objectivity',
    'LINK_FEELING_TO_NVA': 'https://diplomacydojo.com/aspirations',
    'OBJECTIVE_CAUSE': 'https://diplomacydojo.com/objectivity',
    'OBJECTIVE_EFFECT': 'https://diplomacydojo.com/objectivity',
    'THOUGHT_NOT_FEELING': 'https://diplomacydojo.com/thoughtsvsfeelings',
    'NEG_FEELING_WITHOUT_NVA': 'https://diplomacydojo.com/expressingnegativeemotions',
    'PSEUDOFEELING': 'https://diplomacydojo.com/judgmentsquiz',
}


