export const PUBLIC_SCENARIOS_CONTENT = {
    'scenario1': {
        title: 'Aggressive communicator',
        summary: 'You have an employee who is an aggressive communicator. They are dismissive and abrupt when they respond to questions. You can see people feel uncomfortable around them and you would like them to improve their communication style.',
        name: 'dismissiveEmployee'

    },
    'scenario2': {
        title: 'Idea Stomper',
        summary: 'Your fellow team member is always shooting down ideas. Their points on risks and drawbacks are valid. Of course ideas are not born bullet-proof and all plans have trade-offs. But you want everyone to have space to throw out ideas and not be immediately bombarded with risks and drawbacks. You’ve noticed fewer people are freely contributing ideas since they joined the team.',
        name: 'ideaStomper'

    },
    'scenario3': {
        title: 'Boss who only criticizes',
        summary: 'It seems your boss only tells you when you’ve done something wrong or need to improve. It would be nice to hear some encouragement sometimes.',
        name: 'bossOnlyCriticizes'
    },

    'ownscenario': {
        title: 'Own Scenario',
        summary: 'You can prepare a message for your own personal scenario here.',
        name: 'ownScenario'

    }
}
