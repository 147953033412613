import React, { Component } from 'react'

import { createNotesList } from './formFunctions'

class ViewAllNotes extends Component {
    /**
 * @constructor
 * @param {Array.<Object>}  props.docObjList is a list of objects representing the e.g., log
 * Note I will be using id and name as equivalent because I want to just store a 1D list of names (i.e., header_id's), but Multiselect requires the object format
 * @param {string} props.stateNameBase [props.stateNameBase + header_id] will be the name of variable in which checkbox choices for each header type will be chosen
 * @param {Array.<String>} props.valuesFromParent[props.stateNameBase + header_id]  passed from parent component if choices have been already made (saved from localstorage or database)
 * @param {('feelings'|'feelingsPos'|'needs')} props.checkboxFilename,  whether to loads feelingsPos, feelingsNeg or needs into checkbox choices
 * @param {string} props.dropdownPlaceholder text value for dropdown selection
 * @param {string} props.formTitle text value for title of this form
 * @param {string} props.addNoteButtonText text value for Add Note Button
 * @param {string} props.instructionsTooltip text value for tooltip next to title
 * @param {string} props.notesTooltip text value for tooltip next to Add Note
 * @param {callbackFunc} this callback sends state values [props.stateNameBase + header_id]  back to parent coponent
 */

    constructor(props) {
        super();

        this.state = {

        }
    }


    static defaultProps = {
        docObjList: [],
        stateNameBaseList: [], //list of stateNameBase, e.g., ['NegF_', 'NegN_', 'PosF_', 'PosN_']
        urlBase: '',
        id: '',
        title: '',
        headerToColorObj: {},
        titleColor: 'black'
    }




    render() {
        const { stateNameBaseList, urlBase, docObjList, title, headerToColorObj, titleColor } = this.props

        const noteListAll = docObjList.map(docObj => {
            return stateNameBaseList.map(stateNameBase => {
                if (docObj.hasOwnProperty(stateNameBase + 'notesList')) {
                    let currentStep
                    let showPos
                    let showNeg

                    if (stateNameBase === 'NegF_') {
                        currentStep = 1
                        showPos = false
                        showNeg = true
                    }
                    else if (stateNameBase === 'PosF_') {
                        currentStep = 1
                        showPos = true
                        showNeg = false
                    }
                    else if (stateNameBase === 'NegN_') {
                        currentStep = 2
                        showPos = false
                        showNeg = true
                    }
                    else if (stateNameBase === 'PosN_') {
                        currentStep = 2
                        showPos = true
                        showNeg = false
                    }
                    else {
                        currentStep = 1
                        showPos = false
                        showNeg = true
                    }

                    const notes = createNotesList(docObj[stateNameBase + 'notesList'], null, docObj.id, urlBase, true, headerToColorObj, currentStep, showPos, showNeg, docObj)

                    return notes
                }
                else return null

            })
        })

        return (

            <div>
                {title ? <h4 style={{ color: titleColor }}>{title}</h4> : null
                }
                {noteListAll}
            </div >
        )
    }
}

export default ViewAllNotes
