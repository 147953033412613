import React, { Component } from 'react';
import { Container, Row, Col, Button, Tab, Tabs } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import StepThrough from '../forms/StepThrough'
import TextBox from "../../bot_components/TextBox"
import './PublicPages.css'
import { TRANSLATION_PRACTICE_CONTENT, INSTRUCTIONS } from '../../config/TranslationPracticeContent'
import { getDocRef, createNewDoc, validateGetPathFromObj } from '../forms/formFunctions'
import queryString from 'query-string';
import { removeThumbsDownFromBotFeedbackList, displayBotFeedbackList, getPhrasesToWatchOut, arr2str } from '../forms/formFunctions'


class PracticeSummary extends Component {
    constructor(props) {
        super(props);
        // deleteCounter updates so that this component rerenders with deleted items removed.
        this.state = { deleteCounter: 0 };

    }

    static defaultProps = {
        callbackFunc: () => { }
    }


    processItemDeletion = () => {
        this.setState((prevState) => ({ deleteCounter: prevState.deleteCounter + 1 }), () => this.props.callbackFunc(this.props.botFeedbackList))

    }



    translationDisplayArr = () => {

        const displayArr = []
        this.props.textToTranslateArr && this.props.textToTranslateArr.forEach((text, i) => {

            displayArr.push(
                <div key={'note' + i} className="my-3">
                    <div><strong>Unhelpful response: </strong>{text}</div>
                    <div><strong>Your response: </strong>{this.props.userInputArr[i]}</div>
                    <div><strong>Sample response: </strong>{this.props.suggested_phrasesArr[i]}</div >
                </div>)

        })
        return displayArr
    }

    render() {
        const displayArr = this.translationDisplayArr()
        const phrasesToWatchOutArr = getPhrasesToWatchOut(this.props.botFeedbackList)
        const phrasesToWatchOutStr = arr2str(phrasesToWatchOutArr, ', ')
        console.log('phrasesToWatchOutStr', phrasesToWatchOutStr)
        return (
            <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0, offset: 0 }} className='pl-4 pr-4 mt-5 mx-5'  >

                <h5><strong>Summary:</strong></h5>
                {displayArr}
                <h5><strong>AI Feedback:</strong></h5>
                <ul>
                    {displayBotFeedbackList(this.props.botFeedbackList, this.processItemDeletion, true)}
                </ul>
                {phrasesToWatchOutArr.length > 0 ?
                    <><h5><strong>Phrases to watch out for: </strong></h5>{phrasesToWatchOutStr}</> : null
                }

            </Col>

        )

    }
}


class Practice extends Component {

    constructor(props) {
        super(props);
        const localData = localStorage.getItem('PracticeForm' + props.location.pathname)
        const localDataObj = localData ? JSON.parse(localData) : null
        let params = queryString.parse(props.location.search)

        this.state = {
            deleteCounter: 0,
            showSample: false,
            userInputArr: (localDataObj && localDataObj.hasOwnProperty('userInputArr') && localDataObj.userInputArr) || new Array(TRANSLATION_PRACTICE_CONTENT[props.match.params.id].textToTranslateArray.length).fill(''),
            key: (localDataObj && localDataObj.hasOwnProperty('key') && localDataObj.key) || 0,
            botFeedbackList: (localDataObj && localDataObj.hasOwnProperty('botFeedbackList') && localDataObj.botFeedbackList) || [],
            recordAttempt: true,

        };

    }
    static defaultProps = {
        callbackFunc: () => { }
    }

    componentDidMount() {
        this.props.recordBotFeedbackListLength(this.state.botFeedbackList.length)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.botFeedback !== this.props.botFeedback) {
            //remove s_tok field from new feedback from bot, concatenate it to botFeedbackList
            const botFeedback2 = this.props.botFeedback.map((sentenceObj) => {
                sentenceObj.s_tok = []
                return sentenceObj
            }
            )
            console.log('concatenating new feedback to botFeedbackList, length before:', this.state.botFeedbackList.length)
            this.setState((prevState) => ({ botFeedbackList: prevState.botFeedbackList.concat(botFeedback2) }),
                () => { this.writeToLocalStorage(); this.props.recordBotFeedbackListLength(this.state.botFeedbackList.length) })
        }

        if (prevProps.userFeedbackList !== this.props.userFeedbackList) {
            //new feedback from bot, concatenate it to botFeedbackList
            console.log('removing thumbs down', this.props.userFeedbackList)

            this.setState((prevState) => ({
                botFeedbackList:
                    removeThumbsDownFromBotFeedbackList(prevState.botFeedbackList, this.props.userFeedbackList, this.props.currentOffset)
            }))
        }

    }


    toggleShowSample = () => {
        this.setState((prevState) => ({ showSample: !prevState.showSample }))
    }

    //writes jsonified state into session storage. To be called after all calls to setState.
    writeToLocalStorage = () => {
        localStorage.setItem('PracticeForm' + this.props.location.pathname, JSON.stringify(this.state))
    }

    addUserInputToArr = (userInput) => {

        this.setState((prevState) => ({
            userInputArr: [
                ...prevState.userInputArr.slice(0, this.state.key),
                userInput,
                ...prevState.userInputArr.slice(this.state.key + 1)
            ]
        }), this.writeToLocalStorage);

    }

    generateTextTabs = (numItems) => {
        const textTabArr = []
        for (var i = 0; i < numItems; i++) {
            textTabArr.push(
                <Tab eventKey={i} key={i}>
                    <TextBox
                        callbackFunc={this.addUserInputToArr}
                        textAreaClasses=''
                        initialTextValue={this.state.userInputArr[i]}
                        clearFeedbackOnUserInput={false}
                        allowUserFeedback={true}
                        feedbackButtonClass='btn-medium mb-3'
                        prompt=''
                        blockUserInput={false}
                        placeholder={'Write your rephrasing here'}
                        textAreaHeight={3}
                        noVisibleTextBox={false}
                        recordAttempt={this.state.recordAttempt}
                        attemptPath={TRANSLATION_PRACTICE_CONTENT[this.props.match.params.id].attemptPath}
                    />
                </Tab>
            )
        }
        return textTabArr

    }

    deleteAllFeedback = () => {
        this.setState({ botFeedbackList: [] }, () => this.props.recordBotFeedbackListLength(this.state.botFeedbackList.length))
    }
    showPracticeFormCallback = (botFeedbackList) => this.setState({ botFeedbackList }, this.writeToLocalStorage)

    render() {
        const id = this.props.match.params.id;
        const scenarioTitle = TRANSLATION_PRACTICE_CONTENT[id].title
        const scenarioText = TRANSLATION_PRACTICE_CONTENT[id].summary
        const textToTranslateArr = TRANSLATION_PRACTICE_CONTENT[id].textToTranslateArray
        const suggested_phrasesArr = TRANSLATION_PRACTICE_CONTENT[id].suggestedPhrasesArray



        let params = queryString.parse(this.props.location.search)
        const textTabs = this.generateTextTabs(textToTranslateArr.length)
        console.log('params', params, 'pathname', this.props.location.pathname)
        var docRef = null

        if (validateGetPathFromObj(params)) {
            const docPath = 'groups/' + params.groupName + '/' + params.sessionId + '/' + TRANSLATION_PRACTICE_CONTENT[id].name
            console.log('docPath', docPath)
            docRef = getDocRef(docPath)
            console.log('docRef', docRef)
            if (params.initialiseDoc === 'true') {
                let textToTranslateObj = {}
                textToTranslateArr.forEach((item, key) => { textToTranslateObj['s' + key.toString()] = item })
                console.log('textToTranslateObj', textToTranslateObj)
                createNewDoc(docRef, textToTranslateObj)
            }
        }


        return (

            < div className="image-background" >
                <Container  >
                    <Row><Col className="mt-5">

                        <h5>
                            <strong>{scenarioTitle}</strong>
                            <p>
                                {scenarioText}
                            </p>
                        </h5>

                    </Col></Row>
                    <StepThrough
                        show_at_end={true} numItems={textToTranslateArr.length}
                        callbackFunc={(key) => { this.setState({ key: Number(key), showSample: false }) }}
                    >

                        <Row >
                            <Col className="mt-1">
                                {this.state.key < textToTranslateArr.length - 1 ? <h5><i>Rephrase the following:</i></h5> : null}
                                <h5>{textToTranslateArr[this.state.key]}</h5>
                                <Tabs id="left-tabs-example" defaultActiveKey={0} activeKey={this.state.key}>

                                    {textTabs}
                                    <Tab eventKey={textToTranslateArr.length}>
                                        <ReactToPrint
                                            trigger={() => {
                                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                // to the root node of the returned component as it will be overwritten.
                                                return <Button href="#">Print Summary</Button>;
                                            }}
                                            content={() => this.componentRef}
                                        />

                                        <PracticeSummary
                                            ref={el => (this.componentRef = el)}
                                            callbackFunc={this.showPracticeFormCallback}
                                            textToTranslateArr={textToTranslateArr}
                                            userInputArr={this.state.userInputArr}
                                            suggested_phrasesArr={suggested_phrasesArr}
                                            botFeedbackList={this.state.botFeedbackList}
                                        />

                                        <div className='text-right mt-3'><Button onClick={this.deleteAllFeedback}>Delete All Feedback</Button></div>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                        <Row><Col className="mt-5">
                            {this.state.key < textToTranslateArr.length ? <Button style={{ backgroundColor: '#ff7000', border: 'none' }} onClick={this.toggleShowSample}>Show Sample Feedback</Button> : null}
                            {this.state.showSample ?
                                <div className="mt-3"><h5>{suggested_phrasesArr[this.state.key]}</h5></div> : null
                            }
                        </Col></Row>



                    </StepThrough>
                    <Row><Col className="my-5"></Col></Row>
                </Container>

            </div >
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.firebase.auth,
        isAdmin: state.auth.isAdmin, //whether current user is admin
        botFeedback: state.bot.botFeedback,
        userInput: state.bot.userInput,
        userFeedbackList: state.bot.userFeedbackList,
        currentOffset: state.bot.currentOffset //the length of botFeedbackList right before bot gave current feedback that user is currently commenting on

    }
}


const mapDispatchToProps = (dispatch) => {
    return {

        recordBotFeedbackListLength: (botFeedbackListLength) => dispatch({ type: 'RECORD_BOT_FEEDBACK_LIST_LENGTH', botFeedbackListLength }),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Practice))