import React, { Component } from 'react'
import ScenarioList from '../scenarios/ScenarioList'
import ChallengeList from '../challenges/ChallengeList'
//import Notifications from './Notifications'  We deleted the corresponding cloud functions and commented it out of firebase/functions/index.js
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import UpdateUsername from '../auth/UpdateUsername'
import HigherOrderModal from '../../Utilities/HigherOrderModal'
import WelcomeWrapper from "../../Utilities/WelcomeWrapper"
import TermsAndConditions from '../publicpages/TermsAndConditions'
import TooltipCustom from "../../Utilities/TooltipCustom"
import VerifyGroupFirebase from '../auth/VerifyGroupFirebase'

import firebase from "../../config/fbConfig"

const ModalTandC = HigherOrderModal(TermsAndConditions)

const ModalUpdateUsername = HigherOrderModal(UpdateUsername)
const ModalWelcome = HigherOrderModal(WelcomeWrapper)

/**
 * Dashboard class. Has the main screen after login. Props.scenarios are connected via firestoreConnect to automatically
 * sync with scenarios collection in firestore. auth is automatically synced (don't need separate Connect) 
 * isNewUser is 
 * Has a Modal containing changeUserName and also agree to terms for when the user is a newUser. 
 * **NOT FUNCTIONAL**
 */
class Dashboard extends Component {

    /**

    * @property {boolean | undefined} props.profile.terms whether or not user has agreed to the terms and profile
    */
    constructor(props) {
        super(props);



        /**
        * State object of Dashboard
        * @property {('off'|'on')}this.state.terms  whether or not user has checked the Agree to Terms checkbox in the modal
        * @property {boolean} this.state.termsErrorMessage error message saying user must agree to terms before continuing to use the program
        * @property {boolean} seenUpdateUsername whether the updateUserNameModal has been shown (keep it closed if it has already been shown)
        */

        this.state = {
            terms: 'off',
            termErrorMessage: '',
            seenUpdateUsername: false,
            welcomeShown: false

        }

    }





    modalCloseFunctionWelcome = () => {
        this.setState({ welcomeShown: true })
    }

    modalCloseFunctionUpdateUsername = () => {
        this.setState({ seenUpdateUsername: true })
    }

    modalCloseFunctionTandC = () => {
        console.log('into modalCloseFunctionTandC', this.state.terms)
        if (this.state.terms === 'on') {

            const profiles = firebase.firestore().collection('users')
            return profiles.doc(this.props.auth.uid).update({
                terms: true
            }).then(() => {
                console.log('terms accepted')
            }).catch((error) => {
                console.log('error when setting terms')
            })
        }
    }

    handleChange = (e) => {

        if (this.props.authError) {
            this.props.resetAuthErrorSignUp()
        }
        if (this.state.termsMessage) { this.setState({ termsMessage: null }) }

        this.setState({
            [e.target.id]: e.target.value
        })
        if ((e.target.id === 'terms') && (this.state.terms === 'on')) {
            this.setState({ terms: 'off' })
        }
    }


    render() {


        console.log('props', this.props)
        console.log('state', this.state)
        const { auth, scenarios, challenges, terms, isNewUser, userGroup, verifyGroup } = this.props;
        if (!auth.uid) return <Redirect to='/signin' />
        var showStateTandC
        if (typeof terms === 'undefined') { showStateTandC = false } else {
            showStateTandC = terms ? false : true
        }
        const scenarioTooltip = 'Scenarios help you practice difficult situations in your own life.'
        const makeoverTooltip = 'Mini-challenges that describe classic work conflicts along with judgmental sentences for you to diplomatize.'

        //console.log('showStateTandC', showStateTandC)
        //console.log('Username registered as', username)

        //the following controls the open and close states of the two modals: ModalTandC and ModalUpdateUsername
        const showModalCloseTandC = this.state.terms === 'on' ? true : false
        const showStateUpdateUsername = (terms && isNewUser && !this.state.seenUpdateUsername && !this.state.showWelcome && this.state.welcomeShown) ? true : false
        const showWelcome = (terms && isNewUser && !this.state.welcomeShown) ? true : false

        // const showModalCloseUsername = username ? true : false //only show close button if username has been set.
        //allow user to not choose a username

        const modalText = 'By default anything you post to the community will be anonymous. You can choose a username to use if you ever wish to publish anything non-anonymously to the community. We can also generate options for you. Just click on "Generate Username". You can edit your username whenever you wish under the Accounts tab.'

        const welcomeTitle = 'Welcome to Diplomacy Dojo. '
        const textColor = 'black' //'#1E90FF'
        const tooltipColor = '#ff7000'
        return (
            <Container>
                <VerifyGroupFirebase />
                <Row>
                    <Col>
                        <ModalTandC
                            size='xl'
                            showState={showStateTandC}
                            controlShowFromParent={true}
                            showToggleButton={false}
                            showCloseButton={showModalCloseTandC}
                            buttonClassName="termsAndConditions"
                            handleChange={this.handleChange}
                            checked={this.state.terms === 'on' ? true : false}
                            modalCloseFunction={this.modalCloseFunctionTandC} //cannot use onExit because modalClosefunctionTandC needs to be called before close iniitates.
                        />
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <ModalWelcome
                            size='xl'
                            title={welcomeTitle}
                            showState={showWelcome}
                            controlShowFromParent={true}
                            showToggleButton={false}
                            showCloseButton={true}
                            buttonClassName="welcome"
                            modalCloseFunction={this.modalCloseFunctionWelcome}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ModalUpdateUsername
                            size='xl'
                            title="Choose Username"
                            bodyText={modalText}
                            showState={showStateUpdateUsername}
                            controlShowFromParent={true}
                            showToggleButton={false}
                            showCloseButton={true}
                            buttonClassName="updateUsername"
                            modalCloseFunction={this.modalCloseFunctionUpdateUsername}
                            callbackFunc={this.modalCloseFunctionUpdateUsername}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={{ span: 6, offset: 0 }} xs={{ span: 12, offset: 0 }}>
                        <Col md={{ span: 8, offset: 0 }} xs={{ span: 12, offset: 0 }}>
                            <TooltipCustom innertext={scenarioTooltip} className="float-left" color={tooltipColor} />
                            <h3 style={{ color: textColor, border: 0 }} className="mt-5">Situations</h3>

                        </Col>
                        <ScenarioList scenarios={scenarios} urlBase={'/respondtoscenario/'} />

                    </Col>

                    <Col md={{ span: 6, offset: -1 }} sm={{ span: 12 }} className='mb-5'>

                        <Col md={{ span: 8, offset: 0 }} xs={{ span: 12, offset: 0 }}>
                            <TooltipCustom innertext={makeoverTooltip} className="float-left" color={tooltipColor} />
                            <h4 style={{ color: textColor, border: 0 }} className="mt-5">Practice Scenarios</h4>
                        </Col>
                        <ChallengeList challenges={challenges} urlBase={'/takechallenge/'} />

                    </Col>
                    {/*
                    <Col md={{ span: 5, offset: -1 }} sm={{ span: 12 }}>
                        <Notifications notifications={notifications} />

                    </Col>
                     */}
                </Row>
                <Row><Col className="my-5"></Col></Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isNewUser: state.auth.isNewUser, // this will be lost after initial signup if page is refreshed
        terms: state.firebase.profile.terms,
        parentDocsRespondedTo: state.firebase.profile.parentDocs,
        username: state.firebase.profile.username,
        scenarios: state.firestore.ordered.scenarios,
        challenges: state.firestore.ordered.challenges,
        auth: state.firebase.auth,
        userGroup: state.auth.userGroup,
        // notifications: state.firestore.ordered.notifications,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        verifyGroup: (userGroup) => dispatch({ type: 'USER_GROUP', userGroup: userGroup }),
    }
}


export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'scenarios', orderBy: ['createdAt', 'desc'] },
        { collection: 'challenges', orderBy: ['createdAt', 'desc'] },
        //{ collection: 'users', doc: props.auth.uid, subcollections: [{ collection: 'daily', }] }
        // { collection: 'notifications', limit: 3, orderBy: ['time', 'desc'] }
    ])
)(Dashboard)