import React from 'react'
import ArticleSummary from './ArticleSummary'
import { Link } from 'react-router-dom'


//child component of Dashboard (the component routed to '/' which is the main page) 
const ArticleList = ({ articles, urlBase = '/articles/' }) => {

    console.log(articles)
    return (
        <div className="article-list section">
            {/*articles && needs to return true for this clause to run*/}
            {articles && articles.map(article => {
                return (
                    <Link
                        as="div"
                        to={{
                            pathname: urlBase + article.id,
                            state: {
                                article: article,
                            }
                        }}
                        key={article.id}>
                        <ArticleSummary article={article} showDate={false} />
                    </Link>
                )
            })}
        </div>
    )
}

export default ArticleList