import React from "react"



/**
* @constructor
* @param {*} props The properties sent from {@link Feedback} class
* @param {Function} props.useHovering Whether or not to use hovering
* @param {Array.<Object>} props.sentenceOutput Array of sentence objects, output from bot-API
* @param {String} props.highlightedItemClass class for highlighted item.
* @param {String} props.itemClass The class describing type of highlight to be used (seems not to be used in Highlight3)
* @param {Function} props.toggleHoveredEnter callbackfunc of what to do when toggleHoverEntered
* @param {Function} props.toggleHoveredExit callbackfunc of what to do when toggleHoverExited
 * @summary
 * This takes in array of bot-API outputs, one item for each sentence. Each item is its own object with field sentence no (e.g., '0') and value object:
 * {'r_l': [{'id': 31667, 'bad': 1, 'prompt_str': 'OBSERVATION_WANT_REQUEST_PROMPT', 'r_i': [1], 'response_str': "'hate' seems to be describing your attitude towards someone. It seems negative. Instead focus on how observable actions did not affirm values"}], 'sentence': ['I', 'hate', 'you', '.']}
 *  Returns array of React elements (one for each sentence) which are the input text with all portions with feedback highlighted.
 *  Each item of this array of React elements is a concatenation of spans within the sentence that have/don't have highlight
 */




export const Highlight3 = (props) => {
   var responseArray = props.sentenceOutput;
   var regex = /[^\w\s]/gi;
   var htmlArray = [];
   responseArray.forEach((singleSentenceFeedback, index0) => {

      var theSentenceArray = singleSentenceFeedback.s_tok;
      // console.log(theSentenceArray);
      singleSentenceFeedback.r_l.forEach((theResponseFeedback) => {
         // console.log(theResponseFeedback.r_i)
         var comment_id = theResponseFeedback.id;
         //console.log('comment id', theResponseFeedback, comment_id)
         theResponseFeedback.r_i.forEach((indices, index) => {

            var theSentenceVal = theSentenceArray[indices];
            // console.log(comment_id)
            if (typeof (theSentenceVal) === "string")
               theSentenceArray[indices] =
                  <mark
                     style={{ cursor: "pointer" }}
                     onMouseEnter={props.useHovering ? props.toggleHoveredEnter.bind(this, comment_id) : null}
                     onMouseLeave={props.useHovering ? props.toggleHoveredExit.bind(this, comment_id) : null}
                     key={"mark" + index + "_sentence_" + index0 + "" + indices}
                     className={props.highlightedItemClass}>
                     {theSentenceVal}
                  </mark>

         })

      })
      theSentenceArray.forEach((element, index) => {
         var containsNewLine = typeof (element) === "string" ? element.includes("\n") : element.props.children.includes("\n");
         if ((containsNewLine))
            theSentenceArray[index] = <p key={"span" + index + "_sentence_" + index0}></p>
         else if (typeof (element) === "string") {

            if (regex.test(element) && element !== "&")
               theSentenceArray[index] = <span key={"span" + index + "_sentence_" + index0}>{element}</span>
            else {
               if (index + 1 < theSentenceArray.length && typeof (theSentenceArray[index + 1]) !== "string")
                  theSentenceArray[index] = <span key={"span" + index + "_sentence_" + index0}> {element} </span>
               else
                  theSentenceArray[index] = <span key={"span" + index + "_sentence_" + index0}> {element}</span>

            }
         }

      })
      htmlArray = htmlArray.concat(theSentenceArray);

   })


   return htmlArray;
}