import React, { Component } from 'react'
import LogList from '../logs/LogList'
//import Notifications from './Notifications'  We deleted the corresponding cloud functions and commented it out of firebase/functions/index.js
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import TooltipCustom from "../../Utilities/TooltipCustom"
import ViewAllNotes from '../forms/ViewAllNotes'
import { createNameColorObj } from '../forms/formFunctions'
import { feelingsNegativeArr, feelingsPositiveArr, needsArr } from '../forms/readCSV'

const myLogs = 'myLogs'

/**
 * Daily light check-in for user. Checkboxes for feelings (positive and negative) and needs (both met and unmet) and a text box for strategies.
 * Writes to document firestore collection 'logs' under each User.
 */
class DailyLogs extends Component {

    /**
    * State object of TextBox. 
    * @property {boolean | undefined} props.profile.terms whether or not user has agreed to the terms and profile
    */
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        feelingsNegativeArr.then(result => { const negFeelColorObj = createNameColorObj(result); this.setState({ negFeelColorObj }) }).catch((err) => console.log(err));
        feelingsPositiveArr.then(result => { const posFeelColorObj = createNameColorObj(result); this.setState({ posFeelColorObj }) }).catch((err) => console.log(err));
        needsArr.then(result => { const needsColorObj = createNameColorObj(result); this.setState({ needsColorObj }) }).catch((err) => console.log(err));

    }




    render() {
        const logTooltip = 'These logs help you identify your feelings and needs. Once you identify your needs, you can brainstorm strategies that you help you get your needs met.'
        const tooltipColor = '#0099CC'
        const { auth, logs } = this.props;
        if (!auth.uid) return <Redirect to='/signin' />
        const allFeelColorObj = { ...this.state.negFeelColorObj, ...this.state.posFeelColorObj }
        return (
            <Container>
                <Row className="mt-5">
                    <TooltipCustom innertext={logTooltip} className="float-left" color={tooltipColor} />
                    <span style={{ color: tooltipColor }}><h3>Feelings, Needs, and Strategies</h3></span>
                </Row>
                <Row>
                    <Col md={{ span: 6, offset: 0 }} sm={{ span: 12, offset: 0 }}>
                        <div className="input-field mt-5"><Button style={{ background: tooltipColor, border: 0 }} href={'/newlog'}>Create New Log</Button ></div>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col md={{ span: 6, offset: 0 }} sm={{ span: 12, offset: 0 }}>
                        <LogList logs={logs} urlBase={'/mylogs/'} stateNameBaseList={['PosF_', 'NegF_']} headerToColorObj={allFeelColorObj} />
                    </Col>
                    <Col md={{ span: 5, offset: 0 }} sm={{ span: 12 }}>

                        <div>
                            <ViewAllNotes title='Enhance positivity:' titleColor={'black'} docObjList={logs} urlBase={'/editlog/'} stateNameBaseList={['PosN_']} headerToColorObj={this.state.needsColorObj} />
                        </div>
                        <div className='mt-5'>
                            <ViewAllNotes title='Improve negativity:' titleColor={'black'} docObjList={logs} urlBase={'/editlog/'} stateNameBaseList={['NegN_']} headerToColorObj={this.state.needsColorObj} />
                        </div>
                    </Col>


                    {/*
                    <Col md={{ span: 5, offset: -1 }} sm={{ span: 12 }}>
                        <Notifications notifications={notifications} />

                    </Col>
                     */}
                </Row>
                <Row><Col style={{ marginBottom: "10rem" }}></Col></Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {

        logs: state.firestore.ordered[myLogs],
        auth: state.firebase.auth,
        // notifications: state.firestore.ordered.notifications,

    }
}



export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => {
        if (props.auth.uid) {
            return [
                {
                    collection: 'users',
                    doc: props.auth.uid,
                    subcollections: [{ collection: 'logs', orderBy: ['createdAt', 'desc'] }],
                    storeAs: myLogs
                }
            ]
        }

    })
)(DailyLogs)