import React from 'react'
import { connect } from 'react-redux'
import ScenarioForm from './ScenarioForm'
import { createScenario } from '../../store/actions/scenarioActions'


/**
 * RespondToScenario is a functional component that allows users to respond to posted scenarios. 
 * 
 */

const RespondToScenario = ({ location, createScenario }) => {
    //location is passed via history.state.location from the Link redirect from ScenarioList.js
    /**
    * @constructor
    * @param {object} props.location is passed via history.state.location from the Link redirect from ScenarioList.js. Should have state containing scenario object
    *@param { object } props.createScenario is reducer dispatch function imported and then added to props via mapDispatchToProps
*/

    //create new scenario object with only title and description so that the 'answers' are not shown when the user is responding.
    var scenario_truncated = {}
    if (location.state && location.state.scenario) {
        scenario_truncated.id = location.state.scenario.id
        scenario_truncated.title = location.state.scenario.title
        scenario_truncated.summary = location.state.scenario.summary
        scenario_truncated.tags = location.state.scenario.tags
        scenario_truncated.groups = location.state.scenario.groups
    }

    const returnURL = '/myscenarios'
    console.log('location.state', location.state.urlBase, returnURL)
    return (
        <div>
            {
                scenario_truncated ?
                    <ScenarioForm parentDoc={scenario_truncated.id} scenario={scenario_truncated} callbackFunc={createScenario} isPublic={false} returnURL={returnURL} showAllSteps={false} />
                    : <ScenarioForm callbackFunc={createScenario} formTitle='Own Scenario' isPublic={false} returnURL={returnURL} showAllSteps={false} />

            }
        </div>
    )
}

//NOTE: createScenario is now a variable in props and its value is a function that dispatches
//the action-creator 'createScenario' function defined in scenarioActions.js, w
//createScenario is an action-creator which instead of returning an action object (as done when we did not need asynchronous calls)
//now returns a function that makes an asynch call and then calling dispatch. 
//'scenario' below is just a variable in the arrow function



const mapDispatchToProps = (dispatch) => {
    return {
        createScenario: (scenario) => dispatch(createScenario(scenario)),
    }
}


export default connect(null, mapDispatchToProps)(RespondToScenario)

