const initState = {}

const logReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_LOG':
            console.log('created log', action.log)
            return state;
        case 'CREATE_LOG_ERROR':
            console.log('create log error', action.error)
            return state;
        case 'DELETE_LOG':
            console.log('deleted log', action.log)
            return state;
        case 'DELETE_LOG_ERROR':
            console.log('delete log error', action.error)
            return state;
        case 'EDIT_LOG':
            console.log('edit log', action.log)
            return state;
        case 'EDIT_LOG_ERROR':
            console.log('edit log error', action.error)
            return state;
        default:
            return state;


    }
}

export default logReducer