import React from 'react'
import ScenarioSummary from './ScenarioSummary'
import { Link } from 'react-router-dom'


//child component of Dashboard (the component routed to '/' which is the main page) 
const ScenarioList = ({ scenarios, urlBase = '/scenario/' }) => {


    return (
        <div className="scenario-list section">
            {/*scenarios && needs to return true for this clause to run*/}
            {scenarios && scenarios.map(scenario => {
                return (
                    <Link
                        as="div"
                        to={{
                            pathname: urlBase + scenario.id,
                            state: {
                                scenario: scenario,
                                urlBase
                            }
                        }}
                        key={scenario.id}>
                        <ScenarioSummary scenario={scenario} showDate={false} />
                    </Link>
                )
            })}
        </div>
    )
}

export default ScenarioList