export const INSTRUCTIONS = [



]


export const TRANSLATION_PRACTICE_CONTENT_ORIG = {
    'ungrounded_intern': {
        title: 'Ungrounded Intern',
        summary: 'You are in charge of supervising a new intern. They seem unwilling to work on basic tasks. Instead, they are constantly proposing grand, blue-sky ideas that are completely unrealistic.  Also, they are very distant from the deliverables needed for the current deadline. You find the intern super annoying and you know you have to say something.',
        name: 'UngroundedIntern',
        activityType: 'Observations',
        textToTranslateArray: ['You seem to feel like mundane tasks are beneath you.',
            'Your ideas are all completely unrealistic.'

        ],
        suggestedPhrasesArray: ['I noticed you haven’t completed the tasks I set out for you.',
            'Your ideas don\'t account for practical constraints and immediate business priorities.'
        ],
        attemptPath: 'ungrounded_intern',

    },

    'angry_emoter': {
        title: 'Angry Emoter',
        summary: 'You have a high performing employee who regularly indulges in outward displays of anger and frustration with others when discussing work issues.  You can see they are intimidating the rest of the team.',
        name: 'AngryEmoter',
        activityType: 'Observations',
        textToTranslateArray: [
            'I\'d like you to stop being so negative when discussing things with the team.',
            'You get very annoyed when people ask you questions.',
            'You always shout at people.',
            'When you raise your voice at people you intimidate them.',
            'Can you please stop being so rude?',

        ],
        suggestedPhrasesArray: [
            ' I\'d like to talk about how to achieve more effective discussions with the team.',
            'I\'ve noticed you roll your eyes and sigh when people ask you questions.',
            'Several times I\'ve noticed you speak to people in a loud tone of voice.',
            'When you roll your eyes and sigh, or respond in a loud tone, people feel uncomfortable and less able to have an effective discussion.',
            'I\'d like to talk about ways to keep discussions calm. What do you think?',
        ],
        attemptPath: 'angry_emoter'
    },

    'objectivity_practice': {
        title: 'Objectivity Practice',
        summary: '',
        name: 'ObjectivityPractice1',
        activityType: 'Observations',
        textToTranslateArray: [
            'I thought you seemed timid and lacked gravitas in the meeting.',
            'You put me down.',
            'You were dismissive of their point.',
        ],
        suggestedPhrasesArray: [
            'You spoke quietly and quickly in the meeting.',
            'You said "that\'s a bad idea" without explaining further.',
            "Your response didn't address their point."
        ],
        attemptPath: 'objectivity_practice'
    }

}










export const TRANSLATION_PRACTICE_CONTENT = {
    'ungrounded_intern': {
        title: 'Scenario: Ungrounded Intern',
        summary: 'You are in charge of supervising a new intern. They seem unwilling to work on basic tasks. Instead, they are constantly proposing grand, blue-sky ideas that are completely unrealistic.  Also, they are very distant from the deliverables needed for the current deadline. You find the intern super annoying and you know you have to say something.',
        name: 'UngroundedIntern',
        activityType: 'Observations',
        textToTranslateArray: [
            'I want you to be more effective at work',
            "You refuse to do the tasks I set out for you.",
            'Your ideas are all completely unrealistic.',
        ],
        suggestedPhrasesArray: [
            "I'd like to discuss how you can be more effective at work.",
            'You haven’t completed the tasks I set out for you.',
            'Your ideas don\'t account for practical constraints and immediate business priorities.',

        ],
        attemptPath: 'ungrounded_intern',

    },

    'objectivity_practice': {
        title: 'Objectivity Practice',
        summary: 'You are a manager at work and a few members of your team have problematic communication styles. You need to find a way to describe their current communication styles objectively. Here are a series of non-objective sentences describing communication styles. Try to rephrase each sentence in a way that is objective.',
        name: 'ObjectivityPractice1',
        activityType: 'Observations',
        textToTranslateArray: [
            'I thought you seemed timid and lacked gravitas in the meeting.',
            'You put me down.',
            'You were dismissive of their point.',
        ],
        suggestedPhrasesArray: [
            'You spoke quietly and quickly in the meeting.',
            'You said "that\'s a bad idea" without explaining further.',
            "Your response didn't address their point."
        ],
        attemptPath: 'objectivity_practice'
    },

    'angry_emoter': {
        title: 'Scenario: Angry Emoter',
        summary: 'You have a high performing employee who regularly indulges in outward displays of anger and frustration with others when discussing work issues.  You can see they are intimidating the rest of the team.',
        name: 'AngryEmoter',
        activityType: 'Observations',
        textToTranslateArray: [
            'I\'d like you to stop being so negative when discussing things with the team.',
            'You get very annoyed when people ask you questions.',
            'When you act annoyed with people you intimidate them.',
            'Can you please stop being so rude?',

        ],
        suggestedPhrasesArray: [
            ' I\'d like to talk about how to achieve more effective discussions with the team.',
            'I\'ve noticed you roll your eyes and sigh when people ask you questions.',
            'When you roll your eyes and sigh, or respond in a loud tone, people feel uncomfortable and less able to have an effective discussion.',
            'I\'d like to talk about ways to keep discussions calm. What do you think?',
        ],
        attemptPath: 'angry_emoter'
    },



}









