import React from 'react'


function SubscribeFormHome() {
    return (
        <>

            <div className="ml-form-embed"
                data-account="2951050:b7x1g4e3m2"
                data-form="3725083:z4a5y1">
            </div>
        </>
    )
}

export default SubscribeFormHome
