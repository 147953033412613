import React from 'react'
import { Container, Col } from 'react-bootstrap'
import ArticleLayout from './ArticleLayout'

//shown under url /submittedchallenge/
//props are received via mapStateToProps from redux-firestore
//get to this page from ArticleList link set on Link element
const ArticleDetails = ({ tags = [],
    groups = [],
    title = '',
    mainText = '',
    imagePath = '',
    quote = '',
    videoLink = '',
    id = '' }) => {





    return (
        <Container className='mt-5'>
            <Col md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }}    >
                <ArticleLayout id={id} returnURL='/articles' />
            </Col>
        </Container >

    )
}






export default ArticleDetails

