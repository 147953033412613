import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import { Col, Button, Container, Row, Tab, Nav } from 'react-bootstrap'
import { FaInfoCircle, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import HigherOrderCollapsible from '../../Utilities/HigherOrderCollapsible'
import TextBox from "../../bot_components/TextBox"
import CheckboxForm from '../forms/CheckboxForm'
import TooltipCustom from "../../Utilities/TooltipCustom"
import "../../index.css"
import CheckDeleteModal from '../../Utilities/CheckDeleteModal'

import "./ScenarioForm.css"

const CollapsibleCheckboxForm = HigherOrderCollapsible(CheckboxForm)

const defaultTooltipColor = "#0099CC"
/**
 * ScenarioForm class
 * Allows user to create or edit a new scenario and stores it in firestore through callbackFunc defined in parent class.
 * passes a prop function callbackFunc to get arr of checkbox values to CollapsibleCheckboxForm which is CheckboxForm wrapped in a higher order Collapsible Modal
 */


/**
 * @constructor
 * @param {*} props.scenario, all attributes related to the scenario being passed in.
 */

class ScenarioForm extends Component {

    /**
     * @constructor
     * @param {boolean} this.props.scenario.isPublic determines whether the scenario is public or private and thus which collection its stored in
     * @param {array} this.props.scenario.tags, list of tags relating to scenario
     * * @param {array} this.props.scenario.tags, list of groups for whom scenario is relevant (to be used later to organise different groups)
     * @param {boolean} this.props.scenario.authByAdmin whether scenario was created by Admin
     * @param {boolean} this.props.showAllSteps whether to walk through all steps or whether to just show short version
     * @param {boolean} this.props.parentDoc The doc id of the parentScnario if it is responding to a challenge or public scenario, then title and summary should be fixed
 */

    constructor(props) {
        super(props);
        //note these createRef() need to be assigned to a DOM element (e.g., Col) and not a component. It won't work on a component

        this.ownFeelingsRef = React.createRef();
        this.ownNeedsRef = React.createRef();
        this.othersFeelingsRef = React.createRef();
        this.othersNeedsRef = React.createRef();


        const localData = localStorage.getItem('ScenarioForm' + props.location.pathname)

        const localDataObj = localData ? JSON.parse(localData) : null

        //value of isPublic comes from scenarios, if scenarios is passed in as props. Otherwise define it from props.isPublic, otherwise false
        const isPublicFromProps = this.props.isPublic || false

        //need to define conditional || in case props.scenario does not have the attribute. This won't be caught by defaultProps as scenario does exist
        //and the attribute are 2nd order properties.
        //collpasibleState_X are to control collapsibles so only one is open at a time. controlled via function: close_all_other_collapsibles
        this.state = {
            tags: (localDataObj && localDataObj.hasOwnProperty('tags') && localDataObj.tags) || props.scenario.tags || [],
            groups: (localDataObj && localDataObj.hasOwnProperty('groups') && localDataObj.groups) || props.scenario.groups || [],
            isPublic: props.scenario.isPublic || isPublicFromProps,
            authByAdmin: props.scenario.authByAdmin || false,
            title: (localDataObj && localDataObj.hasOwnProperty('title') && localDataObj.title) || props.scenario.title || '',
            summary: (localDataObj && localDataObj.hasOwnProperty('summary') && localDataObj.summary) || props.scenario.summary || '',
            //judgment: (localDataObj && localDataObj.hasOwnProperty('judgment') && localDataObj.judgment) || props.scenario.judgment || '',
            pseudoFeelings: (localDataObj && localDataObj.hasOwnProperty('pseudoFeelings') && localDataObj.pseudoFeelings) || props.scenario.pseudoFeelings || [],
            ownFeelings: (localDataObj && localDataObj.hasOwnProperty('ownFeelings') && localDataObj.ownFeelings) || props.scenario.ownFeelings || [],
            ownNeeds: (localDataObj && localDataObj.hasOwnProperty('ownNeeds') && localDataObj.ownNeeds) || props.scenario.ownNeeds || [],
            othersFeelings: (localDataObj && localDataObj.hasOwnProperty('othersFeelings') && localDataObj.othersFeelings) || props.scenario.othersFeelings || [],
            othersNeeds: (localDataObj && localDataObj.hasOwnProperty('othersNeeds') && localDataObj.othersNeeds) || props.scenario.othersNeeds || [],
            observation: (localDataObj && localDataObj.hasOwnProperty('observation') && localDataObj.observation) || props.scenario.observation || '',
            acknowledge: (localDataObj && localDataObj.hasOwnProperty('acknowledge') && localDataObj.acknowledge) || props.scenario.acknowledge || '',
            aspiration: (localDataObj && localDataObj.hasOwnProperty('aspiration') && localDataObj.aspiration) || props.scenario.aspiration || '',
            strategies: (localDataObj && localDataObj.hasOwnProperty('strategies') && localDataObj.strategies) || props.scenario.strategies || '',
            request: (localDataObj && localDataObj.hasOwnProperty('request') && localDataObj.request) || props.scenario.request || '',
            finalMessage: (localDataObj && localDataObj.hasOwnProperty('finalMessage') && localDataObj.finalMessage) || props.scenario.finalMessage || '',
            parentDoc: props.parentDoc || null,

            collapsibleState_ownFeelings: false,
            collapsibleState_ownNeeds: false,
            collapsibleState_othersFeelings: false,
            collapsibleState_othersNeeds: false,
            copyToObservation: this.props.copyToObservation, //only copy from description to observation once and if user has not already typed observation
            copyToFinalMessage: this.props.copyToFinalMessage, //used to toggle 'key' prop of finalMessage text box so it re-renders when finalMessage updates. components only rerender on state change (not prop-value change)
            showAllSteps: this.props.showAllSteps, //initial state of showAllSteps but can be toggled with showAllStepsToggle
            showCombineButton: true,
            showCombineModal: false,
            key: 0,
        }
    }

    static defaultProps = {
        recordAttempt: false,
        attemptPath: 'generalAttempt',
        formTitle: '',
        scenario: {
            tags: [],
            groups: [],
            isPublic: false,
            authByAdmin: false,
            title: '',
            summary: '',
            //judgment: '',
            pseudoFeelings: [],
            ownFeelings: [],
            ownNeeds: [],
            othersFeelings: [],
            othersNeeds: [],
            observation: '',
            acknowledge: '',
            aspiration: '',
            strategies: '',
            request: '',
            finalMessage: '',
        },
        copyToObservation: true,
        copyToFinalMessage: true,
        showAllSteps: true,
        parentDoc: null,
        showGroups: false,
        showTags: false,
        showAllStepsToggle: false,
        showCombineModal: false,


    }


    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        }, this.writeToLocalStorage)
        if (e.target.id === 'summary' && !this.state.Observation && this.state.copyToObservation)
            this.setState({
                observation: this.state.summary,
            }, this.writeToLocalStorage)
    }

    handleSubmit = (e) => {
        e.preventDefault()
        //callbackFunc is action creator defined in scenarioActions.js
        console.log(this.props)
        let scenario_to_send = Object.assign({}, this.state);
        //remove collapsible, copyTo and shos state keys from what is sent via callbackFun 
        // this is because createScenario and editScenario write all properties of scenario into the database record, using destructuring, i.e., ...scenario
        Object.keys(scenario_to_send).forEach((key) => {
            if (key.startsWith('collapsibleState') || key.startsWith('copyTo') || key.startsWith('show'))
                delete scenario_to_send[key]
        })
        if ('authorId' in this.props.scenario) {
            scenario_to_send['authorId'] = this.props.scenario.authorId
        }

        console.log('scenario_to_send:', scenario_to_send)
        this.props.callbackFunc(scenario_to_send)
        const returnURL = this.props.returnURL || '/'
        if (this.props.history) this.props.history.push(returnURL) //return to the home route
    }

    arr2str = (arr, separator = " ") => {
        if (!arr) return ''
        return arr.toString().replace(/,/g, separator)
    }

    arr2str_with_and = (arr) => {

        if (arr.length === 0) {
            return ''
        }
        else if (arr.length === 1) {
            return arr.toString()
        }
        else if (arr.length > 1) {
            return arr.slice(0, -1).toString().replace(/,/g, ', ').concat(' and ').concat(arr[arr.length - 1])
        }

    }

    make_needs_sentence = () => {
        if (this.state.ownNeeds.length === 0) { return '' }
        else if (this.state.ownFeelings.length >= 1 && this.state.ownNeeds.length >= 1) {
            return 'I felt '.concat(this.arr2str_with_and(this.state.ownFeelings)).concat(' because I wanted ').concat(this.arr2str_with_and(this.state.ownNeeds)).concat('. ')
        }
        else if (this.state.ownNeeds.length >= 1) {
            return 'I need '.concat(this.state.ownNeeds.toString()).concat('. ')
        }
    }

    handle_end_punc = (str) => {
        if (!str) { return '' }
        else {
            str = str.trim()
            const lastchar = str.charAt(str.length - 1)
            if (lastchar.match(/^[.,:!?]/g) !== null) {

                str = str.concat(' ')
                console.log(lastchar.match(/^[.,:!?]/g), 'str', str)
            }
            else {
                str = str.concat('. ')
                console.log('final else entered', str)
            }
            return str
        }
    }

    addDescriptionToObservation = () => {
        if (!this.state.Observation && this.state.copyToObservation)
            this.setState({
                observation: this.state.summary,
                copyToObservation: false
            })

    }


    compose_message = () => {
        this.setState({ copyToFinalMessage: true }) // set off the  key for finalMessage so it will re-render

        // const feelings_needs_str = this.make_needs_sentence()

        // const finalMessage = this.handle_end_punc(this.state.observation).concat(feelings_needs_str).concat(this.handle_end_punc(this.state.aspiration)).concat(this.handle_end_punc(this.state.request))
        const combinedMessage = this.handle_end_punc(this.state.observation).concat(this.handle_end_punc(this.state.acknowledge)).concat(this.handle_end_punc(this.state.aspiration)).concat(this.handle_end_punc(this.state.request))
        // const newFinalMessage = this.state.finalMessage.concat(' ').concat(combinedMessage)
        const newFinalMessage = combinedMessage
        this.setState({
            finalMessage: newFinalMessage,
            //copyToFinalMessage: false,
            //showCombineButton: false,
        }, () => this.setState({ copyToFinalMessage: false }))

        console.log('inside compose_message:', this.state.finalMessage, newFinalMessage)
        if (this.state.showCombineModal)
            this.setState({ showCombineModal: false })

    }

    closeCombineModal = (e) => {
        e.stopPropagation()
        e.preventDefault()
        this.setState({ showCombineModal: false });

    }

    showCombineModal = (e) => {

        if (this.state.finalMessage) {
            this.setState({
                showCombineModal: true
            })
            console.log('delete clicked')
            e.preventDefault()
            e.stopPropagation()
        }
        else {
            this.compose_message()
        }

    }

    close_all_other_collapsibles = (currentCollapsible) => {
        console.log(currentCollapsible)

        const collapsibleList = [

            'collapsibleState_ownFeelings',
            'collapsibleState_ownNeeds',
            'collapsibleState_othersFeelings',
            'collapsibleState_othersNeeds',

        ]
        collapsibleList.forEach(el => {
            //console.log('inside forEach: ', el)
            if (el !== 'collapsibleState_' + currentCollapsible) {
                if (this.state[el] === true) {
                    this.setState({ [el]: false })

                }
            }

        })

        this.setState({ ['collapsibleState_' + currentCollapsible]: true, collapsibleStateCurrent: currentCollapsible })
        //need setTimeout otherwise the DOM is not fully rendered and current.offsetTop is not an accurate value as it's only partly rendered (not closed other collapsibles)
        setTimeout((currentCollapsible) => {


            if (this[currentCollapsible + 'Ref'].current) {

                //const dimensions = this[currentCollapsible + 'Ref'].current.getBoundingClientRect();
                //console.log(currentCollapsible, dimensions.top)
                window.scrollTo(0, this[currentCollapsible + 'Ref'].current.offsetTop);
                //window.scrollTo(0, dimensions.top);
            }
        }, 100, currentCollapsible)
    }



    //writes jsonified state into session storage. To be called after all calls to setState.
    writeToLocalStorage = () => {
        localStorage.setItem('ScenarioForm' + this.props.location.pathname, JSON.stringify(this.state))
    }

    //define prop callbacks ahead of time to prevent re-rendering of checkboxForm, which happens if props are defined inline
    //copyToObservation (which automatically sets observation to description) is set to false after user first types in their own observation
    tagsCallback = (tags) => this.setState({ tags }, this.writeToLocalStorage)
    groupsCallback = (groups) => this.setState({ groups }, this.writeToLocalStorage)
    pseudoFeelingsCallback = (pseudoFeelings) => this.setState({ pseudoFeelings }, this.writeToLocalStorage)
    ownFeelingsCallback = (ownFeelings) => this.setState({ ownFeelings }, this.writeToLocalStorage)
    ownNeedsCallback = (ownNeeds) => this.setState({ ownNeeds }, this.writeToLocalStorage)
    othersFeelingsCallback = (othersFeelings) => this.setState({ othersFeelings }, this.writeToLocalStorage)
    othersNeedsCallback = (othersNeeds) => this.setState({ othersNeeds }, this.writeToLocalStorage)
    observationCallback = (observation) => this.setState({ observation, copyToObservation: false }, this.writeToLocalStorage)
    aspirationCallback = (aspiration) => this.setState({ aspiration }, this.writeToLocalStorage)
    acknowledgeCallback = (acknowledge) => this.setState({ acknowledge }, this.writeToLocalStorage)
    strategiesCallback = (strategies) => this.setState({ strategies }, this.writeToLocalStorage)
    requestCallback = (request) => this.setState({ request }, this.writeToLocalStorage)
    finalMessageCallback = (finalMessage) => this.setState({ finalMessage }, this.writeToLocalStorage)



    signalOpen = (showState, id) => {
        if (showState)
            this.close_all_other_collapsibles(id)
        else
            this.setState({ ['collapsibleState_' + id]: false })
    }


    //function for showAllStepsToggle button that either expands all the detailed steps or collapses them.
    changeShowAllStepsState = () => {
        if (this.state.showAllSteps) {
            this.setState({ showAllSteps: false })
        }
        else {
            this.setState({ showAllSteps: true })
        }

    }

    // method called to handle toggle (next/prev)
    toggleNextButtons = (direction) => {
        console.log('inside toggleNextButton')


        if ((direction === 'next') && (this.state.key < 8)) {
            this.setState((prevState, props) => ({
                key: prevState.key + 1
            }));
        }
        else if ((direction === 'prev') && (this.state.key > 0)) {
            this.setState((prevState, props) => ({
                key: prevState.key - 1
            }));
        }


    }

    handleSelect = (key) => {
        this.setState({ key });
    }


    render() {
        const { auth, isPublic } = this.props;
        if (!auth.uid && !isPublic) return <Redirect to='/signin' />
        const returnURL = this.props.location.pathname.startsWith("/respondtoscenario") ? '/' : this.props.returnURL

        const judgmentsTooltip = 'A natural response is to interpret what we think is happening in a situation. You may perceive these as feelings. But it is actually what you are thinking and interpreting. It is good to acknowledge our judgments and interpreations as a first step to finding out the what issues are important for you underlying a situation.'
        const ownFeelingsTooltip = 'Get in touch with how you feel. Identifying your feelings first can help you think more clearly about the situation.'
        const ownNeedsTooltip = 'Get to the core of the situation. Identify your underlying values and aspirations.'
        const othersFeelingsTooltip = 'If you feel ready for this, it can help to consider how the other might be feeling. Remember these are only guesses.'
        const othersNeedsTooltip = 'It can help you understand the situation if you try to consider what the other\'s underlying needs might have been in the situation. Remember these are only guesses.'
        const observationTooltip = 'Describe the situation without blame or judgment. Describe actions and outcomes only in terms of what you can see and hear, e.g., what can be objectively observed on a video recording. We will help give feedback and let you know if we detect any judgmental language.'
        const acknowledgeTooltip = 'Best to mention this in general terms without directly mentioning them, e.g., "I realise it\'s important to have freedom of expression" NOT "I realise you want to express yourself freely"'


        const aspirationTooltip = 'It can be easy to say what\'s wrong and what we don\'t want. However, it is more powerful and effective to state what we do want. Write what you do want here. We will help give feedback and let you know if anything sounds like blame, judgment, or a demand.'
        const strategiesTooltip = 'Here we invite you to think of the many ways you can get your needs met. We might initially be focused on a specific thing we want but, in reality, there are always a variety of ways to get your needs met. Be creative.'
        const requestTooltip = 'Here is a place to make a request of the other, or make a suggestion to discuss something. We will help give feedback and let you know if anything sounds like blame, judgment, or a demand.'
        const finalMessageTooltip = 'Compose your final message here. Again we will help with feedback if anything sounds judgmental. We can also generate a suggested message based on your answers above. Just click the button \'Generate Auto-Message\''

        const pseudoFeelingsLabel = 'MY INTERPRETATIONS: '.concat(this.arr2str(this.state.pseudoFeelings))
        const ownFeelingsLabel = 'MY FEELINGS: '.concat(this.arr2str(this.state.ownFeelings))
        const ownNeedsLabel = 'MY VALUES: '.concat(this.arr2str(this.state.ownNeeds))
        const othersFeelingsLabel = 'OTHER\'S EMOTIONS?: '.concat(this.arr2str(this.state.othersFeelings))
        const othersNeedsLabel = 'OTHER\'S VALUES?: '.concat(this.arr2str(this.state.othersNeeds))
        const observationLabel = 'OBSERVATION: '.concat(this.state.observation)
        const acknowledgeLabel = 'ACKNOWLEDGMEMENT: '.concat(this.state.acknowledge)
        const aspirationLabel = 'ASPIRATION: '.concat(this.state.aspiration)
        const strategiesLabel = 'STRATEGIES: '.concat(this.state.strategies)
        const requestLabel = 'REQUEST: '.concat(this.state.request)
        const saveButtonText = 'Save'


        const showAllStepsColor = '#FF8C00'
        return (

            <Tab.Container id="left-tabs-example" activeKey={this.state.key} onSelect={this.handleSelect} >
                {!isPublic ? <>
                    < Button variant="link" style={{ color: "#0099CC" }} href={returnURL} > Back</Button>
                    <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0, offset: 0 }} className='pl-4 pr-4'  >
                        <div className="input-field text-right">
                            <Button onClick={this.handleSubmit} style={{ background: '#ff7000', color: { defaultTooltipColor }, border: 0 }}>{saveButtonText}</Button>
                        </div>
                    </Col>
                </> : null}
                <Row>
                    <Col className="mt-5 px-5">
                        <Container >
                            <Row>
                                <Col sm={{ offset: 3 }} style={{ backgroundColor: 'white', border: "1px solid black" }} className="py-3" >

                                    <h5>SCENARIO: {this.state.summary}</h5>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-5 mr-0" sm={3}>
                        <Nav variant="pills" className="flex-column left_navlinks">
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(0) }} eventKey={0} className={this.state.key === "0" ? 'active' : ''}>Instructions</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(1) }} eventKey={1}>Judgments</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(2) }} eventKey={2}>Feelings & Needs</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(3) }} eventKey={3}>Aspirations</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(4) }} eventKey={4}>Acknowledge</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(5) }} eventKey={5}>Situation & Impact</Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(6) }} eventKey={6}>Strategies</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(7) }} eventKey={7}>Request</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { this.handleSelect(8) }} eventKey={8}>Final Message</Nav.Link>
                            </Nav.Item>

                        </Nav>
                    </Col >

                    <Col sm={9} className="mt-5">
                        <Container>

                            <Row><Col className="mt-3 mr-5 pr-5 text-right mb-5">

                                <Button className="btn-carousel-progress" onClick={() => this.toggleNextButtons('prev')}><FaAngleLeft /></Button>
                                <Button className="btn-carousel-progress" onClick={() => this.toggleNextButtons('next')}><FaAngleRight /></Button>

                            </Col></Row>
                            <Row><Col className="px-5 mr-5">
                                <Tab.Content>
                                    <Tab.Pane eventKey={0}>

                                        <span style={{ color: 'black' }}>
                                            <h5>
                                                This guides you through analysing your thoughts and feelings about the situation, and guides you through composing different parts of a diplomatic message. Click on the <FaInfoCircle size="20px" style={{ color: '#0099CC' }} /> icons for more rationale for each step.
                                At the end, the "Combine Previous Steps into Final Message" button will copy all your writing from previous steps into a final message for you to edit and refine as you wish.
                        </h5>

                                        </span>


                                    </Tab.Pane>
                                    <Tab.Pane eventKey={1}>
                                        <TooltipCustom innertext={judgmentsTooltip} className="float-left" color={defaultTooltipColor} />
                                        < CheckboxForm
                                            callbackFunc={this.pseudoFeelingsCallback}
                                            checkboxFilename={'pseudofeelings'}
                                            title={'When conflict arises, it is natural to have interpretations and judgments of what\'s going on. Identifying your interpretations is a starting point to uncovering what is important underneath the situation.'}
                                            useHeaders={false}
                                            classNameValues={'h-blue'}
                                            initialCheckedFields={this.state.pseudoFeelings}
                                            id={'pseudoFeelings'}

                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={2}>
                                        <Col className='pl-4 pr-4' ref={this.ownFeelingsRef}>
                                            <TooltipCustom innertext={ownFeelingsTooltip} className="float-left" color={defaultTooltipColor} />
                                            <CollapsibleCheckboxForm

                                                callbackFunc={this.ownFeelingsCallback}
                                                label={ownFeelingsLabel}
                                                checkboxFilename={'feelings'}
                                                title={'Next, it is important to identify your core feeling, i.e., your indisputable inner experience that doesn\'t involve interpretations of others. Identifying your feelings will help you think more clearly about what is important for you in the situation. '}
                                                classNameValues={'h-blue'}
                                                initialCheckedFields={this.state.ownFeelings}
                                                showState={this.state.collapsibleState_ownFeelings}
                                                signalOpenCallback={this.signalOpen}
                                                id={'ownFeelings'}
                                            />
                                        </Col>
                                        <Col className='mb-5 pl-4 pr-4' ref={this.ownNeedsRef} >
                                            <TooltipCustom innertext={ownNeedsTooltip} className="float-left" color={defaultTooltipColor} />
                                            <CollapsibleCheckboxForm

                                                callbackFunc={this.ownNeedsCallback}
                                                label={ownNeedsLabel}
                                                checkboxFilename={'needs'}
                                                title={'Now it is time to identify what needs you had that were not met in the situation. This allows you to identify the core principles of what is important to you. '}
                                                classNameValues={'h-blue'}
                                                initialCheckedFields={this.state.ownNeeds}
                                                showState={this.state.collapsibleState_ownNeeds}
                                                signalOpenCallback={this.signalOpen}
                                                id={'ownNeeds'}
                                            />
                                        </Col>
                                        <Col className='pl-4 pr-4' ref={this.othersFeelingsRef} >
                                            <TooltipCustom innertext={othersFeelingsTooltip} className="float-left" color={defaultTooltipColor} />
                                            <CollapsibleCheckboxForm

                                                callbackFunc={this.othersFeelingsCallback}
                                                label={othersFeelingsLabel}
                                                checkboxFilename={'feelings'}
                                                title={'If you feel up for it, here you can try to guess how the other(s) may have felt in the situation. Remember these are just guesses, the only way to really know is to ask.'}
                                                classNameValues={'h-blue'}
                                                initialCheckedFields={this.state.othersFeelings}
                                                showState={this.state.collapsibleState_othersFeelings}
                                                signalOpenCallback={this.signalOpen}
                                                id={'othersFeelings'}
                                            />
                                        </Col>
                                        <Col className='mb-5  pl-4 pr-4' ref={this.othersNeedsRef} >
                                            <TooltipCustom innertext={othersNeedsTooltip} className="float-left" color={defaultTooltipColor} />
                                            <CollapsibleCheckboxForm

                                                callbackFunc={this.othersNeedsCallback}
                                                label={othersNeedsLabel}
                                                checkboxFilename={'needs'}
                                                title={'If you feel up for it, here you can consider what needs the other(s) may have had in the situation. '}
                                                classNameValues={'h-blue'}
                                                initialCheckedFields={this.state.othersNeeds}
                                                showState={this.state.collapsibleState_othersNeeds}
                                                signalOpenCallback={this.signalOpen}
                                                id={'othersNeeds'}
                                            />
                                        </Col>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={3}>

                                        <TooltipCustom innertext={aspirationTooltip} className="float-left" color={defaultTooltipColor} />
                                        <TextBox
                                            callbackFunc={this.aspirationCallback}
                                            allowUserFeedback={true}
                                            showFinaliseButton={false}
                                            prompt={'What would the ideal situation look like? Say what you aspire for.'}
                                            textAreaHeight={2}
                                            placeholder={'write what you would say here...'}
                                            initialTextValue={this.state.aspiration}
                                            id={'aspiration'}
                                        />


                                    </Tab.Pane>
                                    <Tab.Pane eventKey={4}>
                                        <TooltipCustom innertext={acknowledgeTooltip} className="float-left" color={defaultTooltipColor} />
                                        <TextBox
                                            callbackFunc={this.acknowledgeCallback}
                                            allowUserFeedback={true}
                                            showFinaliseButton={false}
                                            prompt={'It helps to acknowledge the other\'s possible concerns or needs.'}
                                            textAreaHeight={2}
                                            placeholder={'write what you would say here...'}
                                            initialTextValue={this.state.acknowledge}
                                            id={'acknowledge'}
                                            recordAttempt={true}
                                            attemptPath={'generalAttempt'}
                                        />

                                    </Tab.Pane >
                                    <Tab.Pane eventKey={5}>
                                        <TooltipCustom innertext={observationTooltip} className="float-left" color={defaultTooltipColor} />
                                        <TextBox
                                            callbackFunc={this.observationCallback}
                                            allowUserFeedback={true}
                                            showFinaliseButton={false}
                                            prompt={'Completely objective descriptions are the most powerful way to communicate. How would you describe the situation in a way that involves only observable facts with no interpretation?'}
                                            textAreaHeight={2}
                                            placeholder={'write what you would say here...'}
                                            initialTextValue={this.state.observation}
                                            id={'observation'}
                                        />


                                    </Tab.Pane>

                                    <Tab.Pane eventKey={6}>

                                        <TooltipCustom innertext={strategiesTooltip} className="float-left" color={defaultTooltipColor} />
                                        <TextBox
                                            callbackFunc={this.strategiesCallback}
                                            allowUserFeedback={true}
                                            showFinaliseButton={false}
                                            prompt={'What are some strategies that could lead towards your aspirations? '}
                                            textAreaHeight={2}
                                            placeholder={'write what you would say here...'}
                                            initialTextValue={this.state.strategies}
                                            id={'strategies'}
                                        />


                                    </Tab.Pane>
                                    <Tab.Pane eventKey={7}>
                                        <TooltipCustom innertext={requestTooltip} className="float-left" color={defaultTooltipColor} />
                                        <TextBox
                                            callbackFunc={this.requestCallback}
                                            allowUserFeedback={true}
                                            showFinaliseButton={false}
                                            prompt={'Any request you would like to make?'}
                                            textAreaHeight={2}
                                            placeholder={'write what you would say here...'}
                                            initialTextValue={this.state.request}
                                            id={'request'}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={8}>


                                        <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0, offset: 0 }} className='pl-4 pr-4'  >
                                            <div> {pseudoFeelingsLabel}</div>
                                            <div> {ownFeelingsLabel}</div>
                                            <div> {ownNeedsLabel}</div>
                                            <div> {othersFeelingsLabel}</div>
                                            <div> {othersNeedsLabel}</div>
                                            <div> {observationLabel}</div>
                                            <div> {acknowledgeLabel}</div>
                                            <div> {aspirationLabel}</div>
                                            <div> {strategiesLabel}</div>
                                            <div> {requestLabel}</div>
                                            <TooltipCustom innertext={finalMessageTooltip} className="float-left" color={defaultTooltipColor} />
                                            <div><h5>Compose your final message here.</h5><p></p></div>


                                            < div className="input-field">
                                                <TooltipCustom innertext={"Automatically combine all your text from above into a final message."} color={showAllStepsColor} className="float-left" />
                                                <Button style={{ background: showAllStepsColor, border: 0 }} onClick={this.showCombineModal} disabled={!this.state.showCombineButton}>Combine Previous Steps into Final Message</Button>
                                                <CheckDeleteModal show={this.state.showCombineModal} closeModal={this.closeCombineModal} handleYes={this.compose_message} text="Are you sure you want to overwrite what you have by combining your above sections?" />


                                            </div>

                                            <TextBox
                                                key={this.state.copyToFinalMessage}
                                                callbackFunc={this.finalMessageCallback}
                                                allowUserFeedback={true}
                                                showFinaliseButton={false}
                                                prompt={''}
                                                initialTextValue={this.state.finalMessage}
                                                defaultValue={this.state.finalMessage}
                                                textAreaHeight={5}
                                            />

                                        </Col>
                                    </Tab.Pane>

                                </Tab.Content>
                            </Col></Row >
                            {/** 
                            <Row><Col className="mt-3 mr-5 pr-5 text-right mt-5">

                                <Button className="btn-carousel-progress" onClick={() => this.toggleNextButtons('prev')}><FaAngleLeft /></Button>
                                <Button className="btn-carousel-progress" onClick={() => this.toggleNextButtons('next')}><FaAngleRight /></Button>

                            </Col></Row>
                            */}
                        </Container >
                    </Col >
                </Row >

                <Row><Col style={{ marginBottom: "10rem" }}></Col></Row>
            </Tab.Container >

        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.firebase.auth,
        isAdmin: state.auth.isAdmin, //whether current user is admin

    }
}


export default connect(mapStateToProps)(withRouter(ScenarioForm))

