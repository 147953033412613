import React from 'react'
import moment from 'moment'
import DeleteLogButton from './DeleteLogButton'
import EditLogButton from './EditLogButton'
import { ChoiceSummary } from '../forms/ChoiceSummary'
import { Card } from 'react-bootstrap'
import { colorFraction2RGB, getNameColorIdStatename } from '../forms/formFunctions'

//child component of LogList, inherits log prop from LogList
//headerToColorObj is object with properties of headerNames, (first row in 2d array read from CSV, such as 'Angery / Annoyance') and values of color in hexStr
//stateNameBase are strings for base of property name in log, e.g., 'NegF_'
const LogSummary = ({ log, headerToColorObj = {}, stateNameBaseList = [] }) => {

    /*creates tags with color equal to the most intense last in array for each headerNameClass
    */
    const createSummaryTags = (log, headerToColorObj, stateNameBase) => {
        return Object.keys(headerToColorObj).map(headerName => {
            const { stateName } = getNameColorIdStatename(headerName, stateNameBase)
            if (log.hasOwnProperty(stateName) && log[stateName].length > 0) {
                const fieldVal = log[stateName].slice(-1).pop()
                const colorFraction = fieldVal.indexOf('#') === -1 ? .5 : parseFloat(fieldVal.substr(fieldVal.indexOf('#') + 1))
                const hexStr = headerToColorObj[headerName]
                const color = colorFraction2RGB(colorFraction, hexStr)
                return <span disabled size='sm' key={headerName} style={{ background: color, color: "white" }}> {headerName} </span>
            }
            else return null
        })

    }

    /*creates summary tags for all log variables that start with all nameBases in stateNameBaseList
    *e.g., ['NegF_', 'PosF_']
 
    const createAllSummaryTags = (log, headerToColorObj, stateNameBaseList) => {

        return stateNameBaseList.map(stateNameBase =>
            createSummaryTags(log, headerToColorObj, stateNameBase)

        )
    }

    const summaryTags = createAllSummaryTags(log, headerToColorObj, stateNameBaseList)
       */

    return (
        <div className='mt-3'>
            <Card style={{ width: '80%' }} >
                <Card.Body>
                    <Card.Title as="div" style={{ color: "black " }}>{log.title}</Card.Title>
                    <Card.Text as="div" style={{ color: "black " }} >
                        <div className="className grey-text">{moment(log.createdAt.toDate()).calendar()}</div>
                        <p></p>

                        <ChoiceSummary className="mb-5" showNotes={false} useHeaders={true} stateNameBase='PosF_' checkboxFilename='feelingsPositive' valuesFromParent={log} notesLabel='NOTES: ' />
                        {/*<div><b>Strategies:</b> {log.strategies}</div><p></p>*/}


                    </Card.Text>
                    {/* need to call id separately because when Edit/DeleteLogButton is called from LogDetails.js, that log is from firestore 
                    and does not have id as a property in log, so log.id is null called over there, even though log.id has a value here */}
                    <Card.Link to="#" as="div" className="float-left"><EditLogButton log={log} id={log.id} /></Card.Link>
                    <Card.Link to="#" as="div" className="float-right"><DeleteLogButton log={log} id={log.id} /></Card.Link>
                </Card.Body>
            </Card >
        </div>
    )
}

export default LogSummary