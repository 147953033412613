import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn, processSuccessfulSignIn } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
import { Form, Col, Button, Container, Row } from 'react-bootstrap'
import firebase, { firebaseAuth } from '../../config/fbConfig'
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import 'firebase/functions'
import './SignUp.css'


class SignIn extends Component {
    state = {
        email: '',
        password: '',
    }

    uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            {/*
            firebase.auth.GithubAuthProvider.PROVIDER_ID,
            firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID
            */}
        ],
        callbacks: {
            signInSuccessWithAuthResult: (authResult) => {
                console.log('auth Result:', authResult)
                this.props.processSuccessfulSignIn(authResult, null, null)

            },

        }
    }


    handleChange = (e) => {
        if (this.props.authError) {
            this.props.resetAuthErrorSignIn()
        }
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state)
    }

    customErrorMessage = (authError) => {
        if (authError) {
            if (authError.code === "auth/user-not-found") {
                return <span className='text-right'> User not found in records. Try <a href='/signup'>signing up</a> instead </span>
            }
            if (authError.code === 'ALTERNATIVE AUTH USED') {

                return <h5>{authError.message}</h5>
            }
            if (authError.code === "auth/popup-closed-by-user") {
                return null
            }
            else {
                console.log(authError)
                return authError.message
            }
        }
    }

    render() {
        const { authError, auth } = this.props; //has access to authError from the state's store (set in authReducer) because of mapStateToProps below
        if (auth.uid && !this.props.inPage) return <Redirect to='/' />
        return (
            <div>
                <Container fluid>
                    <Container >
                        <Row>
                            <Col className="mt-lg-5" md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }}    >
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Row>
                                        <h3 >Sign in</h3>
                                    </Form.Row>
                                    <Form.Group controlId="email" as={Row}>
                                        <Form.Label column="lg" sm="3">
                                            Email
                            </Form.Label>
                                        <Col sm="9">
                                            <Form.Control size="lg" type="text" onChange={this.handleChange} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group controlId="password" as={Row} >
                                        <Form.Label column="lg" sm="3">
                                            Password
                            </Form.Label>
                                        <Col sm="9">
                                            <Form.Control size="lg" sm="9" type="password" onChange={this.handleChange} />
                                        </Col>
                                    </Form.Group>

                                    <div className="input-field text-right">
                                        <Button type="submit" className='btn-blue'> Sign in</Button>
                                    </div>
                                    <div>
                                        {authError ? <div className='text-danger'>{this.customErrorMessage(authError)}</div> : null}
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center" > <a style={{ color: "#0099CC" }} href='/forgotpassword'>Forgot Password?</a> </Col>
                        </Row>
                        <Row>
                            <Col className="text-center" >  <a href='/signup' style={{ color: '#ff7000' }}>Don't have an account? Sign up</a>  </Col>
                        </Row>

                        <Row >
                            <Col className="text-center">
                                <StyledFirebaseAuth
                                    uiConfig={this.uiConfig}
                                    firebaseAuth={firebaseAuth}
                                />
                            </Col>

                        </Row>
                        <Row>
                            <Col className="mt-5 mb-5">
                            </Col>
                        </Row>
                    </Container>
                </Container >

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds)),
        processSuccessfulSignIn: (creds) => dispatch(processSuccessfulSignIn(creds)),
        resetAuthErrorSignIn: () => {
            console.log('hello anne')
            dispatch({ type: 'LOGIN_ERROR', error: null })
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)