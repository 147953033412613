import React from 'react';
import './Collapsible.css'
import { Container } from 'react-bootstrap'



/**
 * HigherOrderCollapsible higher order component that wraps any content inside
 * @param {*} WrappedComponent 
 * @param {string} props.label the label shown on the collapsible field
 * @param {string} props.title the title shown in the title field of the expanded text
 * @param {boolean} props.showState whether modal defaults to being open on declaration.
 * @param {string} props.classNameValues str of additional classNameValues to allow us to customise individual collapsibles
 */
const HigherOrderCollapsible = (WrappedComponent) => {
    return class extends React.PureComponent {
        constructor(props) {
            super(props)
            this.state = {
                show: props.showState, //default has to be false because unless propsshowState is true, the default will always be chosen anyway

            }

        }
        static defaultProps = {

            title: '',
            bodyText: null,
            showState: false,
            classNameValues: '',
            id: null,
            color: null,
            signalOpenCallback: () => { },
            showLabel: true

        }
        componentDidUpdate(prevProps) {
            // Make sure your showState prop is different

            if (prevProps.showState !== this.props.showState) {
                // Update state with new showState value
                this.setState({
                    show: this.props.showState
                });
            }
        }

        togglePanel = (e) => {
            if (this.state.show === false) {
                this.setState({ show: true })
                this.props.signalOpenCallback(true, this.props.id)
            }
            else {
                this.setState({ show: false })
                this.props.signalOpenCallback(false, this.props.id)
            }
        }


        //need to use style display block vs none and not return component: null because we want to retain the state values in wrapped component.
        render() {
            const headerType = this.props.showLabel ? 'header ' : 'link '
            return (
                <Container>
                    <div onClick={(e) => this.togglePanel(e)} className={headerType + this.props.classNameValues} style={{ background: ((this.props.showLabel && this.props.color) ? this.props.color : null) }} >

                        {this.props.label}
                    </div>
                    <div style={{ display: (this.state.show ? 'block' : 'none') }}>
                        {this.props.children}
                        <WrappedComponent {...this.props} />
                    </div>
                </Container>

            );
        }
    }
}


export default HigherOrderCollapsible