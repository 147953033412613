//an action creator, originally this means it is a function that returns an action object
//now to use with thunk, this function does not return an action. Instead it
// returns a function that makes asynchronous call (pausing the dispatch) and then carry with dispatch afterwards
//scenario is just a variable/argument below. We will be passing a scenario object into createScenario

export const saveActivity = (activity, activityType) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //make async call to database (writing 'scenario' is  shorthand for 'scenario: scenario' )
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid
        const docLocation = firestore.collection('users').doc(authorId).collection(activityType)

        docLocation.add({ //if scenarios does not exist, it will create a new collection
            ...activity, //this is like putting title: scenario.title and content: scenario.summary
            authorId: authorId,
            createdAt: new Date(),
            //new fields get automatically added. I just needed to refresh browser for change to take place

        }).then(() => {
            dispatch({ type: 'SAVE_ACTIVITY', activity, activityType })
            var userprofiledoc = firestore.collection('users').doc(authorId);
            return userprofiledoc.update({
                [activityType]: firestore.FieldValue.arrayUnion(activity.finalScore)
            })
                .then(function () {
                    console.log("Quiz results saved to profile for activityType", activityType);
                })
                .catch(function (error) {
                    // The document probably doesn't exist.
                    console.error("Error saving results of quiz to profile: ", error);
                });


        }).catch((error) => {
            dispatch({ type: 'SAVE_ACTIVITY_ERROR', error })
        })
    }

}


export const deleteActivity = (id, activity, activityType, auth) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        if (activity.authorId === auth.uid) {
            //make async call to database (writing 'scenario' is  shorthand for 'scenario: scenario' )
            const firestore = getFirestore();
            const docLocation = firestore.collection('users').doc(auth.uid).collection(activityType)
            docLocation.doc(id).delete(
            ).then(() => {
                dispatch({ type: 'DELETE_ACTIVITY', activityType })

            }).catch((error) => {
                dispatch({ type: 'DELETE_ACTIVITY_ERROR', error })
            })
        }
        else {
            console.log('you are not the right user')
            return null
        }

    }
}
