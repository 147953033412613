//an action creator, originally this means it is a function that returns an action object
//now to use with thunk, this function does not return an action. Instead it
// returns a function that makes asynchronous call (pausing the dispatch) and then carry with dispatch afterwards
//challenge is just a variable/argument below. We will be passing a challenge object into createChallenge

export const createChallenge = (challenge) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //make async call to database (writing 'challenge' is  shorthand for 'challenge: challenge' )
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid
        const docLocation = firestore.collection('publicChallenges')
        console.log('inside create challenge', challenge)
        docLocation.add({ //if challenges does not exist, it will create a new collection
            ...challenge, //this is like putting title: challenge.title and content: challenge.summary
            authorId: authorId,
            createdAt: new Date(),
            //new fields get automatically added. I just needed to refresh browser for change to take place

        }).then(() => {
            dispatch({ type: 'CREATE_CHALLENGE', challenge })

        }).catch((error) => {
            dispatch({ type: 'CREATE_CHALLENGE_ERROR', error })
        })
    }

}

export const deleteChallenge = (id, challenge, auth, isAdmin) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        if ((challenge.authorId === auth.uid) || (isAdmin && challenge.isPublic)) {
            //make async call to database (writing 'challenge' is  shorthand for 'challenge: challenge' )
            const firestore = getFirestore();
            const docLocation = firestore.collection('publicChallenges')
            docLocation.doc(id).delete(
            ).then(() => {
                dispatch({ type: 'DELETE_CHALLENGE', challenge })

            }).catch((error) => {
                dispatch({ type: 'DELETE_CHALLENGE_ERROR', error })
            })
        }
        else {
            console.log('you are not the right user')
            return null
        }

    }
}

export const editChallenge = (id, challenge, auth, isAdmin) => {
    //double checking that author is the logged in user. But if I go directly to edit/id title is undefined
    //because no challenge will have been passed in through so it's impossible for the EditChallenge.js page to load with the 
    //button to call teh editChallenge function anyway. So I don't need the check. XXX
    console.log('is admin inside challengeActions ', isAdmin)
    if ((challenge.authorId === auth.uid) || (isAdmin && challenge.isPublic)) {

        return (dispatch, getState, { getFirebase, getFirestore }) => {
            //make async call to database (writing 'challenge' is  shorthand for 'challenge: challenge' )
            const firestore = getFirestore();
            const docLocation = firestore.collection('publicChallenges')

            docLocation.doc(id).update({
                ...challenge,
            }).then(() => {
                dispatch({ type: 'EDIT_CHALLENGE', challenge })
            }).catch((error) => {
                dispatch({ type: 'EDIT_CHALLENGE_ERROR', error })
            })
        }
    }
    else {
        console.log('you are not the right user')
        return null
    }


}



export const createChallengeResponse = (challenge) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //make async call to database (writing 'challenge' is  shorthand for 'challenge: challenge' )
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid

        const docLocation = firestore.collection('users').doc(authorId).collection('challenges')
        docLocation.add({ //if challenges does not exist, it will create a new collection
            ...challenge, //this is like putting title: challenge.title and content: challenge.summary
            authorId: authorId,
            createdAt: new Date(),
            //new fields get automatically added. I just needed to refresh browser for change to take place

        }).then(() => {
            dispatch({ type: 'CREATE_CHALLENGE_RESPONSE', challenge })
            if (challenge.parentDoc) {
                var userprofiledoc = firestore.collection('users').doc(authorId);
                return userprofiledoc.update({
                    challenges: firestore.FieldValue.arrayUnion(challenge.parentDoc)
                })

                    .then(function () {
                        console.log("List of challengeParentDocs successfully updated!");
                    })
                    .catch(function (error) {
                        // The document probably doesn't exist.
                        console.error("Error updating list of challengeParentDocs: ", error);
                    });

            }


        }).catch((error) => {
            dispatch({ type: 'CREATE_CHALLENGE_RESPONSE_ERROR', error })
        })
    }

}

export const deleteChallengeResponse = (id, challenge, auth, isAdmin) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        if ((challenge.authorId === auth.uid) || (isAdmin && challenge.isPublic)) {
            //make async call to database (writing 'challenge' is  shorthand for 'challenge: challenge' )
            const firestore = getFirestore();
            const docLocation = firestore.collection('users').doc(auth.uid).collection('challenges')
            docLocation.doc(id).delete(
            ).then(() => {
                dispatch({ type: 'DELETE_CHALLENGE_RESPONSE', challenge })

                if (challenge.parentDoc) {
                    var userprofiledoc = firestore.collection('users').doc(challenge.authorId);
                    return userprofiledoc.update({
                        challenges: firestore.FieldValue.arrayRemove(challenge.parentDoc)
                    })

                        .then(function () {
                            console.log("List of challengeParentDocs successfully updated!");
                        })
                        .catch(function (error) {
                            // The document probably doesn't exist.
                            console.error("Error updating list of challengeParentDocs: ", error);
                        });

                }


            }).catch((error) => {
                dispatch({ type: 'DELETE_CHALLENGE_ERROR_RESPONSE', error })
            })
        }
        else {
            console.log('you are not the right user')
            return null
        }

    }
}