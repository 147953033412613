import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SubscribeFormFooter from './SubscribeFormFooter'
import logo from '../../files/EHE_logoHeartOnly.svg'


function Footer() {
    return (

        // <footer id="sticky-footer" className="fixed-bottom py-4 bg-light text-black-50">
        <footer className="publicfooter " >


            < Container fluid className="bg-light " >

                <Row className="ml-5">



                    <Col className=" mt-4 ml-0" lg={{ span: 4, offset: 0 }} sm={{ span: 12, offset: 0 }} >
                        <Row>
                            <Col className="mr-lg-3 " lg={{ span: 2, offset: 0 }} sm={{ span: 12, offset: 0 }} >
                                <img src={logo} width="80" className="d-inline-block pz-0" alt="logo" />
                            </Col>
                            <Col className="ml-lg-3" >
                                <div ><a href="https://www.emotionalhealthessentials.com/contact" style={{ color: "black", fontSize: '16px', lineHeight: '1.8' }}>Contact Us</a></div>
                                <div ><a href={"mailto:hello@emotionalhealthessentials.com"} style={{ color: "black", fontSize: '16px', lineHeight: '1.8' }}>hello@emotionalhealthessentials.com</a></div>
                                <div style={{ color: "black", fontSize: '16px', lineHeight: '1.8' }}>Mile End Rd</div>
                                <div style={{ color: "black", fontSize: '16px', lineHeight: '1.8' }}>London, E14NS, UK</div>
                            </Col>
                        </Row>

                    </Col>
                    {/** 
                    <Col className="mt-lg-0 pt-0 mr-lg-0 pr-lg-0" lg={{ span: 5, offset: 3 }} sm={{ span: 12, offset: 0 }} >
                        <SubscribeFormFooter />

                    </Col>
*/}
                    <Col className="mt-4 mr-lg-0 pr-lg-0" lg={{ span: 5, offset: 3 }} sm={{ span: 12, offset: 0 }} >
                        {/** <SubscribeFormFooter />*/}
                        <iframe
                            src="https://dashboard.mailerlite.com/forms/342206/81083343444117237/share"
                            width="350rem"
                            height="250rem"
                        ></iframe>

                    </Col>
                </Row>
                <Row >
                    <Col className="mx-5 mt-3 text-center">

                        <span className="mx-2"><a style={{ display: "table-cell", color: "black", fontSize: '16px', lineHeight: '1.8' }} href="https://www.emotionalhealthessentials.com/termsofuse" >Terms of Use</a></span>
                        <span className="mx-2"><a style={{ display: "table-cell", color: "black", fontSize: '16px', lineHeight: '1.8' }} href="https://www.emotionalhealthessentials.com/privacypolicy" >Privacy Policy</a></span>




                    </Col>

                </Row >
                <Row >
                    <Col className="text-center mb-3">
                        <small>Copyright &copy;
                            Emotional Health Essentials 2023
                        </small>
                    </Col>
                </Row>

            </Container >

        </footer >

    )
}

export default Footer
