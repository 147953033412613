import React, { useState } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Form, Col, Button, Container, Row } from 'react-bootstrap'
import { db } from "../../config/fbConfig"


/**
 * AddGroup functional component
 * Allows admin to creates a new group on system if it doesn't exist, create new group profile under groups with name == groupName.
 * group documents viewable to group members will be stored under groupName.
 * adds a new field to GroupAccess document under 'central' with map values of {}
 * 
 *  */




function AddGroup({ auth, isAdmin, profile }) {



    const [groupName, setGroupName] = useState('');
    const [accessCode, setAccessCode] = useState('');

    const [maxNum, setMaxNum] = useState(50);



    const handleSubmit = (e) => {
        e.preventDefault()
        if (groupName) {
            var groupAccessUpdate = {};
            groupAccessUpdate[accessCode] = { name: groupName, count: 0, maxNum: maxNum };

            db.collection("administration").doc("groupAccess").set(groupAccessUpdate, { merge: true })
                .then(() => console.log('new group created with field:', groupAccessUpdate))
        }
    }



    if (isAdmin)
        return (

            <Container>
                <Button variant="link" style={{ color: "#0099CC" }} href='/'>Back</Button>
                <Row>



                    <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0, offset: 0 }}    >

                        <Form onSubmit={handleSubmit}>



                            <div>
                                <h4>{groupName}</h4>
                                <br></br>
                                <h5>{accessCode}</h5>

                            </div>

                            <Form.Group controlId="groupName" size="lg" >
                                <Form.Label column="lg">
                                    Group Name
                                </Form.Label>
                                <Col >
                                    <Form.Control size="lg" type="text" onChange={e => setGroupName(e.target.value)} value={groupName} />
                                </Col>
                            </Form.Group>
                            <Form.Group controlId="accessCode"  >
                                <Form.Label column="lg">
                                    Access Code
                            </Form.Label>
                                <Col>
                                    <Form.Control size="lg" as="textarea" onChange={e => setAccessCode(e.target.value)} placeholder={'groupAccessCode'} value={accessCode} />
                                </Col>
                            </Form.Group>



                        </Form>
                    </Col>







                    <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0, offset: 0 }} className='pl-4 pr-4'  >
                        <div className="input-field text-right">
                            <Button onClick={handleSubmit} >Save</Button>
                        </div>
                    </Col>

                </Row>
                <Row><Col style={{ marginBottom: "10rem" }}></Col></Row>
            </Container >

        )
}



const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        isAdmin: state.auth.isAdmin, //whether current user is admin
        profile: state.firebase.profile,
    }
}


export default connect(mapStateToProps)(withRouter(AddGroup))

