import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { Container, Col } from 'react-bootstrap'
import ScenarioDetailCard from './ScenarioDetailCard'
const myScenarios = 'myPrivateScenarios'

const MyScenarioDetails = (props) => {
    const { auth, scenario, id } = props


    if (!auth.uid) return <Redirect to='/signin' />
    if (scenario) {
        console.log(props)
        console.log(props.history.goBack)

        return (
            <Container className='mt-5'>
                <Col md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }}    >
                    <ScenarioDetailCard scenario={scenario} id={id} returnURL='/myscenarios' />
                </Col>
            </Container >

        )
    }
    else {
        return (
            <div className="container center">
                <p>Loading scenario...</p>
            </div>
        )

    }

}



//react router dom passes following value match.params.id to props of ScenarioDetails because of this line in App.js:
//     <Route path='/scenario/:id' component={ScenarioDetails} />
//Thus redux can access this through the optional built-in 'ownProps' argument in mapStateToProps

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const scenarios = state.firestore.data[myScenarios]
    const scenario = scenarios ? scenarios[id] : null
    return {
        id: id,
        scenario: scenario,
        auth: state.firebase.auth
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => {
        return [
            {
                collection: 'users',
                doc: props.auth.uid,
                subcollections: [{ collection: 'scenarios', orderBy: ['createdAt', 'desc'] }],
                storeAs: myScenarios
            }


        ]
    })
)(MyScenarioDetails)

