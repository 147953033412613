import React from 'react'
import { connect } from 'react-redux'
import CreateScenarioActivity from './CreateScenarioActivity'
import { createScenario } from '../../store/actions/scenarioActions'

function CreatePublicScenario(props) {
    if (props.isAdmin)
        return (
            <div>
                <CreateScenarioActivity callbackFunc={props.createScenario} formTitle='New Scenario Activity' isPublic={true} />
            </div>
        )
    else return null
}

//NOTE: createScenario is now a variable in props and its value is a function that dispatches
//the action-creator 'createScenario' function defined in scenarioActions.js, w
//createScenario is an action-creator which instead of returning an action object (as done when we did not need asynchronous calls)
//now returns a function that makes an asynch call and then calling dispatch. 
//'scenario' below is just a variable in the arrow function

const mapStateToProps = (state) => {
    return {

        isAdmin: state.auth.isAdmin, //whether current user is admin

    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        createScenario: (scenario) => dispatch(createScenario(scenario)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CreatePublicScenario)

