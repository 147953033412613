import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

class CreateArticle extends Component {
    constructor(props) {
        super(props);

        //need to define conditional || in case props.article does not have the attribute. This won't be caught by defaultProps as article does exist
        //and the attribute are 2nd order properties.
        //collpasibleState_X are to control collapsibles so only one is open at a time. controlled via function: close_all_other_collapsibles
        this.state = {
            tags: props.article.tags || [],
            groups: props.article.groups || [],
            title: props.article.title || '',
            mainText: props.article.mainText || '',
            imagePath: props.article.imagePath || '',
            quote: props.article.quote || '',
            videoLink: props.article.videoLink || '',
            id: props.article.id || '',

        }
    }
    static defaultProps = {
        formTitle: '',
        article: {
            tags: [],
            groups: [],
            title: '',
            mainText: '',
            imagePath: '',
            quote: '',
            videoLink: '',
            id: '',
        },
        callbackFunc: () => { }

    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        // console.log(this.state);
        this.props.callbackFunc(this.state);
        this.props.history.push('/');
    }
    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to='/signin' />
        return (
            <div className="container">
                <form className="white" onSubmit={this.handleSubmit}>
                    <h5 className="grey-text text-darken-3">Create a New Article</h5>
                    <div className="input-field">
                        <input type="text" id='title' onChange={this.handleChange} />
                        <label htmlFor="title">Article Title</label>
                    </div>
                    <div className="input-field">
                        <textarea id="content" className="materialize-textarea" onChange={this.handleChange}></textarea>
                        <label htmlFor="content">Article Content</label>
                    </div>
                    <div className="input-field">
                        <button className="btn pink lighten-1">Create</button>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}



export default connect(mapStateToProps)(CreateArticle)