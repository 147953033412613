import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Form, Row, Col, Button, Container, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import { withRouter } from 'react-router-dom';
import CheckboxForm from '../forms/CheckboxForm'

import "../../index.css"




class CreatePublicChallengeForm extends Component {

    constructor(props) {
        super(props);

        //each sentence object has sentence str, prompt_str (optional) and type (str)

        this.state = {
            tags: props.challenge.tags || [],
            groups: props.challenge.groups || ['GENERAL'],
            direction: props.challenge.direction || 'a',
            title: props.challenge.title || '',
            summary: props.challenge.summary || '',
            challengeObjectList: props.challenge.challengeObjectList || [],



        }
    }

    static defaultProps = {
        challenge: {}
    }
    /**
* @function
* @param Adds object template to challengeObjectList, which in turn will cause createSentences to render a notes component
*/
    handleAddChallengeObject = () => {
        //t is the "Type", e.g., Aspiration (A), Acknowledgment (Ak), Observation (O) etc.
        //p is the prompt, e.g., "How would you say this neutrally?"
        //sb is the original sentence that needs to be rephrased.
        this.setState({ challengeObjectList: [...this.state.challengeObjectList, { t: '', p: '', s: '' }] })
    };

    /**
    * @function
    * @param updates values of note object (with position 'index') in noteList
    */
    updateChallengeObjectList = (index, property, val) => {
        let challengeObjectList = this.state.challengeObjectList
        challengeObjectList[index][property] = val;
        this.setState({ challengeObjectList })
    }


    /**
    * @function
    * @param remove note object from noteList
    */
    deleteChallengeObject = index => {
        //console.log('remove from index', index)
        //const challengeObjectList = [...this.state.challengeObjectList];
        let challengeObjectList = this.state.challengeObjectList
        challengeObjectList.splice(index, 1);
        this.setState({ challengeObjectList: challengeObjectList })
    }

    handleChangeDirection = (val) => this.setState({ direction: val }, this.writeToSessionStorage);
    handleChangeType = (val) => this.setState({ type: val }, this.writeToSessionStorage);
    /**
    * @function
    * @param creates a form to extract object with sentence, prompt, type properties and appends this to this.state.challengeObjectList
    */
    createSentenceForm = (index) => {

        return <>
            <Form.Group controlId={index} >
                <Col>
                    <Form.Control size="lg" as="textarea" rows="1" onChange={(e) => this.updateChallengeObjectList(index, 'p', e.target.value)} placeholder={'prompt'} value={this.state.challengeObjectList[index].p} />
                </Col>
            </Form.Group>
            <Form.Group controlId={index}  >
                <Col>
                    <Form.Control size="lg" as="textarea" rows="1" onChange={(e) => this.updateChallengeObjectList(index, 's', e.target.value)} placeholder={'sentence to translate'} value={this.state.challengeObjectList[index].s} />
                </Col>
            </Form.Group>
            <Form.Group controlId={index}>
                <ToggleButtonGroup type="radio" onChange={(val) => this.updateChallengeObjectList(index, 't', val)} name="options" defaultValue={this.state.challengeObjectList[index].t} className="mx-3">
                    <ToggleButton value={'A'}>Aspiration</ToggleButton>
                    <ToggleButton value={'Ak'}>Acknowledgment</ToggleButton>
                    <ToggleButton value={'O'}>Observation</ToggleButton>
                    <ToggleButton value={'S'}>Strategy</ToggleButton>
                    <ToggleButton value={'R'}>Request</ToggleButton>
                    <ToggleButton value={'M'}>Final Message</ToggleButton>
                    <ToggleButton value={'G'}>General</ToggleButton>
                </ToggleButtonGroup>
            </Form.Group>


        </>
    }


    /**
    * @function
    * @param Renders a sentence object collection item for each object (even if empty) in challengeObjectList
    */
    createAllSentenceForms = (challengeObjectList) => {


        if (challengeObjectList.length === 0)
            return <>

                <Button onClick={this.handleAddChallengeObject}>Add Sentence</Button>
            </>


        return (
            <div>
                {challengeObjectList && challengeObjectList.map((x, i) => {
                    const sentenceForm = this.createSentenceForm(i)
                    return (
                        <div className='mt-1' key={'sentence' + i}>

                            <Col>
                                {sentenceForm}
                            </Col>
                            <Col className='mt-1'>
                                {challengeObjectList.length !== 0 &&
                                    <Button onClick={() => this.deleteChallengeObject(i)} >Remove</Button>}

                            </Col>

                            {challengeObjectList.length - 1 === i &&
                                <div className='mt-5'>

                                    <Button onClick={this.handleAddChallengeObject} >Add Sentence</Button>
                                </div>
                            }

                        </div>
                    )
                })}
            </div>

        )
    }



    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })

    }

    handleSubmit = (e) => {
        e.preventDefault()
        //callbackFunc is action creator defined in challengeActions.js
        console.log(this.props)
        let challenge_to_send = Object.assign({}, this.state);
        //remove collapsible, copyTo and shos state keys from what is sent via callbackFun 
        // this is because createScenario and editScenario write all properties of challenge into the database record, using destructuring, i.e., ...challenge

        if ('authorId' in this.props.challenge) {
            challenge_to_send['authorId'] = this.props.challenge.authorId
        }

        console.log('challenge_to_send:', challenge_to_send)
        this.props.callbackFunc(challenge_to_send)
        const returnURL = this.props.returnURL || '/challenges'
        if (this.props.history) this.props.history.push(returnURL) //return to the home route

    }

    writeToSessionStorage = () => {
        sessionStorage.setItem('CreateScenarioActivity' + this.props.location.pathname, JSON.stringify(this.state))
    }

    //define prop callbacks ahead of time to prevent re-rendering of checkboxForm, which happens if props are defined inline
    //copyToObservation (which automatically sets observation to description) is set to false after user first types in their own observation
    tagsCallback = (tags) => this.setState({ tags }, this.writeToSessionStorage)
    groupsCallback = (groups) => this.setState({ groups }, this.writeToSessionStorage)

    render() {
        const { auth } = this.props;
        const returnURL = this.props.returnURL || '/createchallenge'
        if (!auth.uid) return <Redirect to='/signin' />
        if (!this.props.isAdmin) { return null }
        const sentenceForms = this.createAllSentenceForms(this.state.challengeObjectList)

        return (

            <Container>
                <Row>
                    <Col md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }}    >
                        Hello Anne
                        <Form onSubmit={this.handleSubmit}>
                            <div className="input-field text-right" ><Button variant="link" style={{ color: "#0099CC" }} href={returnURL}>Back</Button></div>
                            <Form.Group>
                                <h3 >{this.props.formTitle}</h3>
                            </Form.Group>

                            <div className="input-field text-right">
                                <Button type="submit" style={{ background: '#ff7000', border: 0 }}>create</Button>
                            </div>


                            <Form.Group controlId="title" size="lg" >
                                <Form.Label column="lg">
                                    Title
                                </Form.Label>
                                <Col >
                                    <Form.Control size="lg" type="text" onChange={this.handleChange} value={this.state.title} />
                                </Col>
                            </Form.Group>
                            <Form.Group controlId="summary"  >
                                <Form.Label column="lg">
                                    What's the situation?
                            </Form.Label>
                                <Col>
                                    <Form.Control size="lg" as="textarea" rows="3" onChange={this.handleChange} placeholder={'Brief summary here (for your reference)'} value={this.state.summary} />
                                </Col>
                            </Form.Group>
                            {sentenceForms}
                            <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0 }} className='mb-5 pl-4 pr-4 '   >

                                < CheckboxForm
                                    callbackFunc={this.tagsCallback}
                                    label={'Tags'}
                                    checkboxFilename={'tags'}
                                    title={'Check all related themes'}
                                    useHeaders={false}
                                    classNameValues={'h-blue'}
                                    initialCheckedFields={this.state.tags}
                                    id={'tags'}
                                />
                            </Col>
                            <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0 }} className='mb-5 pl-4 pr-4 '   >
                                < CheckboxForm
                                    callbackFunc={this.groupsCallback}
                                    label={'Groups'}
                                    checkboxFilename={'groups'}
                                    title={'Groups (that scenario is for)'}
                                    useHeaders={false}
                                    classNameValues={'h-blue'}
                                    initialCheckedFields={this.state.groups}
                                    id={'groups'}

                                />
                            </Col>
                            <Col>

                                <ToggleButtonGroup type="radio" onChange={(val) => this.setState({ direction: val }, this.writeToSessionStorage)} name="options" defaultValue={this.state.direction} className="mx-3">
                                    <ToggleButton value={'u'}>downwards</ToggleButton>
                                    <ToggleButton value={'d'}>upwards</ToggleButton>
                                    <ToggleButton value={'a'}>across</ToggleButton>

                                </ToggleButtonGroup>

                            </Col>
                            <div className="input-field text-right">
                                <Button type="submit" style={{ background: '#ff7000', border: 0 }}>create</Button>
                            </div>


                        </Form>
                    </Col>
                </Row>
                <Row><Col className="my-5">
                </Col></Row>
            </Container >
        )

    }
}

//NOTE: createPublicChallenge is now a variable in props and its value is a function that dispatches
//the action-creator 'createPublicChallenge' function defined in challengeActions.js, w
//createPublicChallenge is an action-creator which instead of returning an action object (as done when we did not need asynchronous calls)
//now returns a function that makes an asynch call and then calling dispatch. 
//'challenge' below is just a variable in the arrow function


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        isAdmin: state.auth.isAdmin, //whether current user is admin
        profile: state.firebase.profile,
    }
}


export default connect(mapStateToProps)(withRouter(CreatePublicChallengeForm))

