import React, { Component } from 'react'
import { Container, Form, Col, Button, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import firebase, { db } from '../../config/fbConfig'
import { setUserGroup } from '../../store/actions/authActions'
import '../../index.css'

/**
 * EnterValidGroup class. 
 * Requires user to enter a valid group before seeing main content. Because we cannot prevent google-authenticated users from signing up without a valid group.
 * 
 */



class EnterValidGroup extends Component {

    /**
    * @constructor
    * @param {*} props.profile Obtained from redux mapstatetoprops to obtain any current username in profile (may be '')
    */
    constructor(props) {
        super(props);


        /**
         * State Object for UpdateUnsername Class
         * @property {string} username Textbox value of username. Updated through handleChange as it detects user typed input
         * and this value gets merged to Firestore profile upon update.
          *@property { string } groupCodeMessage message that gets displayed when username is not successfully updated
          */
        this.state = {
            groupCode: '',
            groupCodeMessage: "",
            group: props.group

        }
    }







    handleChange = (e) => {
        this.setState({ groupCodeMessage: null, usernameMessage: null, textboxUntouched: false })
        this.setState({
            [e.target.id]: e.target.value
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();
        const validateGroup = firebase.functions().httpsCallable('validateGroup');
        return validateGroup(this.state.groupCode).then((result) => {
            console.log(result)
            if (result.data) {

                console.log('Called validateGroup and returned', result.data)
                console.log('userCredential', this.props.auth.email, this.state.groupCode)
                if (result.data) {
                    setUserGroup(this.props.auth.email, this.state.groupCode)
                }

                console.log('valid group was entered. Now trying to add this to user profile.')
                db.collection('users').doc(this.props.auth.uid).update({
                    group: this.state.groupCode
                }).then(() => {
                    console.log('group successfully added to user', this.state.groupCode)
                }).catch((err) => console.log('error adding group to user profile', err))

            }
            else {
                this.setState({ groupCodeMessage: "Invalid group code" })
            }

        }).catch((err) => {
            this.setState({ groupCodeMessage: "could not access group validation module" })
            console.log('could not access validateGroup', err)
        })

    }
    render() {
        return (

            this.state.group ?

                null :
                <Container>
                    <h3>Please enter valid joining code to access the site</h3>
                    <Row>
                        <Col lg={{ span: 9, order: 0, offset: 0 }} xs={{ span: 12, order: 0, offset: 0 }}    >
                            <Form onSubmit={this.handleSubmit}>

                                <Form.Group controlId="groupCode" as={Row} >
                                    <Form.Label column="lg" sm="3">
                                        Joining Code
                                </Form.Label>
                                    <Col sm="3">
                                        <Form.Control required size="lg" sm="9" type="text" onChange={this.handleChange} />
                                    </Col>
                                </Form.Group>
                                <p className='text-danger'>{((this.state.groupCodeMessage) && (!this.state.groupCodeValidated)) ? this.state.groupCodeMessage : null}</p>
                                <div className="input-field">
                                    <Button type="submit" > Join With Group Code</Button>
                                    {this.state.groupError ? <p className='text-danger'>This is not a valid group code</p> : null}

                                </div>

                            </Form>
                        </Col>
                    </Row>
                </Container>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        group: state.firebase.profile.group,
        auth: state.firebase.auth,

    }
}

export default connect(mapStateToProps)(EnterValidGroup)
