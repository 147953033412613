import React, { Component } from 'react';
import TextBox from "../TextBox";
import { Container, Col, Row } from 'react-bootstrap';
//import SignIn from '../../components/auth/SignIn'
import CheckboxForm from '../../components/forms/CheckboxForm'
import HigherOrderModal from '../../Utilities/HigherOrderModal'
import Papa from 'papaparse';


/**
 * Iframe Class
 * Calls TextBox component passed with props userFeedback (boolean) and urlParameters (retrieved from url)
 * 
 */

const ModalCheckboxForm = HigherOrderModal(CheckboxForm)

class IframeFunctions extends Component {
   /**
    * @constructor
    * @param {*} props To pass any property from where it is called
    */
   constructor(props) {
      super(props);
      var newUrl = new URL(window.location.href);
      var urlParameters = Object.fromEntries(newUrl.searchParams);
      console.log('url parameters', urlParameters)


      if (!urlParameters.prompt) { //no prompt in urlParaemters means get question from file/questions.csv
         console.log('getting CSV question')
         //const questionFilename = urlParameters.questionFileName || '../../files/Questions.csv'
         //this.getCSVQuestion(questionFilename); 
         this.getCSVQuestion('../../files/Questions.csv'); //populates CSVquestions with an array of questions from questions.csv
      }



      /**
       * State Object for IframeFunctions Class
       * @property {boolean} userFeedback Determines whether to show Thumbs Up/Down for user.
       * @property {*} urlParameters The parameters given in the URL. If urlParameters.prompt exists, use this as the prompt
       * @property {array}  CSVquestions Array of question read using @getCSVQuestion, if no prompt in urlParameters
       * @property {int} CSVquestionNumber the index of CSVquestion to use. If it is not in urlParameters, then default to 0
       * @property {string} userInput what user types into text box being returned from prop function,getUserInput, passed to Textbox component
       * @property {string} userNeeds array of needs from prop function, getUserNeeds, passed to CheckboxForm component 
       */
      this.state = {
         userFeedback: true,
         urlParameters,
         userInput: '',
         userNeeds: [],
         CSVquestionNumber: urlParameters.questionNumber ? urlParameters.questionNumber : 0,
         prompt: urlParameters.prompt ? urlParameters.prompt : ''
      }

   }
   /**
    * @function
    * @summary
    * Updates the state value prompt from {@link Questions.csv}
    * 
    */
   getCSVQuestion = () => {
      var csvFilePath = require('../../files/Questions.csv');
      Papa.parse(csvFilePath, {
         header: true,
         download: true,
         skipEmptyLines: true,
         complete: this.updateQuestionInState
      });
   }

   /**
    * @function
    * @summary
    * If questions are to be loaded from CSV, this sets the list of questions from CSV file {@link getCSVQuestion} to the state property 
    */
   updateQuestionInState = (result) => {
      const data = result.data;
      const questionRow = data[this.state.CSVquestionNumber] //this is the row from Questions.csv
      const prompt = questionRow ? questionRow.Prompt : ''  //this accesses the Prompt column of that row
      if (!prompt) {
         console.log("no CSV question found. Question number:", this.state.questionNumber)
      }

      this.setState({ prompt })

   }


   getUserInput = (userInput) => {
      console.log('user input is: ', userInput)
      this.setState({ userInput: userInput })

   }

   updateNeeds = (arr) => {
      arr.forEach(arrVal => console.log('chosen:', arrVal))
      this.setState({ userNeeds: arr })
   }
   /**
    * @function
    * @summary
    * The render method for IframeFunctions
    */
   render() {

      return (
         <div className="IFrame">
            <section id="services" >
               <div>{window === window.top ? "" : "Inside Iframe!"}</div>

               <Container>
                  <Row>
                     <Col className="mt-5"  >
                        <div><ModalCheckboxForm openButtonText={'See More Examples'} size='lg' style={{ background: '#ff7000', color: "white", border: 0 }} showState={false} useHeaders={true} callbackFunc={this.updateNeeds} /></div>
                     </Col>
                  </Row>

                  <Row className="mt-5">
                     <Col     >
                        <h5>{this.state.prompt}</h5>
                     </Col>
                  </Row>

               </Container>
               <Container>
                  <Row>
                     <Col className='mb-5'>

                        <TextBox prompt={this.state.prompt} showPrompt={false} urlParameters={this.state.urlParameters} allowUserFeedback={true} textAreaHeight={2} iframe={true} callbackFunc={this.getUserInput} />
                     </Col>
                  </Row>
               </Container>
            </section>
         </div >)

   };

}

export default IframeFunctions