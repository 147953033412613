import React, { Component } from 'react'

import { db } from '../../config/fbConfig'
import { Col, Button, Row, Container, Tab, Nav } from 'react-bootstrap'
import TextBox from "../../bot_components/TextBox"
import { connect } from 'react-redux'
import ChallengeIntroText from './ChallengeIntroText'
import { createChallengeResponse } from '../../store/actions/challengeActions'




/**
 * ChallengeForm class
 * creates a series higherorder collapsible forms challenge and stores it in firestore through callbackFunc defined in parent class.
  */


//child component of Dashboard (the component routed to '/' which is the main page) 
class RespondToChallenge extends Component {
    /**
     * @constructor
     * @param {boolean} this.props.sentencesToTranslate. List of sentencesToTranslate that need to be translated.
 */
    constructor(props) {
        //don't need to set collapsibleState_sentence[n] states because they get automatically created in close_all_other_collapsibles
        //the first time a collapsible is opened.
        super(props);

        this.collapsibleList = []

        this.state = {
            title: (props.location.state && props.location.state.challenge.title) || '',
            summary: (props.location.state && props.location.state.challenge.summary) || '',
            tags: (props.location.state && props.location.state.challenge.tags) || [],
            groups: (props.location.state && props.location.state.challenge.groups) || [],
            direction: (props.location.state && props.location.state.challenge.direction) || 'a',
            challengeObjectList: (props.location.state && props.location.state.challenge.challengeObjectList) || [],
            parentDoc: (props.location.state && props.location.state.challenge.id) || null,
            additional: ''
        }


    }

    componentDidMount() {
        if (this.state.challengeObjectList.length === 0) {
            const pathname = this.props.location.pathname
            const endpoint = pathname.substring(pathname.lastIndexOf("/") + 1,)
            var docRef = db.collection("publicChallenges").doc(endpoint);
            console.log('trying to access this doc:', endpoint)
            docRef.get().then((doc) => {
                if (doc.exists) {
                    console.log("Document data:", doc.data());
                    this.setState(doc.data())
                    this.setState({ parentDoc: endpoint })
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            });

        }

    }


    createNavItems = (challengeObjectList) => {

        return (
            <>
                {challengeObjectList && challengeObjectList.map((x, i) => {

                    return (
                        <Nav.Item key={'NavItem' + i}>
                            <Nav.Link eventKey={i}>{`Rephrasing ${i}`}</Nav.Link>
                        </Nav.Item>
                    )
                })}
            </>

        )
    }

    createTabItems = (challengeObjectList) => {

        return (
            <>
                {challengeObjectList && challengeObjectList.map((challengeObject, i) => {

                    return (
                        <Tab.Pane eventKey={i} key={'TabItem' + i}>
                            {challengeObject.p}
                            {challengeObject.s}
                            <TextBox
                                callbackFunc={(userInput) => this.addUserInputToChallengeObject(userInput, i)}
                                allowUserFeedback={true}
                                showFinaliseButton={false}
                                prompt={''}
                                textAreaHeight={1}
                                placeholder={'write the rephrased version here...'}
                                initialTextValue={''}
                                type={challengeObject.t}
                                tags={this.state.tags}
                                groups={this.state.groups}
                                direction={this.state.direction}
                                message_to_translate={challengeObject.s}
                                recordAttempt={true}

                            />
                        </Tab.Pane>
                    )
                })}
            </>

        )
    }


    //adds user input to property in challengeObject so it can be displayed in summary screen
    addUserInputToChallengeObject = (userInput, i) => {
        let challengeObjectList = this.state.challengeObjectList
        challengeObjectList[i].m = userInput;
        this.setState({ challengeObjectList })
    }



    render() {
        console.log(this.props.location)
        if (this.state.challengeObjectList.length === 0) {

            return null
        }

        const navItems = this.createNavItems(this.state.challengeObjectList)
        const tabItems = this.createTabItems(this.state.challengeObjectList)

        return (

            <>
                <div className="input-field" ><Button variant="link" style={{ color: "#0099CC" }} href={'/'}>Back to Main Page</Button></div>
                <Tab.Container id="left-tabs-example" defaultActiveKey={-2}>


                    <Row>
                        <Col className="mt-5 mr-0" sm={3}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item >
                                    <Nav.Link eventKey={-2}>Introduction</Nav.Link>
                                </Nav.Item>
                                <Nav.Item >
                                    <Nav.Link eventKey={-1}>Scenario</Nav.Link>
                                </Nav.Item>
                                {navItems}

                            </Nav>
                        </Col>
                        <Col sm={9} className="mt-5 ">
                            <Container><Row><Col className="px-5 mr-5">
                                <Tab.Content>
                                    <Tab.Pane eventKey={-2}>
                                        <ChallengeIntroText />


                                    </Tab.Pane>
                                    <Tab.Pane eventKey={-1}>
                                        <Row>
                                            <Col md={{ span: 9, order: 0, offset: 3 }} xs={{ span: 12, order: 0, offset: 0 }} className=' mt-5 px-5' >
                                                <div>

                                                    <h4>{this.state.title} </h4>


                                                    <br></br>
                                                    <h5>{this.state.summary}</h5>
                                                    <br></br>

                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>

                                    {tabItems}
                                </Tab.Content>
                            </Col></Row></Container>
                        </Col>
                    </Row>


                    <Row>
                        <Col className="my-5">
                        </Col>
                    </Row>

                </Tab.Container>
            </>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        createChallengeResponse: (challenge) => dispatch(createChallengeResponse(challenge)),
    }
}


export default connect(null, mapDispatchToProps)(RespondToChallenge)

