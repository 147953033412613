import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import StepThrough from '../forms/StepThrough'
import TextBox from "../../bot_components/TextBox"
import './PublicPages.css'

const scenarioText = ['You are in charge of supervising a new intern. They seem unwilling to work on basic tasks. Instead, they are constantly proposing grand, blue-sky ideas that are completely unrealistic.  Also, they are very distant from the deliverables needed for the current deadline. You find the intern super annoying and you know you have to say something.']

const textArr = ['You seem to feel like mundane tasks are beneath you.',
    'Your ideas are all completely unrealistic.',
    'Try your own message if you\'d like.'
]


const suggested_phrasesArr = ['I noticed you haven’t completed the tasks I set out for you.',
    'Your ideas don\'t account for practical constraints and immediate business priorities.',
    ''
]

function PracticeFeedback() {
    const [index, setIndex] = useState(0);
    const [showSample, setShowSample] = useState(false)



    const toggleShowSample = () => {

        setShowSample(previousShowSample => !previousShowSample);
    }

    return (

        < div className="image-background" >
            <Container  >
                <Row><Col className="mt-5">
                    <h1><b>Feedback Practice</b></h1>
                    <h5>
                        <strong>Ungrounded Intern: </strong>
                        <p>
                            {scenarioText}
                        </p>
                    </h5>

                </Col></Row>
                <StepThrough
                    show_at_end={true} numItems={textArr.length - 1}
                    callbackFunc={(index) => { setIndex(Number(index)); console.log(index); setShowSample(false) }}
                >

                    {index < textArr.length ? <><Row><Col className="mt-3 mb-5">


                    </Col></Row>
                        <Row >
                            <Col className="mt-1">
                                {index < textArr.length - 1 ? <h5><i>Rephrase the following to be more objective and non-personal:</i></h5> : null}
                                <h5>{textArr[index]}</h5>
                                {index === 0 ?
                                    <TextBox
                                        textAreaClasses=''
                                        clearFeedbackOnUserInput={false}
                                        allowUserFeedback={true}
                                        feedbackButtonClass='btn-medium mb-3'
                                        prompt=''
                                        blockUserInput={false}
                                        placeholder={'Write your rephrasing here'}
                                        textAreaHeight={3}
                                        noVisibleTextBox={false}
                                    /> : null}

                                {index === 1 ?
                                    <TextBox
                                        textAreaClasses=''
                                        clearFeedbackOnUserInput={false}
                                        allowUserFeedback={true}
                                        feedbackButtonClass='btn-medium mb-3'
                                        prompt=''
                                        blockUserInput={false}
                                        placeholder={'Write your rephrasing here'}
                                        textAreaHeight={3}
                                        noVisibleTextBox={false}
                                    /> : null}

                                {index === 2 ?
                                    <TextBox
                                        textAreaClasses=''
                                        clearFeedbackOnUserInput={false}
                                        allowUserFeedback={true}
                                        feedbackButtonClass='btn-medium mb-3'
                                        prompt=''
                                        blockUserInput={false}
                                        placeholder={'Write your rephrasing here'}
                                        textAreaHeight={3}
                                        noVisibleTextBox={false}
                                    /> : null}

                            </Col>
                        </Row>
                        <Row><Col className="mt-5">
                            {index < textArr.length - 1 ? <Button style={{ backgroundColor: '#ff7000', border: 'none' }} onClick={toggleShowSample}>Show Sample Feedback</Button> : null}
                            {showSample ?
                                <div className="mt-3"><h5>{suggested_phrasesArr[index]}</h5></div> : null
                            }
                        </Col></Row>


                    </> : <><div>Thanks for trying the feedback practice! Any questions, please get in contact using our contact form </div>
                        <div className="mt-2"><Link to="https://www.diplomacydojo.com/contact" style={{ color: "black" }}><Button className="btn-blue btn-large mr-5 mb-lg-0 mb-3">Contact Us</Button></Link></div>

                        <div className="mt-2">or email <a href="mailto:info@diplomacydojo.com">info@diplomacydojo.com</a></div>
                    </>}

                </StepThrough>
                <Row><Col className="my-5"></Col></Row>
            </Container>

        </div >
    )
}

export default PracticeFeedback
