import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import { Form, Col, Button, Container, Row, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import HigherOrderCollapsible from '../../Utilities/HigherOrderCollapsible'
import CheckboxForm from '../forms/CheckboxForm'

import "../../index.css"




const CollapsibleCheckboxForm = HigherOrderCollapsible(CheckboxForm)

const defaultTooltipColor = "#0099CC"
/**
 * CreateScenarioActivity class
 * Allows user to create or edit a new scenario and stores it in firestore through callbackFunc defined in parent class.
 * passes a prop function callbackFunc to get arr of checkbox values to CollapsibleCheckboxForm which is CheckboxForm wrapped in a higher order Collapsible Modal
 */


/**
 * @constructor
 * @param {*} props.scenario, all attributes related to the scenario being passed in.
 */

class CreateScenarioActivity extends Component {

    /**
     * @constructor
     * @param {boolean} this.props.scenario.isPublic determines whether the scenario is public or private and thus which collection its stored in
     * @param {array} this.props.scenario.tags, list of tags relating to scenario
     * * @param {array} this.props.scenario.tags, list of groups for whom scenario is relevant (to be used later to organise different groups)
     * @param {boolean} this.props.scenario.authByAdmin whether scenario was created by Admin
     * @param {boolean} this.props.showAllSteps whether to walk through all steps or whether to just show short version
     * @param {boolean} this.props.parentDoc The doc id of the parentScnario if it is responding to a challenge or public scenario, then title and summary should be fixed
 */

    constructor(props) {
        super(props);


        const localData = sessionStorage.getItem('CreateScenarioActivity' + props.location.pathname)



        //need to define conditional || in case props.scenario does not have the attribute. This won't be caught by defaultProps as scenario does exist
        //and the attribute are 2nd order properties.
        //collpasibleState_X are to control collapsibles so only one is open at a time. controlled via function: close_all_other_collapsibles
        this.state = {
            tags: [],
            groups: [],
            direction: ['all'], //direction is like upwards, downwards, all,
            type: '', //type is like observation, request, acknowledgment
            isPublic: true,
            authByAdmin: false,
            title: '',
            summary: '',


        }
    }

    static defaultProps = {
        formTitle: '',
        scenario: {
            tags: [],
            groups: [],
            type: [],
            isPublic: false,
            authByAdmin: false,
            title: '',
            summary: '',
            direction: 'all',

        },

    }


    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        }, this.writeToSessionStorage)

    }

    handleSubmit = (e) => {
        e.preventDefault()
        //callbackFunc is action creator defined in scenarioActions.js
        console.log(this.props)
        let scenario_to_send = Object.assign({}, this.state);
        //remove collapsible, copyTo and shos state keys from what is sent via callbackFun 
        // this is because createScenario and editScenario write all properties of scenario into the database record, using destructuring, i.e., ...scenario
        Object.keys(scenario_to_send).forEach((key) => {


            if (key.startsWith('collapsibleState') || key.startsWith('copyTo') || key.startsWith('show'))
                delete scenario_to_send[key]
        })

        if ('authorId' in this.props.scenario) {
            scenario_to_send['authorId'] = this.props.scenario.authorId
        }

        console.log('scenario_to_send:', scenario_to_send)
        this.props.callbackFunc(scenario_to_send)
        const returnURL = this.props.returnURL || '/'
        if (this.props.history) this.props.history.push(returnURL) //return to the home route
    }




    close_all_other_collapsibles = (currentCollapsible) => {
        console.log(currentCollapsible)

        const collapsibleList = [
            'collapsibleState_tags',
            'collapsibleState_groups',
            'collapsibleState_type',
            'collapsibleState_direction',

        ]
        collapsibleList.forEach(el => {
            //console.log('inside forEach: ', el)
            if (el !== 'collapsibleState_' + currentCollapsible) {
                if (this.state[el] === true) {
                    this.setState({ [el]: false })

                }
            }

        })

        this.setState({ ['collapsibleState_' + currentCollapsible]: true, collapsibleStateCurrent: currentCollapsible })

    }






    //writes jsonified state into session storage. To be called after all calls to setState.
    writeToSessionStorage = () => {
        sessionStorage.setItem('CreateScenarioActivity' + this.props.location.pathname, JSON.stringify(this.state))
    }

    //define prop callbacks ahead of time to prevent re-rendering of checkboxForm, which happens if props are defined inline
    //copyToObservation (which automatically sets observation to description) is set to false after user first types in their own observation
    tagsCallback = (tags) => this.setState({ tags }, this.writeToSessionStorage)
    groupsCallback = (groups) => this.setState({ groups }, this.writeToSessionStorage)



    signalOpen = (showState, id) => {
        if (showState)
            this.close_all_other_collapsibles(id)
        else
            this.setState({ ['collapsibleState_' + id]: false })
    }


    //function for showAllStepsToggle button that either expands all the detailed steps or collapses them.
    changeShowAllStepsState = () => {
        if (this.state.showAllSteps) {
            this.setState({ showAllSteps: false })
        }
        else {
            this.setState({ showAllSteps: true })
        }

    }

    handleChangeDirection = (val) => this.setState({ direction: val }, this.writeToSessionStorage);
    handleChangeType = (val) => this.setState({ type: val }, this.writeToSessionStorage);


    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to='/signin' />
        const returnURL = '/'



        return (

            <Container>
                <Button variant="link" style={{ color: "#0099CC" }} href={returnURL}>Back</Button>
                <Row>



                    <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0, offset: 0 }}    >

                        <Form onSubmit={this.handleSubmit}>

                            <Form.Group>
                                <h3 >{this.props.formTitle}</h3>
                            </Form.Group>


                            <div>
                                <h4>{this.state.title}</h4>
                                <br></br>
                                <h5>{this.state.summary}</h5>

                            </div>

                            <Form.Group controlId="title" size="lg" >
                                <Form.Label column="lg">
                                    Title
                                </Form.Label>
                                <Col >
                                    <Form.Control size="lg" type="text" onChange={this.handleChange} value={this.state.title} />
                                </Col>
                            </Form.Group>
                            <Form.Group controlId="summary"  >
                                <Form.Label column="lg">
                                    What's the situation?
                            </Form.Label>
                                <Col>
                                    <Form.Control size="lg" as="textarea" rows="3" onChange={this.handleChange} placeholder={'Brief summary here (for your reference)'} value={this.state.summary} />
                                </Col>
                            </Form.Group>



                        </Form>
                    </Col>
                    <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0 }} className='mb-5 pl-4 pr-4 '   >




                        <ToggleButtonGroup type="radio" onChange={this.handleChangeDirection} name="options" defaultValue={this.state.direction} className="mx-3">
                            <ToggleButton value={'all'}>All</ToggleButton>
                            <ToggleButton value={'up'}>Up</ToggleButton>
                            <ToggleButton value={'down'}>Down</ToggleButton>
                        </ToggleButtonGroup>

                    </Col>

                    <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0 }} className='mb-5 pl-4 pr-4 '   >
                        <ToggleButtonGroup type="radio" onChange={this.handleChangeType} name="options" defaultValue={this.state.type} className="mx-3">
                            <ToggleButton value={'all'}>All</ToggleButton>
                            <ToggleButton value={'up'}>Up</ToggleButton>
                            <ToggleButton value={'down'}>Down</ToggleButton>
                        </ToggleButtonGroup>

                    </Col>



                    <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0 }} className='mb-5 pl-4 pr-4 '   >

                        < CollapsibleCheckboxForm
                            callbackFunc={this.tagsCallback}
                            label={'Tags'}
                            checkboxFilename={'tags'}
                            title={'Check all related themes'}
                            useHeaders={false}
                            classNameValues={'h-blue'}
                            initialCheckedFields={this.state.tags}
                            showState={this.state.collapsibleState_tags}
                            signalOpenCallback={this.signalOpen}
                            id={'tags'}
                        />
                    </Col>
                    <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0 }} className='mb-5 pl-4 pr-4 '   >
                        < CollapsibleCheckboxForm
                            callbackFunc={this.groupsCallback}
                            label={'Groups'}
                            checkboxFilename={'groups'}
                            title={'Groups (that scenario is for)'}
                            useHeaders={false}
                            classNameValues={'h-blue'}
                            initialCheckedFields={this.state.groups}
                            showState={this.state.collapsibleState_groups}
                            signalOpenCallback={this.signalOpen}
                            id={'groups'}

                        />
                    </Col>




                    <Col md={{ span: 12, order: 0, offset: 0 }} xs={{ span: 12, order: 0, offset: 0 }} className='pl-4 pr-4'  >
                        <div className="input-field text-right">
                            <Button onClick={this.handleSubmit} style={{ background: '#ff7000', color: { defaultTooltipColor }, border: 0 }}>Save</Button>
                        </div>
                    </Col>

                </Row>
                <Row><Col style={{ marginBottom: "10rem" }}></Col></Row>
            </Container >

        )
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        isAdmin: state.auth.isAdmin, //whether current user is admin
        profile: state.firebase.profile,
    }
}


export default connect(mapStateToProps)(withRouter(CreateScenarioActivity))

