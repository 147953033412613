
import { connect } from 'react-redux';
import { firebaseAuth } from '../../config/fbConfig'

/**
 * A renderless functional component for checking whether user has custom claim isAdmin
 */
const VerifyAdminFirebase = ({ verifyAdmin }) => {
    console.log('inside verifyAdminFirebase')
    const user = firebaseAuth.currentUser
    if (user) {
        console.log('inside verifyAdmin with user:', user)
        user.getIdTokenResult().then(idTokenResult => {
            user.isAdmin = idTokenResult.claims.admin;
            console.log('inside verifyAdmin', user.isAdmin);
            if (user.isAdmin) {
                verifyAdmin(user.isAdmin)
            }
        });
    }

    return (null)
}





const mapDispatchToProps = (dispatch) => {
    return {
        verifyAdmin: (isAdmin) => dispatch({ type: 'IS_ADMIN', isAdmin: isAdmin }),
    }
}

export default connect(null, mapDispatchToProps)(VerifyAdminFirebase)

