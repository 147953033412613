import React, { Component, useState } from 'react'
import { Container, Row, Col, Button, Collapse } from 'react-bootstrap'
import queryString from 'query-string';
import InstantEmpathy from "../../bot_components/InstantEmpathy"
import { analytics } from '../../config/fbConfig'
import Sempi from '../../files/Sempi.png'
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

export class MessageHelper extends Component {

    constructor(props) {
        super()
        let params = queryString.parse(props.location.search)

        console.log('params', params)

        //        const [utterance, setUtterance] = useState('');
        //        const [response, setResponse] = useState('');
        //        const callAPI = () => {
        //
        //        fetch(`https://conversation-dojo.herokuapp.com/getEmpathicResponse?input=My%20manager%20is%20dismissive%20of%20my%20point!`)
        //           .then(res => res.json())
        //           .then(data => {
        //              setResponse(data);
        //           });
        //        }

        this.state = {
            input: props.location.state && props.location.state.input ? props.location.state.input : '',
            getFeedbackOnLoad: props.location.state && props.location.state.getFeedbackOnLoad ? props.location.state.getFeedbackOnLoad : false,
            sessionResponse: props.location.state && props.location.state.sessionResponse ? props.location.state.sessionResponse : false, //response for this session
            showPrivacy: false,
            showSubscribe: false,
            showAbout: true,
            recordAttempt: params.recordAttempt === 'true' ? true : false,
            groups: params.groups ? params.groups : ''
        }
    }

    componentDidMount() {
        if (this.state.getFeedbackOnLoad)
            window.scrollTo(0, 0)
    }

    //not used, only needed when MessageHelperFront is used.
    textBoxCallback = (userInput) => {
        console.log('clearing history')
        this.props.history.replace()
        this.setState({ getFeedbackOnLoad: false, loadingScreenText: 'Getting feedback for you' })
    }

    privacyToggle = () => {
        if (!this.state.showPrivacy)
            analytics.logEvent('MH_PrivacyViewed', { location_id: 'messagehelper' })
        this.setState(prevState => ({ showPrivacy: !prevState.showPrivacy }))
    }

    openSubscribe = () => {
        analytics.logEvent('MH_SubscribeViewed', { location_id: 'messagehelper' })
        window.open('/subscribe')
    }

    aboutToggle = () => {
        if (!this.state.showAbout)
            analytics.logEvent('MH_AboutViewed', { location_id: 'messagehelper' })
        this.setState(prevState => ({ showAbout: !prevState.showAbout }))
    }

    render() {
        return (
            <>
                <Button className="text-left" variant="link" style={{ color: "#007399" }} href='https://www.diplomacydojo.com/'>Back to Home</Button>
                <Container className=" min-vh-100 d-flex flex-column ">

                    <Row><Col className="mt-5">

                        <h1 ><strong>Instant Empathy</strong><img src={Sempi} width="50" alt={'Sempi'} className='ml-2 mb-2' /></h1>
                        <Button variant="link" onClick={this.aboutToggle} className="ml-0 pl-0 link" style={{ border: 'none', color: this.state.showAbout ? '#00394d' : '#007399' }}>{this.state.showAbout ? 'Less Info' : 'More Info'}</Button>
                        <Collapse in={this.state.showAbout}>
                            <div className="mt-3">
                                <strong>Hi, I'm Aimée.</strong>
                                <p>
                                    I offer you instant empathy.
                                </p>
                                {/** 
                                <strong>I'd love feedback too</strong>
                                <p>
                                    I want you to know I'm not perfect, I'm still learning too.
                                    Please help me improve by giving me feedback.
                                    <FaThumbsDown display={"inline"} style={{ color: "#0099CC" }} className="ml-1" aria-controls="example-collapse-text" size="20px" />
                                    <FaThumbsUp display={"inline"} style={{ color: "#0099CC" }} className="ml-1" size="20px" />




                            </p>

*/}
                            </div>
                        </Collapse>
                    </Col></Row>
                    <Row><Col className="my-5 mx-5">

                        <InstantEmpathy

                            textAreaClasses=''
                            clearFeedbackOnUserInput={false}
                            allowUserFeedback={true}
                            feedbackButtonClass='btn-medium mb-3'
                            prompt=''
                            blockUserInput={false}
                            initialTextValue={this.state.input}
                            textAreaHeight={3}
                            recordAttempt={this.state.recordAttempt}
                            getFeedbackOnLoad={this.state.getFeedbackOnLoad} //automatically ask for input when the text box is called, to be used from 'Get Feedback' from the home page
                            sessionResponse={this.state.sessionResponse}
                            groups={this.state.groups}
                            attemptLimit={10}
                        />
                    </Col></Row>
                    <Row><Col>

                        <Button variant="link" onClick={this.privacyToggle} className="ml-0 pl-0" style={{ border: 'none', color: this.state.showPrivacy ? '#00394d' : '#007399' }}>{this.state.showPrivacy ? 'Hide Privacy Info' : 'View Privacy Info'}</Button>

                        {/** 
                        <Button variant="link" onClick={this.openSubscribe} style={{ border: 'none', color: '#007399' }} >Subscribe</Button>
*/}

                        <Collapse in={this.state.showPrivacy}>
                            <div className="mt-5">
                                <strong>Message Helper Privacy Info: </strong>
                                <p>We don't collect any personal data (e.g., no IP addresses) and your input is completely anonymous.
                                    We will be using random samples of your inputs to help improve the algorithm.  If you happen to write any identifying inputs, we will de-identify the data before using it for our algorithms.
                                </p>
                                <p>If you would like to give general feedback, you have an option to
                                    include your email and/or your recent input to the Message Helper to help us understand your comments. But these are both optional. We will delete your message as soon as your query is addressed.
                                </p>
                            </div>
                        </Collapse>

                    </Col></Row>
                    <Row><Col style={{ marginBottom: '10rem' }}></Col></Row>
                </Container >
            </>
        )
    }
}

export default MessageHelper
