import React, { Component, useRef } from 'react';
import { connect } from 'react-redux'
import firebase, { db, analytics } from '../../config/fbConfig'
import axios from 'axios';
import qs from 'qs';
import UserFeedback from "./UserFeedback"
import { Button, Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as config from '../../config/default_config'
import ModalProblem from "./ProblemNotDetected"
//import HighlightFunction from "./HighlightFunction"
import HighlightFunction, { HiglightWithoutSpace } from "./HighlightFunction"
import { Highlight3 } from "./HighlightFunction/HighLight3"
//import DisplayImage from './DisplayImage';
import TooltipCustom from "../../Utilities/TooltipCustom"
import { recordUserFeedback } from '../../store/actions/botActions'

import '../bot_index.css'
import './Feedback.css'


/**
 * Feedback Class. Called from TextBox to show the bot feedback. Has child components ModalProblem from "./ProblemNotDetected", HighlightFunction from "./HighlightFunction"
and DisplayImage from './DisplayImage';
This is the component that makes the API call to the bot. It sends urlParameter values 
 */
class Feedback extends Component {

   /**
    * @constructor
    * @param {*} props To pass any property from where it is called.
    * @param {boolean} props.executeLoadingScreen callback function that allows loading screen to be set to false when axis returns from API call
    * @param {boolean} props.allowUserFeedback  this is whether or not user can give feedback back to bot (thumbs up and down)
    * @param {*} props.urlParameters Even if originally no urlParameters, TextBox creates this object to be empty object. when !urlParemeters.prompt because it is read from CSV file, we add it in below to pass to API
    * @param {string} props.user_input typed input from the user
    * @param {boolean} props.funMemes  whether Mems should be shown--currently not implemented
    * @param {string} props.prompt the prompt that was shown to the user. We pass this here to pass to the API
    * @param {boolean} props.recordAttempt whether or not to record user attempt when getFeedback is called
    * @param {string} props.attemptPath, where in firestore attempt is recorded. should be of the form 'path' or 'path/doc/path1'  the default value is 'generalAttempt'
    * @param {string} props.message_to_translate prop to be stored with attempt. 
    * @param {string} props.usageRecordsPath, where in firestore records usage attempt should be of the form 'path' or 'path/doc/path1'  the default value is null (no record is recorded)
    * @param {string} props.usageId the name to be appended to the array emails
   */
   constructor(props) {
      super(props);
      const initialCountedAttempts = parseInt(localStorage.getItem('countedAttempts'))


      /**
       * State Object for Feedback Class
       * @property {int}  number of countedAttempts that have been attempted (only count if props.countAttempt = true)
       * @property {boolean}  userFeedbackNeeded Determines whether to display feedback giving option for user, 
       * @property {boolean}  requestCancelled Used to check the request made to API return or not.
       * @property {{*}} urlParameters This is passed from props defined in TextBox. Even if originally no urlParameters, TextBox creates this to be empty object. We add it to this.state.urlParameters in ComponentDidMount/Update below
       * @property {{}} source Used as reference for API request cancelled or returned
       * @property {array} sentencesOutput The sentences returned from the API
       * @property {string}  message Output message from the API of userInput
       * @property {boolean}  APIfeedback Determines if there is Feedback to display.
       * @property {boolean}  noFeedback If there is noFeedback returned from API
       * @property {{}} userFeedback When User gives feedback from Up/Down arrow for UserFeedback
       * @property {boolean} disableSendFeedback Used to disable the Send Feedback Button
       * @property {string}  sendFeedbackButton Text for Send Feedback Button
       * @property {string} NoFeedbackResponse Text Returned from API when No feedback is there to Display
       * @property {boolean} memes Checker whether Memes are used
       * @property {string} noFeedbackResponseDefault Default Message when no Feedback is returned from API.
       */
      this.state = {

         userFeedbackNeeded: this.props.allowUserFeedback,
         requestCancelled: false,
         urlParameters: props.urlParameters ? props.urlParameters : {},
         source: useRef,
         sentencesOutput: [],
         message: "Did we miss anything? Or any other issues? Please let us know how we can support you better.",
         APIfeedback: false,
         noFeedback: false,
         userFeedback: {},
         disableSendFeedback: false,
         sendFeedbackButton: "Send Feedback on Comments",
         NoFeedbackResponse: "",
         memes: false,
         noFeedbackResponseDefault: "Looks Good.",
         hovered: {},
         newInput: this.props.newInput,
         displayMessage: null,
         currentAttemptDocId: null,
         userAttemptCount: initialCountedAttempts || 0, //this is a prop sent to userFeedback so that when it changes (every time get Feedback is submitted) the thumbsup/down are cleared.
         getFeedbackOnLoad: this.props.getFeedbackOnLoad, //automatically ask for input when the text box is called, to be used from 'Get Feedback' from the home page
         sessionResponse: this.props.sessionResponse, //session responseid to be sent to FB analytics
         botFeedbackListLengthBeforeFeedback: null, //length of botFeeedbackList upon calling of getFeedback

      }
   }

   static defaultProps = {
      countAttempts: false,
      attemptLimit: 3,
      callbackFunc: () => { }, //callbackFunc to send variables back to textbox
      feedbackButtonClass: '',
      clearFeedbackOnUserInput: false,
      newInput: false,
      type: 'G',
      groups: [],
      tags: [],
      direction: 'a',
      recordAttempt: false,
      attemptPath: 'generalAttempt',//should be of the form 'path' or 'path/doc/path1'  the default value is 'generalAttempt'
      message_to_translate: '',//if there is a prompt for a message that is being translated
      location_id: 'default',//to be passed to FB analytics so we know where getFeedack was called
      getFeedbackOnLoad: false, //automatically ask for input when the text box is called, to be used from 'Get Feedback' from the home page
      sessionResponse: '', //session responseid to be sent to FB analytics
      usageId: null, //usageId for thinkific users to be stored in usageRecords, e.g., thinkificUsers/courses/difficultConversationsTaster
      usageRecordsPath: null,
      feedbackButtonText: "Get Empathy"
   }

   /**
    * @function
    * @summary
  * When prompt is not included in props it is not present, we add it to this.state.urlParameters in ComponentDidMount/Update below. This is because
  * react children components do not rerender just because props 2nd level object changed (it only rerenders when first-level properties of props change). Thus we need to manually 
  * add prompt to urlParameters when componentDidUpdate. Cannot put this in the constructor as 
  * constructor only is called once upon the initial construction of the component, not on subsequent rerenders.
  */

   componentDidMount() {

      if (!this.state.urlParameters.hasOwnProperty('prompt') && this.props.prompt) {
         this.setState({
            urlParameters: {
               ...this.state.urlParameters,
               prompt: this.props.prompt
            }
         })

      }
      if (this.state.getFeedbackOnLoad) {
         this.setState({ getFeedbackOnLoad: false })
         console.log('calling handle submit')
         console.log('logging analytics')
         analytics.logEvent('get_feedback', {
            location_id: 'redirect_from_home', // id is a prop passed from textbox so that we know where this get_feedback button was clicked on from. Will be a repeat event from
            sessionResponse: this.props.sessionResponse
         });

         analytics.logEvent('get_feedback_from_homepage', {
            sessionResponse: this.props.sessionResponse
         });
         this.handleSubmit()
      }
   }
   componentDidUpdate(prevProps) {
      if ((this.props.newInput !== prevProps.newInput) && this.props.clearFeedbackOnUserInput && this.props.newInput === true && this.state.newInput === false) {
         this.setState({
            sentencesOutput: [], newInput: true, displayMessage: null, noFeedback: false
         })
      }
      if (!this.state.urlParameters.hasOwnProperty('prompt') && this.props.prompt) {
         this.setState({
            urlParameters: {
               ...this.state.urlParameters,
               prompt: this.props.prompt
            }
         })
      }
      //if (this.prevprops.botFeedbackList !== this.props.botFeedbackList)
      //this.setState({ botFeedbackListLengthBeforeFeedback: this.props.botFeedbackList.length })
      //we want feedback.js to have the latest botFeebackList.length without necessarily causing a re-render
      // console.log('infinite loop')
   }


   //writes jsonified state into session storage. To be called after all calls to setState.
   writeToLocalStorage = () => {
      localStorage.setItem('countedAttempts', this.state.userAttemptCount)
   }

   /**
    * @function
    * @summary
    * Highlight & Display {@link message}
    * This function was built initially which using packages from NPM to do highlight.
    * However, the function highlights all part of string which matches the string syntax from the highlighted word, rather than highlighting only the exact word.
    */
   generateDisplayMessage = () => {

      //      const val = this.state.message.split("\n").map((item, i) => {
      //
      //         return <p key={i}>
      //            <HighlightFunction
      //               sentence={item}
      //               attributes={this.state.sentencesOutput}
      //            // toggleHovered={this.toggleHovered}
      //            // initialSetHover = {this.initialSetHover}
      //            />
      //         </p>;
      //      })
      //this.setState({ displayMessage: val });

   }
   initialSetHover = (i) => {
      this.setState({
         hovered: {
            ...this.state.hovered,
            [i]: false
         }
      })
   }

   /**
    * @function
    * @summary
    * Used for highlighting
    * This has issues with new line and the spacing are not consistant when it comes to mutliple \n
    * Additionally, this function is not 100% complete, as there are many unchecked test
    */
   generateDisplayMessage3 = () => {
      this.setState({ displayMessage: "" });
      const val =
         <HiglightWithoutSpace
            attributes={this.state.sentencesOutput}
         />


      this.setState({ displayMessage: val });

   }
   /**
    * @function
    * @summary
    * This function was built from scratch to highlight only the marked words & phrases.
    * It deals with \n & white spaces better compared to the other two functions.
    * This would be used if the highlight portion are to be matched against word rather than sub string
    * This function might have issues if the API output results changes in user_input.sentence{i}.r_l, user_input.sentence{i}.sentence, user_input.sentence{i}.r_l[j].response_indices
    * sentencesOutput is a list of the sentence fields from API output, e.g., [ 'sentence0':{}, 'sentence1':{} ...]
   */
   generateDisplayMessage2 = () => {
      this.setState({ displayMessage: "" });
      if (!this.state.noFeedback) {
         const val =
            <Highlight3
               useHovering={this.props.useHovering}
               sentenceOutput={this.state.sentencesOutput}
               highlightedItemClass="text-danger"
               itemClass="text-italics"
               toggleHoveredEnter={this.toggleHoveredEnter}
               toggleHoveredExit={this.toggleHoveredExit}
            />


         this.setState({ displayMessage: val });
      }
   }

   toggleHoveredExit = (i) => {

      this.setState({
         hovered: {
            [i]: false
         }
      })
   }
   toggleHoveredEnter = (i) => {
      this.setState({
         hovered: {
            ...this.state.hovered,
            [i]: true
         }
      })
   }

   recordUserAttempt = ({ attempt, collectionPath }) => {

      //collectionPath should be in form 'publicScenarios/{scenarioId}/Attempt'

      const collectionPathArr = collectionPath.split("/")
      let docLocation
      try {
         if (collectionPathArr.length === 1)
            docLocation = db.collection(collectionPathArr[0])
         else if (collectionPathArr.length === 3)
            docLocation = db.collection(collectionPathArr[0]).doc(collectionPathArr[1]).collection(collectionPathArr[2])
         else throw new Error("'collectionPath needs to be of the form 'path' or 'path/doc/path1'")
      }
      catch (err) {
         console.log(err)
      }

      docLocation.add({ //if attempt does not exist, it will create a new collection
         ...attempt, //this is like putting title: challenge.title and content: challenge.summary
         d: new Date(),
         //new fields get automatically added. I just needed to refresh browser for change to take place

      }).then((docRef) => {
         this.setState({ currentAttemptDocId: docRef.id })
         console.log('docRef', docRef.id)

      })
         .catch(function (error) {
            console.log('error recording user attempt', error); // TypeError: failed to fetch

         })

   }

   //Keeps an array of objects with field of emails and unix time stamps for every time someone calls get_Feedback from thinkific
   //I don't put this inside Feedback/index.js because I want it to exist in the higher level component that has access to the url parameters.
   recordUserBotCall = (collectionPath, id) => {
      const collectionPathArr = collectionPath.split("/")
      let docLocation
      try {
         if (collectionPathArr.length === 1)
            docLocation = db.collection(collectionPathArr[0])
         else if (collectionPathArr.length === 3)
            docLocation = db.collection(collectionPathArr[0]).doc(collectionPathArr[1]).collection(collectionPathArr[2])
         else throw new Error("'collectionPath needs to be of the form 'path' or 'path/doc/path1'")
      }
      catch (err) {
         console.log(err)
      }

      docLocation.update({
         emails: firebase.firestore.FieldValue.arrayUnion({ id, t: new Date() })
      }).then((docRef) => {
         console.log('appended to docRef', docRef.id)
      }).catch((error) => {
         console.log('error recording user bot call', error)
      })


   }

   /**
   * HTML content rendered of {@link Feedback} class 
   */
   render() {
      const link_list = [] //keep track of links so user is not shown duplicate links

      return (
         <Container fluid className="text-left mt-2">
            <ToastContainer
               autoClose={5000}
               className="toast-container"
               closeOnClick={true}
               pauseOnFocusLoss={false}
               hideProgressBar={true}
            />

            <Button type="button submit" className={"btn-blue float-right " + this.props.feedbackButtonClass} onClick={this.handleSubmit} value=""> {this.props.feedbackButtonText}</Button>

            <br />
            <br />

            <Row className="pt-auto mt-3">
               <Col >

                  {this.state.APIfeedback ?


                     <h6 className='font-italic' style={{ overflowWrap: 'break-word', lineHeight: '1.6' }} >
                        {this.state.displayMessage}
                     </h6> : <></>}
               </Col>
            </Row>


            {this.state.APIfeedback && !this.state.noFeedback ?
               <div className="FeedbackReturnedByAPI">


                  <TooltipCustom hide={!this.state.userFeedbackNeeded} color="black" />

                  <br />
                  <br />
                  <Container className="float-left FeedbackListTable">

                     {this.state.sentencesOutput.map((val, sentenceIndex) => {

                        return (val.r_l.map((value) => {
                           let response_str

                           //console.log('prompt_str', value.prompt_str, config.PROMPT_OBJECT.hasOwnProperty(value.prompt_str))
                           if (value.prompt_str && config.PROMPT_OBJECT.hasOwnProperty(value.prompt_str)) {
                              response_str = value.r_str + " " + config.PROMPT_OBJECT[value.prompt_str]
                           }
                           else {
                              response_str = value.r_str
                           }
                           let readMoreLink = null
                           //only add readMoreLink if it has not already been shown in previous response
                           if (config.PROMPT_OBJECT_LINKS.hasOwnProperty(value.prompt_str) && link_list.indexOf(config.PROMPT_OBJECT_LINKS[value.prompt_str]) === -1) {
                              readMoreLink = config.PROMPT_OBJECT_LINKS[value.prompt_str]
                              link_list.push(readMoreLink)
                           }



                           return (
                              <div key={value.id + value.r_i.join()}>
                                 {/* // UserFeedback goes to it's function */}

                                 <UserFeedback
                                    updateUserFeedback={this.updateUserFeedback}
                                    comment_id={value.id}
                                    ref={"Child" + value.id}
                                    responseFeedbackAPI={response_str}
                                    readMoreLink={readMoreLink}
                                    sentenceArray={val.s_tok}
                                    sentenceStr={val.s_str}
                                    response_str={value.r_str}
                                    cid={value.c}
                                    indices={value.r_i}
                                    definitely_bad={value.bad}
                                    userFeedbackNeeded={this.state.userFeedbackNeeded}
                                    hovered={this.state.hovered[value.id]}
                                    userAttemptCount={this.state.userAttemptCount}
                                    sentenceIndex={sentenceIndex}
                                 />

                              </div>)
                        }
                        )
                        )
                     })
                     }
                  </Container> </div>
               :
               (this.state.noFeedback ? <h5><p>{this.state.noFeedbackResponseDefault}</p>
               </h5>
                  :
                  "")

            }

            {this.state.APIfeedback && this.state.userFeedbackNeeded ?
               <div className="UserFeedbackInteractionButton">
                  <Button
                     disabled={this.state.disableSendFeedback}
                     hidden={this.state.noFeedback}
                     className="btn btn-orange float-right"
                     variant="light"
                     onClick={this.sendUserFeedbackWrapper}
                  >
                     {this.state.sendFeedbackButton}
                  </Button>

                  {/* Printing API Response when no feedback is found */}
                  <h5>{this.state.NoFeedbackResponse.split("\n").map((val, i) => {
                     return <p key={i}>{val}</p>
                  })}</h5>

                  {/* Memes Implementation - Not complete
                  <DisplayImage

                     showMemes={this.state.memes}
                     image_url={this.state.image_url}
                     image_name_list={this.state.image_name_list}
                  />
 */}
                  <ModalProblem

                     message={this.state.message}
                     message_id={this.state.message_id}
                     displayMsg={this.state.displayMessage}
                     sendRequestToAPI={this.sendRequestToAPI} />
               </div> : <></>}
            {this.props.attemptLimit && (this.state.userAttemptCount >= this.props.attemptLimit) ?
               <Row><Col><h5><i>Limit on attempts exceeded. For further access, please
                  <a href="https://www.diplomacydojo.com/contact"> Contact Us </a></i></h5></Col></Row>
               : null
            }
         </Container>

      )
   }
   /**
       * @function
       * @summary
       * When submit button is pressed.
       * Uses the {@link Feedback} Component reference to execute the method from there.
            */
   handleSubmit = () => {
      console.log('userAttemptCount', this.state.userAttemptCount)
      if (this.props.attemptLimit && (this.state.userAttemptCount >= this.props.attemptLimit)) {
         console.log('attempt limit exceeded')
         return
      }
      if (this.props.user_input.length > 0) {
         this.setState({ newInput: false })
         //the value of botFeedbackListLengthBeforeFeedback is set and fixed for this rendering of tis Feedback component every time getFeedback (i.e., handleSubmit) is called
         this.setState({ botFeedbackListLengthBeforeFeedback: this.props.botFeedbackListLength })
         //console.log('newInput', this.state.newInput)
         this.getFeedbackForUserInput(this.props.user_input, this.props.funMemes);
         this.setState(prevState => {
            return { userAttemptCount: prevState.userAttemptCount + 1 }
         }, () => {
            if (this.props.attemptLimit) {
               this.writeToLocalStorage()
            }
         })
         analytics.logEvent('get_empathy', {
            location_id: this.props.location_id, // id is a prop passed from textbox so that we know where this get_feedback button was clicked on from.
            sessionResponse: this.props.sessionResponse
         });
      }
      else
         toast.info("No input detected");
   }

   fetchAPI = url => {
      return fetch(url)
         .then(response => response.text())
         .then(data => {
            return data;
         })
         .catch(err => { console.log(err); });
   }

   handleAPICall = (value) => {
      const apiURL = "https://conversation-dojo.herokuapp.com/getEmpathicResponse?input=" + value;
      return this.fetchAPI(apiURL);
   }

   /**
    * @function
    * @summary
    * User Input given in the textbox to be send to the API
    * @param value the UserInput given
    * @param memes Whether to display meme messages
    * @returns null if no response is recieved.
    */

   callApiWrapper = (value) => {
      //prepare data into an object to be passed to axios
      this.setState({ userFeedback: {} });
      if (value.length !== 0) {
         var data2;
         data2 = {
            "data": value,
            db_flag: config.DB_FLAG

         }
         if (this.state.urlParameters) {
            data2 = {
               ...data2,
               ...this.state.urlParameters

            }
         }
         // const callAPI = firebase.functions().httpsCallable('callAPI')
         //return this.handleAPICall(qs.stringify(data2.data))
         return this.handleAPICall(data2.data)
      }
      else
         toast.info("No Input data")
   }

   /**
    * @function
    * @summary
    * Actual call to the API
    */
   getFeedbackForUserInput = (value, memes) => {
      //console.log('inside getFeedbackForUserInput')
      if (value.length !== 0) {
         this.setState({ memes });
         var user_input = value;
         this.props.executeLoadingScreen(true);
         //this.getDataFromAPI(user_input)
         console.log(user_input)
         this.callApiWrapper(user_input)
            .then(response2 => {
               var response = response2
               //console.log('response0', response)
               if (!this.state.requestCancelled && response != null) {
                  //console.log('response', response)
                  // this.setDataForPost(response);
                  // 🔥
                  document.getElementsByClassName("pt-auto mt-3 row")[0].innerHTML = "<i>" + response + "</i>"
                  this.props.setGotFeedback(true)
               }
            }).catch(err => {
               console.log(err)
               toast.error("Oops, there seems to be an issue, Please try later")
               this.setState({ requestCancelled: true })
            }).finally(() => {
               this.props.executeLoadingScreen(false);
            })

      }
   }

   /**
    * @function
    * @summary
    * When a userFeedback is given by Up/Down Arrow to update the userFeedback Response
    * @param response Responses given in the terms of Up/Down arrow or Added Optional Comments.
    */
   updateUserFeedback = (response) => {
      this.setState({
         disableSendFeedback: false,
         sendFeedbackButton: "Send Feedback",
         userFeedback: {
            ...this.state.userFeedback,
            [response.comment_id]: response,

         }
      })
   }


   /**
            * @function
            * @summary
            * When Send Feedback Button is pressed.
            * This functions structures the code to be sent to the API.
            * @property { } state.userFeedback Used to Structuring the Request
            * @property {array} userFeedbackList is used to send as data to the API.
            */
   sendUserFeedbackWrapper = () => {
      if (this.state.userFeedback.length === 0) {
         toast.warn(config.FEEDBACK_NOT_GIVEN_MSG);
         return;
      }



      var userFeedbackList = [];

      Object.values(this.state.userFeedback).forEach((value) => {
         var comment_id = value.comment_id;
         var user_feedback_value = value.agree ? "+1" : (value.disagree ? "-1" : "0");
         var user_feedback_str = value.disagreementReason;
         var s_str = value.s_str;
         var c = value.cid;
         var r_str = value.response_str;
         //store sentence index to be used in updateBotFeedbackList
         var s_i = value.sentenceIndex;
         //offset is to be used with removeThumbsDownFromBotFeedbackList


         //console.log('inside sendUserFeedbackWrapper', r_str)

         if (user_feedback_value !== "0") {
            userFeedbackList.push({ comment_id, user_feedback_value, user_feedback_str, r_str, s_i });
            this.props.recordUserFeedback({ comment_id, user_feedback_value, user_feedback_str, s_str, c, r_str, s_i })
         }
      }
      );
      if (userFeedbackList.length === 0) {
         toast.warn(config.FEEDBACK_NOT_GIVEN_MSG)
         return;
      }
      //userFeedbackList 
      this.props.recordUserFeedbackList(userFeedbackList, this.state.botFeedbackListLengthBeforeFeedback)
      toast.success(config.USER_FEEDBACK_THANK_YOU_MSG)
      //this.setState({disableSendFeedback: true, sendFeedbackButton: "Sending..." })
      //Feedback to be sent
      //console.log(userFeedbackList);
      // this.sendUserFeedbackToAPI(userFeedbackList);
   }

   /**
    * @function
    * @async
    * @param {{}} data to be send to API
            * @property {string} url Used from the {@link default_config} File.
            *
            * Executed from {@link sendUserFeedbackWrapper} with data
            * If sucess displays Success toast,
            * Else
            * Error toast something went wrong.
            */

   /*
   sendUserFeedbackToAPI = async (data) => {
    
     const url = config.CORS_URL + config.API_URL + config.USER_FEEDBACK_ENDPOINT;
            //prints the data to be sent
            //console.log(data);

            const outcome = await this.sendRequestToAPI(url, data);
            if (!this.state.requestCancelled) {
        if (outcome.data.SUCCESS && outcome.status === 200) {
               toast.success(config.USER_FEEDBACK_THANK_YOU_MSG)
           this.setState({sendFeedbackButton: "Thanks!" });
        }
            else {
               this.setState({ disableSendFeedback: false, sendFeedbackButton: "Send Your Feedback" });
            toast.error("Ops, Something went wrong.")
        }
     }
            else {
               this.setState({ disableSendFeedback: false, sendFeedbackButton: "Send Your Feedback" });
            toast.error("Ops, Something went wrong.")
     }
    
   }
            */


   /**
    * @function
    * @async
    * @param value the UserInput send to the API
    * Executed from {@link getFeedbackForUserInput}
            * Used to structure the request so that the API accepts
            * @property {*} config Used to collect the {@link default_config} File.
            * @property {string} config.CORS_URL The cors url reference.
            * @property {string} config.API_URL The api url reference.
            * @property {string} config.FEEDBACK_ENDPOINT The endpoint to send to the API.
            * @returns {Promise < {} >} The data from calling the URL.
            * @summary
            * This function get the response from the API by calling {@link sendRequestAPI}
            */

   /*
      getDataFromAPI = async (value) => {
         try {
               this.setState({ requestCancelled: false, userFeedback: {} });
            if (value.length !== 0) {
               this.props.executeLoadingScreen(true);
            // set the url
            //const url = config.CORS_URL + config.API_URL + config.FEEDBACK_ENDPOINT;
            // request data object
            var data;
            data = {
               "data": value,
            db_flag: config.DB_FLAG
               }
            if (this.state.urlParameters) {
               data = {
                  ...data,
                  ...this.state.urlParameters

               }
            }
               //prints the user_input ready for post
            // console.log(data);
            // getting the response in res.

            const res = await this.sendRequestToAPI(url, qs.stringify(data));

            //prints the response as raw data
            // console.log(res);
            if (!this.state.requestCancelled) {
                  if (res.data.error) {
               this.setState({ requestCancelled: true });
            //prints the error message if the api throws an error on purpose.
            console.error(res.data.error)
            toast.error("Ops, there seems to be an issue, Please try later")
                  }
            //prints the response data from the API
            //console.log('res.data', res.data);
            return res.data;
               }
            }
            else
            toast.info("No Input data")
    
         } catch (err) {
               this.setState({ requestCancelled: true });
            console.error(err);
         }
            finally {
               this.props.executeLoadingScreen(false);
         }
      }
            */

   /**
    * @function
    * @async
    * @param {string} url Used to determine the api & its endpoint
            * @param { } data The data to be sent to API
            *
            * @summary
            * Used by Both {@link sendUserFeedbackToAPI} & {@link getDataFromAPI}
            * This functions send the recieve response from the API.
            * @returns Output from the URL.
            */
   sendRequestToAPI = async (url, data) => {

      this.setState({ source: axios.CancelToken.source() });

      //initiates wait function if the request take too long.
      await this.wait(20000);
      //console.log('data inside Feedback', data)
      return axios.put(url, data, { cancelToken: this.state.source.token })
         .catch(thrown => {
            if (axios.isCancel(thrown)) {
               console.error(thrown.message);
               toast.warn("Request Cancelled due to taking too long.")
            } else {
               // handle error
               console.error();
               toast.error("Ops, there seems to be an issue, Please try later")
            }
            this.setState({ requestCancelled: true })

         });
   }


   /**
    * @function
    * @param {*} response Response received from {@link getFeedbackForUserInput}
            * @summary sets relevant response objects to the relevant variable to prepare for user feedback and also to record attempt via recordUserAttempt
            *
            */
   setDataForPost = async (response) => {
      //outputResults contains the message at index 0, and the remeaining the feedback from the api.
      const outputResults = [];
      var message_id, message, end_response_str, image_name_list, image_url;
      //var c, c_s, cp, cp_s

      const s = []


      Object.keys(response).forEach(function (key) {
         //put sentence outputs into a list called outputResults
         //also create a list of sentence objects to store for firestore (more shallow than API output because firestore can't handle the degree of nestedness of API output)
         if (key.startsWith("sentence")) {
            outputResults.push(response[key]);
            let current_obj = { r: [], c: [], id: [], r_i: [] }
            if ('s_str' in response[key])
               current_obj.str = (response[key].s_str)

            if ('r_l' in response[key]) {
               response[key].r_l.forEach(function (response_obj) {
                  current_obj.r.push(response_obj.r_str)
                  current_obj.c.push(response_obj.c)
                  current_obj.id.push(response_obj.id)
                  current_obj.r_i.push(JSON.stringify(response_obj.r_i))
               })
            }

            if ('phr' in response[key])
               current_obj.phr = response[key].phr
            s.push(current_obj)
         }
         //the below, c_s, cp, cp_s was for AWS sql user feedback and no longer used
         else if (key === "message")
            message = response[key];
         else if (key === "message_id")
            message_id = response[key];
         else if (key === "end_response_str")
            end_response_str = response[key];
         else if (key === "image_name_list")
            image_name_list = response[key];
         else if (key === "url_for_images")
            image_url = response[key];
      });



      //check if noFeedback is false, then don't display default no feedback response
      var noFeedback = true;
      outputResults.forEach(value => {
         if (value.r_l.length > 0) {
            return noFeedback = false;
         }
      });

      this.setState({
         message: message,
         sentencesOutput: outputResults,
         message_id: message_id,
         APIfeedback: true,
         feedback: true,
         loadingScreen: false,
         disableSendFeedback: false,
         noFeedback: noFeedback,
         NoFeedbackResponse: end_response_str,
         image_url: image_url,
         image_name_list: image_name_list,
         sendFeedbackButton: config.SUBMIT_FEEDBACK_TEXT,
         RAW_API_OUTPUT: response
      }, () => {
         //Change this function either to **generateDisplayMessage** for sub string highlight
         // Change this fucntion either to **generateDisplayMessage2** for word highlight. However, beware of changes in the API output.
         this.generateDisplayMessage2();
         this.props.recordBotFeedback(outputResults, this.props.user_input)
      });


      console.log('attemptPath', this.props.attemptPath)
      console.log('s', s)
      if (this.recordUserAttempt && this.props.recordAttempt) {
         this.recordUserAttempt({
            attempt: {
               g: this.props.groups,
               d: this.props.direction,
               tg: this.props.tags,
               oldM: this.props.message_to_translate, //this is for Challenges where a specific sentence/message is being translated
               m: message,
               t: this.props.type,
               sess_id: this.props.sessionResponse,
               s
               //c, c_s, cp, cp_s, 

            },
            collectionPath: this.props.attemptPath
         })
      }


      if (this.props.usageRecordsPath) {
         this.recordUserBotCall = (this.props.usageRecordsPath, this.props.usageId)
      }
   }

   /**
    * @function
    * @async
    * @summary
    * Wait function used by {@link sendRequestToAPI}
            */
   wait = (ms) => {
      setTimeout(() => {
         this.state.source.cancel('Request canceled due to timeout.');
      }, ms);
   }

}

const mapStateToProps = (state) => {
   return {

      auth: state.firebase.auth,
      usageId: state.bot.usageId, //usageId for thinkific users to be stored in usageRecords,
      usageRecordsPath: state.bot.usageRecordsPath, //path for recording usageRecords, e.g., thinkificUsers/courses/difficultConversationsTaster
      userFeedbackList: state.bot.userFeedbackList,
      botFeedbackListLength: state.bot.botFeedbackListLength
   }
}



const mapDispatchToProps = (dispatch) => {
   return {
      recordUserFeedback: (userFeedback) => dispatch(recordUserFeedback(userFeedback)),
      recordBotFeedback: (outputResults, userInput) => dispatch({ type: 'RECORD_GET_FEEDBACK', botFeedback: outputResults, userInput: userInput }),
      recordUserFeedbackList: (userFeedbackList, currentOffset) => dispatch({ type: 'RECORD_USER_FEEDBACK_LIST', userFeedbackList, currentOffset })
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)


