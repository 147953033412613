import React from "react";
import { Nav, Navbar, NavDropdown, Button } from 'react-bootstrap';
import './Navbar.css'

const SignedOutLinks = () => {
    return (

        <Nav className="container-fluid ">


            {/** 
            <Nav.Item className="mr-md-0 mr-auto">
                <Nav.Link href="https://www.emotionalhealthessentials.com/training"><Navbar.Text><span className='linkstyle'>Training</span></Navbar.Text></Nav.Link>
            </Nav.Item>
   */}

            <Nav.Item className="mr-md-0 mr-auto">
                <NavDropdown title={<Navbar.Text><span className='linkstyle'>Workshops</span></Navbar.Text>} id="nav-dropdown1" >
                    <Nav.Item className="mr-md-0 mr-auto">
                        <Nav.Link href="https://www.emotionalhealthessentials.com/workshop"><Navbar.Text><span className='linkstyle'>In-House Workshop</span></Navbar.Text></Nav.Link>

                        <Nav.Link href="https://www.emotionalhealthessentials.com/publicworkshop"><Navbar.Text><span className='linkstyle'>Public Workshop</span></Navbar.Text></Nav.Link>
                        <Nav.Link href="https://www.emotionalhealthessentials.com/i-human"><Navbar.Text><span className='linkstyle'>I-HUMAN</span></Navbar.Text></Nav.Link>

                    </Nav.Item>
                </NavDropdown>
            </Nav.Item>

            <Nav.Item className="mr-md-0 mr-auto">
                <Nav.Link href="https://www.emotionalhealthessentials.com/licensing"><Navbar.Text><span className='linkstyle'>Licenses</span></Navbar.Text></Nav.Link>
            </Nav.Item>
            <Nav.Item className="mr-md-0 mr-auto">
                <Nav.Link href="https://www.emotionalhealthessentials.com//technology"><Navbar.Text><span className='linkstyle'>AI tools</span></Navbar.Text></Nav.Link>
            </Nav.Item>
            <Nav.Item className="mr-md-0 mr-auto">
                <NavDropdown title={<Navbar.Text><span className='linkstyle'>Resources</span></Navbar.Text>} id="nav-dropdown1" >
                    <NavDropdown.Item href="https://www.emotionalhealthessentials.com/casestudies">Case Studies</NavDropdown.Item>
                    <NavDropdown.Item href="https://www.emotionalhealthessentials.com/articles">Articles</NavDropdown.Item>



                    <NavDropdown.Item href="https://www.emotionalhealthessentials.com/science">The Science</NavDropdown.Item>

                </NavDropdown>
            </Nav.Item>

            <Nav.Item className="mr-md-0 mr-auto">
                <Nav.Link href="https://www.emotionalhealthessentials.com/about"><Navbar.Text><span className='linkstyle'>About</span></Navbar.Text></Nav.Link>
            </Nav.Item>
            {/**   <Nav.Item className="mr-md-0 mr-auto">
                <Nav.Link href="https://www.emotionalhealthessentials.com/events"><Navbar.Text><span className='linkstyle'>Events</span></Navbar.Text></Nav.Link>
            </Nav.Item>
           
    
            <Nav.Item className="mr-md-0 mr-auto">
                <Nav.Link href="/tools"><Navbar.Text><span className='linkstyle'>Tools</span></Navbar.Text></Nav.Link>
            </Nav.Item>
            <Nav.Item className="mr-md-0 mr-auto">
                <Nav.Link href="https://www.emotionalhealthessentials.com/subscribe"><Navbar.Text><span className='linkstyle'>Subscribe</span></Navbar.Text></Nav.Link>
            </Nav.Item>
 */}
            <Nav.Item className="ml-md-auto mr-md-0 mr-auto">
                <Nav.Link href="/signin"><Navbar.Text><span className='linkstyle'>Sign in</span></Navbar.Text></Nav.Link>
            </Nav.Item>
            <Nav.Item className="ml-md-0 mr-md-0 mr-auto">
                <Nav.Link href="https://www.emotionalhealthessentials.com/set_up_demo"><Navbar.Text><span className='linkstyle'><Button className="btn-blue2 btn-rounded">Set Up a Demo</Button></span></Navbar.Text></Nav.Link>
            </Nav.Item>




        </Nav >

    );
};

export default SignedOutLinks;
