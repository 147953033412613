import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Container, Button, Col, Row, Tab, Nav } from 'react-bootstrap'
import TooltipCustom from "../../Utilities/TooltipCustom"
import MakeChoicesAndNotes from '../forms/MakeChoicesAndNotes'
import LogDetailCard from '../logs/LogDetailCard'
import '../logs/LogFeelingsAndNeeds.css'

import SelfReflectionImage from '../../files/SelfReflection.jpg'
//HigherOrderStep takes props of step and currentStep and only shows value if step === currentStep

const instructionColorDark = '#213378'
const instructionColorLight = '#f58437'
const logTypeList = ['NegF_', 'NegN_', 'PosF_', 'PosN_']

export class LogFeelingsAndvaluesPublic extends Component {

    constructor(props) {
        super()
        this.logRef = React.createRef()
        this.state = {
            currentStep: props.currentStep || 1,

            log: null,
            logGenerated: false

        }
        this.baseState = this.state

        this.sessionVarName = 'LogFeelingsAndvalues' + props.location.pathname
        this.localData = sessionStorage.getItem([this.sessionVarName])
        this.localDataObj = this.localData ? JSON.parse(this.localData) : null
        //console.log(this.localDataObj)

        //if there is a localDataObj, initiate state with that
        if (this.localDataObj)
            Object.keys(this.localDataObj).forEach((key) => {
                //only put into state from storage if it is a variable starting with prefix in logTypeList
                //otherwise variables like createdAt start being loaded in without their supportedhods and this causes crash
                if (logTypeList.includes(key.slice(0, 5)))
                    this.state[key] = this.localDataObj[key]

            })
        //if there is a log prop passed through, initialise state with that.
        else if (props.log)
            Object.keys(props.log).forEach((key) => { this.state[key] = props.log[key] })
    }


    static defaultProps = {
        callbackFunc: (obj) => { console.log(obj) },
        returnURL: '/'
    }


    setActiveStep = (event) => {
        const { id } = event.target;
        if (id === this.state.activeStep)
            this.setState({ activeStep: null })
        else
            this.setState({ activeStep: id })
    }






    handleChange(event) {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    // Save log
    generateLog = (e) => {

        //callbackFunc is action creator defined in logActions.js
        console.log(this.props)
        let log = Object.assign({}, this.state);

        //remove state keys that don't have logTypeList prefix  
        Object.keys(log).forEach((key) => {
            if (!logTypeList.includes(key.slice(0, 5))) {
                console.log('key', key.slice(0, 5))
                delete log[key]
            }
        })

        console.log('log:', log, 'history:', this.props.history)
        //this.props.callbackFunc(log)
        this.setState({
            log,
            logGenerated: true
        })




    }




    deleteAll = () => {
        sessionStorage.clear()
        Object.keys(this.state).forEach(
            (key) => {
                if (key.startsWith('NegN') || key.startsWith('PosN') || key.startsWith('PosF') || key.startsWith('NegF'))
                    this.setState({ [key]: [] })
            }

        )
        window.location.reload(false)
        console.log('inside deleteAll')

    }

    updateStateAndStorage = (obj) => {
        console.log('updateStateAndStorage', this.sessionVarName, 'obj', obj)
        this.setState(obj, () => { sessionStorage.setItem([this.sessionVarName], JSON.stringify(this.state)) })
    }


    render() {




        return (
            <>


                <Tab.Container id="left-tabs-example" defaultActiveKey="intro">
                    <Container>
                        <Row>
                            <Col className="mt-5 text-center ">
                                <h1><b>Reflect on Emotions and Values</b></h1>
                            </Col></Row><Row><Col className="">
                                <h5>Note: Your entries are only being stored in your local cache, and not being sent over the internet. Please use the "Clear All" button on the right or close this tab to refresh to a clean page.</h5>

                            </Col>
                        </Row>
                        <Row><Col className="mb-5 text-right">
                            <Button style={{ background: '#FF8C00', border: 'none' }} onClick={this.deleteAll} >Clear All</Button>
                        </Col>
                        </Row>

                    </Container>
                    <Row>

                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column" onSelect={this.generateLog}>
                                <Nav.Item>
                                    <Nav.Link eventKey="intro"><h5>Intro</h5></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="negativeEmotions"><h5>Negative Emotions</h5></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="unsupportedValuesAndNeeds"><h5>Unsupported Values & Needs</h5></Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="positiveEmotions"><h5>Positive Emotions</h5></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="supportedValuesAndNeeds"><h5>Supported Values & Needs</h5></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="summary" ><h5>Summary</h5></Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="intro">
                                    <Container>
                                        <Row>
                                            <Col className="text-center">
                                                <h1>Introduction</h1>
                                            </Col>
                                        </Row>

                                        <Row >
                                            <Col className="text-center mb-5"><img src={SelfReflectionImage} width={'60%'} alt={''} /></Col>

                                        </Row>
                                        <Row><Col className="mb-3">
                                            <h5>
                                                This log helps you reflect on your emotions, needs and values.
                                            </h5> </Col></Row>
                                        <Row><Col>
                                            <h5>

                                                <ul>
                                                    <li>
                                                        Identifying negative emotions can reduce their intensity.                                                   </li>
                                                    <li>
                                                        Identifying the values and needs that were unsupported can help you process negative events.
                                                    </li>
                                                    <li>
                                                        Identifying positive emotions can help you savor and be grateful for positive experiences.
                                                    </li>
                                                    <li>
                                                        Identifying the values and needs that were supported can help you understand why you feel good and help you do more of what is working.
                                                    </li>
                                                </ul>
                                                At the bottom of each page, you can also make notes to help you reflect on the circumstances that caused these feelings and how to get your values and needs supported.
                                            </h5>
                                        </Col></Row>
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="negativeEmotions">

                                    <MakeChoicesAndNotes
                                        step={1}
                                        formTitle='Negative Emotions'
                                        formText="Identifying negative emotions can reduce their intensity."
                                        notesTooltip='Here you can add notes, e.g., about what happened that led to these emotions. Use the dropdown menu accompanying each note to choose the emotions your note corresponds to.'
                                        tooltipColor={'grey'}
                                        titleColor='black'
                                        currentStep={this.state.currentStep}
                                        dropdownPlaceholder='Associated Feelings'
                                        textPlaceholder='Write notes here'
                                        stateNameBase='NegF_'
                                        checkboxFilename='feelings'
                                        valuesFromParent={this.state}
                                        callbackFunc={this.updateStateAndStorage}
                                    />

                                </Tab.Pane>
                                <Tab.Pane eventKey="unsupportedValuesAndNeeds">

                                    <MakeChoicesAndNotes
                                        step={2}
                                        formTitle='Unsupported Values & Needs'
                                        formText='Identify the unsupported values and needs that gave rise to your negative emotions. '
                                        notesTooltip='Add notes for possible strategies for meeting your values more. You can also choose which values each strategy refers to from the dropdown menu.'
                                        tooltipColor={'grey'}
                                        titleColor='black'
                                        currentStep={this.state.currentStep}
                                        dropdownPlaceholder='Associated values'
                                        textPlaceholder='Write strategy here'
                                        stateNameBase='NegN_'
                                        checkboxFilename='needs'
                                        addNoteButtonText='Another Note'
                                        valuesFromParent={this.state}
                                        callbackFunc={this.updateStateAndStorage}
                                    />

                                </Tab.Pane>


                                <Tab.Pane eventKey="positiveEmotions">

                                    <MakeChoicesAndNotes
                                        step={1}
                                        formTitle='Positive Emotions'
                                        formText="Identifying positive emotions can enhance these emotions and promote appreciation and gratitiude."
                                        notesTooltip='Here you can add notes about what happened that led to these emotions. Use the dropdown menu accompanying each note to choose the emotions your note corresponds to.'
                                        tooltipColor={'grey'}
                                        titleColor='black'
                                        currentStep={this.state.currentStep}
                                        dropdownPlaceholder='Associated Feelings'
                                        textPlaceholder='Write notes here'
                                        stateNameBase='PosF_'
                                        checkboxFilename='feelingsPositive'
                                        valuesFromParent={this.state}
                                        callbackFunc={this.updateStateAndStorage}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="supportedValuesAndNeeds">


                                    <MakeChoicesAndNotes
                                        step={2}
                                        formTitle='Supported Values & Needs'
                                        formText='Identifying your supported values and needs can help you recognise what is working well in your life so you can keep it up and do it more.'
                                        notesTooltip='Add notes for strategies you used to meet your values. You can also choose which values each strategy refers to from the dropdown menu. '
                                        tooltipColor={'grey'}
                                        titleColor='black'
                                        currentStep={this.state.currentStep}
                                        dropdownPlaceholder='Associated values'
                                        textPlaceholder='Write strategy here'
                                        stateNameBase='PosN_'
                                        checkboxFilename='needs'
                                        addNoteButtonText='Another Note'
                                        valuesFromParent={this.state}
                                        callbackFunc={this.updateStateAndStorage}
                                    />

                                </Tab.Pane>

                                <Tab.Pane eventKey="summary">
                                    <h2><b>Summary</b></h2>
                                    {/** 
                                    <Button
                                        className="btn float-center btn-pink"
                                        type="button" onClick={this.generateLog}>
                                        {this.state.logGenerated ? <span>Update Summary </span> : <span> Generate Summary</span>}
                                    </Button>
                                    */}
                                    <Container className='mt-5'>
                                        {
                                            this.state.logGenerated ?
                                                <LogDetailCard log={this.state.log} isPublic={true} /> : null
                                        }
                                    </Container >
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                    <Container>
                        <Row>
                            <Col xs={{ span: 12, order: 0, offset: 0 }} className="my-5">
                                <span onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className="ml-0 pl-0 link" style={{ border: 'none' }}> Back to top</span>

                            </Col>
                        </Row>
                        <Row><Col className="my-5">
                        </Col></Row>
                    </Container>

                </Tab.Container>




            </>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        isAdmin: state.auth.isAdmin, //whether current user is admin
        profile: state.firebase.profile,
    }
}


export default connect(mapStateToProps)(withRouter(LogFeelingsAndvaluesPublic))

