//an action creator, originally this means it is a function that returns an action object
//now to use with thunk, this function does not return an action. Instead it
// returns a function that makes asynchronous call (pausing the dispatch) and then carry with dispatch afterwards
//log is just a variable/argument below. We will be passing a log object into createLog

export const createLog = (log) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //make async call to database (writing 'log' is  shorthand for 'log: log' )
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid
        const docLocation = firestore.collection('users').doc(authorId).collection('logs')

        docLocation.add({ //if logs does not exist, it will create a new collection
            ...log, //this is like putting title: log.title and content: log.summary
            authorId: authorId,
            createdAt: new Date(),
            //new fields get automatically added. I just needed to refresh browser for change to take place

        }).then(() => {
            dispatch({ type: 'CREATE_LOG', log })

        }).catch((error) => {
            dispatch({ type: 'CREATE_LOG_ERROR', log })
        })
    }

}

export const deleteLog = (id, log, auth) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        if (log.authorId === auth.uid) {
            //make async call to database (writing 'log' is  shorthand for 'log: log' )
            const firestore = getFirestore();
            const docLocation = firestore.collection('users').doc(auth.uid).collection('logs')
            docLocation.doc(id).delete(
            ).then(() => {
                dispatch({ type: 'DELETE_LOG', log })
            }).catch((error) => {
                dispatch({ type: 'DELETE_LOG_ERROR', error })
            })
        }
        else {
            console.log('you are not the right user')
            return null
        }

    }
}

export const editLog = (id, log, auth) => {
    //double checking that author is the logged in user. But if I go directly to edit/id title is undefined
    //because no log will have been passed in through so it's impossible for the EditLog.js page to load with the 
    //button to call teh editLog function anyway. So I don't need the check. XXX
    console.log('id', log.authorId, auth.uid)
    if (log.authorId === auth.uid) {

        return (dispatch, getState, { getFirebase, getFirestore }) => {
            //make async call to database (writing 'log' is  shorthand for 'log: log' )
            const firestore = getFirestore();
            const docLocation = firestore.collection('users').doc(auth.uid).collection('logs')

            docLocation.doc(id).update({
                ...log,
            }).then(() => {
                dispatch({ type: 'EDIT_LOG', log })
            }).catch((error) => {
                dispatch({ type: 'EDIT_LOG_ERROR', error })
            })
        }
    }
    else {
        console.log('you are not the right user')
        return null
    }


}
