import React from 'react'
import DeleteScenarioButton from './DeleteScenarioButton'
import EditScenarioButton from './EditScenarioButton'
import { Card } from 'react-bootstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'

function ScenarioDetailCard({ scenario, id, returnURL = '/' }) {


    return (

        <Card >
            <Card.Body>
                <Card.Title as="div" style={{ color: "black " }}><h3>{scenario.title}</h3></Card.Title>
                <Card.Text as="div" style={{ color: "black " }} >
                    <div>{scenario.summary}</div>
                    <p></p><p></p>
                    {/*<div>Posted by {scenario.authorUsername} </div>*/}
                    <p></p><p></p>

                    {(scenario.observation || scenario.aspiration || scenario.strategies || scenario.request || scenario.acknowledge) ?
                        <>
                            <div><b>Observation:</b> {scenario.observation}</div><p></p>
                            <div><b>Acknowledgement:</b> {scenario.acknowledge}</div><p></p>
                            <div><b>Aspiration:</b> {scenario.aspiration}</div><p></p>
                            <div><b>Strategies:</b> {scenario.strategies}</div><p></p>
                            <div><b>Request:</b> {scenario.request}</div><p></p><p></p></>
                        : null
                    }

                    <div>
                        <b>Final Message:</b>
                        <div> {scenario.finalMessage} </div>
                    </div>

                    {(scenario.pseudoFeelings.length || scenario.ownFeelings.length || scenario.ownNeeds.length) ?
                        <>
                            <p></p><p></p>
                            <div><b>Interpretations, Feelings, and Needs</b></div>
                            <div>Own Interpretations: <i>{scenario.pseudoFeelings.toString().replace(/,/g, ', ')} </i></div>
                            <div>Own Feelings: <i>{scenario.ownFeelings.toString().replace(/,/g, ', ')}</i> </div>
                            <div>Own Needs: <i>{scenario.ownNeeds.toString().replace(/,/g, ', ')} </i></div>
                            <p></p>
                        </> : null}

                    {(scenario.othersFeelings.length || scenario.othersNeeds.length) ?
                        <>
                            <div>Other&apos;s Feelings: <i>{scenario.othersFeelings.toString().replace(/,/g, ', ')} </i></div>
                            <div>Other&apos;s Needs: <i>{scenario.othersNeeds.toString().replace(/,/g, ', ')} </i></div>
                        </> : null}



                    <p></p><p></p>
                    <div className="className grey-text">{moment(scenario.createdAt.toDate()).calendar()}</div>
                </Card.Text>
                <p></p><p></p>
                <Card.Link as={Link} to={returnURL} className="float-left">Back</Card.Link>
                <Card.Link as={Link} to="#" className="float-right"><DeleteScenarioButton scenario={scenario} id={id} returnURL={returnURL} /></Card.Link>
                <Card.Link as={Link} to="#" className="float-right"><EditScenarioButton scenario={scenario} id={id} returnURL={returnURL} /></Card.Link>

            </Card.Body>
        </Card >

    )
}

export default ScenarioDetailCard
