import React, { useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize';
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Sempi from '../../files/Sempi.png'
import { HashLink } from 'react-router-hash-link';

const r = Math.floor(Math.random() * 90000) + 10000;;

function MessageHelperFront() {

    const [input, setInput] = useState('')




    return (
        <>
            <Container>
                <Row><Col className="my-5"></Col></Row>
                <Row><Col className="mt-5 ">
                    <h1 className="text-center"><b>Try Our Free Message Helper</b><img src={Sempi} width="50" alt={'Sempi'} className='ml-2 mb-2' /></h1>
                    <h5>
                        What would you say when giving feedback or in a difficult conversation? Message Helper alerts you to language that may trigger a defensive response. <HashLink to="/#neuroscienceVideo">Learn more</HashLink>

                    </h5>
                </Col></Row>
                <Row><Col className="my-5 mx-5">
                    <TextareaAutosize
                        className={"form-control z-depth-1"}
                        minRows={3}
                        placeholder={'Write your message here. NOTE: You will be redirected to the Message Helper page.'}
                        value={input}
                        maxLength={1000}
                        onChange={e => setInput(e.target.value)} />

                </Col></Row>
                <Row><Col className="text-right">
                    <Link
                        as="div"
                        to={{
                            pathname: '/messagehelper',
                            search: '?response=' + r.toString(),
                            state: {

                                getFeedbackOnLoad: true,
                                sessionResponse: r.toString(),
                                input
                            }
                        }}
                    >

                        <Button disabled={input.length === 0} className={"btn-blue btn-medium mb-3 float-right mt-2"} value=""> Get Feedback </Button>
                    </Link>
                </Col></Row>
            </Container>
        </>
    )
}

export default MessageHelperFront
