import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { redirect } from 'react-router-dom'
import SubscribeFormHome from './SubscribeFormHome'

function Tools() {


    return (

        <div >

            <Container fluid className="min-vh-100 d-flex flex-column" >

                <Container>

                    <Row>
                        <Col className="mt-5" lg={{ span: 8, offset: 0 }} sm={{ span: 12, offset: 0 }} >
                            Watch this space for upcoming tools...
                        </Col>
                        <Col className="my-5" lg={{ span: 4, offset: 0 }} sm={{ span: 12, offset: 0 }} >
                            <SubscribeFormHome />


                        </Col>
                    </Row>
                </Container>
            </Container >

            <Container className="min-vh-100 d-flex flex-column">



                <Row >
                    <Col className="my-5">

                    </Col>
                </Row>

            </Container >

        </div >


    )
}

export default Tools
