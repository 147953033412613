import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router";



export class EditChallengeButton extends Component {
    state = {

    }


    handleEdit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        console.log(this.props)
        this.props.history.push({
            pathname: '/editchallenge/' + this.props.id,
            state: {
                ...this.props.challenge,
                id: this.props.id,
                prevPath: this.props.location.pathname
            }
        })

    }

    render() {

        const { challenge, auth, isAdmin } = this.props;

        if ((challenge.authorId === auth.uid) || (isAdmin)) {
            return (
                <span className="card-action" onClick={this.handleEdit}>Edit</span>


            )
        } else { return (null) }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        isAdmin: state.auth.isAdmin
    }
}



export default connect(mapStateToProps)(withRouter(EditChallengeButton))
