//an action creator, originally this means it is a function that returns an action object
//now to use with thunk, this function does not return an action. Instead it
// returns a function that makes asynchronous call (pausing the dispatch) and then carry with dispatch afterwards
//scenario is just a variable/argument below. We will be passing a scenario object into createScenario

export const createScenario = (scenario) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //make async call to database (writing 'scenario' is  shorthand for 'scenario: scenario' )
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid
        const docLocation = scenario.isPublic ? firestore.collection('publicScenarios') : firestore.collection('users').doc(authorId).collection('scenarios')

        docLocation.add({ //if scenarios does not exist, it will create a new collection
            ...scenario, //this is like putting title: scenario.title and content: scenario.summary
            authorId: authorId,
            createdAt: new Date(),
            //new fields get automatically added. I just needed to refresh browser for change to take place

        }).then(() => {
            scenario.isPublic ? dispatch({ type: 'CREATE_SCENARIO', scenario }) : dispatch({ type: 'CREATE_PRIVATE_SCENARIO', scenario })
            if (scenario.parentDoc) {
                var userprofiledoc = firestore.collection('users').doc(authorId);
                return userprofiledoc.update({
                    scenarioParents: firestore.FieldValue.arrayUnion(scenario.parentDoc)
                })

                    .then(function () {
                        console.log("List of parentDocs successfully updated!");
                    })
                    .catch(function (error) {
                        // The document probably doesn't exist.
                        console.error("Error updating list of parentDocs: ", error);
                    });

            }


        }).catch((error) => {
            scenario.isPublic ? dispatch({ type: 'CREATE_SCENARIO_ERROR', scenario }) : dispatch({ type: 'CREATE_PRIVATE_SCENARIO_ERROR', scenario })
        })
    }

}

export const deleteScenario = (id, scenario, auth, isAdmin) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        if ((scenario.authorId === auth.uid) || (isAdmin && scenario.isPublic)) {
            //make async call to database (writing 'scenario' is  shorthand for 'scenario: scenario' )
            const firestore = getFirestore();
            const docLocation = scenario.isPublic ? firestore.collection('scenarios') : firestore.collection('users').doc(auth.uid).collection('scenarios')
            docLocation.doc(id).delete(
            ).then(() => {
                dispatch({ type: 'DELETE_SCENARIO', scenario })
                if (scenario.parentDoc) {
                    var userprofiledoc = firestore.collection('users').doc(scenario.authorId);
                    return userprofiledoc.update({
                        scenarioParents: firestore.FieldValue.arrayRemove(scenario.parentDoc)
                    })

                        .then(function () {
                            console.log("List of scenarioParentDocs successfully updated!");
                        })
                        .catch(function (error) {
                            // The document probably doesn't exist.
                            console.error("Error updating list of scenarioParentDocs: ", error);
                        });

                }
            }).catch((error) => {
                dispatch({ type: 'DELETE_SCENARIO_ERROR', error })
            })
        }
        else {
            console.log('you are not the right user')
            return null
        }

    }
}

export const editScenario = (id, scenario, auth, isAdmin) => {
    //double checking that author is the logged in user. But if I go directly to edit/id title is undefined
    //because no scenario will have been passed in through so it's impossible for the EditScenario.js page to load with the 
    //button to call teh editScenario function anyway. So I don't need the check. XXX
    console.log('is admin inside scenarioActions ', isAdmin)
    if ((scenario.authorId === auth.uid) || (isAdmin && scenario.isPublic)) {

        return (dispatch, getState, { getFirebase, getFirestore }) => {
            //make async call to database (writing 'scenario' is  shorthand for 'scenario: scenario' )
            const firestore = getFirestore();
            const docLocation = scenario.isPublic ? firestore.collection('scenarios') : firestore.collection('users').doc(auth.uid).collection('scenarios')

            docLocation.doc(id).update({
                ...scenario,
            }).then(() => {
                dispatch({ type: 'EDIT_SCENARIO', scenario })
            }).catch((error) => {
                dispatch({ type: 'EDIT_SCENARIO_ERROR', error })
            })
        }
    }
    else {
        console.log('you are not the right user')
        return null
    }


}

//here the "PublicScenario" has a different meaning, it is an individual scenario activity for a public (non-logged-in) user.
export const submitPublicScenario = (scenario) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //make async call to database (writing 'scenario' is  shorthand for 'scenario: scenario' )
        const firestore = getFirestore();
        const groupName = scenario.groupName || 'general'
        const collectionName = scenario.parentDoc || 'generalscenario'

        const docLocation = firestore.collection('groups').doc(groupName).collection(collectionName)

        docLocation.add({ //if collectionName does not exist, it will create a new collection
            ...scenario, //this is like putting title: scenario.title and content: scenario.summary
            createdAt: new Date(),
            //new fields get automatically added. I just needed to refresh browser for change to take place

        }).then(() => {
            dispatch({ type: 'CREATE_SCENARIO', scenario })

        }).catch((error) => {
            dispatch({ type: 'CREATE_SCENARIO_ERROR', scenario })
        })
    }

}

