import React from "react";
import Highlighter from "react-highlight-words"

/**
 * @function
 * @summary
 * The Highlight Component Called in Feedback Class
 * @param {*} props Property Sent from Parent
 * @param {string} props.sentence The Sentence to Highlight
 * @param {*} props.attributes The sentenceOutput to be used to identify highlighting words.
 */
var comment_id = [];
var classWords = [];
const Highlight = (props) => {
   var sentence = props.sentence;
   var attributes = props.attributes;
   classWords = getWords(attributes)


   return (
      <Highlighter
         highlightClassName="messageAPIResponse text-danger"
         // highlightonMouseEnter={props.toggleHovered.bind(this,comment_id)} 
         // highlightonMouseLeave={props.toggleHovered.bind(this,comment_id)}
         searchWords={classWords}
         autoEscape={true}
         textToHighlight={sentence}
         className=""
      />
      // <>{getSentence(sentence)}</>

   )
}
export const HiglightWithoutSpace = (props) => {
   var feedbackOutputArray = props.attributes
   // var messageToHighlight = props.sentence

   var wordsBucket = [];
   var highlightedMsg = [];
   feedbackOutputArray.forEach(singleFeedback => {
      
      var theResponseArray = singleFeedback.sentence
      console.log(theResponseArray)
      singleFeedback.response_list.forEach(theResponse => {
         var workBuc = [];
         var theStartIndices = theResponse.response_indices[0]
         theResponse.response_indices.forEach((indices, index) => {
            
            wordsBucket.push(theResponseArray[indices]);
            workBuc.push(theResponseArray[indices]);
         
            if (index !== 0)
               theResponseArray[indices] = ""
            

         })
         if(typeof (theResponseArray[theStartIndices]) != "object"){
         theResponseArray[theStartIndices] = <mark className="text-danger">{workBuc.join(" ")}</mark>
         }

      })
      highlightedMsg = highlightedMsg.concat(theResponseArray);

   })

   console.log(highlightedMsg)
   return (highlightedMsg.map((element, index) => {
      console.log(typeof(element), {element}, typeof(element)==="string"?element.includes("/\n/g"):"")
      if (typeof(element)==="string"&& element==="\n")
         return <p key={index} />

      else if (element === "." || element === "!" || element === "?" || index === 0)
         return <span key={element + index}>{element}</span>
      else
         return <span key={element + index}> {element}</span>
   }))
}




/**
 * @function
 * @summary 
 * The function determines the highlighting highwords to highlight
 * @param {*} attributes The sentenceOutput to be used to identify highlighting words.
 */
const getWords = (attributes) => {
   var words = [];
   attributes.forEach(element => {
      const sentence = element.sentence;

      element.response_list.forEach(response => {
         const val = getWord(response.response_indices, sentence).join(" ");
         comment_id.push(response.comment_id)
         words.push(val);
      })
   })
   classWords = words;
   return words;
}
/**
 * @function
 * @summary
 * The method below gets the word from the sentence to highlight.
 * @param {array<int>} ind Indices of the position to highlight in the given sentence from {@link props.attributes}
 * @param {string} sent The single sentence on which to highlight.
 */
const getWord = (ind, sent) => {
   var value = ind.map(val => {
      return sent[val];
   })
   value = value.filter((val) => {
      return val !== "."
   })
   return value;
}
/**
 * @function
 * @summary
 * CURRENTLY NOT USED
 * The function used to Highlight word in each feedback.
 * @param {*} props 
 */
export const HighlightFeedback = (props) => {
   var highlightFeedbackComment = false;
   var sentence = props.sentence;
   var response_indices = props.indices;
   var response_str = props.response_str;
   if (highlightFeedbackComment)
      return (
         <Highlighter
            highlightClassName="commentHighlight text-danger"
            searchWords={getWord(response_indices, sentence)}
            textToHighlight={response_str}
            autoEscape={true}
            caseSensitive={true}
         />
      );
   else
      return (response_str)
}


export default Highlight;

