import React, { Component } from 'react'
import { feelingsNegativeArr, feelingsPositiveArr, needsArr } from './readCSV'
import { getNameColorIdStatename, createNotesList, createTags, createNameColorObj } from './formFunctions'

/**
 * ChoiceSummary class. Summarises choices made from different csvRead options above. Works with both headers or no headers
 */
export class ChoiceSummary extends Component {

    /**
     * @constructor
    * @param {Boolean} props.useHeaders whether or not to assume the first value in a row is a header. Otherwise csv should be a single row read (pseudofeelings or groups, tags)
    * @param {string} props.valuesFromParent.stateNameBase [props.stateNameBase + header_id] will be the name of variable in which checkbox choices for each header type will be chosen
    *if useHeaders = false, then stateNameBase will be the array that holds the values.
     * @param {Boolean} props.showHeaderTitle whether or not to show the header title above each section
     * @param {('feelings'|'feelingsPos'|'needs')} props.checkboxFilename,  whether to loads feelingsPos, feelingsNeg or needs into checkbox choices
     */

    constructor(props) {
        super(props);
        this.state = {
            headersList: null,
            valToColorObj: {}
        }
    }

    static defaultProps = {
        stateNameBase: '',
        checkboxFilename: '',
        useHeaders: false,
        showHeaderTitle: false,
        notesLabel: 'NOTES: ',
        className: '',
        showNotes: true
    }

    //cannot call the imported async result feelingsNegativeArr in Constructor because this.State is called immediately and maybe before readCSV promise is resolved
    ///and it is bad practice to have an asynch call inside constructor
    componentDidMount() {
        if (this.props.useHeaders) {
            var readCSVPromise
            switch (this.props.checkboxFilename) {
                case 'needs':
                    readCSVPromise = needsArr
                    break
                case 'feelings':
                    readCSVPromise = feelingsNegativeArr
                    break
                case 'feelingsPositive':
                    readCSVPromise = feelingsPositiveArr
                    break
                default:
                    readCSVPromise = needsArr
            }
            readCSVPromise.then(result => {
                this.initialiseHeaderListFrom2DArr(result)
                const valToColorObj = createNameColorObj(result);
                this.setState({ valToColorObj })

            }).catch((err) => console.log(err));
        }
    }


    initialiseHeaderListFrom2DArr = (result) => {
        //console.log('papa parse output', result.data)
        var data = result.data //for some reason calling result.forEach causes an error
        if (!data) {
            console.log("no CSV results found:", data)
        }
        else {
            //data should be a 2D array where each item is an array representing row from csv
            //We assume the first value of each inner array is the header to be used as choice buttons (general feeling categories)
            //This assumes PapaParse returns 2D array (even when reading a one row csv). Will error out if it returns single array.
            //creates a state [stateName] for list of checked items for that header
            //also fills in a state headersList, list of all headers (including baseColor ending)
            var headersList = []

            data.forEach(arr => {
                headersList.push(arr[0])
                //let { id } = getNameColorIdStatename(arr[0])
                //this.setState({ ['collapsibleState_' + id]: false })
            })
            this.setState({ headersList })
        }
    }






    createAllTags = () => {
        if (!this.props.useHeaders) return (createTags(this.props.valuesFromParent[this.props.stateNameBase]))
        else {
            //else it's a 2d array and there are variables in props of props[stateNameBase + header_id]
            if (this.state.headersList)

                return (this.state.headersList.map(header => {
                    const { headerLabel, stateName, baseColor } = getNameColorIdStatename(header, this.props.stateNameBase)
                    const arr = this.props.valuesFromParent[stateName]
                    const temp = createTags(arr, baseColor)

                    if (temp)
                        return (

                            <div key={'h' + stateName} >
                                {this.props.showHeaderTitle && <h5 style={{ color: baseColor }}>{headerLabel}: </h5>}
                                {temp}
                            </div >)
                    else return null
                }))
            else return null

        }

    }



    render() {
        const labels = this.createAllTags()
        const notesList = this.props.valuesFromParent[this.props.stateNameBase + 'notesList']
        const notes = createNotesList(notesList, null, null, null, true, this.state.valToColorObj)

        return (
            <>
                <div className={this.props.className}>

                    {labels}
                </div>
                {this.props.showNotes ?
                    <div className="mt-3">
                        <h5>{notes && notes.length > 0 ? <i>{this.props.notesLabel}</i> || null : null}</h5>
                        {notes}
                    </div> : null
                }
            </>
        )
    }
}

export default ChoiceSummary
