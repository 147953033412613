import React, { Component } from "react";
import { FaInfoCircle } from 'react-icons/fa';
import { IconContext } from "react-icons";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { DEFAULT_TOOLTIP_INFO } from "../config/default_config"
import "./Tooltip.css"



/**
 * Info button. Can take optional props.innerText which customises text to be whatever you want
 * Otherwise default text is DEFAULT_TOOLTIP_INFO from default_config
 * Also takes prop props.hide for whether or not the tooltip is hidden
 */

class TooltipCustom extends Component {


   renderTooltipFeedbackInfo = (innertext) => {
      return <Popover >
         <Popover.Content>{innertext}</Popover.Content>
      </Popover>;

   }
   static defaultProps = {
      color: "grey",
      className: "float-right",

   }

   render() {
      const innertext = this.props.innertext ? this.props.innertext : DEFAULT_TOOLTIP_INFO

      return (
         <span className={this.props.className} hidden={this.props.hide}>
            <IconContext.Provider value={{ color: this.props.color }} >
               <OverlayTrigger
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltipFeedbackInfo(innertext)}
                  placement="bottom"


               >
                  <FaInfoCircle size="20px" />
               </OverlayTrigger>
            </IconContext.Provider>
         </span>
      )
   }
}
export default TooltipCustom;