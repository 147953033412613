import React, { useEffect } from 'react'

function SubscribeFormHomeLong({ callbackfunc = () => { } }) {

    useEffect(() => {
        // Update the document title using the browser API
        callbackfunc(true)
    });
    return (
        <div className="ml-form-embed"
            data-account="2951050:b7x1g4e3m2"
            data-form="3878884:h4s9l9">
        </div>
    )
}

export default SubscribeFormHomeLong
