import React, { Component } from 'react'
import { connect } from 'react-redux'
import ArticleForm from './ArticleForm'
import { editArticle } from '../../store/actions/articleActions'
import { Redirect } from 'react-router-dom'


//This creates JSX for the user to create a new article

class EditArticle extends Component {
    state = {
        //article passed through prop location's state parameter
        //as set in EditArticleButton: call: this.props.history.push
        ...this.props.location.state
    }

    static defaultProps = {
        returnURL: '/articles',
    }

    //need this to pass additional arguments to editArticle
    editArticleWrapper = (article) => {
        //article returned from Article component only has the article features
        //it no longer has the 'id' property. Even though when we pass the Article component location.state, it does include the id property, the id property isn't used.
        const auth = this.props.auth;
        //editArticle is action creator defined in articleActions.js
        console.log('article', article)
        console.log('current auth', auth)

        const id = this.props.location.state.id
        this.props.editArticle(id, article, auth)
    }

    render() {
        const { auth, location } = this.props;

        const article = location.state

        const returnURL = location.state.prevPath
        console.log(article)
        if (!auth.uid) return <Redirect to='/signin' />
        return (
            <div>
                <ArticleForm callbackFunc={this.editArticleWrapper} article={article} formTitle='Edit Article' returnURL={returnURL} />
            </div>
        )
    }
}




const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,

    }
}

//NOTE: editArticle is now a variable in props and its value is a function that dispatches
//the action-creator 'editArticle' function defined in articleActions.js, w
//editArticle is an action-creator which instead of returning an action object (as done when we did not need asynchronous calls)
//now returns a function that makes an asynch call and then calling dispatch.
//'article' below is just a variable in the arrow function
const mapDispatchToProps = (dispatch) => {
    return {
        editArticle: (id, article, auth) => dispatch(editArticle(id, article, auth)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EditArticle)