import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { createScenario } from '../../store/actions/scenarioActions'
import TextBox from "../../bot_components/TextBox"


class CheckMessage extends Component {
    constructor(props) {
        super(props);


        this.state = {
            tags: props.scenario.tags || [],
            isPublic: false,
            authByAdmin: props.scenario.authByAdmin || false,
            title: props.scenario.title || '',
            summary: props.scenario.summary || '',
            judgment: props.scenario.judgment || '',
            observation: props.scenario.observation || '',
            acknowledge: props.scenario.acknowledge || '',
            pseudoFeelings: props.scenario.pseudoFeelings || [],
            ownFeelings: props.scenario.ownFeelings || [],
            ownNeeds: props.scenario.ownNeeds || [],
            othersFeelings: props.scenario.othersFeelings || [],
            othersNeeds: props.scenario.othersNeeds || [],
            aspiration: props.scenario.aspiration || '',
            strategies: props.scenario.strategies || '',
            request: props.scenario.request || '',
            finalMessage: props.scenario.finalMessage || '',
        }
    }

    static defaultProps = {
        formTitle: '',
        scenario: {
            tags: [],
            isPublic: false,
            authByAdmin: false,
            title: '',
            summary: '',
            finalMessage: '',
        },

    }

    getUserInput = (userInput) => {
        this.setState({ finalMessage: userInput })

    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log('inside handle save scenario')
        this.props.createScenario(this.state)
        this.props.history.push('/myscenarios')
    }
    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to='/signin' />
        return (
            <Container>

                <Row><Col className="input-field text-right mt-5">
                    <Button type="submit" className="bg-whiteBlue" onClick={this.handleSubmit}>Save Message As Scenario</Button>
                </Col></Row>
                <Form>
                    <Form.Group controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" onChange={this.handleChange} value={this.state.title} placeholder={'Add title if you want to save message'} />
                    </Form.Group>
                </Form>




                <Row className='mt-5'>
                    <Col>
                        <div>

                            <TextBox prompt='Check your message here' allowUserFeedback={true} callbackFunc={this.getUserInput} />

                        </div>
                    </Col>
                </Row>


                <Row><Col style={{ marginBottom: "10rem" }}></Col></Row>
            </Container >
        )
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
    }
}



const mapDispatchToProps = (dispatch) => {
    return {
        createScenario: (scenario) => dispatch(createScenario(scenario)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckMessage))

