import React from 'react'
import { connect } from 'react-redux'
import ScenarioForm from './ScenarioForm'
import { createScenario } from '../../store/actions/scenarioActions'

function CreatePrivateScenario(props) {
    return (
        <div>
            <ScenarioForm callbackFunc={props.createScenario} formTitle='New Scenario' isPublic={false} returnURL={'/myscenarios'} />
        </div>
    )
}

//NOTE: createScenario is now a variable in props and its value is a function that dispatches
//the action-creator 'createScenario' function defined in scenarioActions.js, w
//createScenario is an action-creator which instead of returning an action object (as done when we did not need asynchronous calls)
//now returns a function that makes an asynch call and then calling dispatch. 
//'scenario' below is just a variable in the arrow function



const mapDispatchToProps = (dispatch) => {
    return {
        createScenario: (scenario) => dispatch(createScenario(scenario)),
    }
}


export default connect(null, mapDispatchToProps)(CreatePrivateScenario)

