import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import { Container, Button, Col, Row } from 'react-bootstrap'
import TooltipCustom from "../../Utilities/TooltipCustom"
import MakeChoicesAndNotes from '../forms/MakeChoicesAndNotes'
import { ChoiceSummary } from '../forms/ChoiceSummary'
import './LogFeelingsAndNeeds.css'

//HigherOrderStep takes props of step and currentStep and only shows value if step === currentStep


const instructionColorDark = '#213378'
const instructionColorLight = '#f58437'
const instructionColor = 'black'
const instructionTooltipColor = 'grey'
const logTypeList = ['NegF_', 'NegN_', 'PosF_', 'PosN_']

export class LogFeelingsAndNeeds extends Component {

    constructor(props) {
        super()
        this.logRef = React.createRef()
        //console.log('has own prop showNeg', props.hasOwnProperty('showNeg'), props.showNeg)
        this.state = {
            currentStep: props.currentStep || 1,
            numSteps: 2,
            showPos: props.hasOwnProperty('showPos') ? props.showPos : true,
            showNeg: props.hasOwnProperty('showNeg') ? props.showNeg : false,
            activeStep: 'posF'

        }

        this.sessionVarName = 'LogFeelingsAndNeeds' + props.location.pathname
        this.localData = sessionStorage.getItem([this.sessionVarName])
        this.localDataObj = this.localData ? JSON.parse(this.localData) : null
        //console.log(this.localDataObj)

        //if there is a localDataObj, initiate state with that
        if (this.localDataObj)
            Object.keys(this.localDataObj).forEach((key) => {
                //only put into state from storage if it is a variable starting with prefix in logTypeList
                //otherwise variables like createdAt start being loaded in without their methods and this causes crash
                if (logTypeList.includes(key.slice(0, 5)))
                    this.state[key] = this.localDataObj[key]

            })
        //if there is a log prop passed through, initialise state with that.
        else if (props.log)
            Object.keys(props.log).forEach((key) => { this.state[key] = props.log[key] })
    }


    static defaultProps = {
        callbackFunc: (obj) => { console.log(obj) },
        returnURL: '/mylogs'
    }



    setActiveStep = (event) => {
        const { id } = event.target;
        if (id === this.state.activeStep)
            this.setState({ activeStep: null })
        else
            this.setState({ activeStep: id })
    }


    focusLog = () => {

        this.logRef.current.scrollIntoView()
    }

    renderButtons = () => {
        let btn_classPosF = this.state.activeStep === 'posF' ? "choice-btn light selected" : "choice-btn light";
        let btn_classNegF = this.state.activeStep === 'negF' ? "choice-btn dark selected" : "choice-btn dark";
        let btn_classPosN = this.state.activeStep === 'posN' ? "choice-btn light selected" : "choice-btn light";
        let btn_classNegN = this.state.activeStep === 'negN' ? "choice-btn dark selected" : "choice-btn dark";



        const NegF_Instructions = 'Putting words to negative feelings can be soothing. This is also the first step to uncovering your unmet needs that caused these feelings. Further down on this page, you can make notes about the situations that led to these feelings.'
        const PosF_Instructions = 'Identifying positive feelings can help affirm the good things in your life and promote gratitude. Further down on this page, you can make notes about the situations that led to these feelings.'
        const NegN_Instructions = 'Negative feelings are a result of underlying unmet needs. This page helps you identify what unmet needs that were at the core of your negative feelings. Understanding your core needs will help you find strategies for having these needs met in the future. Further down, you can make notes about strategies that can help you get these needs met more in the future.'
        const PosN_Instructions = 'Positive feelings come from needs that were met. This page helps you identify the needs that were met that led to your positive feelings. Understanding how having your needs met lead to your positive feelings will help you be aware of how to continue to have these needs met in the future. '


        return (
            <>
                <Row>
                    <Col md={{ span: 3 }} >
                        <TooltipCustom innertext={PosF_Instructions} color={instructionColorLight} className="d-inline-flex" />
                        <Button id='posF' className={'mr-1 ml-1 ' + btn_classPosF} onClick={this.setActiveStep}>Positive Feelings</Button>
                    </Col><Col md={{ span: 3 }} >
                        <TooltipCustom innertext={PosN_Instructions} color={instructionColorLight} className="d-inline-flex" />
                        <Button id='posN' className={'mr-1 ml-1 ' + btn_classPosN} onClick={this.setActiveStep}>Met Needs</Button>
                    </Col><Col md={{ span: 3 }} >
                        <TooltipCustom innertext={NegF_Instructions} color={instructionColorDark} className="d-inline-flex" />
                        <Button id='negF' className={'mr-1 ml-1 ' + btn_classNegF} onClick={this.setActiveStep}>Negative Feelings</Button>
                    </Col > <Col md={{ span: 3 }} >

                        <TooltipCustom innertext={NegN_Instructions} color={instructionColorDark} className="d-inline-flex" />
                        <Button id='negN' className={'mr-1 ml-1 ' + btn_classNegN} onClick={this.setActiveStep}>Unmet Needs</Button>
                    </Col>
                </Row>
            </>
        )
    }


    handleChange(event) {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    // Save log
    handleSubmit = (e) => {
        e.preventDefault()
        //callbackFunc is action creator defined in logActions.js
        console.log(this.props)
        let log = Object.assign({}, this.state);

        //remove collapsible state keys from what is sent via callbackFun so only keys involving log is written into database   
        Object.keys(log).forEach((key) => {
            if (!logTypeList.includes(key.slice(0, 5)))
                delete log[key]
        })
        if (this.props.log && 'authorId' in this.props.log) {
            log['authorId'] = this.props.log.authorId
        }
        console.log('log:', log, 'history:', this.props.history)
        this.props.callbackFunc(log)
        sessionStorage.removeItem([this.sessionVarName])


        if (this.props.history) this.props.history.push(this.props.returnURL) //return to the home route
    }


    updateStateAndStorage = (obj) => {
        console.log('updateStateAndStorage', this.sessionVarName, 'obj', obj)

        this.setState(obj, () => { sessionStorage.setItem([this.sessionVarName], JSON.stringify(this.state)) })
    }


    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to='/signin' />
        const buttons = this.renderButtons()

        return (

            <Container ref={this.logRef} >
                <Row>
                    <Col>
                        <div className="input-field" ><Button variant="link" style={{ color: "#0099CC" }} href={this.props.returnURL} onClick={() => sessionStorage.removeItem([this.sessionVarName])}>Back</Button></div>
                    </Col>
                </Row>
                <Row>

                </Row>
                <Row>

                    <Col className='mt-5 text-center'>

                        {buttons}

                    </Col>
                </Row>
                <Row><Col>
                    {this.state.activeStep === 'negF' ?

                        <MakeChoicesAndNotes
                            step={1}
                            formTitle='Identify Negative Feelings'
                            instructionsTooltip="Identify negative feelings you've felt recently. Identifying these feelings can reduce their intensity. This can also help you get to the root of the issue by uncovering the unmet needs behind your feelings."
                            notesTooltip='Here you can add notes, e.g., about what happened that led to these feelings. Use the dropdown menu accompanying each note to choose the feelings your note corresponds to.'
                            tooltipColor={instructionTooltipColor}
                            titleColor={instructionColor}
                            currentStep={this.state.currentStep}
                            dropdownPlaceholder='Associated Feelings'
                            textPlaceholder='Write notes here'
                            stateNameBase='NegF_'
                            checkboxFilename='feelings'
                            valuesFromParent={this.state}
                            callbackFunc={this.updateStateAndStorage}
                        >
                            <ChoiceSummary className="mb-5" useHeaders={true} stateNameBase='NegF_' checkboxFilename='feelings' valuesFromParent={this.state} notesLabel='NOTES: ' />
                        </MakeChoicesAndNotes > : null
                    }
                    {
                        this.state.activeStep === 'negN' ?
                            <MakeChoicesAndNotes
                                step={2}
                                formTitle='Identify Unmet Needs Behind Negative Feelings'
                                instructionsTooltip='Negative feelings are indicators that we had unmet needs. Identify needs of yours that were not met that gave rise to the negative feelings above'
                                notesTooltip='Add notes for possible strategies that could help you meet these needs more. You can also choose which needs each strategy refers to from the dropdown menu accompanying each note.'
                                tooltipColor={instructionTooltipColor}
                                titleColor={instructionColor}
                                currentStep={this.state.currentStep}
                                dropdownPlaceholder='Associated Needs'
                                textPlaceholder='Write strategy here'
                                stateNameBase='NegN_'
                                checkboxFilename='needs'
                                valuesFromParent={this.state}
                                callbackFunc={this.updateStateAndStorage}
                            >

                                <ChoiceSummary className="mb-5" useHeaders={true} stateNameBase='NegF_' checkboxFilename='feelings' valuesFromParent={this.state} notesLabel='NOTES: ' />
                            </MakeChoicesAndNotes >
                            : null
                    }


                    {this.state.activeStep === 'posF' ?

                        <MakeChoicesAndNotes
                            step={1}
                            formTitle='Identify Positive Feelings'
                            instructionsTooltip="Identify positive feelings you've felt recently. Identifying positive feelings can promote appreciation and gratitiude and "
                            notesTooltip='Here you can add notes about what happened that led to these feelings. Use the dropdown menu accompanying each note to choose the feelings your note corresponds to.'
                            tooltipColor={instructionTooltipColor}
                            titleColor={instructionColor}
                            currentStep={this.state.currentStep}
                            dropdownPlaceholder='Associated Feelings'
                            textPlaceholder='Write notes here'
                            stateNameBase='PosF_'
                            checkboxFilename='feelingsPositive'
                            valuesFromParent={this.state}
                            callbackFunc={this.updateStateAndStorage}
                        >
                            <ChoiceSummary className="mb-5" useHeaders={true} stateNameBase='PosF_' checkboxFilename='feelingsPositive' valuesFromParent={this.state} notesLabel='NOTES: ' />
                        </MakeChoicesAndNotes>
                        : null
                    }
                    {
                        this.state.activeStep === 'posN' ?
                            <MakeChoicesAndNotes
                                step={2}
                                formTitle='Identify Met Needs Behind Positive Feelings'
                                instructionsTooltip='Positive feelings come from our needs being met. Identify needs of yours that were met that gave rise to the positive feelings above. This process of identifying met needs can help you recognise what is working well in your life so you can keep it up and do it more.'
                                notesTooltip='Add notes for strategies for boosting even more your ability to have these needs met. You can also choose which needs each strategy refers to from the dropdown menu accompanying each note. '
                                tooltipColor={instructionTooltipColor}
                                titleColor={instructionColor}
                                currentStep={this.state.currentStep}
                                dropdownPlaceholder='Associated Needs'
                                textPlaceholder='Write strategy here'
                                stateNameBase='PosN_'
                                checkboxFilename='needs'
                                valuesFromParent={this.state}
                                callbackFunc={this.updateStateAndStorage}
                            >
                                <ChoiceSummary className="mb-5" useHeaders={true} stateNameBase='PosF_' checkboxFilename='feelingsPositive' valuesFromParent={this.state} notesLabel='NOTES: ' />
                            </MakeChoicesAndNotes>
                            : null
                    }
                </Col></Row>
                <Row>
                    <Col xs={{ span: 12, order: 0, offset: 0 }}>
                        {this.state.showPos || this.state.showNeg ? <span onClick={this.focusLog} className="ml-0 pl-0 link" style={{ border: 'none' }}> Back to top</span> : null}

                    </Col>
                </Row>
                <Row>
                    <Col className="mt-3" md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }} style={{ marginBottom: '15rem' }}>


                        <Button
                            className="btn float-right btn-blue"
                            type="button" onClick={this.handleSubmit}>
                            Save & Close
                        </Button>
                    </Col>
                </Row>

            </Container>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        isAdmin: state.auth.isAdmin, //whether current user is admin
        profile: state.firebase.profile,
    }
}


export default connect(mapStateToProps)(withRouter(LogFeelingsAndNeeds))

