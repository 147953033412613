import React, { Component } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { saveActivity } from '../../store/actions/activityActions'
import QuizForm from '../forms/QuizForm'
import { pseudofeelings, feelingsNegative } from '../../files/FeelingsAndPseudoFeelings'
import { shuffleArray } from '../forms/formFunctions'
import JudgmentJudge from '../../files/JudgmentJudge.png'
import FeelingHeart from '../../files/FeelingHeart.png'
import PersonConfused from '../../files/PeopleThinking.png'
import ShowQuizAnswersCategoryTypes from '../forms/ShowQuizAnswersCategoryTypes'
import ShowScore from '../forms/ShowScore'

import SubscribeLong from '../publicpages/SubscribeLong'
const numQuestions = Math.floor(10 / 2)
shuffleArray(pseudofeelings)
shuffleArray(feelingsNegative)
const pseudofeelingsList = pseudofeelings.slice(0, numQuestions)
const feelingsNegativeList = feelingsNegative.slice(0, numQuestions)

const pseudofeelingsObjectList = pseudofeelingsList.map(item => ({ question: item, answers: [{ content: 'Judgment', value: "Correct" }, { content: 'Feeling', value: "Incorrect" }] }))
const feelingsNegativeObjectList = feelingsNegativeList.map(item => ({ question: item, answers: [{ content: 'Judgment', value: "Incorrect" }, { content: 'Feeling', value: "Correct" }] }))

const quizQuestions = [...feelingsNegativeObjectList, ...pseudofeelingsObjectList];
//pseudofeelingsObjectList + feelingsNegativeObjectList

//quizImages is an object where properties correspond to answers[n].content (values of option buttons)
const quizImages = { Judgment: JudgmentJudge, Feeling: FeelingHeart }
shuffleArray(quizQuestions)


class PseudofeelingsQuiz extends Component {

    constructor(props) {
        super()
        this.quizRef = React.createRef()
        this.state = {
            scoreObj: {} //scoreObj is only populated once the last quiz question is completed
        };
    }

    static defaultProps = {

    }

    focusQuiz = () => {

        this.quizRef.current.scrollIntoView()
    }


    //saveFinalScore function that calls saveQuizResults with quizType and finalscore value (in fraction) as well as answersRecord
    //so users can view which words they got right and wrong

    savePseudofeelingsQuiz = () => {
        if (this.props.auth.uid) {
            const finalScore = (this.state.scoreObj.Correct > 0 && this.state.scoreObj.Incorrect >= 0) ? this.state.scoreObj.Correct / (this.state.scoreObj.Correct + this.state.scoreObj.Incorrect) : 0
            this.setState({ finalScore }, () => { this.props.saveActivity(this.state, 'pseudofeelingsQuiz') })
        }
    }

    refreshPage = () => {
        window.location.reload(false);
    }

    render() {
        const optionCategories = [{ header: 'Feelings', category: 'Feeling' }, { header: 'Judgments', category: 'Judgment' }]
        return (

            <div className="image-background" style={{ backgroundColor: "white" }}>
                <Container fluid className="bg-light min-vh-100 d-flex flex-column" >
                    <Button className="text-left" variant="link" style={{ color: "#007399" }} href='https://www.diplomacydojo.com/'>Back to Home</Button>
                    <Container>
                        <Row>
                            <Col className="pl-5" lg={{ span: 9, offset: 0 }} sm={{ span: 12, offset: 0 }} >
                                <Row >
                                    <Col className="text-center"><img src={PersonConfused} width="60%" alt={''} /></Col>

                                </Row>
                                <Row>

                                    <Col className="mt-3">
                                        <h1 className="text-center"><b>Can you distinguish feelings from judgments?</b></h1>
                                        <h5 className="mt-3" style={{ display: 'inline-block' }}>
                                            We often confuse judgments as feelings. Judgments involve our interpretations of others' thoughts whereas
                                            feelings only involve ourselves. Judgment puts attention on faulting others,
                                            and distract us from understanding ourselves. Also, if we express our judgments to others, we are interpreting
                                            others' thoughts and intentions, and this can lead to defensiveness and unhelpful debate. On the other hand, people cannot dispute our true feelings because
                                            this describes only what is going on inside ourselves without inferring anything about others.
                                            For each sentiment below, choose whether it's a
                            <img src={quizImages.Judgment} width="36" height="30" alt={'Judgment'} />  or  <img src={quizImages.Feeling} width="36" height="30" alt={'Feeling'} />?
                             <div onClick={this.focusQuiz} className="ml-0 pl-0 link" style={{ border: 'none' }}>Go to quiz</div>
                                        </h5>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="my-5" lg={{ span: 3, offset: 0 }} sm={{ span: 12, offset: 0 }} >
                                <Row>
                                    <SubscribeLong />
                                </Row>
                                <Row><Col className="mt-5 bg-light" >
                                    <h1><b>Want help for your organization?</b></h1>
                                    <h5>
                                        <a href="contact" >Contact Us</a></h5>
                                    <h5 >or email us at: <a href={"mailto:info@diplomacydojo.com"}>info@diplomacydojo.com</a></h5>

                                </Col></Row>

                            </Col></Row>
                    </Container>
                </Container>
                <Container >
                    <Container ref={this.quizRef} className=" min-vh-100 d-flex flex-column" >
                        <Row className="mt-5 py-5">
                            <Col md={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
                                {Object.keys(this.state.scoreObj).length !== 0 ?
                                    <Container >
                                        <Col className="text-center mt-3 mb-3"><Button className="btn-blue" style={{ minWidth: '10rem' }} onClick={this.refreshPage} >Try More</Button></Col>
                                        <Col className="text-center"><Button className="btn-blue mb-3" style={{ minWidth: '10rem' }} onClick={() => this.props.history.push('/')}>Back To Home</Button></Col>
                                    </Container> : null}
                                <div>
                                    <QuizForm
                                        quizQuestions={quizQuestions}
                                        quizImages={quizImages}
                                        additionalQuestionText="I feel"
                                        callbackFunc={(answersRecord) => this.setState({ answersRecord })}
                                        callbackSendScore={(scoreObj) => this.setState({ scoreObj }, this.savePseudofeelingsQuiz)}
                                    />
                                </div>
                            </Col>
                            <Col md={{ span: 5, offset: 1, order: 2 }} xs={{ span: 12, order: 1 }}>
                                <div>
                                    <ShowQuizAnswersCategoryTypes optionCategories={optionCategories} answersRecord={this.state.answersRecord} />
                                </div>
                                <div>
                                    <ShowScore numCorrect={(this.state.scoreObj && this.state.scoreObj.Correct) || 0} numIncorrect={(this.state.scoreObj && this.state.scoreObj.Incorrect) || 0} />
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </Container>

            </div >

        )
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveActivity: (activity, activityType) => dispatch(saveActivity(activity, activityType)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PseudofeelingsQuiz)
