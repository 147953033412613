import React from "react"
import SignedInLinks from "./SignedInLinks"
import SignedOutLinks from "./SignedOutLinks"
import { connect } from 'react-redux'

import { Container, Navbar } from 'react-bootstrap';
import { IconContext } from "react-icons";
import { FaBars } from 'react-icons/fa';
//import logo from '../../files/logo.png'
import logo from '../../files/EHE_logoHorizontal.svg'
import './Navbar.css'

const NavigationBar = (props) => {
  const { auth, profile } = props
  //state.firebase.profile could also have been made available directly using mapstatetoprops in SignedInLinks
  //but tutorial chose to map it in this parent component (Navbar) and pass it to SignedInLinks as a prop

  const links = auth.uid ? <SignedInLinks profile={profile} /> : <SignedOutLinks />
  return (


    <Navbar className="color-nav sticky-top navbar active" expand="md" >
      {/* <Navbar className={navbar ? "color-nav sticky-top navbar active" : "color-nav sticky-top navbar"} expand="md" >*/}
      <Container fluid>
        {/* <Navbar.Brand href="/" target="_blank" rel="noopener noreferrer" className="text-blue"><h3>Diplomacy Dojo </h3></Navbar.Brand>*/}

        <Navbar.Brand href="https://www.emotionalhealthessentials.com/">
          <img
            alt=""
            src={logo}

            height="60"
            className="d-inline-block align-top pz-0"
          />{' '}

        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggler">
          <IconContext.Provider
            value={{ borderColor: 'white' }}
          >
            <FaBars
              color={"#0099CC"}

            />
          </IconContext.Provider>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="navbar-links" >

          {links}

        </Navbar.Collapse>
      </Container>
    </Navbar >


  );
};


const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps)(NavigationBar);
