import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Col, Button } from 'react-bootstrap'
import HigherOrderCollapsible from '../../Utilities/HigherOrderCollapsible'
import CheckboxForm from './CheckboxForm'
import { getNameColorIdStatename } from './formFunctions'
import TooltipCustom from "../../Utilities/TooltipCustom"
import { feelingsNegativeArr, feelingsPositiveArr, needsArr } from './readCSV'
import Note from './Note'


const CollapsibleCheckboxForm = HigherOrderCollapsible(CheckboxForm)
const addNoteButtonClass = "btn-grey"

/**
 * MakeChoicesAndNotes class. takes in a csv with headers and allows notes to be added at the bottom
 * The type of CSV is defined as a prop and read in from readCSV, where first item of each row is the header.
 * state variables will be dynamically assigned based on this.state[prop.stateName + header_id]
 * where header_id is the initial sequence of strings in headerFullStr without spaces 
 * headerLabel is the full str sequence with baseColor (e.g., #FFFF) removed from end
 * Allows dynamically added Note components to be created. 
 * Each Note component can be associated with any number of options via Multiselect component (multi-choice dropdown). Defaults to header values if options are not specified.
 */
class MakeChoicesAndNotes extends Component {

    /**
     * @constructor
     * @param {Array.<Object>}  props.valuesFromParent[props.stateNameBase + 'noteslist'], passed from parent component if list has been made. list of objects, with properties 'text' and 'selected'. each object corresponds to a note taken. Each note has properties of text and array of choices (headers associated with the note.text)
     * @param {Array.<Object>} props.options, list of objects, {name: name, id: name}, which is the options prop for Multiselect. (To be passed as prop into Note which passes as prop to Multiselect)
     * If options is null, then use headers
     * Note I will be using id and name as equivalent because I want to just store a 1D list of names (i.e., header_id's), but Multiselect requires the object format
     * @param {string} props.stateNameBase [props.stateNameBase + header_id] will be the name of variable in which checkbox choices for each header type will be chosen
     * @param {Array.<String>} props.valuesFromParent[props.stateNameBase + header_id]  passed from parent component if choices have been already made (saved from localstorage or database)
     * @param {('feelings'|'feelingsPos'|'needs')} props.checkboxFilename,  whether to loads feelingsPos, feelingsNeg or needs into checkbox choices
     * @param {string} props.dropdownPlaceholder text value for dropdown selection
     * @param {string} props.formTitle text value for title of this form
     * @param {string} props.addNoteButtonText text value for Add Note Button
     * @param {string} props.instructionsTooltip text value for tooltip next to title
     * @param {string} props.notesTooltip text value for tooltip next to Add Note
     * @param {callbackFunc} this callback sends state values [props.stateNameBase + header_id]  back to parent coponent
     */



    constructor(props) {
        super(props);

        this.state = {
            [props.stateNameBase + 'notesList']: (props.valuesFromParent && props.valuesFromParent[props.stateNameBase + 'notesList']) || [], //list of notes with integers numbered 1...* that have been made. Each note is associated with checkboxheaders via  notesAssociations
            options: props.options || null, ///if null options will be extracted from headerLabels
        }
    }


    static defaultProps = {
        formTitle: '',
        formText: '',
        stateNameBase: 'NegF_',
        checkboxFilename: 'feelings',
        dropdownPlaceholder: 'Choose Associations',
        textPlaceholder: 'Write text here',
        addNoteButtonText: 'Add Note',
        tooltipColor: 'black',
        titleColor: 'black',
        callbackFunc: (objForParent) => { }
    }

    //cannot call the imported async result feelingsNegativeArr in Constructor because this.State is called immediately and maybe before readCSV promise is resolved
    ///and it is bad practice to have an asynch call inside constructor
    componentDidMount() {
        var readCSVPromise
        switch (this.props.checkboxFilename) {
            case 'needs':
                readCSVPromise = needsArr
                break
            case 'feelings':
                readCSVPromise = feelingsNegativeArr
                break
            case 'feelingsPositive':
                readCSVPromise = feelingsPositiveArr
                break
            default:
                readCSVPromise = needsArr
        }
        readCSVPromise.then(result => { this.initialiseHeaderStatesFrom2DArr(result) }).catch((err) => console.log(err));

    }




    /**
     * @function
     * @param result returned from promse from readCSV
     * Sets state values for array of checkbox choices in stateName ( =[props.stateNameBase + header_id]  )
     * id is the first subset of string values in header without a space and with any color specification at end removed
     * sets state values for collapsible + id state values which set whether the collapsible is open or not
     * sets state values for headersList
     * creates const id+Ref values of type React.createRef() for each collapsible
     */
    //takes a 2d array where the first element of the inside array is assumed to be the header
    //sets States to be an array of header values and also sets states for user input values corresponding to each of headings
    initialiseHeaderStatesFrom2DArr = (result) => {
        //console.log('papa parse output', result.data)
        var data = result.data //for some reason calling result.forEach causes an error
        if (!data) {
            console.log("no CSV results found:", data)
        }
        else {
            //data should be a 2D array where each item is an array representing row from csv
            //We assume the first value of each inner array is the header to be used as choice buttons (general feeling categories)
            //This assumes PapaParse returns 2D array (even when reading a one row csv). Will error out if it returns single array.
            //creates a state [stateName] for list of checked items for that header
            //also fills in a state headersList, list of all headers (including baseColor ending)
            var headersList = []
            data.forEach(arr => {
                //id is the short name as a result of the Header
                const { id, stateName } = getNameColorIdStatename(arr[0], this.props.stateNameBase)
                //set State for header buttons, for headersLists and needs as function of feelings

                this.setState({
                    [stateName]: (this.localDataObj && this.localDataObj.hasOwnProperty(stateName) && this.localDataObj[stateName]) || (this.props.valuesFromParent && this.props.valuesFromParent[stateName]) || [],
                    ['collapsibleState_' + id]: false,
                })
                headersList.push(arr[0])
                this[id + 'Ref'] = React.createRef();



            })

            this.setState({ headersList })

        }
    }

    /**
     * @function
     * @param takes a headersList, extracts headerLabels and create options, list of multi-choice option objects
     *  to feed as prop into Multichoice component via Notes child component
     *  
     */
    createOptionsFromList = (headersList) => {
        const options = []
        headersList && headersList.forEach(header => {
            const { headerLabel } = getNameColorIdStatename(header, this.props.stateNameBase)
            options.push({ id: headerLabel, name: headerLabel })
        })
        return options
    }

    /**
     * @function
     * @param creates options from values in arrays in state with prefix, prefix
     *
     */
    createOptionsFromSelectedValues = (prefix) => {
        let options = []
        Object.keys(this.state).forEach((key) => {
            if (key.startsWith(prefix)) {
                const objectList = this.state[key].flatMap((item) => {
                    //I think map is entered even when this.state[key] is an empty array and item is undefined.toString() is used in getNameColorIdStatename, and this throws an error
                    let label
                    if (typeof item === "string") {
                        const { headerLabel } = getNameColorIdStatename(item)
                        label = headerLabel
                    }
                    else
                        label = null
                    return (label ? { id: label, name: label } : [])
                })
                options = options.concat(objectList)
            }
        })
        return options
    }




    /**
    * @function
    * @param transforms array to string with spaces separating each item. For use in showing chosen feelings in the collapsible
    *
    */
    arr2str = (arr, separator = " ") => {
        if (!arr) return ''
        var arr2 = []
        arr.forEach((val) => {
            const { headerLabel } = getNameColorIdStatename(val)
            arr2.push(headerLabel)
        })
        return arr2.toString().replace(/,/g, separator)
    }



    /**
    * @function
    * @param opens collapsible with id currentCollapsible and puts window focus on its center
    * called from toggleCollapsible
    */

    open_and_center_collapsible = (id) => {
        this.setState({ ['collapsibleState_' + id]: true, collapsibleStateCurrent: id })

        /*
        //need setTimeout otherwise the DOM is not fully rendered and current.offsetTop is not an accurate value as it's only partly rendered (not closed other collapsibles)
       
        setTimeout((id) => {
            if (this[id + 'Ref'].current) {
                console.log('going to current collapsible', id, this[id + 'Ref'].current.offsetTop)
                window.scrollTo(0, this[id + 'Ref'].current.offsetTop);
    
            }
        }, 100, id)
            */
    }


    /**
    * @function
    * @param callback function sent to child component CollapsibleCheckboxForm. Controls opening and closing collapsible
    */
    toggleCollapsible = (showState, id) => {
        if (showState)
            this.open_and_center_collapsible(id)
        else
            this.setState({ ['collapsibleState_' + id]: false })
    }


    /**
    * @function
    * @param opens or closes all collapsible
    */
    changeShowAll = () => {
        const collapsibleState_showAll = !this.state.collapsibleState_showAll
        this.state.headersList.forEach(checkboxHeader => {
            const { id } = getNameColorIdStatename(checkboxHeader)
            this.setState({ ['collapsibleState_' + id]: collapsibleState_showAll })
        })
        this.setState({ collapsibleState_showAll })
    }


    /**
    * @function
    * @param updates values of note object (with position 'index') in noteList
    */
    updateNote = (index, property, val) => {
        let notesList = JSON.parse(JSON.stringify(this.state[this.props.stateNameBase + 'notesList']))
        //let notesList = [...this.state[this.props.stateNameBase + 'notesList']];
        notesList[index][property] = val;
        this.setState({ [this.props.stateNameBase + 'notesList']: notesList }, () => { this.props.callbackFunc({ [this.props.stateNameBase + 'notesList']: notesList }) })
    }


    /**
    * @function
    * @param remove note object from noteList
    */
    deleteNote = index => {
        //console.log('remove from index', index)
        //const notesList = [...this.state[this.props.stateNameBase + 'notesList']];
        let notesList = JSON.parse(JSON.stringify(this.state[this.props.stateNameBase + 'notesList']))
        notesList.splice(index, 1);
        this.setState({ [this.props.stateNameBase + 'notesList']: notesList }, () => { this.props.callbackFunc({ [this.props.stateNameBase + 'notesList']: notesList }) })
    }

    /**
    * @function
    * @param Adds object template to notesList, which in turn will cause createNotes to render a notes component
    */
    handleAddNote = () => {
        this.setState({ [this.props.stateNameBase + 'notesList']: [...this.state[this.props.stateNameBase + 'notesList'], { text: "", selected: [] }] })
    };





    //callbackVarType is combined with id to be the state variable where ticked checkbox value array is stored.
    //this state variable should already be defined in ComponentDidMount, e.g., 'ANNOYED_Feelings'
    createCollapsibles = (headersList) => {
        return (<div > {headersList.map(checkboxHeader => {
            const { id, baseColor, headerLabel, stateName } = getNameColorIdStatename(checkboxHeader, this.props.stateNameBase)
            return (
                <div key={headerLabel} ref={this[id + 'Ref']}  >
                    <CollapsibleCheckboxForm
                        callbackFunc={(callbackValue) => this.setState({ [stateName]: callbackValue }, () => { this.props.callbackFunc({ [stateName]: callbackValue }) })}
                        label={headerLabel + ': ' + this.arr2str(this.state[stateName])}
                        checkboxFilename={this.props.checkboxFilename}
                        title={null}
                        classNameValues={''}
                        initialCheckedFields={this.state[stateName]}
                        showState={this.state['collapsibleState_' + id]}
                        showDeselectAll={false}
                        showIndividualGroup={id}
                        signalOpenCallback={this.toggleCollapsible}
                        showHeaders={false}
                        color={baseColor}
                        id={id}
                    />

                </div>)

        })
        }
        </div>)
    }

    //creates a new note with noteID one greater than the largest noteID in notesList
    createNotes = (notesList, prefix = '') => {
        const options = this.props.options || this.createOptionsFromSelectedValues(prefix)
        // const options = this.props.options || this.createOptionsFromList(this.state.headersList)

        if (notesList.length === 0)
            return <>
                {this.props.notesTooltip ?
                    <TooltipCustom color={this.props.tooltipColor} innertext={this.props.notesTooltip} className="float-left" /> : null
                }
                <Button className={addNoteButtonClass} onClick={this.handleAddNote}>{this.props.addNoteButtonText}</Button>
            </>



        return (
            <div>
                {notesList && notesList.map((x, i) => {
                    return (
                        <div className='mt-1' key={'notes' + i}>

                            <Col md={{ span: 8, order: 0, offset: 0 }} xs={{ span: 12, order: 0, offset: 0 }}>
                                <Note
                                    dropdownOptions={options}
                                    textPlaceholder={this.props.textPlaceholder}
                                    dropdownPlaceholder={this.props.dropdownPlaceholder}
                                    showCheckbox={true}
                                    selectedValuesIDList={x.selected}
                                    callbackUpdateNote={this.updateNote}
                                    noteID={i}
                                    textValue={x.text}
                                    minRows="2"

                                />
                            </Col>
                            <Col className='mt-1'>
                                {notesList.length !== 0 &&
                                    <Button onClick={() => this.deleteNote(i)} className={addNoteButtonClass}>Remove</Button>}

                            </Col>

                            {notesList.length - 1 === i &&
                                <div className='mt-5'>
                                    {this.props.notesTooltip ?
                                        <TooltipCustom color={this.props.tooltipColor} innertext={this.props.notesTooltip} className="float-left" /> : null
                                    }
                                    <Button onClick={this.handleAddNote} className={addNoteButtonClass}>{this.props.addNoteButtonText}</Button>
                                </div>
                            }

                        </div>
                    )
                })}
            </div>

        )
    }

    render() {


        const notes = this.createNotes(this.state[this.props.stateNameBase + 'notesList'], this.props.stateNameBase)

        const collapsibles = this.state.headersList ? this.createCollapsibles(this.state.headersList, '_Feelings') : null
        return (
            <>

                <Col xs={{ span: 12, order: 0, offset: 0 }} className='mb-5' >
                    {this.props.children}
                    {this.props.instructionsTooltip ?
                        <TooltipCustom color={this.props.tooltipColor} innertext={this.props.instructionsTooltip} className="float-left" /> : null
                    }

                    <h2 style={{ color: this.props.titleColor }}><b>{this.props.formTitle}</b></h2>
                    <h5>{this.props.formText}</h5>
                </Col>
                <Col xs={{ span: 12, order: 0, offset: 0 }} className='mt-2 mb-3' >

                    <Button className="btn-grey ml-1" onClick={this.changeShowAll}>{this.state.collapsibleState_showAll ? 'Hide All' : 'Show All'}</Button>
                </Col>
                <Col xs={{ span: 12, order: 0, offset: 0 }} className='mb-5' >

                    {collapsibles}
                </Col>
                <Col className="btn-box right-align mt-3 mb-5" xs={{ span: 12, order: 0 }}>
                    {notes}
                </Col>
            </>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin, //whether current user is admin
        profile: state.firebase.profile,
    }
}


export default connect(mapStateToProps)(withRouter(MakeChoicesAndNotes))

