import React, { Component } from 'react'
import firebase from '../../config/fbConfig'
import { Form, Col, Button, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import AddGroupAccess from './AddGroupAccess'


class Admin extends Component {
    state = {
        email: '',
        addAdminError: '', //error gets displayed when trying to add new user as admin
        addAdminSuccess: '', //success message gets displayed when trying to add new user as admin
        removeAdminError: '', //error gets displayed when trying to remove new user as admin
        removeAdminSuccess: '' //success message gets displayed when trying to remove new user as admin
    }



    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            addAdminError: '',
            addAdminSuccess: '',
            removeAdminError: '',
            removeAdminSuccess: '',
        })
    }

    addAdmin = (data) => {

        const addAdminRole = firebase.functions().httpsCallable('addAdminRole');
        return addAdminRole(data).then((result) => {
            console.log('add admin result', result)
            if (result.data.errorInfo) {
                this.setState({ addAdminError: result.data.errorInfo.message })
            }
            if (result.data.message) {
                this.setState({ addAdminSuccess: result.data.message })

            }
        })
            .catch((error) => {
                console.log(`error: ${JSON.stringify(error)}`);
            });
    }


    removeAdmin = (data) => {

        const removeAdminRole = firebase.functions().httpsCallable('removeAdminRole');
        return removeAdminRole(data).then((result) => {
            console.log('remove admin result', result)
            if (result.data.errorInfo) {
                this.setState({ removeAdminError: result.data.errorInfo.message })
            }
            if (result.data.message) {
                this.setState({ removeAdminSuccess: result.data.message })

            }
        })
            .catch((error) => {
                console.log(`error: ${JSON.stringify(error)}`);
            });
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.addAdmin(this.state)

    }

    handleSubmitRemove = (e) => {
        e.preventDefault();
        this.removeAdmin(this.state)

    }




    render() {
        if (!this.props.auth.uid) return <Redirect to='/signin' />
        if (!this.props.isAdmin) { return null }
        console.log(this.props.profile.token)

        return (
            <Container>
                <Col md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }}    >
                    <AddGroupAccess />
                </Col>
                <Col md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }}    >
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                            <h3 >Add Admin</h3>
                        </Form.Row>
                        <Form.Group controlId="email" as={Row}>
                            <Form.Label column="lg" sm="2">
                                Email
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control size="lg" type="text" onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <div className="input-field text-right">
                            <Button type="submit" > Add Admin</Button>
                            {this.state.addAdminError ? <p className='text-danger'>{this.state.addAdminError}</p> : null}
                            {this.state.addAdminSuccess ? <p className='text-danger'>{this.state.addAdminSuccess}</p> : null}
                        </div>
                    </Form>
                </Col>

                <Col md={{ span: 9, order: 0, offset: 1 }} xs={{ span: 12, order: 0, offset: 0 }}    >
                    <Form onSubmit={this.handleSubmitRemove}>
                        <Form.Row>
                            <h3 >Remove Admin</h3>
                        </Form.Row>
                        <Form.Group controlId="email" as={Row}>
                            <Form.Label column="lg" sm="2">
                                Email
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control size="lg" type="text" onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <div className="input-field text-right">
                            <Button type="submit" > Remove Admin</Button>
                            {this.state.removeAdminError ? <p className='text-danger'>{this.state.removeAdminError}</p> : null}
                            {this.state.removeAdminSuccess ? <p className='text-danger'>{this.state.removeAdminSuccess}</p> : null}
                        </div>
                    </Form>
                </Col>
            </Container >


        )
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        isAdmin: state.auth.isAdmin, //whether current user is admin
        profile: state.firebase.profile,
    }
}





export default connect(mapStateToProps)(Admin)

