import React from 'react'
import DeleteLogButton from './DeleteLogButton'
import EditLogButton from './EditLogButton'
import { Card } from 'react-bootstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { ChoiceSummary } from '../forms/ChoiceSummary'



function LogDetailCard({ log, id = '1', isPublic = false, returnURL = '/mylogs' }) {



    return (

        <Card >
            <Card.Body>

                <Card.Title as="div" style={{ color: "black " }}></Card.Title>
                <Card.Text as="div" style={{ color: "black " }} >
                    <div className="className grey-text">{isPublic == false ? moment(log.createdAt.toDate()).calendar() : null}</div>
                    {/*
                    <p></p>
                    <div>{log.summary}</div>
                    <p></p>
                    <div>Positive Feelings: <i>{log.negativeFeelings && log.negativeFeelings.toString().replace(/,/g, ', ')} </i></div>
                    <div>Fulfilled With: <i>{log.unmetNeeds && log.unmetNeeds.toString().replace(/,/g, ', ')} </i></div>
                    <div>Negative Feelings: <i>{log.positiveFeelings && log.positiveFeelings.toString().replace(/,/g, ', ')}</i> </div>
                    <div>Needed More: <i>{log.metNeeds && log.metNeeds.toString().replace(/,/g, ', ')} </i></div>
                    <p></p>
                    <div><b>Strategies:</b> {log.strategies}</div><p></p>

                    <p></p><p></p>
                    */}
                    <h5 className='mt-2'><b>Negative Emotions:</b></h5>
                    <ChoiceSummary useHeaders={true} stateNameBase='NegF_' checkboxFilename='feelings' valuesFromParent={log} notesLabel='Notes:' />
                    <h5 className='mt-2'><b>Unsupported Values & Needs:</b></h5>
                    <ChoiceSummary useHeaders={true} stateNameBase='NegN_' checkboxFilename='needs' valuesFromParent={log} notesLabel="Notes:" />
                    <h5 className='mt-2'><b>Positive Emotions:</b></h5>
                    <ChoiceSummary useHeaders={true} stateNameBase='PosF_' checkboxFilename='feelingsPositive' valuesFromParent={log} notesLabel="Notes:" />
                    <h5 className='mt-2'><b>Supported Values & Needs:</b></h5>
                    <ChoiceSummary useHeaders={true} stateNameBase='PosN_' checkboxFilename='needs' valuesFromParent={log} notesLabel="Notes:" />



                </Card.Text>
                {isPublic == false ?

                    <><p></p><p></p>

                        <Card.Link as={Link} to={returnURL} className="float-left">Back</Card.Link>
                        <Card.Link as={Link} to="#" className="float-right"><DeleteLogButton log={log} id={id} returnURL={returnURL} /></Card.Link>
                        <Card.Link as={Link} to="#" className="float-right"><EditLogButton log={log} id={id} returnURL={returnURL} /></Card.Link>
                    </> : null
                }
            </Card.Body>
        </Card >

    )
}

export default LogDetailCard
