import Papa from 'papaparse';

Papa.parsePromise = function (filetype) {

    var csvFilePath

    switch (filetype) {
        case 'needs':
            csvFilePath = require('../../files/NeedsList.csv')
            break
        case 'feelingsNegative':
            csvFilePath = require('../../files/NegFeelingsRatedList.csv')
            break
        case 'feelingsNegativeExpandable':
            csvFilePath = require('../../files/NegFeelingsRatedListExpandable.csv')
            break
        case 'feelingsPositive':
            csvFilePath = require('../../files/PosFeelingsRatedList.csv')
            break
        case 'pseudofeelings':
            csvFilePath = require('../../files/PseudoFeelingsList.csv')
            break
        case 'tags':
            csvFilePath = require('../../files/tagsList.csv')
            break
        case 'groups':
            csvFilePath = require('../../files/groupsList.csv')
            break
        default:
            csvFilePath = require('../../files/NegFeelingsRatedList.csv')
    }


    return new Promise(function (complete, error) {
        Papa.parse(csvFilePath, {
            header: false,
            download: true,
            skipEmptyLines: true,
            complete
        });
    });
};





export const feelingsNegativeArr = Papa.parsePromise('feelingsNegative')
export const feelingsNegativeArrExpandable = Papa.parsePromise('feelingsNegativeExpandable')
export const feelingsPositiveArr = Papa.parsePromise('feelingsPositive')
export const needsArr = Papa.parsePromise('needs')
export const pseudofeelingsArr = Papa.parsePromise('pseudofeelings')
export const tagsArr = Papa.parsePromise('tags')
export const groupsArr = Papa.parsePromise('groups')