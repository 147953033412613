import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router";

export class EditLogButton extends Component {
    state = {

    }


    handleEdit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        console.log(this.props)
        this.props.history.push({
            pathname: '/editlog/' + this.props.id,
            state: {
                log: this.props.log,
                id: this.props.id,
                prevPath: this.props.location.pathname
            }
        })

    }

    render() {

        const { log, auth } = this.props;

        if (log.authorId === auth.uid) {
            return (
                <span className="card-action" onClick={this.handleEdit}>Edit</span>


            )
        } else { return (null) }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        isAdmin: state.auth.isAdmin
    }
}



export default connect(mapStateToProps)(withRouter(EditLogButton))
