import React, { useState } from 'react';

function App() {
  const [utterance, setUtterance] = useState('');
  const [response, setResponse] = useState('');

  const fetchAPI = url => {
    return fetch(url)
      .then(response => response.text())
      .then(data => {
        setResponse(data);
      })
      .catch(err => { console.log(err); });
  }

  const handleAPICall = () => {
    const apiURL = "https://conversation-dojo.herokuapp.com/getEmpathicResponse?input=" + document.getElementsByName("utterance")[0].value;
    fetchAPI(apiURL);
  }

  return (
    <div>
      <div>
      <textarea rows="5" cols="80" length="2000" type="text" name="utterance" value={utterance} onChange={event => setUtterance(event.target.value)} placeholder="You may type any text in any language, e.g. Diplomacy Dojo apporte une solution innovante pour permettre à vos collaborateurs de nouer des relations de confiance, travailler plus efficacement ensemble et créer un cadre de travail agréable" />
      </div>
      <button onClick={handleAPICall}>Get Empathy</button>
      <div>{response}</div>
    </div>
  );
}

export default App;