
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers/rootReducer'
import { Provider, useSelector } from 'react-redux'
import thunk from 'redux-thunk'
import fbConfig from './config/fbConfig'
import 'firebase/auth'
import 'firebase/firestore' // <- needed if using firestore
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase'
import { getFirestore, createFirestoreInstance, reduxFirestore } from 'redux-firestore'
import { isLoaded } from 'react-redux-firebase'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'typeface-roboto';



//import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
//needed to adjust this for React V6:
//http://docs.react-redux-firebase.com/history/v3.0.0/docs/v3-migration-guide.html#remove-createFirebaseConnect-and-createFirestoreConnect
// https://stackoverflow.com/questions/55559816/typeerror-object-is-not-a-function-on-index-js
// https://github.com/prescottprue/react-redux-firebase/issues/620




const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunk.withExtraArgument({ getFirestore, getFirebase })),
    reduxFirestore(fbConfig))
  //compose(applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })))
)

//tells firebase to sync document inside firestore collection with profile object on the store state
//This only works for the users you created using your app form. Not the initial user you created inside Firebase 
//as that one does not exists inside the "users" collection. 
//https://stackoverflow.com/questions/55871931/react-redux-firebase-not-populating-profile-in-firebase-reducer
const rrfConfig = {
  userProfile: 'users',
  enableClaims: true,
  attachAuthIsReady: true,
  firebaseStateName: 'firebase',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
}

const rrfProps = {
  firebase: fbConfig,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}

//ReactDOM.render(<Provider store={store}><ReactReduxFirebaseProvider {...rrfProps}><App /></ReactReduxFirebaseProvider></Provider>, document.getElementById('root'));

//Needed the following for v3 (different from tutorial)
//https://stackoverflow.com/questions/54605781/how-can-i-find-if-firebaseauthisready-with-v3-0-0
//NOTE: the profile is only populated for users created using app form. Not initial user created in user collection
//https://stackoverflow.com/questions/55871931/react-redux-firebase-not-populating-profile-in-firebase-reducer

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return <div>Loading Screen...</div>;
  return children
}

ReactDOM.render(<Provider store={store}><ReactReduxFirebaseProvider {...rrfProps}>
  <AuthIsLoaded><App /> </AuthIsLoaded></ReactReduxFirebaseProvider>
</Provider>, document.getElementById('root'));

registerServiceWorker();