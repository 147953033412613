import React from 'react'
import { connect } from 'react-redux'
import { createArticle } from '../../store/actions/articleActions'
import ArticleForm from './ArticleForm'

function CreateArticle(props) {
    return (
        <div>
            <ArticleForm callbackFunc={props.createArticle} returnURL={'/'} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createArticle: (article) => dispatch(createArticle(article))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateArticle)