import React from 'react'
import TextBox from "../../bot_components/TextBox"
import { Container, Row, Col } from 'react-bootstrap'
import MakeChoicesAndNotes from '../forms/MakeChoicesAndNotes'
const instructionTooltipColor = 'grey'
const instructionColor = 'black'

export default function PlainTextBox() {
    return (
        <div>
            <Container>

                <Row><Col className="mb-5 mt-5">
                    <MakeChoicesAndNotes
                        step={2}
                        formTitle='What are you needing?'
                        instructionsTooltip='Positive feelings come from our needs being met. Identify needs of yours that were met that gave rise to the positive feelings above. This process of identifying met needs can help you recognise what is working well in your life so you can keep it up and do it more.'
                        notesTooltip='Add notes for strategies for boosting even more your ability to have these needs met. You can also choose which needs each strategy refers to from the dropdown menu accompanying each note. '
                        tooltipColor={instructionTooltipColor}
                        titleColor={instructionColor}
                        dropdownPlaceholder='Associated Needs'
                        textPlaceholder='Write strategy here'
                        stateNameBase='PosN_'
                        checkboxFilename='needs'
                    />
                </Col></Row>
                <Row><Col className='mt-3'></Col></Row>
                <Row><Col className='mt-5 mb-5'></Col></Row>
                <Row><Col fluid className="px-5 mt-5" >
                    <TextBox prompt='What would you like to say?' allowUserFeedback={false} placeholder={''} />
                </Col></Row>
                <Row><Col style={{ marginBottom: "20rem" }}>
                </Col></Row>
            </Container>
        </div>
    )
}
