import React, { useState } from 'react'
import { Row, Col, Container, Button, Collapse } from 'react-bootstrap'
import TextBox from "../../bot_components/TextBox"
import './PublicPages.css'
import Sempi from '../../files/Sempi.png'
import { analytics } from '../../config/fbConfig'
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

function Demo() {
    const sampleSentences = [
        'I thought you seemed timid and lacked gravitas in the meeting.',
        'You spoke quietly and quickly in the meeting.',
        'You lack a sense of urgency.',
        'The task requires urgency.',
        'You put me down.',
        'You said "that\'s a bad idea" without explaining further.',
        'You are so dismissive.',
        'Can you acknowledge other\'s points before making yours?'
    ]
    const [sampleIndex, setSampleIndex] = useState(0)
    const [showAbout, setShowAbout] = useState(false)

    const getSample = () => {
        if (sampleIndex < sampleSentences.length - 1)
            setSampleIndex(prevSampleIndex => prevSampleIndex + 1)
        else
            setSampleIndex(0)
    }

    const aboutToggle = () => {

        if (!showAbout)
            analytics.logEvent('MH_AboutViewed', { location_id: 'example' })
        setShowAbout(previousValue => !previousValue);



    }


    return (
        <>

            <Button className="text-left" variant="link" style={{ color: "#007399" }} href='https://www.diplomacydojo.com/'>Back to Home</Button>
            <Container className=" min-vh-100 d-flex flex-column ">

                <Row><Col className="mt-5">

                    <h1 ><strong>Message Helper Examples</strong></h1>
                    <Button variant="link" onClick={aboutToggle} className="ml-0 pl-0 link" style={{ border: 'none', color: showAbout ? '#00394d' : '#007399' }}>{showAbout ? 'Less Info' : 'More Info'}</Button>
                    <Collapse in={showAbout}>
                        <div className="mt-3">
                            <strong>Hi, I'm Sempi.</strong><img src={Sempi} width="50" alt={'Sempi'} className='ml-2 mb-2' />
                            <p>
                                I alert you to language that may sound judgmental or triggering. When people are triggered, they'll become less likely to hear you and respond in a way you'd like.
                                I'll help you stick to objective facts so you can convey your message more effectively. I'll also give suggestions for ways you can make your message more powerful and clear.
                            </p>




                            <strong>I'd love feedback too</strong>
                            <p>
                                I want you to know I'm not perfect, I'm still learning too.
                                Please help me improve by giving me feedback.
                                <FaThumbsDown display={"inline"} style={{ color: "#0099CC" }} className="ml-1" aria-controls="example-collapse-text" size="20px" />
                                <FaThumbsUp display={"inline"} style={{ color: "#0099CC" }} className="ml-1" size="20px" />
                            </p>


                        </div>
                    </Collapse>
                </Col></Row>



                <Row><Col className="mt-5">
                    <div> <h5> Get feedback for the sample sentence or <span onClick={getSample} className="ml-0 pl-0 link" style={{ border: 'none' }}>try another sentence</span> </h5>


                    </div>

                    <TextBox
                        textAreaClasses='pl-0'
                        clearFeedbackOnUserInput={true}
                        allowUserFeedback={false}
                        feedbackButtonClass='btn-medium mb-3'
                        prompt=''
                        blockUserInput={true}
                        initialTextValue={'SENTENCE: ' + sampleSentences[sampleIndex]}
                        textAreaHeight={3}
                        noVisibleTextBox={true}
                    />
                </Col></Row></Container>

        </>
    )
}

export default Demo
